import Button, { ButtonProps } from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React, { FC, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

type MenuItemType = { label: string; action: Function; icon: any };
type MenuDivider = { isDivider: boolean };

type PositionedMenuProps = {
  buttonProps: ButtonProps;
  items: (MenuItemType | MenuDivider)[];
  children?: React.ReactNode;
};
const PositionedMenu: FC<PositionedMenuProps> = ({ buttonProps, children, items }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = useRef(uuid());

  return (
    <div>
      <Button
        id={id.current}
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        {...buttonProps}
      >
        {children}
      </Button>
      <Menu
        id={id.current}
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{ minWidth: 200 }}
      >
        {items.map((item: any, key) =>
          item.hasOwnProperty("label") ? (
            <MenuItem
              style={{ minWidth: 150 }}
              key={item?.label + key}
              onClick={() => {
                (item as MenuItemType).action?.();
                handleClose();
              }}
            >
              <ListItemIcon>{(item as MenuItemType).icon || null}</ListItemIcon>
              <Typography>{(item as MenuItemType).label}</Typography>
            </MenuItem>
          ) : (
            <Divider />
          )
        )}
      </Menu>
    </div>
  );
};
export default PositionedMenu;
