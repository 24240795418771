import { Backdrop } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import React, { CSSProperties, MouseEvent, ReactNode, useEffect, useRef, useState } from "react";

interface PopoverProps {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
  x: number;
  y: number;
}

const Popover: React.FunctionComponent<PopoverProps> = ({ children, open, onClose, x, y }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverOverlayStyleRef = useRef<CSSProperties | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      const popoverWidth = 265;
      const viewportWidth = window.innerWidth;

      let leftPosition = x;
      if (x + popoverWidth > viewportWidth) {
        leftPosition = Math.max(viewportWidth - popoverWidth, 0);
      }

      const popoverOverlayStyle: CSSProperties = {
        position: "absolute",
        top: y + 35,
        left: leftPosition,
        zIndex: 10,
        backgroundColor: theme.palette.background.paper,
        minWidth: "210px",
        borderRadius: "10px",
        margin: "0 0 0 -10px",
      };

      popoverOverlayStyleRef.current = popoverOverlayStyle;

      setIsOpen(true);
    }
  }, [open, x, y]);

  const popoverContentStyle: CSSProperties = {};

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    onClose?.();
    setIsOpen(false);
  };
  if (!open) {
    return <></>;
  }
  return (
    <Backdrop
      open={isOpen}
      sx={{
        backgroundColor: "rgba(45, 49, 62, 0.5)",
        zIndex: 100,
      }}
      onClick={handleClick}
      draggable
      onDragStart={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {open && isOpen && popoverOverlayStyleRef.current && (
        <div style={popoverOverlayStyleRef.current}>
          <div style={popoverContentStyle} onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </div>
      )}
    </Backdrop>
  );
};

export default Popover;
