import { Tooltip, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IconEdit, IconPlus, IconTrashX, IconUnlink } from "@tabler/icons-react";
import { useState } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXConfirmationDialog } from "../AlertDialog/ConfirmationDialog";

type Props = {
  appProfiles: any;
  appId: any;
  onSaveClick?: any;
  onEditClick?: any;
  onDeleteClick?: any;
  onAddClick?: any;
};

const UserProfiles = (props: Props) => {
  const { appProfiles, appId, onEditClick, onDeleteClick, onAddClick } = props;
  const { getAuth, setLoadingApps, setCurrentProfileId, setIsSwitchingMode } = useBXContext();
  const [isTrash, setIsTrash] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<any>();
  const { palette } = useTheme();

  return (
    <>
      <BXConfirmationDialog
        open={!!isTrash}
        title={"Are you sure you want to delete this profile?"}
        iconButton
        buttonProps={{ color: "error", children: <IconTrashX /> }}
        onConfirm={() => {
          onDeleteClick(selectedProfile);
          setSelectedProfile(null);
          setIsTrash(false);
        }}
        onCancel={() => {
          setSelectedProfile(null);
          setIsTrash(false);
        }}
      />
      <Grid item xs={12} paddingInlineStart={2} pt={1}>
        <Typography fontSize={20} fontWeight={500}>
          User Profiles
        </Typography>
      </Grid>
      <Grid container spacing={2} pt={4} paddingInlineStart={2}>
        {appProfiles?.[appId]?.map((profile: any) => {
          const { user } = getAuth(appId, profile?.id, profile?.signInWithGoogle) || {};

          return (
            <Grid item marginInlineStart={2} style={{ cursor: "pointer" }} mb={2}>
              <Grid
                container
                width={300}
                minHeight={200}
                paddingY={1}
                paddingX={1}
                border={`1px solid ${palette.primary.main}`}
                borderRadius={5}
                spacing={1.5}
                onClick={() => {
                  setLoadingApps(true);
                  localStorage.setItem(appId + "-profileId", profile?.id);
                  setCurrentProfileId(profile?.id);
                  setIsSwitchingMode(false);
                  setTimeout(() => {
                    setLoadingApps(false);
                  }, 0);
                }}
              >
                <Grid item paddingX={2} container alignItems='center' justifyContent={"center"} xs={12}>
                  <Avatar src={user?.profilePhoto} sx={{ width: 50, height: 50 }} />
                </Grid>
                <Grid xs={12} paddingX={2} container alignItems='center' justifyContent={"center"} item>
                  <Typography color='textPrimary' noWrap fontWeight={600} width={200} textAlign={"center"}>
                    {profile?.profileName || user?.name}
                  </Typography>
                </Grid>
                <Grid xs={12} paddingX={2} container alignItems='center' justifyContent={"center"} item>
                  <Typography color='textPrimary' noWrap fontWeight={600} width={200} textAlign={"center"}>
                    {user?.handle}
                  </Typography>
                </Grid>
                <Grid xs={12} paddingX={2} container alignItems='center' justifyContent={"center"} item minHeight={"60px"}>
                  {!profile?.signInWithGoogle && (
                    <>
                      <Tooltip title='Edit'>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            onEditClick(profile);
                          }}
                          sx={{ marginInlineEnd: 1 }}
                        >
                          <IconEdit color={"currentColor"} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title='Unlink'>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            setSelectedProfile(profile);
                            setIsTrash(true);
                          }}
                        >
                          <IconUnlink />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Grid item display='flex' style={{ cursor: "pointer" }}>
          <Grid
            container
            padding={1}
            justifyContent={"center"}
            alignItems='center'
            alignSelf={"center"}
            onClick={() => {
              onAddClick();
            }}
            border={`1px solid ${palette.primary.main}`}
            borderRadius={5}
          >
            <IconPlus />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfiles;
