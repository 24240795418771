import { Autocomplete, CircularProgress, FormControl, InputProps, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import axios from "axios";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { useQuery } from "react-query";
import { BXIcon } from "src/components/BXUI/Icon";

type BXIconPickerProps = {
  label?: string;
  name?: any;
  defaultValue?: any;
  watchedValue?: any;
  id?: string;
  error?: any;
  onChange?: any;
  showCaption?: boolean;
  previewIcon?: boolean;
  control?: Control<any>;
  inputLabelProps?: React.HTMLProps<HTMLLabelElement>;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  size?: string;
  setValue?: UseFormSetValue<any>;
  objName?: {};
  handleSetChangeIconProp?: any;
} & TextFieldProps;

const fetchMediaOptions = async (pageParam: string | undefined, type: string | undefined, value: string | null) => {
  const accessToken = localStorage.getItem("accessToken");
  const deviceToken = localStorage.getItem("accessToken-device");
  const token = accessToken || deviceToken;
  return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/media", {
    params: {
      cursor: pageParam,
      type: type,
      keyword: value?.split("://")[1],
      strategy: "auto_complete",
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};

const debouncedFetchMediaOptions = _.debounce(
  async (pageParam: string | undefined, type: string | undefined, value: string | null, callback: (data: any) => void) => {
    const data = await fetchMediaOptions(pageParam, type, value);
    callback(data);
  },
  500
);

export const BXIconPicker: FC<BXIconPickerProps> = ({
  name,
  control: propControl,
  label,
  id,
  defaultValue,
  watchedValue,
  onChange,
  error,
  showCaption = true,
  previewIcon = true,
  inputLabelProps,
  inputProps,
  size,
  setValue,
  objName,
  handleSetChangeIconProp,
  ...rest
}) => {
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [type, setType] = useState<string | undefined>(undefined);
  const value = watchedValue?.icon ?? defaultValue ?? null;
  const [inputValue, setInputValue] = useState(value);
  const [iconConfig, setIconConfig] = useState<{ icon?: string; url?: string; visibility?: string }>({
    icon: watchedValue?.icon ?? defaultValue ?? null,
    url: watchedValue?.url || "",
    visibility: watchedValue?.visibility || "",
  });
  useEffect(() => {
    if (value && typeof value === "string" && !value.startsWith("http://") && !value.startsWith("https://")) {
      const typeValue = value.split("://")[0];
      setType(typeValue);
    }
  }, [value]);

  const queryKey = ["media-options", value];

  const { data: options, isFetching } = useQuery(
    queryKey,
    ({ pageParam }) =>
      new Promise<any>(resolve => {
        debouncedFetchMediaOptions(pageParam, type, value, data => {
          resolve(data);
        });
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!value?.split("://")[1] && autoCompleteOpen,
    }
  );

  const filteredOptions = (options?.data ?? []).filter(option => {
    const searchText = inputValue?.split("://")[1];
    return option?.name?.toLowerCase().includes(searchText?.toLowerCase());
  });

  const loading = autoCompleteOpen && isFetching && filteredOptions.length === 0;

  return (
    <Grid container spacing={1} flexWrap={"nowrap"}>
      <Grid item xs={previewIcon ? 10 : 12}>
        <>
          <FormControl fullWidth>
            <Autocomplete
              value={value || ""}
              inputValue={value || ""}
              options={filteredOptions}
              open={autoCompleteOpen}
              renderOption={(props, option) => {
                const type = value?.split("://")[0];
                return (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {type && <span>{type}://</span>}
                    {option?.name}
                  </li>
                );
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={option => {
                if (typeof option === "object" && option?.name) {
                  return type ? `${type}://${option?.name}` : option?.name;
                } else {
                  return option;
                }
              }}
              loading={loading}
              disableClearable
              onOpen={(event: any) => {
                if (value?.split("://")[1] || event?.target?.value?.split("://")[1]) {
                  setAutoCompleteOpen(true);
                }
              }}
              onClose={() => {
                setAutoCompleteOpen(false);
              }}
              onInputChange={(event, newValue) => {
                setInputValue(newValue);
                if (onChange) {
                  onChange(newValue);
                }
                setAutoCompleteOpen(!!value?.split("://")[1] || !!newValue?.split("://")[1]);
              }}
              onChange={(event, newValue) => {
                const type = value?.split("://")[0] || "icons";
                const fullValue = newValue?.name ? `${type}://${newValue.name}` : newValue;

                setIconConfig({
                  icon: fullValue,
                  url: newValue?.url || "",
                  visibility: newValue?.visibility || "",
                });

                if (objName && newValue && handleSetChangeIconProp) {
                  handleSetChangeIconProp(`${objName}`)({
                    icon: fullValue || "",
                    url: newValue.url || "",
                    visibility: newValue.visibility || "",
                  });
                }
                if (objName && newValue && setValue) {
                  setValue(`${objName}.url`, newValue.url || "");
                  setValue(`${objName}.visibility`, newValue.visibility || "");
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    style: {
                      ...(inputLabelProps?.style || {}),
                    },
                  }}
                  size={size || "medium"}
                  name={name || ""}
                  fullWidth
                  variant='outlined'
                  label={label || "Icon"}
                  error={error}
                  onChange={e => {
                    const inputValue = e.target.value;

                    if (inputValue === "") {
                      setIconConfig({ icon: "", url: "", visibility: "" });
                    } else {
                      setIconConfig(prev => ({ ...prev, icon: inputValue }));
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      ...(inputProps?.style || {}),
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    ...(inputProps?.InputProps as InputProps),
                  }}
                  onBlur={() => {
                    const currentIcon = inputValue || value;

                    if (!currentIcon || currentIcon === "") {
                      setIconConfig({ icon: "", url: "", visibility: "" });
                      return;
                    }

                    const typeValue = currentIcon.split("://")[0];
                    const nameValue = currentIcon.split("://")[1];

                    if (typeValue && nameValue) {
                      const matchingOption = filteredOptions.find(option => {
                        const optionName = `${typeValue}://${option.name}`;
                        return optionName === currentIcon;
                      });

                      if (matchingOption) {
                        setIconConfig(prev => ({
                          ...prev,
                          icon: currentIcon,
                          url: matchingOption.url || "",
                          visibility: matchingOption.visibility || "",
                        }));

                        if (objName && handleSetChangeIconProp) {
                          handleSetChangeIconProp(`${objName}`)({
                            icon: currentIcon,
                            url: matchingOption.url || "",
                            visibility: matchingOption.visibility || "",
                          });
                        }

                        if (objName && setValue) {
                          setValue(`${objName}.url`, matchingOption.url || "");
                          setValue(`${objName}.visibility`, matchingOption.visibility || "");
                        }
                      } else {
                        setIconConfig(prev => ({
                          ...prev,
                          icon: currentIcon,
                          url: "",
                          visibility: "",
                        }));

                        if (objName && handleSetChangeIconProp) {
                          handleSetChangeIconProp(`${objName}`)({
                            icon: currentIcon,
                            url: "",
                            visibility: "",
                          });
                        }
                      }
                    }
                  }}
                />
              )}
            />
            {showCaption && <Typography variant={"caption"}>Enter logo URL or specify the media type and name to view as image</Typography>}
          </FormControl>
        </>
      </Grid>
      {previewIcon && (
        <Grid item alignItems={"center"} mt={1}>
          <BXIcon icon={iconConfig.icon} url={iconConfig.url} visibility={iconConfig.visibility} />
        </Grid>
      )}
    </Grid>
  );
};
