import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { IconLine, IconTrashX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import BXModal from "src/components/BXUI/Modal";
import { v4 as uuidv4 } from "uuid";

const MapValuesComponent = ({
  label,
  value,
  handleChangeProp,
  inputLabelProps,
  inputProps,
  enableMapValues,
  valueString,
  mapValuesObject,
  defaultValue = undefined,
  isStatusMessage = false,
}) => {
  interface MapValue {
    id: any;
    key: any;
    value: any;
  }
  const [errors, setErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [localMapValues, setLocalMapValues] = useState<MapValue[]>([]);
  const [condition, setCondition] = useState(mapValuesObject?.condition || "equal");

  useEffect(() => {
    if (!isStatusMessage && enableMapValues) {
      const values =
        mapValuesObject?.values && Object.keys(mapValuesObject?.values).length > 0
          ? mapValuesObject?.values
          : { default: defaultValue || "" };

      setLocalMapValues(
        Object.entries(values || {}).map(([key, value]) => ({
          id: uuidv4(),
          key,
          value,
        }))
      );
      // if (!isOpen && !isStatusMessage) {
      //   value = defaultValue;
      //   const mappedValuesObject = {
      //     condition,
      //     values: Object.fromEntries(localMapValues.map(({ key, value }) => [key, value])),
      //   };

      //   handleChangeProp(valueString)({
      //     target: {
      //       value: {
      //         value,
      //         mapValuesObject: mappedValuesObject,
      //       },
      //     },
      //   });
      // }
    }
  }, [isOpen]);

  useEffect(() => {
    if (isStatusMessage) {
      const values =
        mapValuesObject?.values && Object.keys(mapValuesObject?.values).length > 0 ? mapValuesObject.values : { default: value || "" };

      setLocalMapValues(
        Object.entries(values || {}).map(([key, value]) => ({
          id: uuidv4(),
          key,
          value,
        }))
      );
    }
  }, [mapValuesObject]);

  const handleConditionChange = (newCondition: string) => {
    setCondition(newCondition);
  };
  const updateMapValuesObject = updatedMapValues => {
    setLocalMapValues(updatedMapValues);
    const mappedValuesObject = { condition, values: Object.fromEntries(updatedMapValues.map(({ key, value }) => [key, value])) };

    if (!isStatusMessage) {
      handleChangeProp(valueString)({ target: { value: { value, mapValuesObject: mappedValuesObject } } });
    } else {
      handleChangeProp("mapValuesObject")({ target: { value: mappedValuesObject } });
    }
  };

  const handleAddRow = () => {
    const newRow = { id: uuidv4(), key: "", value: "" };
    const updatedMapValues = [...localMapValues, newRow];
    updateMapValuesObject(updatedMapValues);
  };

  const handleRemoveRow = id => {
    const updatedMapValues = localMapValues.filter(row => row.id !== id);
    updateMapValuesObject(updatedMapValues);
  };

  const handleKeyChange = (id, newKey) => {
    const updatedMapValues = localMapValues.map(row => (row.id === id ? { ...row, key: newKey } : row));
    setErrors({});
    updateMapValuesObject(updatedMapValues);
  };

  const handleValueChange = (id, newValue) => {
    const updatedMapValues = localMapValues.map(row => (row.id === id ? { ...row, value: newValue } : row));
    updateMapValuesObject(updatedMapValues);
  };

  const handleSave = handleClose => {
    const keyCounts = localMapValues.reduce((acc, row) => {
      acc[row.key] = (acc[row.key] || 0) + 1;
      return acc;
    }, {});

    const newErrors = {};
    localMapValues.forEach(row => {
      if (keyCounts[row.key] > 1) {
        newErrors[row.id] = "Duplicate key";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      updateMapValuesObject(localMapValues);
      handleClose();
    }
  };

  return (
    <TextField
      size='small'
      fullWidth
      label={label}
      value={value || ""}
      onChange={
        enableMapValues && !isStatusMessage
          ? handleChangeProp(valueString, { mapValues: true, mapValuesObject: mapValuesObject })
          : handleChangeProp(valueString)
      }
      InputLabelProps={{ style: { fontSize: "12px", ...inputLabelProps?.style }, ...inputLabelProps }}
      InputProps={{
        style: { fontSize: "12px", ...inputProps?.style },
        endAdornment: enableMapValues ? (
          <InputAdornment position='end'>
            <IconButton onClick={() => setIsOpen(true)}>
              {" "}
              {/* Open the modal */}
              <IconLine size={12} />
            </IconButton>
            <BXModal
              open={isOpen}
              onClose={() => setIsOpen(false)}
              label={"Map values"}
              showIconHeader={false}
              icon={<IconLine size={12} />}
              maxWidth='md'
              title={`Map values ${label}`}
              withoutLabel
            >
              {handleClose => (
                <>
                  <FormControl fullWidth size='small' sx={{ mb: 2 }}>
                    <InputLabel>Condition</InputLabel>
                    <Select value={condition} onChange={e => handleConditionChange(e.target.value as string)}>
                      <MenuItem value='equal'>Equal</MenuItem>
                      <MenuItem value='startWith'>Start With</MenuItem>
                    </Select>
                  </FormControl>

                  {localMapValues.map(({ id, key, value }) => (
                    <Grid container spacing={2} mb={2} key={id}>
                      <Grid item xs>
                        <TextField
                          size='small'
                          fullWidth
                          label={"Key"}
                          value={key}
                          disabled={key === "default" ? true : false}
                          onChange={e => handleKeyChange(id, e.target.value)}
                          error={!!errors[id]}
                          helperText={errors[id]}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          size='small'
                          fullWidth
                          label={"Value"}
                          value={value}
                          onChange={e => handleValueChange(id, e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        {key !== "default" && (
                          <IconButton onClick={() => handleRemoveRow(id)}>
                            <IconTrashX color='red' size={18} />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <Grid container justifyContent='center' alignItems='center' spacing={2}>
                    <Grid item>
                      <Button variant='outlined' onClick={handleAddRow}>
                        Add row
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant='contained' color='primary' onClick={() => handleSave(handleClose)}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </BXModal>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default MapValuesComponent;
