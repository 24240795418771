// src/components/AppController.tsx
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppState } from "src/features/appState/hooks";
import { initializeAppField, registerValidation, setAppError } from "../../../features/appState/appStateSlice";
import { useAppDirty, useAppError, useAppValue } from "../../../features/appState/selectors";

interface AppControllerProps {
  name: string;
  defaultValue?: any;
  validate?: { [key: string]: (value: any) => true | string };
  isDisabledDirtyField?: boolean;
  disabled?: boolean;
  render: (props: {
    value: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error: string;
    isDirty: boolean;
  }) => JSX.Element;
}

const AppController: React.FC<AppControllerProps> = ({ name, defaultValue = null, validate, isDisabledDirtyField, disabled, render }) => {
  const stateName = `${name}.state`;
  const dispatch = useDispatch();
  const value = useAppValue(stateName) || "";
  const error = useAppError(stateName);
  const isDirty = useAppDirty(stateName);
  const { setValue } = useAppState();

  // Initialize the field on mount
  useEffect(() => {
    if (!disabled) {
      dispatch(initializeAppField({ name: stateName, value: defaultValue }));
    }
  }, [dispatch, stateName, JSON.stringify(defaultValue)]);

  useEffect(() => {
    if (validate && !disabled) {
      registerValidation(stateName, validate);
    }
  }, []);

  const handleChange = (e: any) => {
    if (disabled) return;
    let newValue;

    // Check if `e` is an event or a direct value
    if (e && e.target && typeof e.target.value !== "undefined") {
      newValue = e.target.value; // It's an event, get the value from the event
    } else {
      newValue = e; // It's a direct value, use it as it is
    }

    // Update the value in the state
    setValue(`${stateName}`, newValue, isDisabledDirtyField);

    // Validate the input field if a validation function is provided
    if (validate) {
      let validationError = "";

      for (const ruleKey in validate) {
        if (validate.hasOwnProperty(ruleKey)) {
          const rule = validate[ruleKey];
          const result = rule(newValue);
          if (result !== true) {
            validationError = result as string; // Get the error message from the validation
            break; // Stop on the first validation error
          }
        }
      }
      dispatch(setAppError({ name: stateName, error: validationError }));
    }
  };

  return render({ value, onChange: handleChange, error, isDirty });
};

export { AppController };
