import React from "react";

// material-ui
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

// project imports
import { useTheme } from "@mui/material";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXIcon } from "src/components/BXUI/Icon";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

// style constant
const useStyles = makeStyles((theme: any) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
    fontWeight: "600",
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export interface NavGroupProps {
  item: {
    id?: string;
    type?: string;
    icon?: string;
    iconConfig?: {
      icon?: string;
      url?: string;
      visibility?: string;
    };
    appName?: string;
    children?: NavGroupProps["item"][];
    title?: React.ReactNode | string;
    caption?: React.ReactNode | string;
    color?: "primary" | "secondary" | "default" | undefined;
    url?: string;
    canViewCollections?: boolean;
  };
}

const NavGroup = ({ item }: NavGroupProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { checkPermissions, isAdministrationMode } = useBXContext();
  if (
    item?.url &&
    !isAdministrationMode &&
    !checkPermissions([{ path: [item.appName, item.title].join(".") as string, action: "VIEW" }], Boolean(item.canViewCollections))
  )
    return null;
  // menu list collapse & items
  const items = item.children
    ?.map(menu => {
      switch (menu.type) {
        case "collapse":
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        case "item":
          if (
            menu?.url &&
            !isAdministrationMode &&
            !checkPermissions(
              [{ path: [item.appName, item.title, menu.title].join(".") as string, action: "VIEW" }],
              item.canViewCollections
            )
          )
            return null;
          return <NavItem key={menu.id} item={menu} level={1} />;
        default:
          return <></>;
      }
    })
    .filter(Boolean);

  return (
    <>
      <List
        subheader={
          item.title && (
            <Grid container alignItems='center'>
              <Grid item height={25}>
                <BXIcon
                  icon={
                    (item?.iconConfig?.visibility === "PUBLIC" && item?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                      ? item?.iconConfig?.url
                      : item?.iconConfig?.icon) || item?.icon
                  }
                  color={theme?.palette.text.primary}
                  height={25}
                  width={25}
                  fallBack={
                    (item?.iconConfig?.visibility === "PUBLIC" && item?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                      ? item?.iconConfig?.url
                      : item?.iconConfig?.icon) || item?.icon
                  }
                />
              </Grid>
              <Grid item>
                <Typography variant='caption' className={classes.menuCaption} display='block' gutterBottom>
                  {item.title}
                  {item.caption && (
                    <Typography variant='caption' className={classes.subMenuCaption} display='block' gutterBottom>
                      {item.caption}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      >
        {items}
      </List>
    </>
  );
};

export default NavGroup;
