import { MenuItem, Select } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useBXContext } from "src/BXEngine/BXContext";
import axiosServices from "src/utils/axios";

const LanguagesSection = () => {
  const theme = useTheme();
  const { currentApp, updateRTL, multiLingual, setMultiLingual } = useBXContext();

  const handleLanguageChange = event => {
    const selectedLanguage = event.target.value;
    const dataObj = {
      ...multiLingual,
      selectedLanguage: selectedLanguage,
    };

    try {
      if (currentApp?.id) {
        axiosServices.put(`/admin/multilingual/app/${currentApp?.id}`, dataObj).then(({ data }) => {
          if (setMultiLingual) {
            setMultiLingual(dataObj);
            updateRTL(dataObj);
          }
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const renderLanguageOptions = () => {
    return multiLingual?.languages?.map(language => (
      <MenuItem key={language.id} value={language.name}>
        {language.name.charAt(0).toUpperCase() + language.name.slice(1)}
      </MenuItem>
    ));
  };

  return (
    <>
      {multiLingual && multiLingual?.languages?.length > 1 ? (
        <Select
          size='small'
          value={multiLingual?.selectedLanguage || ""}
          color='primary'
          onChange={handleLanguageChange}
          displayEmpty
          fullWidth
          sx={{
            width: 120,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          {renderLanguageOptions()}
        </Select>
      ) : (
        <></>
      )}
    </>
  );
};

export default LanguagesSection;
