import { Delete, DragIndicator } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { v4 as uuid } from "uuid";
import { TranslationInput } from "../../../AppBuilder/components/TranslationInput";
interface ViewBuilderReorderItemProps {
  label: any;
  index: number;
  moveLabel: (oldIndex: number, newIndex: number) => void;
  activeComponent: any;
  handleChangeTabLabel: (e: any, index: number) => void;
  handleSetActive: (index: number) => void;
  handleDeleteTab: (index: number) => void;
  multiLingual: any;
  setMultiLingual: any;
  appId: any;
  item: any;
  onChangeProp: any;
}
export default function TabsComponentEditor({
  activeComponent,
  onChangeProp,
  setView,
  multiLingual,
  setMultiLingual,
  appId,
  item,
}: {
  activeComponent: any;
  onChangeProp: any;
  setView: any;
  multiLingual: any;
  setMultiLingual: any;
  appId: any;
  item: any;
}) {
  const activeComponentId = activeComponent.config?.parent?.id;
  // handle add new tab for customTabs component


  const updateComponentRecursively = (components, parentId, updateFunction) => {
    return components.map(el => {
      if (el.id === parentId) {
        return updateFunction(el);
      } else if (el.children) {
        return {
          ...el,
          children: updateComponentRecursively(el.children, parentId, updateFunction),
        };
      }
      return el;
    });
  };

  const handleAddNewTab = () => {
    setView((prev: any) => ({
      ...prev,
      dataSource: {
        ...prev.dataSource,
        formBuilder: updateComponentRecursively(prev.dataSource.formBuilder, activeComponentId, el => ({
          
          ...el,
          children: [
            {
              ...el.children[0],
              props: {
                ...el.children[0].props,
                tabs: [...el.children[0].props.tabs, { label: "" }],
              },
            },
            ...el.children.slice(1),
            {
              id: uuid(),
              type: "CustomContainer",
              props: {
                sx: {
                  width: "100%",
                  height: "100%",
                  minHeight: "100px",
                },
                id: `Component-${uuid()}-0`,
                key: `Component-${uuid()}-0`,
              },
              config: {
                tabValue: el.children[0].props.tabs.length,
                defaultWidth: "0%",
                defaultHeight: "100%",
                minWidth: 0,
                minHeight: 0,
                disableDrag: true,
                selectParent: true,
                disableResizeWidth: true,
                disableResizeHeight: true,
                isNotResizable: true,
                fixedWidth: false,
                isPercentageHeight: false,
                sideMenuSx: {
                  border: "1px dashed #57585c",
                },
                parent: {
                  id: activeComponentId,
                  type: "CustomContainer",
                  config: {
                    parent: {
                      id: null,
                      type: null,
                      config: null,
                    },
                  },
                },
                widthPx: {
                  xs: "100%",
                  xl: "100%",
                },
                heightPx: {
                  xs: "100%",
                  xl: "100%",
                },
                widthPercentage: {
                  xs: "100%",
                  xl: "100%",
                },
                hasCustom: false,
                placeholderConfig: {
                  title: "Container",
                  image:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABKpJREFUeJztmktsVGUUx3/ffQwlaGGQxBgTF7jRgAmCiLDQBa/yMLpwJdCm8TERSacQRigjcA19UGso05BqE0wzpTWxG2MsL5EEUUAlICsXauLGhSZqAcPD3jv3uOgQy9jOzJ0HTMP320zm++53zvmf+R7n3jug0Wg0Go1Go9FoNJp7DzVRR0si6QC7b32PR+tUul3GXjcp2oX2eGPd9vF0GuM1ptmdpW9yodgWeExLIimZ2Z2M5NKRbQZo7gXMux3ARAwODppz5sxRp06dKusyzHYKCPy3q95JHGcwZIdvnAZmusO/POY4jl+orVw6KnIPCIWvNwKLAK8Y8flQcQlo2f/Rg4KKAyjU1nL7q7gEYHjvAtUCQzuitUfK7a6k67uteyDse95hhOvxaN2yoOObu3qfUWKcBVwff+7OaP1Pt/pa9ydPisFUw7LWNG1cN1yqmEs2A9q6B8K+651AWIxQFXS84ziGErMLUAidY8UDCExBWOy73om27oFwqeKeMAFBKsG9PT3Tfdc7BiwALimLF4IGEpoxux5kIcjvrmG2Zva74q8FdR5YkHK9k86+gzPzsVv2SnBvT8/01M2q48DTwCVlsmzHpro/g9hwuvqrRckeAIURcxrWX/3fNZvrL7t+agWo8wqetC3rRL5JyEZRCXC6+qvT4hdRoHgAW3wHeAjFuaaGDf0T+huTBETNty2r6OVQcAI6Ovqm2ZL6jCLF7+nsexxkE+ALNCqlsi67zCT4rnfE6eqvLlBGYadAR0fftJGQHAGepQjxAM2JvqMKqQEOxqN1r+U7zunsnWEb5ucgC1GcczFrxls6uQg8A9LiD1MC8a2dh15Mi79qW/7OIGNvnwkstiV1tL39w/uDxhD4FEj/8s+h5KKbcpcWKt7p7a0Sw38PABHnrTfrfwtsY3P9ZTc1UoOSi8ASr8oayrymYp8HhK4YW4BHQX6YNfWfA3crjsAJCI2o1cCXiJpvm/bJ1gPJB4LaaN6XfFgUTQCIsSUSibhBbUB6HzBDxxA1Hzhr3fTWBrUROAGxWO210IhaA5wG5kmKL4ImwTDoAO4DPok31h4PGgOMswkqc9W2ba/8HdROwfcChZ4EzYlDSxT+18CIj/9EZsmbD7eJh29cZa4s5ASAIvaAWKz2mqvM54FvyXMmjNb7fgJQIqqjaPFKLhq2tbpQ8VDkvYDTsP6qWXVzJXkmwQrPfh3FU8CvU1z2Bg02U7zrectz3RmW/RTYHolcSSfhO7Ikoa17IKwYrfdBxWKx2mtB/IwVL/C963nLnS2v/lVs/CU5BrdHIlcM26oBLgDzxOPTzGt813sHmAXy1Y6GDR8H9WErYyi95i+YtrW0FOKhhHVA08Z1w4ZtLUc4g5LrY/taEn1zgTeAFL405Kr3x0MpbiCcMWwr57QPZLdUhrLRnEgeV7AC1PvxaO3GO+EzX8peCbZ2JV8aFc+w61q7yu0vKCV5IpQNEdU++skuZ+vLfxRrLyi5dFjlDkDgR+Bn7/IjH5TbVyGUPQFvR2tXldtHMVTeewGN5o6S8+3wLSrqPz9FtGcy8R6gGPdPRZMU524HoNFoNBqNRqPRaCqLfwEZ5lFdrkcdWwAAAABJRU5ErkJggg==",
                },
              },
              index: el.children[0].props.tabs.length + 1,

              level: null,
              hasCustom: false,
              left: {
                xs: 0,
                xl: 0,
              },
              top: {
                xs: 0,
                xl: 0,
              },
              leftPercentage: { xs: "0%", xl: "0%" },
            },
          ],
        })),
      },
    }));
  };

  const handleChangeTabLabel = (e: any, index: number) => {
    const newItem = {
      ...activeComponent,
      props: {
        ...activeComponent?.props,
        tabs: activeComponent?.props?.tabs.map((tab: { label: string }, i: number) => (i === index ? { label: e.target.value } : tab)),
      },
    };
    onChangeProp?.(newItem);
  };

  const handleDeleteTab = index => {
    setView(prev => {
      const newItem = updateComponentRecursively(prev.dataSource.formBuilder, activeComponentId, el => {
        if (el.id === activeComponentId) {
          let newActiveIndex = el.children[0].props.value;

          if (newActiveIndex === index) {
            newActiveIndex = index > 0 ? index - 1 : 0;
          } else if (newActiveIndex > index) {
            newActiveIndex--;
          }

          const updatedTabs = el.children[0].props.tabs.filter((_tab, i) => i !== index);

          const reorderedChildren = reorderElement(el.children.slice(1), newActiveIndex);

          const updatedChildren = [
            {
              ...el.children[0],
              props: {
                ...el.children[0].props,
                tabs: updatedTabs,
                value: newActiveIndex,
              },
            },
            ...reorderedChildren
              .filter(child => child.config.tabValue !== index)
              .map(child => ({
                ...child,
                config: {
                  ...child.config,
                  tabValue: child.config.tabValue > index ? child.config.tabValue - 1 : child.config.tabValue,
                },
              })),
          ];

          return {
            ...el,
            children: updatedChildren,
          };
        }
        return el;
      });

      return {
        ...prev,
        dataSource: {
          ...prev.dataSource,
          formBuilder: newItem,
        },
      };
    });
  };

  const reorderElement = (elements: any, index: number) => {
    const targetedElement = elements.find((el: any) => el.config.tabValue === index);
    const restElement = elements
      .filter((el: any) => el.config.tabValue !== index)
      .map((el: any) => ({
        ...el,
        config: { ...el.config, defaultWidth: "0%", defaultHeight: "0%", hideElement: true },
        props: { ...el.props, sx: { ...el.props.sx, display: "none !important" } },
      }));
    return [
      ...restElement,
      {
        ...targetedElement,
        config: { ...targetedElement.config, defaultWidth: "100%", defaultHeight: "100%", hideElement: false },
        props: { ...targetedElement.props, sx: { ...targetedElement.props.sx, display: "unset" } },
      },
    ];
  };

  const handleSetActive = (index: number) => {
    setView((prev: any) => {
      const newItem = updateComponentRecursively(prev.dataSource.formBuilder, activeComponentId, el => {
        if (el.id === activeComponentId) {
          return {
            ...el,
            children: [
              { ...el.children[0], props: { ...el.children[0].props, value: index } },
              ...reorderElement(el.children.slice(1), index),
            ],
          };
        }
        return el;
      });

      return {
        ...prev,
        dataSource: {
          ...prev.dataSource,
          formBuilder: newItem,
        },
      };
    });
  };

  const moveLabel = (oldIndex: number, newIndex: number) => {
    const updatedLabels = [...activeComponent?.props?.tabs];
    const [removed] = updatedLabels.splice(oldIndex, 1);
    updatedLabels.splice(newIndex, 0, removed);

    setView((prev: any) => {
      const newItem = updateComponentRecursively(prev.dataSource.formBuilder, activeComponentId, el => {
        if (el.id === activeComponentId) {
          return {
            ...el,
            children: [
              {
                ...el.children[0],
                props: {
                  ...el.children[0].props,
                  value: el.children[0].props?.value === oldIndex ? newIndex : el.children[0].props?.value,
                  tabs: updatedLabels,
                },
              },
              ...(el.children.slice(1) || []).map((item: any) =>
                item?.config?.tabValue === oldIndex || item?.config?.tabValue === newIndex
                  ? {
                    ...item,
                    index: item?.config?.tabValue === oldIndex ? newIndex + 1 : oldIndex + 1,
                    config: {
                      ...item?.config,
                      tabValue: item?.config?.tabValue === oldIndex ? newIndex : oldIndex,
                    },
                  }
                  : item
              ),
            ],
          };
        }
        return el;
      });

      return {
        ...prev,
        dataSource: {
          ...prev.dataSource,
          formBuilder: newItem,
        },
      };
    });
  };

  return (
    <Grid container spacing={2} alignItems='center' marginTop={1}>
      <Grid item xs={12} justifyContent={"center"}>
        <Box display='flex' alignItems='center' pb={2}>
          <Grid container spacing={2}>
            {activeComponent?.props?.tabs?.map((tab: { label: string }, index: number) => (
              <ViewBuilderReorderItem
                key={index}
                label={tab.label}
                index={index}
                moveLabel={moveLabel}
                activeComponent={activeComponent}
                handleChangeTabLabel={handleChangeTabLabel}
                handleSetActive={handleSetActive}
                handleDeleteTab={handleDeleteTab}
                multiLingual={multiLingual}
                setMultiLingual={setMultiLingual}
                appId={appId}
                item={activeComponent}
                onChangeProp={onChangeProp}
              />
            ))}
          </Grid>
        </Box>
        <Button sx={{ fontSize: "12px" }} onClick={handleAddNewTab}>
          Add new tab
        </Button>
      </Grid>
    </Grid>
  );
}

const ViewBuilderReorderItem: React.FC<ViewBuilderReorderItemProps> = ({
  label,
  index,
  moveLabel,
  activeComponent,
  handleChangeTabLabel,
  handleSetActive,
  handleDeleteTab,
  multiLingual,
  setMultiLingual,
  appId,
  item,
  onChangeProp,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "LABEL",
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop({
    accept: "LABEL",
    hover: (item: any) => {
      if (item.index === index) {
        return;
      }
      moveLabel(item.index, index);
      item.index = index;
    },
  });

  const handleMouseEnter = () => {
    document.body.style.cursor = "grab";
  };

  const handleMouseLeave = () => {
    document.body.style.cursor = "auto";
  };
  const [selectedRow, setSelectedRow] = useState(-1);
  const isFirstTab = index === 0;
  useEffect(() => {
    const translations = multiLingual?.translations;
    if (selectedRow >= 0 && translations && selectedRow < translations.length) {
      const selectedTranslation = translations[selectedRow];
      const newLabel = `{i18n.${selectedTranslation.key}}`;
      const newItem = {
        ...activeComponent,
        props: {
          ...activeComponent?.props,
          tabs: activeComponent?.props?.tabs?.map((tab, tabIndex) => (tabIndex === index ? { label: newLabel } : tab)),
        },
      };

      onChangeProp?.(newItem);
    }
  }, [selectedRow]);

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      item
      xs={12}
      ref={node => drag(drop(node))}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: isDragging ? "grabbing" : "grab" }}
      px={1}
      py={2}
    >
      <Grid item xs={1}>
        <DragIndicator cursor={isDragging ? "grabbing" : "grab"} />
      </Grid>
      <Grid item xs={6}>
        <TranslationInput
          label={"label"}
          fullWidth
          value={label || ""}
          onChange={e => handleChangeTabLabel(e, index)}
          multiLingual={multiLingual}
          setMultiLingual={setMultiLingual}
          appId={appId}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      </Grid>
      <Grid container justifyContent='flex-end' item xs={2}>
        <Switch size='small' checked={index === activeComponent?.props?.value} onChange={() => handleSetActive(index)} />
      </Grid>
      <Grid container justifyContent='flex-end' item xs={1}>
        <IconButton onClick={() => handleDeleteTab(index)} disabled={isFirstTab}>
          <Delete style={{ fontSize: "14px" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};
