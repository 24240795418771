import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogActions, FormHelperText, Grid, IconButton, Typography } from "@mui/material";
import { IconTrashX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, useEffect } from "react";
import { FieldValues, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { v4 as uuid } from "uuid";
import * as yup from "yup";

type CreateEnvFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  row?: any;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateEnvForm: FC<CreateEnvFormProps> = ({ onCancel = _.noop, onSave = _.noop, row, setIsDirty }) => {
  const schema = yup
    .object({
      name: yup.string().required(),
      config: yup.array().of(
        yup.object().shape({
          key: yup.string().required("key is a required field"),
          value: yup.string().required("value is a required field"),
          isPrivate: yup.boolean(),
        })
      ),
    })
    .required();

  const defaultValues = {
    name: row?.name,
    config: [...(row?.config || [])],
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
  } = useForm<FieldValues>({
    defaultValues,
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: `config`,
  });

  useCallbackPrompt(isDirty);
  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  return (
    <Box flex={1}>
      <Grid container spacing={3}>
        <Grid item xs={6} my={2}>
          <BXInput name={"name"} control={control} label='Name' variant='outlined' error={errors.name} />
        </Grid>

        <Grid item xs={12}>
          <Typography>Variables:</Typography>
        </Grid>

        <>
          <Grid item xs={12}>
            {fields?.map((item: any, index: number) => {
              return (
                <Grid container spacing={2} mb={2} key={item.id}>
                  <Grid item xs>
                    <BXInput
                      size='small'
                      fullWidth
                      label={"Key"}
                      name={`config.${index}].key`}
                      control={control}
                      error={errors?.config?.[index]?.key}
                    />
                    {errors && <FormHelperText error>{errors?.message}</FormHelperText>}
                  </Grid>

                  <Grid item xs>
                    <BXInput
                      size='small'
                      fullWidth
                      label={"Value"}
                      name={`config.${index}].value`}
                      control={control}
                      onChange={() => {}}
                      error={errors?.config?.[index]?.value}
                    />
                    {errors && <FormHelperText error>{errors?.message}</FormHelperText>}
                  </Grid>

                  <Grid item xs>
                    <BXSwitch
                      label={watch(`config.[${index}].isPrivate`) ? "Private" : "Public"}
                      control={control}
                      error={errors?.config?.[index]?.isPrivate}
                      name={`config.[${index}].isPrivate`}
                    />
                    {errors && <FormHelperText error>{errors?.message}</FormHelperText>}
                  </Grid>
                  <Grid item xs>
                    <IconButton
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <IconTrashX color='red' />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
            <Button
              variant='outlined'
              onClick={() => {
                append({ id: uuid(), key: "", value: "", isPrivate: true });
              }}
            >
              Add row
            </Button>
          </Grid>
          {handleSubmit && (
            <Grid item xs={12}>
              <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
                <Button onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
                  Save
                </Button>
              </DialogActions>
            </Grid>
          )}
        </>
      </Grid>
    </Box>
  );
};
