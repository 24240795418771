import { yupResolver } from "@hookform/resolvers/yup";
import MonacoEditor from "@monaco-editor/react";
import { Box, Button, DialogActions, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import _ from "lodash";
import React, { FC, useEffect } from "react";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";
import OASSelector from "../OASSelector";

type CreateSelectComponentFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  editing?: boolean;
  isBuilder?: boolean;
  row?: any;
  height?: string | number;
  withoutPassword?: boolean;
  onlyPassword?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateSelectComponentForm: FC<CreateSelectComponentFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  height,
  isBuilder = false,
  setIsDirty,
  row = {},
}) => {
  const schema = yup
    .object({
      name: yup.string(),
      config: yup.object().shape({
        source: yup.object(),
        sourceType: yup.string().required(),
      }),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
    getValues,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: {
      ...row,
      config: {
        ...row?.config,
        sourceType: row?.config?.sourceType || "API",
      },
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const handleSave = (formData: any) => {
    const payload = {
      ...formData,
    };
    if (formData?.config?.selectValues && typeof formData?.config?.selectValues == "string") {
      payload.config.selectValues = JSON.parse(formData?.config?.selectValues || "{}");
    }
    onSave(formData);
  };

  return (
    <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
      <Grid container spacing={3} px={2} py={1}>
        <Grid item xs={6}>
          {!isBuilder && <BXInput name={"name"} control={control} label='Name' variant='outlined' error={errors?.name} />}
          {isBuilder && <Typography>Source</Typography>}
        </Grid>
        <Grid item xs={12}>
          {["API", "LIST"].map((type: any) => (
            <Controller
              key={type}
              control={control}
              name={`config.sourceType`}
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup color='primary' value={value} exclusive>
                  <ToggleButton
                    style={{ marginInlineEnd: 12 }}
                    value={value}
                    onClick={() => {
                      onChange(type);
                    }}
                    selected={value === type}
                    key={type}
                  >
                    {type === "LIST" ? "STATIC" : type}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          ))}
        </Grid>
        {watch("config.sourceType") === "API" && (
          <>
            <Grid item xs={6}>
              <ApiInput
                watch={watch}
                apiLabel='API URL'
                control={control}
                path='config.source.payload'
                pathURL='config.source.apiUrl'
                OASElement={
                  <OASSelector
                    swaggerProps={{
                      template: watch(".template"),
                      formBuilder: watch("source.formBuilder"),
                      onSuccess: (values: any, data: any) => {
                        setValue("config.source.template", values.template);
                        setValue("config.source.url", data?.path);
                        setValue("config.source.apiUrl", data?.path);
                        setValue("config.source.payload.uri", data?.path);
                        setValue("config.source.payload.method", (data?.method as string).toUpperCase());
                        setValue("config.source.payload.body", formatJSON(JSON.stringify(data?.body)) || {});
                        setValue("config.source.dataEntry", values.dataEntry);
                      },
                    }}
                  />
                }
                getValues={getValues}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={6}>
              <BXInput
                name={"config.dataEntry"}
                control={control}
                label='Data Entry'
                variant='outlined'
                error={(errors as any)?.config?.dataEntry}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <BXInput name={"config.key"} control={control} label='Key' variant='outlined' error={(errors as any)?.config?.key} />
        </Grid>
        {watch("config.sourceType") === "LIST" && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"config.selectValues"}
              render={({ field: { onChange, value } }) => (
                <>
                  <Typography fontWeight={"400"} mb={2}>
                    Options
                  </Typography>
                  <MonacoEditor
                    height='150px'
                    language='json'
                    theme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) || "" : value}
                    options={{ colorDecorators: true }}
                    onChange={(newValue = "") => {
                      try {
                        onChange(newValue);
                        JSON.parse(newValue);
                      } catch (e: any) {}
                    }}
                  />
                </>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit(handleSave)} variant={"contained"} aria-label={"save"}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
