import { Backdrop } from "@mui/material";
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import { FC, useCallback, useMemo, useState } from "react";
import { isUnixTimestamp, isValidDate } from "src/utils/generalUtils";

const DATECOMPONENTS_MAP = {
  DatePicker: DatePicker,
  DateCalendar: DateCalendar,
};

export const CustomDatePicker: FC<any> = componentProps => {
  const { config = undefined, $config = undefined, value, onChange: onSideChange, ...props } = componentProps;
  const componentConfig = $config ? $config : config;
  const { displayStyle, startDate, endDate } = componentConfig;

  const DatePickerComponent = DATECOMPONENTS_MAP?.[displayStyle || "DatePicker"];

  const [isOpen, setIsOpen] = useState(false);

  const openDateTimePicker = () => {
    setIsOpen(true);
  };

  const closeDateTimePicker = () => {
    setIsOpen(false);
  };

  const computedValue = useMemo(() => {
    if (!value?.ISO) {
      return null;
    }

    if (!isValidDate(value?.ISO)) {
      return null;
    }

    return new Date(value?.ISO);
  }, [value]);

  const defaultValue = useMemo(() => {
    if (!props?.defaultValue?.ISO) {
      if (isUnixTimestamp(Number(props?.defaultValue))) {
        return new Date(Number(props?.defaultValue));
      }

      if (isValidDate(props?.defaultValue)) {
        return new Date(props?.defaultValue);
      }

      return null;
    }

    if (!isValidDate(props?.defaultValue?.ISO)) {
      return null;
    }

    return new Date(props?.defaultValue?.ISO);
  }, [props?.defaultValue]);

  const handleChange = useCallback(
    date => {
      if (date && date instanceof Date && !isNaN(date.getTime())) {
        const formattedDate = {
          ISO: date.toISOString(),
          EPOCH: date.valueOf(),
        };
        onSideChange?.(formattedDate);
      }
    },
    [onSideChange]
  );

  return (
    <>
      <Backdrop open={isOpen} sx={{ opacity: 0, backgroundColor: "transparent" }} onClick={closeDateTimePicker} />
      <DatePickerComponent
        {...props}
        onOpen={openDateTimePicker}
        onClose={closeDateTimePicker}
        open={isOpen}
        value={computedValue}
        defaultValue={defaultValue}
        minDate={startDate ? new Date(startDate) : undefined}
        maxDate={endDate ? new Date(endDate) : undefined}
        onChange={handleChange}
        views={["year", "month", "day"]}
      />
    </>
  );
};
