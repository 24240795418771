import { cloneDeep } from "lodash";
import { NavItemType, NavItemTypeObject } from "src/types";
import { BXApp } from "src/types/BXAppType";
import { BxPatterns } from "./BXValidate/BxPatterns";

/**
 * object tree of pages into nav items
 * @param app
 */
export const pagesToSidebarItems = (
  app: BXApp | null,
  isAdministrationMode?: boolean,
  user?: any,
  fqdnApp?: BXApp | undefined
): NavItemType[] => {
  let groups: (NavItemTypeObject & NavItemType)[] = [];

  if (!isAdministrationMode) {
    if (app === null) return [];
    // we have a limitation in the original codebase, menu items must belong to a group
    let _templateConfig;
    if (app?.templateConfig) {
      _templateConfig = cloneDeep(app.templateConfig);
    } else {
      _templateConfig = cloneDeep(app?.upTemplateConfig);
    }
    let collections = _templateConfig?.collections || [];
    collections = collections?.filter(col => !col.isShared);


    for (let i = 0; i < collections.length; i++) {
      const collection = collections?.[i];
      const pages = collection.pages?.filter(page => page?.info?.visibility !== "Hidden");
      const groupName = collection.slug;

      for (let j = 0; j < pages.length; j++) {
        const pageSlug = pages[j].slug;
        const pathPrefix = fqdnApp ? "" : app?.slug;
        const foundGroup = groups.find(group => group.id === collection.id);
        const pageIcon =
          (pages[j]?.iconConfig?.visibility === "PUBLIC" && pages[j]?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
            ? pages[j]?.iconConfig?.url
            : pages[j]?.iconConfig?.icon) || pages[j].icon;
        const pageRoute: NavItemType = {
          title: pages[j].name,
          id: pages[j].name,
          type: "item",
          url: `/${pathPrefix}${collection.slug}${pageSlug ? `/${pageSlug}` : null}`.replaceAll(/\/+/g, "/"),
          icon: pageIcon,
        };

        if (foundGroup) {
          foundGroup.children?.push(pageRoute);
        } else {
          groups.push({
            id: collection.id,
            type: "group",
            url: `/${groupName}`,
            title: collection.name,
            appName: app.name,
            children: [pageRoute],
            icon:
              (collection?.iconConfig?.visibility === "PUBLIC" && collection?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                ? collection?.iconConfig?.url
                : collection?.iconConfig?.icon) || collection?.icon,
          });
        }
      }
    }
  } else {
    groups.push({
      id: "buildx/app",
      type: "group",
      url: "/",
      title: "",
      children: [
        {
          title: "Manage Apps",
          id: "Manage Apps",
          type: "item",
          url: "/buildx/app",
          icon: "Apps",
        },
        process.env.REACT_APP_WITH_OAS == "true"
          ? {
              title: "Manage OAS",
              id: "Manage OAS",
              type: "item",
              url: "/buildx/oas",
              icon: "FileCode",
            }
          : {},
        {
          title: "Manage Environment",
          id: "Manage ENV",
          type: "item",
          url: "/buildx/env",
          icon: "Key",
        },
        {
          title: "Manage Components",
          id: "Manage Components",
          type: "item",
          url: "/buildx/component",
          icon: "Components",
        },
        {
          title: "Manage Templates",
          id: "Manage Templates",
          type: "item",
          url: "/buildx/templates",
          icon: "Template",
        },
        user?.organization.id === "default"
          ? {
              title: "Migrations",
              id: "Migrations",
              type: "item",
              url: "/buildx/migrations",
              icon: "GitMerge",
            }
          : {},
        {
          title: "Manage Users",
          id: "Manage Users",
          type: "item",
          url: "/buildx/users",
          icon: "Users",
        },
        {
          title: "Manage Policies",
          id: "Manage Policies",
          type: "item",
          url: "/buildx/policies",
          icon: "License",
        },
        {
          title: "Manage Roles",
          id: "Manage Roles",
          type: "item",
          url: "/buildx/roles",
          icon: "ChairDirector",
        },
        {
          title: "Manage Media",
          id: "Manage Media",
          type: "item",
          url: "/buildx/media",
          icon: "Photo",
        },
      ],
      icon: "users",
    });
  }

  return groups;
};

export const pagesToProfileItems = (app: BXApp | null): NavItemType[] => {
  let groups: (NavItemTypeObject & NavItemType)[] = [];
  if (app === null) return [];
  // we have a limitation in the original codebase, menu items must belong to a group
  const collections = (app.templateConfig?.collections || []).filter(col => !col.isShared);
  
  const appName = app.name;
  for (let i = 0; i < collections.length; i++) {
    const collection = collections?.[i];
    const pages = collection.pages;
    const groupName = collection.slug;

    for (let j = 0; j < pages.length; j++) {
      if (pages[j].inProfileMenu) continue;
      const pageName = pages[j].name;
      const foundGroup = groups.find(group => group.title === groupName);
      const pageRoute: NavItemType = {
        title: pageName,
        id: pages[j].name,
        type: "item",
        url: `/${appName}/${groupName}${pageName ? `/${pageName}` : null}`,
        icon: pages[i].icon,
      };

      if (foundGroup) {
        foundGroup.children?.push(pageRoute);
      } else {
        groups.push({
          id: groupName,
          type: "group",
          url: `/${groupName}`,
          title: groupName,
          children: [pageRoute],
        });
      }
    }
  }

  return groups;
};
