/**
 * general Page component
 * @constructor
 */
import { Box, Chip, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import _ from "lodash";

import React, { FC, useEffect, useMemo, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXIcon } from "src/components/BXUI/Icon";
import MainCard from "src/components/cards/MainCard";
import { gridSpacing } from "src/stores/constant";
import { ILayout } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import { CustomLoadingView } from "src/views/pages/BuildX/FormBuilder/CustomLoadingView";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import withPageLayout from "./withPageLayout";

type BXPageProps = {
  page: BXPageType;
  mainAction?: {
    label: string;
    actionUrl: string;
    style: string;
    isALink: boolean;
  };
  children?: any | any[];
  pageId?: string;
  path?: string;
  layouts?: ILayout[];
};

const BXPage: FC<BXPageProps> = ({ children: rawChildren, pageId: _pageId, mainAction = null, page, layouts }) => {
  const { title, icon, views, layout, hidePageTitle } = page || {};
  const pageId = _pageId || page?.id;

  const { viewsState, viewStacks, currentApp, setViewStacks } = useBXContext();
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders();
  const { pathname } = useLocation();
  const { palette } = useTheme();
  // general page component
  const [activeView, setActiveView] = useState(0);

  const titleDisplayed = useMemo(
    () =>
      replaceDataPlaceholders({
        queryString: title,
        env: currentApp?.env,
      }),
    [title, currentApp?.env]
  );

  const loadingPageConfig = useMemo(() => {
    if (!page.layoutId) {
      //If the page has no layout assigned, it has no loading view assigned so return empty
      return null;
    }

    //Extract the loading view config from the app layout assigned to the page
    const pageLayout = layouts?.find(layout => layout?.id === page.layoutId);
    const pageElement = pageLayout?.dataSource?.formBuilder?.find(el => el.type === "BXLayout");

    return pageElement?.config?.loadingConfig || {};
  }, [page, layouts]);

  useEffect(() => {
    return () => {
      setViewStacks([]);
      setActiveView(0);
    };
  }, [pathname]);

  const rowChildren =
    !!rawChildren?.length &&
    rawChildren?.sort(
      (x, y) => Number(x?.props?.layout?.[0]?.info?.visibility === "Hidden") - Number(y?.props?.layout?.[0]?.info?.visibility === "Hidden")
    );

  const children = !!rowChildren?.length
    ? React.Children.map(rowChildren, child => {
        return <React.Fragment>{child}</React.Fragment>;
      })
    : rawChildren;

  return (
    <>
      <MainCard
        style={{ position: "relative", width: "100%", height: "100%" }}
        title={
          <Grid container alignItems='center' justifyContent='space-between' spacing={gridSpacing}>
            <Grid item xs={12} md={5} paddingTop={"12px !important"}>
              {!hidePageTitle && (
                <Box display='flex' alignItems='center' gap={1}>
                  {icon && <BXIcon icon={icon} size={26} color={palette?.text.primary} />}
                  <Typography variant='h3' fontSize={"16px"} color={palette?.text.primary}>
                    {titleDisplayed}
                  </Typography>
                </Box>
              )}
              {layout === "stack" && !!viewStacks?.length && (
                <Grid container mt={1} marginInlineStart={1} alignItems='center'>
                  <Chip
                    label={replaceDataPlaceholders({
                      queryString: children?.[activeView]?.props?.children?.props?.layout?.[0]?.info?.name,
                      viewsState,
                      pageId,
                      env: currentApp?.env,
                      __data: children?.[activeView]?.props?.__data,
                    })}
                    onClick={() => {
                      setViewStacks([]);
                    }}
                  />
                  <Typography style={{ cursor: "pointer" }} fontSize={"16px"}>
                    &nbsp; {`>`}&nbsp;
                  </Typography>

                  {viewStacks.map((viewStack: any, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Chip
                          label={replaceDataPlaceholders({
                            queryString: viewStack?.props?.layout?.[0]?.info?.name,
                            viewsState,
                            pageId,
                            env: currentApp?.env,
                            __data: viewStack?.props?.__data,
                          })}
                          onClick={() => {
                            if (viewStacks.length - 1 == index) return;

                            setViewStacks(prev => {
                              return prev.slice(0, index + 1);
                            });
                          }}
                          disabled={viewStacks.length - 1 == index}
                          style={
                            (viewStacks.length - 1 == index && {
                              backgroundColor: "transparent",
                              marginInlineStart: "-10px",
                              opacity: 1,
                            }) ||
                            {}
                          }
                        />
                        <Typography fontSize={"16px"} fontWeight={viewStacks.length - 1 == index ? "bold" : "500"}>
                          &nbsp;{viewStacks.length - 1 != index && `>`}&nbsp;
                        </Typography>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing='15' justifyContent='flex-end' alignItems='center'>
                <Grid item>{/* i deleted the search box */}</Grid>
                <Grid item>
                  {layout === "switch" && (
                    <>
                      {Array.isArray(views) && views.length > 1
                        ? _.cloneDeep(views)
                            .sort((x, y) => Number(x.info?.visibility === "Hidden") - Number(y.info?.visibility === "Hidden"))
                            .map((view, index) => {
                              if (view.info?.visibility == "Hidden") return <></>;
                              return (
                                <ToggleButton
                                  key={index}
                                  value={index}
                                  selected={index === activeView}
                                  size={"medium"}
                                  onClick={() => setActiveView(index)}
                                  style={{ marginInlineStart: 8 }}
                                >
                                  {view?.info?.name}
                                  {/* {icons?.[index]?.({ key: index, size: 24 })} */}
                                </ToggleButton>
                              );
                            })
                        : null}
                    </>
                  )}

                  {mainAction && (
                    <Button variant='contained' color='secondary' component={Link} to='/user/add/null'>
                      {mainAction.label}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        content={false}
        sx={{ backgroundColor: "transparent" }}
        parent-id={pageId}
      >
        {loadingPageConfig && <CustomLoadingView loadingConfig={loadingPageConfig} componentKey={pageId} />}
        {Array.isArray(children) && layout === "switch" ? (
          children[activeView]
        ) : Array.isArray(children) && layout === "stack" ? (
          <>
            {viewStacks?.map((stack: any, index) => {
              return (
                <Box key={`${stack?.props?.selectedViewId}-${index}`} display={viewStacks?.length - 1 !== index ? "none" : "black"}>
                  {stack}
                </Box>
              );
            })}
            <Box display={viewStacks?.length ? "none" : "black"}>{children[activeView]}</Box>
          </>
        ) : (
          children
        )}
      </MainCard>
    </>
  );
};

export default withPageLayout(BXPage);
