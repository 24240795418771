import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ILayout } from "src/types/BXAppType";
import { FormBuilderEditor } from "src/views/pages/BuildX/FormBuilder/FormBuilderEditor";
import { useBXBuilderContext } from "../../../../BXEngine/BXBuilderContext";

type LayoutBuilderProps = {
  params?: {
    appId?: string;
    templateId?: string;
  };
  appBuilderMode?: boolean;
  onSave?: (data: any) => Promise<any>;
};
export const LayoutBuilder: FC<LayoutBuilderProps> = () => {
  const [layout, setLayout] = useState<ILayout | undefined>();
  const { appId, layoutId } = useParams();
  const { apps, editApp } = useBXBuilderContext();
  const navigate = useNavigate();

  const openNewWindow = useCallback(() => {
    const urlToNavigate = `/buildx/app?appId=${appId}&layoutBuilder=${layoutId}`;
    navigate(urlToNavigate);
  }, []);

  const handleBackClick = () => {
    openNewWindow();
  };

  useEffect(() => {
    const app = apps?.find(app => app?.id === appId);
    const layout = app?.templateConfig?.layouts?.find(layout => layout?.id === layoutId);

    setLayout(layout);

    return () => { };
  }, [apps]);

  const onSave = useCallback(
    async (data, onSuccess) => {
      if (!appId || !layoutId) return;
      const app = apps?.find(app => app?.id === appId)!;

      editApp?.(
        appId,
        {
          ...app,
          templateConfig: {
            ...app?.templateConfig,
            layouts: app?.templateConfig?.layouts?.map(layout => (layout?.id === layoutId ? data : layout)),
          },
        },
        onSuccess
      );
    },
    [apps, layout]
  );
  if (!layout) return null;
  return <FormBuilderEditor onSave={onSave} params={{ appId, layoutId }} layout={layout} appBuilderMode onBackClick={handleBackClick} />;
};
