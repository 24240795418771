import { useTheme } from "@mui/material";
import { IconAppWindow, IconEditCircle, IconPlus, IconTrashX } from "@tabler/icons-react";
import update from "immutability-helper";
import _, { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import BXModal from "src/components/BXUI/Modal";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { v4 as uuid } from "uuid";
import { changeIdsOfObject } from "../";
import { CreatePageForm } from "../forms/CreatePageForm";
import BuilderColumn from "./BuilderColumn";

type Props = {
  app?: BXApp;
  collection?: BXAppCollection;
  selectedData?: any;
  onSelect: (data: any) => void;
};

let isUpdateApps = true;

const PageColumn = (props: Props) => {
  const { collection, app, selectedData, onSelect } = props;

  const { apps, addPage, editPage, deletePage, editCollection } = useBXBuilderContext();

  const [pages, setPages] = useState(collection?.pages);
  const [page, setPage] = useState<BXPageType>();

  const [isEdit, setIsEdit] = useState(false);
  const [isTrash, setIsTrash] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const { palette } = useTheme();

  const navigate = useNavigate();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const appId = searchParams.get("appId");
  const collectionId = searchParams.get("collectionId");
  const pageId = searchParams.get("pageId");
  const isPageBuilder = searchParams.get("pageBuilder");
  const { loadApps, setSelectedPageId } = useBXContext();

  useEffect(() => {
    if (apps?.length && appId && collectionId && pageId && isPageBuilder) {
      const app = apps?.find(app => app?.id === appId);
      const collection = app?.templateConfig?.collections?.find(collection => collection?.id == collectionId);
      const page = collection?.pages?.find(page => page?.id === pageId);

      setIsEdit(true);
      setPage(page);
      navigate(`/buildx/app`);
    }
  }, [loadApps]);

  useEffect(() => {
    if (!isUpdateApps) isUpdateApps = true;
    else setPages(collection?.pages);
  }, [collection?.pages]);

  const moveCard = (dragIndex: any, hoverIndex: number) => {
    return setPages(prev => {
      const pages = update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev![dragIndex]],
        ],
      });

      return pages;
    });
  };

  const updateCardDebounce = useCallback(
    debounce((app, collection, pages) => {
      isUpdateApps = false;
      editCollection(app?.id!, collection?.id, { ...collection, pages });
    }, 800),
    []
  );

  const updateCard = () => {
    updateCardDebounce(app, collection, pages);
  };

  const handleEditClick = (value: any) => {
    setIsEdit(true);
    setPage(value);
  };

  const handleTrashClick = (value: any) => {
    setIsTrash(true);
    setPage(value);
  };

  const handleDuplicateClick = (value: any) => {
    const page = { ...value, name: value?.name + " copy", slug: value.slug + "-copy" };
    const changedTemplateConfigIds = changeIdsOfObject(_.cloneDeep(page));
    addPage(app?.id!, collection?.id!, changedTemplateConfigIds);
  };

  const data = pages?.map((item: BXPageType) => ({
    ...item,
    title: item?.title,
    subTitle: item?.slug,
    image: item?.icon,
    fallBack: "BrandPagekit",
  }));

  const handleMoveClick = (handleClose: any, appId: any, collectionId: any, pageId: any, item: any) => {
    addPage(appId, collectionId, item as any, () => {
      deletePage(app?.id!, collection?.id!, item?.id);
    });
    handleClose?.();
  };

  return (
    <>
      {app?.id && collection?.id && page?.id && (
        <>
          <BXConfirmationDialog
            open={isTrash}
            title={"Are you sure you want to delete this page?"}
            iconButton
            buttonProps={{ color: "error", children: <IconTrashX /> }}
            onConfirm={() => {
              deletePage(app.id, collection.id, page.id);
              setIsTrash(false);
            }}
            onCancel={() => setIsTrash(false)}
          />
          <BXModal
            open={isEdit}
            icon={<IconEditCircle />}
            buttonProps={{ startIcon: <IconEditCircle />, color: "secondary" }}
            label={"Edit"}
            title={`Edit Page : ${app?.name} > ${collection?.name} > ${page?.name}`}
            onClose={() => setIsEdit(false)}
            isDirty={isDirty}
          >
            {(handleClose: Function) => {
              return (
                <CreatePageForm
                  page={page}
                  layouts={app?.templateConfig?.layouts}
                  onSave={async (data, closeOnSuccess = true) => {
                    const newPage: BXPageType = {
                      ...page,
                      ...data,
                    };

                    editPage(app.id, collection.id, page.id, newPage);
                    if (closeOnSuccess) {
                      handleClose?.(true);
                      setIsEdit(false);
                    }
                  }}
                  params={{ appId: app?.id, collectionId: collection?.id }}
                  setIsDirty={setIsDirty}
                  onCancel={() => {
                    handleClose?.(false, () => {
                      setIsEdit(false);
                    });
                  }}
                />
              );
            }}
          </BXModal>
        </>
      )}

      <BuilderColumn
        id={collection?.id}
        headerName={"Pages"}
        rows={data}
        selectedData={selectedData}
        moveApp={moveCard}
        updateApp={updateCard}
        onEditClick={handleEditClick}
        onTrashClick={handleTrashClick}
        onDuplicateClick={handleDuplicateClick}
        onSelect={onSelect}
        type={"page"}
        withMove
        onMoveClick={handleMoveClick}
        modal={
          <BXModal
            label={"Page"}
            icon={<IconAppWindow />}
            buttonProps={{
              variant: "contained",
              style: { backgroundColor: palette.primary.main, borderRadius: 24 },
              startIcon: <IconPlus />,
              fullWidth: true,
              disabled: !collection?.id || collection?.isShared,
            }}
            title={`New Page : ${app?.name} > ${collection?.name}`}
          >
            {(handleClose: Function) => {
              return (
                <CreatePageForm
                  params={{ appId: app?.id, collectionId: collection?.id }}
                  layouts={app?.templateConfig?.layouts}
                  onSave={async data => {
                    const newPage: BXPageType = {
                      id: uuid(),
                      icon:
                        (data?.iconConfig?.visibility === "PUBLIC" && data?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                          ? data?.iconConfig?.url
                          : data?.iconConfig?.icon) || data?.icon,
                      slug: data.slug,
                      name: data.name,
                      views: [],
                      title: data.title,
                      layout: data.layout,
                      inProfileMenu: false,
                    };
                    addPage(app?.id!, collection?.id!, newPage);
                    setSelectedPageId(newPage.id);
                    handleClose?.();
                  }}
                  onCancel={() => handleClose?.()}
                />
              );
            }}
          </BXModal>
        }
      />
    </>
  );
};

export default PageColumn;
