import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, Paper, Tab, Theme, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { FC, useState } from "react";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Avatar from "src/components/extended/Avatar";
import { BXApp } from "src/types/BXAppType";
import { default as axios, default as axiosServices } from "src/utils/axios";
import { queryClient } from "../../../../../BXEngine/BXContext";

type ConnectAppUsersProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel: Function;
  app: BXApp;
};

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  root: { border: "0px solid" },
  indicatorStyle: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ConnectAppUsers: FC<ConnectAppUsersProps> = ({ onCancel = _.noop, onSave = _.noop, app }) => {
  const [value, setValue] = useState("1");

  const classes = useStyles();
  const { palette } = useTheme();

  const { data: notConnectedData } = useQuery(["not-connected-users", app.id], () => {
    return axiosServices.get(`/admin/application/${app.id}/user`, {
      params: {
        connected: false,
      },
    });
  });

  const { data: connectedData } = useQuery(["connected-users", app.id], () => {
    return axiosServices.get(`/admin/application/${app.id}/user`, {
      params: {
        connected: true,
      },
    });
  });

  const { mutate } = useMutation(
    userId => {
      return axios.post(`/admin/application/${app.id}/user`, {
        user: {
          id: userId,
        },
      });
    },
    {
      onSuccess: (responseData, variables) => {
        // @ts-ignore
        queryClient.setQueryData(["connected-users", app.id], (data: any) => ({
          data: [notConnectedData?.data?.find((item: any) => item.id === variables), ...data?.data],
        }));
        queryClient.setQueryData(["not-connected-users", app.id], (data: any) => {
          return {
            // @ts-ignore
            data: data?.data?.filter?.((item: any) => item.id !== variables),
          };
        });
      },
    }
  );

  const { mutate: disconnect } = useMutation(
    userId => {
      return axios.delete(`/admin/application/${app.id}/user/${userId}`);
    },
    {
      onSuccess: (responseData, variables) => {
        queryClient.setQueryData(["connected-users", app.id], (data: any) => {
          return {
            // @ts-ignore
            data: data?.data?.filter?.((item: any) => item.id !== variables),
          };
        });

        queryClient.setQueryData(["not-connected-users", app.id], (data: any) => {
          return {
            // @ts-ignore
            data: [connectedData.data.find(item => item.id === variables), ...data.data],
          };
        });
      },
    }
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderTop: 1, borderColor: "divider", justifyContent: "center", display: "flex", color: "white", marginTop: 1.5 }}>
              <TabList
                textColor='inherit'
                classes={{ indicator: classes.indicatorStyle, flexContainer: classes.root }}
                onChange={handleChange}
                aria-label='lab API tabs example'
              >
                <Tab style={{ textTransform: "none" }} label='Authorized Users' value='1' />
                <Tab style={{ textTransform: "none" }} label='Available Users' value='2' />
              </TabList>
            </Box>

            <TabPanel value='1'>
              <Grid item container justifyContent={"center"} alignItems='center' sm={12}>
                <Grid sm={6}>
                  {connectedData?.data?.map?.((user: any) => (
                    <Grid item xs={12} key={user?.id}>
                      <Paper style={{ marginBottom: 20 }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Avatar src={user.profilePhoto} alt={user.name} />
                            <div style={{ display: "flex", flexDirection: "column", marginInlineStart: 12 }}>
                              <span>{user.name}</span>
                              <span style={{ opacity: 0.9, fontSize: 12 }}>{user.handle}</span>
                            </div>
                          </div>
                          <Button
                            variant='contained'
                            style={{ backgroundColor: "#343434", borderRadius: 24 }}
                            onClick={() => disconnect(user.id)}
                          >
                            Revoke
                          </Button>
                        </div>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value='2'>
              <Grid item container justifyContent={"center"} alignItems='center' sm={12}>
                <Grid sm={6}>
                  {(
                    notConnectedData?.data?.map((user: any) => ({
                      ...user,
                      action: () => mutate(user.id),
                    })) || []
                  )?.map?.((user: any) => {
                    return (
                      <Grid item xs={12} key={user?.id}>
                        <Paper style={{ marginBottom: 20 }}>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Avatar src={user.profilePhoto} alt={user.name} />
                              <div style={{ display: "flex", flexDirection: "column", marginInlineStart: 12 }}>
                                <span>{user.name}</span>
                                <span style={{ opacity: 0.9, fontSize: 12 }}>{user.handle}</span>
                              </div>
                            </div>
                            <Button
                              variant='contained'
                              style={{ backgroundColor: palette.primary.main, borderRadius: 24 }}
                              onClick={() => user?.action()}
                            >
                              Authorize
                            </Button>
                          </div>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Box>
  );
};
