import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import axios from "axios";
import { joinObjects } from "hd-utils";
import _ from "lodash";
import { memo, useEffect, useLayoutEffect, useState } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useQuery } from "react-query";
import { AcceptsTargetTypes, BXEngine } from "src/BXEngine";
import { toCamelCase } from "src/utils/generalUtils";
import {
  addChildrenToObjectId,
  chartsNames,
  isParentFlex,
  removeChildrenFromObject,
  setParents,
  snapToStepperGrid,
  stepperGroupComponentState,
  updateStepperGroupsChildren,
} from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import { DragElement } from "./DragElement";
import { MUI_COMPONENTS } from "./FormBuilderEditor";
import { ComponentItemType, StepperComponentState } from "./types";

const BoxComponent = (props: any) => {
  const {
    item: containerItem,
    index: parentIndex,
    itemIndex,
    setView,
    onLayout = () => {},
    layoutBreak,
    setInnerComponentDropping,
    selectedItemsId,
    activeComponent,
    viewsLength,
    formMoveBoxFunction,
    parentBoxMoveBoxComponent,
    path,
    zoomFactor,
    views,
    isStepperEnabled,
    isRTL,
    stepperGroups,
  } = props;

  // const boxItemRef = useRef<any>();
  const [boxItemRef, setBoxItemRef] = useState<any>();
  const theme = useTheme();

  const Component = MUI_COMPONENTS[containerItem.type];

  const moveMultipleBoxes = (data: any) => {
    const newElements = data?.map((el: any) => {
      const { item, ...rest } = el || {};

      const newItem = { ...item, ...rest };
      if (newItem?.id) {
        return newItem;
      } else {
        const newId = uuid();
        const camelCaseType = newItem?.type ? toCamelCase(newItem.type) : "";

        return {
          id: newId,
          ...newItem,
          props: {
            ...newItem?.props,
            id: `${camelCaseType}${newId}-${viewsLength || 0}`,
            key: `${camelCaseType}${newId}-${viewsLength || 0}`,
            testId: "",
          },
        };
      }
    });
    const elementsIds = newElements?.map((el: any) => el?.id) || [];
    const newContainerItem = containerItem;
    newContainerItem.children = [
      ...newElements,
      ...(newContainerItem?.children?.filter((oldItem: any) => !elementsIds?.includes(oldItem?.id)) || []),
    ];

    setView((prev: any) => {
      const oldObject = removeChildrenFromObject(prev?.dataSource?.formBuilder, elementsIds);
      const newObject = addChildrenToObjectId(oldObject, containerItem?.id, newElements);

      const newFormBuilder = setParents(newObject);
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: newFormBuilder,
        },
        stepperGroups: updateStepperGroupsChildren(newFormBuilder, prev?.stepperGroups),
      };
    });
  };

  const updateElementConfigAndProps = (el: any, containerItem: any) => {
    const isCheckboxOrRadio = el?.type === ComponentItemType.CustomCheckbox || el?.type === ComponentItemType.CustomRadio;
    const parentAcceptsType = containerItem?.config?.acceptTypes?.includes(el?.type);
    let updatedProps;
    if (isCheckboxOrRadio && parentAcceptsType) {
      updatedProps = {
        ...el?.props,
        groupName: containerItem?.props?.key,
        singleValue: containerItem?.props?.singleValue,
        required: containerItem?.props?.required,
        customRequiredMessageContent: containerItem?.props?.customRequiredMessageContent,
        defaultValue: containerItem?.props?.defaultValue,
        isChildContainerGroup: true,
      };
    } else {
      updatedProps = el?.props;
    }

    return { updatedProps };
  };
  const moveBoxFunction = (movedItem: any, monitor?: any, newPosition?: any, step: number = 1) => {
    if (!monitor.isOver()) {
      return;
    }
    const boxSize = boxItemRef?.getBoundingClientRect();

    const delta = monitor ? monitor.getSourceClientOffset() : document.getElementById(movedItem?.id)?.getBoundingClientRect();
    if (!delta) return undefined;

    let translateX = 0;
    let translateY = 0;
    let top = 0;
    let left = 0;
    const components = movedItem?.length ? movedItem : [movedItem];

    const newComponentMove = components?.map((el: any) => {
      const realItem = el?.id && document.getElementById(el?.id)?.getBoundingClientRect();
      const itemWidth = realItem?.width || el?.config?.defaultWidth;

      if (movedItem?.length) {
        translateX = (monitor?.getSourceClientOffset()?.x - monitor?.getInitialSourceClientOffset()?.x) / zoomFactor;
        translateY = (monitor?.getSourceClientOffset()?.y - monitor?.getInitialSourceClientOffset()?.y) / zoomFactor;
        const leftValue = Number(translateX) - Number(boxSize?.x) + Number(realItem?.x);
        left = isRTL ? boxSize.width - itemWidth - leftValue : leftValue;
        top = Number(translateY) - Number(boxSize?.y) + Number(realItem?.y);
      } else if (realItem) {
        translateX = Math.round(Number(monitor.getDifferenceFromInitialOffset().x / zoomFactor + realItem?.x + (newPosition?.x || 0)));
        translateY = Math.round(Number(monitor.getDifferenceFromInitialOffset().y / zoomFactor + realItem?.y + (newPosition?.y || 0)));
        const leftValue = Number(translateX) - Number(boxSize?.x);
        left = isRTL ? boxSize.width - itemWidth - leftValue : leftValue;
        top = Number(translateY) - Number(boxSize?.y) + Number(el?.config?.draggingOffSet || 0);
      } else {
        translateX = ((Number(delta?.x) || realItem?.x) + (newPosition?.x || 0)) / (el?.isSideMenu ? zoomFactor : 1);
        translateY = ((Number(delta?.y) || realItem?.y) + (newPosition?.y || 0)) / (el?.isSideMenu ? zoomFactor : 1);
        const leftValue = Number(translateX) - Number(boxSize?.x);
        left = isRTL ? boxSize.width - itemWidth - leftValue : leftValue;
        top = Number(translateY) - Number(boxSize?.y) + Number(el?.config?.draggingOffSet || 0);
      }

      if (isStepperEnabled) {
        [left, top] = snapToStepperGrid(left, top);
      }

      const PLeft = left < 0 ? 0 : left + realItem?.width > boxSize?.width ? boxSize?.width - realItem?.width : left;
      const PTop = top < 0 ? 0 : top + realItem?.height > boxSize?.height ? boxSize?.height - realItem?.height : top;

      const leftPercentage = (PLeft / boxSize?.width || 0) * 100 + "%";
      const PWidth = (itemWidth / boxSize?.width) * 100;
      const percentageWidth = PWidth > 100 ? "100%" : PWidth.toFixed(1) + "%";

      const PHeight = ((realItem?.height || el?.config?.defaultHeight) / boxSize?.height) * 100;
      const percentageHeight = PHeight > 100 ? "100%" : PHeight.toFixed(1) + "%";

      const hasCustom = el?.config?.hasCustom;
      const { updatedProps } = updateElementConfigAndProps(el, containerItem);

      return {
        item: el,
        hasCustom: layoutBreak === "xs",
        left: { ...el?.left, xs: hasCustom ? el.left?.xs : left, [layoutBreak]: PLeft },
        top: { ...el?.top, xs: hasCustom ? el.top?.xs : top, [layoutBreak]: PTop },
        leftPercentage: { ...el?.leftPercentage, xs: hasCustom ? el.leftPercentage?.xs : leftPercentage, [layoutBreak]: leftPercentage },
        config: {
          ...el?.config,
          widthPx: {
            ...el?.config?.widthPx,
            xs: hasCustom ? el?.config?.widthPx?.xs : Math.round(realItem?.width || el?.config?.defaultWidth),
            [layoutBreak]: Math.round(realItem?.width || el?.config?.defaultWidth),
          },
          heightPx: {
            ...el?.config?.heightPx,
            xs: hasCustom ? el?.config?.heightPx?.xs : Math.round(realItem?.height || el?.config?.defaultHeight),
            [layoutBreak]: Math.round(realItem?.height || el?.config?.defaultHeight),
          },
          widthPercentage: {
            ...el?.config?.widthPercentage,
            xs: el?.config?.widthPercentage?.xs || percentageWidth,
            [layoutBreak]: percentageWidth,
          },
          heightPercentage: {
            ...el?.config?.heightPercentage,
            xs: el?.config?.heightPercentage?.xs || percentageHeight,
            [layoutBreak]: percentageHeight,
          },
        },
        props: { ...updatedProps },
      };
    });
    moveMultipleBoxes(newComponentMove);
    return undefined;
  };
  const [{ canDrop, isOverCurrent }, drop] = useDrop({
    accept: AcceptsTargetTypes,
    drop: (draggedItem: any, monitor) => {
      if (draggedItem || !draggedItem?.config?.parent?.id || !draggedItem?.id) {
        moveBoxFunction(draggedItem, monitor);
        return { changeLevel: "Box" };
      }
    },

    canDrop: (item, monitor) => canDropComponent(monitor),

    collect: monitor => ({
      canDrop: !monitor.canDrop(),
      isOverCurrent: monitor.isOver(),
    }),
  });

  const canDropComponent = (monitor?: DropTargetMonitor<any, unknown>, itemVal?: any, newPosition?: any) => {
    const components = monitor?.getItem()?.length ? monitor?.getItem() : [monitor ? monitor?.getItem() : itemVal];
    if (containerItem?.config?.acceptTypes?.length > 0) {
      const allValidComponents = components.every(component => containerItem.config.acceptTypes.includes(component?.type));
      return allValidComponents;
    }

    const isValid = components?.every((el: any) => {
      return el?.id !== containerItem?.id;
    });
    return isValid;
  };

  const [{ isDragging, difference }, drag, preview] = useDrag({
    type: "Group",
    item: containerItem?.children
      ?.filter((el: any) => selectedItemsId.includes(el?.id))
      ?.map((item: any) => ({ ...item, boxPosition: boxItemRef?.getBoundingClientRect(), level: null })),
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      difference: monitor.getDifferenceFromInitialOffset(),
    }),
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [isDragging, difference]);

  const fetchCustomComponentData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${containerItem?.config?.customComponentId}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      }
    );
    const ids: any = {};
    data?.items?.forEach((item: any) => {
      ids[item?.id] = item;
    });

    return ids;
  };

  const { data: componentData, isLoading: isCustomComponentLoading } = useQuery(
    ["customComponent", containerItem?.config?.customComponentId],
    fetchCustomComponentData,
    {
      enabled: !!containerItem?.config?.customComponentId, // Only run the query if customComponentId exists
      staleTime: Infinity,
    }
  );

  const componentProps = _.cloneDeep(containerItem?.props);

  useEffect(() => {
    if (containerItem?.config?.cssPaths) {
      containerItem?.config?.cssPaths?.forEach((path: any) => {
        _.set(componentProps, path, containerItem?.config?.isDynamicHeight ? "unset" : _.get(componentProps, path));
      });
    }
  }, [containerItem?.config?.isDynamicHeight]);

  const parentFlex = isParentFlex(containerItem);

  if (stepperGroupComponentState(containerItem?.props?.key, stepperGroups, true) === StepperComponentState.FoundButNotSelected) {
    return <></>;
  }

  return (
    <>
      {[
        ComponentItemType.CustomContainer,
        ComponentItemType.FlexContainer,
        ComponentItemType.GridContainer,
        ComponentItemType.StepperContainer,
      ].includes(containerItem.type) ? (
        <DragElement
          key={`${parentIndex}`}
          {...props}
          parentIndex={parentIndex}
          itemIndex={itemIndex}
          formMoveBoxFunction={formMoveBoxFunction}
          parentBoxMoveBoxComponent={parentBoxMoveBoxComponent}
          zoomFactor={zoomFactor}
        >
          <Box
            height={"100%"}
            width={"100%"}
            ref={(e: any) => setBoxItemRef(e)}
            sx={
              joinObjects(
                {
                  position: containerItem?.config?.isDynamicHeight ? "relative" : "absolute",
                  backgroundColor: containerItem?.config?.flexCanvas
                    ? "unset"
                    : isOverCurrent
                    ? "rgba(255, 222, 0, 0.2)"
                    : theme.palette.primary.light,
                },
                parentFlex && {
                  position: "static",
                  backgroundColor: isOverCurrent ? "rgba(255, 222, 0, 0.2)" : theme.palette.primary.light,
                }
              ) as any
            }
          >
            {boxItemRef && (
              <Box
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
                ref={drop}
              >
                <Component {...containerItem?.props}>
                  {containerItem?.children && (
                    <>
                      {containerItem?.children
                        ?.filter((el: any) => !selectedItemsId.includes(el?.id))
                        ?.map((itemElement: any, index: number) => {
                          return (
                            <BoxComponent
                              {...props}
                              key={`${itemElement?.id}`}
                              index={parentIndex}
                              itemIndex={index}
                              item={itemElement}
                              active={selectedItemsId?.includes(itemElement?.id)}
                              selectedItems={containerItem?.children?.filter((el: any) => selectedItemsId.includes(el?.id))}
                              selectedItemsId={selectedItemsId}
                              boxPosition={boxItemRef?.getBoundingClientRect()}
                              className={"mouse-select__selectable"}
                              layoutBreak={layoutBreak}
                              setView={setView}
                              setInnerComponentDropping={setInnerComponentDropping}
                              activeComponent={activeComponent}
                              formMoveBoxFunction={formMoveBoxFunction}
                              parentBoxMoveBoxComponent={moveBoxFunction}
                            />
                          );
                        })}
                    </>
                  )}
                  {!!containerItem?.children?.filter((el: any) => selectedItemsId.includes(el?.id))?.length && (
                    <Box sx={{ display: "contents" }} ref={drag} className={"mouse-select__selectable"}>
                      {containerItem?.children
                        ?.filter((el: any) => selectedItemsId.includes(el?.id))
                        ?.map((itemElement: any, index: number) => {
                          return (
                            <BoxComponent
                              {...props}
                              key={`${index}`}
                              index={parentIndex}
                              itemIndex={index}
                              item={{ ...itemElement, children: [] }}
                              active={selectedItemsId?.includes(itemElement?.id)}
                              canDrag={
                                !(selectedItemsId?.length || itemElement?.children?.some((child: any) => child?.id === activeComponent))
                              }
                              isDragging={isDragging}
                              selectedItems={containerItem?.children?.filter((el: any) => selectedItemsId.includes(el?.id))}
                              selectedItemsId={selectedItemsId}
                              boxPosition={boxItemRef?.getBoundingClientRect()}
                              className={"mouse-select__selectable"}
                              layoutBreak={layoutBreak}
                              setView={setView}
                              setInnerComponentDropping={setInnerComponentDropping}
                              activeComponent={activeComponent}
                              formMoveBoxFunction={formMoveBoxFunction}
                              parentBoxMoveBoxComponent={moveBoxFunction}
                            />
                          );
                        })}
                    </Box>
                  )}
                </Component>
              </Box>
            )}
          </Box>
        </DragElement>
      ) : (
        <DragElement
          key={`${parentIndex}`}
          {...props}
          boxItemRef={(e: any) => setBoxItemRef(e)}
          itemIndex={itemIndex}
          formMoveBoxFunction={formMoveBoxFunction}
          parentBoxMoveBoxComponent={parentBoxMoveBoxComponent}
          zoomFactor={zoomFactor}
        >
          {containerItem?.config?.customComponent ? (
            <>
              {!isCustomComponentLoading && (
                <BXEngine
                  path={path}
                  auth={{}}
                  componentName={componentData?.[containerItem?.config?.customComponentId]?.name}
                  componentData={{
                    ...containerItem?.props,
                    config: chartsNames.includes(containerItem?.type)
                      ? containerItem?.configData
                      : componentData?.[containerItem?.config?.customComponentId]?.config,
                    disableApis: true,
                  }}
                  layout={[{ id: containerItem?.id, type: containerItem?.type } as any]}
                  onLayout={(values: any) =>
                    onLayout(values, { skip: !!componentData?.[containerItem?.config?.customComponentId]?.config })
                  }
                />
              )}
            </>
          ) : containerItem?.config?.BxComponent ? (
            <BXEngine
              path={path}
              auth={{}}
              layout={[{ id: containerItem?.id, type: containerItem?.type } as any]}
              componentData={{
                ...containerItem?.props,
                config: containerItem?.configData,
                disableApis: true,
              }}
            />
          ) : (
            <Component {...containerItem?.props} $config={containerItem?.config} $views={views} stepperGroups={stepperGroups} />
            // <Component
            //   {...containerItem?.props}
            //   $config={containerItem?.config}
            //   $views={views}
            //   $children={containerItem?.children}
            //   $builderProps={{
            //     ...props,
            //     index: parentIndex,
            //     selectedItems: containerItem?.children?.filter((el: any) => selectedItemsId.includes(el?.id)),
            //     selectedItemsId: selectedItemsId,
            //     boxPosition: boxItemRef?.getBoundingClientRect(),
            //     className: "mouse-select__selectable_builder",
            //     layoutBreak: { layoutBreak },
            //     setView: setView,
            //     setInnerComponentDropping: setInnerComponentDropping,
            //     activeComponent: activeComponent,
            //     formMoveBoxFunction: formMoveBoxFunction,
            //     parentBoxMoveBoxComponent: moveBoxFunction,
            //   }}
            // />
          )}
        </DragElement>
      )}
    </>
  );
};

export default memo(BoxComponent);
