import { yupResolver } from "@hookform/resolvers/yup";
import MonacoEditor from "@monaco-editor/react";
import { Box, Button, DialogActions, Grid, Switch, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";
import { BXMapValues } from "../FormBuilder/BXMapValues";
import OASSelector from "../OASSelector";

type CreateSelectDataSourceProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  editing?: boolean;
  isBuilder?: boolean;
  row?: any;
  height?: string | number;
  withoutPassword?: boolean;
  onlyPassword?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfigChange?: any;
  onChangeProp?: any;
  item?: any;
  isMapDataSource?: any;
};

export const CreateSelectDataSource: FC<CreateSelectDataSourceProps> = ({
  onSave = _.noop,
  height,
  setIsDirty,
  row = {},
  handleConfigChange,
  onChangeProp,
  item,
  isMapDataSource,
}) => {
  const schema = yup
    .object({
      name: yup.string(),
      config: yup.object().shape({
        source: yup.object(),
        sourceType: yup.string().required(),
      }),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
    getValues,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: {
      ...row,
      config: {
        ...row?.config,
        sourceType: row?.config?.sourceType || "API",
      },
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  useCallbackPrompt(isDirty);
  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);
  const [selectedValue, setSelectedValue] = useState<string>("");

  const handleSave = (formData: any) => {
    const payload = {
      ...formData,
    };
    if (formData?.config?.selectValues && typeof formData?.config?.selectValues == "string") {
      payload.config.selectValues = JSON.parse(formData?.config?.selectValues || "{}");
    }

    onSave(formData, selectedValue);
  };

  const { dataSourceMap, ...restOfItem } = item || {};
  const { dataSourceMapKey } = item?.config || {};
  const { conditionDataSourceMap } = item?.config || {};
  const [condition, setCondition] = useState(item?.config?.conditionDataSourceMap || "equal");

  useEffect(() => {
    if (!item?.dataSourceMap) {
      const newItem = {
        ...item,
        dataSourceMap: {
          default: { ...item?.configData } || {},
        },
      };
      setSelectedValue("default");
      onChangeProp?.(newItem);
    } else {
      setSelectedValue("default");
    }
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const newKey = item?.dataSourceMap?.[selectedValue]?.paginationKey || "";
      setValue("config.paginationKey", newKey, { shouldValidate: true, shouldDirty: true, shouldTouch: true });

      const newCursorKey = item?.dataSourceMap?.[selectedValue]?.cursorKey || "";
      setValue("config.cursorKey", newCursorKey, { shouldValidate: true, shouldDirty: true, shouldTouch: true });

      const newSourceType = item?.dataSourceMap?.[selectedValue]?.sourceType || "API";
      setValue("config.sourceType", newSourceType, { shouldValidate: true, shouldDirty: true, shouldTouch: true });

      const newSelectValues = item?.dataSourceMap?.[selectedValue]?.selectValues || "";
      setValue("config.selectValues", newSelectValues, { shouldValidate: true, shouldDirty: true, shouldTouch: true });

      setValue("config.source.apiUrl", item?.dataSourceMap?.[selectedValue]?.source?.apiUrl || "", {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [selectedValue]);

  const handleConditionChange = (newCondition: string) => {
    setCondition(newCondition);
    const newItem = {
      ...item,
      config: {
        ...item?.config,
        conditionDataSourceMap: newCondition,
      },
    };
    onChangeProp?.(newItem);
  };
  function copyFromDefault(value) {
    const defaultValue = item?.optionMap?.default || {};
    const newItem = {
      ...item,
      dataSourceMap: {
        ...item?.dataSourceMap,
        [value]: { ...defaultValue },
      },
    };
    onChangeProp?.(newItem);
    setSelectedValue(value);
  }

  function copyFromValue(value, source) {
    const newItem = {
      ...item,
      dataSourceMap: {
        ...item?.dataSourceMap,
        [value]: { ...(source?.actions as []) },
      },
    };
    onChangeProp?.(newItem);
  }

  function addNewValue(value) {
    setSelectedValue(value);
    const newItem = {
      ...item,
      dataSourceMap: {
        ...item?.dataSourceMap,
        [value]: {},
      },
    };

    onChangeProp?.(newItem);
  }
  function deleteValue(value) {
    const updatedDataSourcenMap = { ...dataSourceMap };
    delete updatedDataSourcenMap[value];
    onChangeProp?.({
      ...item,
      selectedType: "default",
      optionMap: updatedDataSourcenMap,
    });
    setSelectedValue("default");
  }

  return (
    <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
      <Grid container spacing={3} px={2} py={1}>
        <Grid item xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"}>
          <Typography fontSize={12}>Map Value</Typography>
          <Switch
            size='medium'
            checked={isMapDataSource}
            onChange={handleConfigChange("isMapDataSource", "boolean")}
            inputProps={{ "aria-label": "controlled" }}
            sx={{ ml: 2 }}
          />
        </Grid>
        {isMapDataSource && (
          <Grid
            display='flex'
            gap={2}
            marginInlineStart={"5px"}
            marginInlineEnd={"5px"}
            item
            xs={12}
            justifyContent='center'
            alignItems='center'
          >
            <BXMapValues
              initialKey={dataSourceMapKey}
              queryString={"dataSourceMapKey"}
              targetMap={dataSourceMap}
              handleKeyChange={handleConfigChange}
              setSelectedValue={setSelectedValue}
              addNewValue={addNewValue}
              copyFromDefault={copyFromDefault}
              copyFromValue={copyFromValue}
              deleteValue={deleteValue}
              condition={condition}
              setCondition={setCondition}
              handleConditionChange={handleConditionChange}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography>Source</Typography>
        </Grid>
        <Grid item xs={12}>
          {["NONE", "API", "LIST"].map((type: any) => (
            <Controller
              key={type}
              control={control}
              name={`config.sourceType`}
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup color='primary' value={value} exclusive>
                  <ToggleButton
                    style={{ marginInlineEnd: 12 }}
                    value={value}
                    onClick={() => {
                      onChange(type);
                    }}
                    selected={value === type}
                    key={type}
                  >
                    {type === "LIST" ? "FIXED DATA" : type}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          ))}
        </Grid>
        {watch("config.sourceType") === "API" && (
          <>
            <Grid item xs={6}>
              <ApiInput
                watch={watch}
                apiLabel='API URL'
                control={control}
                path='config.source.payload'
                pathURL='config.source.apiUrl'
                OASElement={
                  <OASSelector
                    swaggerProps={{
                      template: watch(".template"),
                      formBuilder: watch("source.formBuilder"),
                      onSuccess: (values: any, data: any) => {
                        setValue("config.source.template", values.template);
                        setValue("config.source.url", data?.path);
                        setValue("config.source.apiUrl", data?.path);
                        setValue("config.source.payload.uri", data?.path);
                        setValue("config.source.payload.method", (data?.method as string).toUpperCase());
                        setValue("config.source.payload.body", formatJSON(JSON.stringify(data?.body)) || {});
                        setValue("config.source.dataEntry", values.dataEntry);
                      },
                    }}
                  />
                }
                getValues={getValues}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={6}>
              <BXInput
                name={"config.paginationKey"}
                control={control}
                label='Pagination Key'
                variant='outlined'
                error={(errors as any)?.config?.key}
              />
            </Grid>
            <Grid item xs={6}>
              <BXInput
                name={"config.cursorKey"}
                control={control}
                label='Cursor Key'
                variant='outlined'
                error={(errors as any)?.config?.key}
              />
            </Grid>
          </>
        )}

        {watch("config.sourceType") === "LIST" && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"config.selectValues"}
              render={({ field: { onChange, value } }) => (
                <>
                  <Typography fontWeight={"400"} mb={2}>
                    Options
                  </Typography>
                  <MonacoEditor
                    height='150px'
                    language='json'
                    theme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) || "" : value}
                    options={{ colorDecorators: true }}
                    onChange={(newValue = "") => {
                      try {
                        onChange(newValue);
                        JSON.parse(newValue);
                      } catch (e: any) {}
                    }}
                  />
                </>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit(handleSave)} variant={"contained"} aria-label={"save"}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
