import { Box, Toolbar, Typography } from "@mui/material";
import Header from "src/layout/MainLayout/Header";

const BXNavBar = ({ $pageOutlet, $config, __config, metaData, $drawerOpen, $drawerToggle, ...rest }) => {
  if (__config) {
    return (
      <Toolbar sx={{ backgroundColor: "background.paper" }}>
        <Header handleLeftDrawerToggle={$drawerToggle} />
      </Toolbar>
    );
  }

  return (
    <Box {...rest}>
      <Box
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"} fontSize={"24px"}>
          NavBar
        </Typography>
      </Box>
    </Box>
  );
};

export { BXNavBar };
