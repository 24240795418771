import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { IconActivity, IconPlaylistAdd, IconTrashX } from "@tabler/icons-react";
import axios from "axios";
import _, { isArray } from "lodash";
import { FC, useEffect, useState } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import { useBXContext } from "src/BXEngine/BXContext";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { RHFIconPicker } from "src/components/BXUI/FormControls/RHFIconPicker";
import BXModal from "src/components/BXUI/Modal";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { BXApp } from "src/types/BXAppType";
import { UIElement } from "src/types/UIElement";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axiosServices from "src/utils/axios";
import { decompressData } from "src/utils/services";
import { DataSource } from "src/views/pages/BuildX/AppBuilder/components/DataSource";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import OASSelector from "../../OASSelector";
import SelectViewComponent from "../components/SelectViewComponent";
import UploadInfo from "../components/UploadInfo";
import ViewInfo from "../components/ViewInfo";
import DraggableRow from "./DraggableRow";
import StatusMessages from "./StatusMessages";

type CreateViewFormProps = {
  appId?: string;
  collectionId?: string;
  pageId?: string;
  viewId?: string;
  onSave: any;
  onCancel: Function;
  view?: UIElement;
  id?: string | number;
  tables: UIElement[];
  views: UIElement[] | undefined;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  layout?: string;
  app?: BXApp;
};

const CreateViewForm: FC<CreateViewFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  view,
  layout,
  id,
  tables,
  views,
  setIsDirty,
  appId,
  collectionId,
  pageId,
  viewId,
  app,
}) => {
  const schema = yup
    .object({
      type: yup.string().required("Field is required"),
      info: yup.object().shape({
        name: yup
          .string()
          .test("name", "View should be unique", name => {
            const result = views?.find(_view => _view?.info?.name == name && _view?.id != view?.id);
            return !result;
          })
          .required("Field is required"),
        viewName: yup
          .string()
          .test("name", "View should be unique", name => {
            const result = views?.find(_view => _view?.info?.viewName == name && _view?.id != view?.id);
            return !result;
          })
          .required("Field is required"),
      }),
      uploadConfig: yup.object().shape({
        uploadAllowedTypes: yup
          .array()
          .test("uploadAllowedTypes", "Field is required", values => (view?.type == "upload" ? !!values?.length : true))
          .required("Field is required"),
        signedUrl: yup.string().when("uploadUrlType", {
          is: (uploadUrlType: any) => uploadUrlType == "aws" && view?.type == "upload",
          then: yup.string().required("Field is required"),
        }),
        customUrl: yup.string().when("uploadUrlType", {
          is: (uploadUrlType: any) => uploadUrlType == "custom" && view?.type == "upload",
          then: yup.string().required("Field is required"),
        }),
      }),
      dataSource: yup.object().shape({
        userInputs: yup.array().of(
          yup.object().shape({
            label: yup.string().required("Field is required"),
            inputDataSource: yup.string().required("Field is required"),
          })
        ),
        apiUrl: yup.string().when("sourceType", {
          is: (sourceType: any) => sourceType !== "API" || view?.type == "upload",
          then: yup.string(),
          otherwise: yup.string().required("Field is required"),
        }),
        // payload: yup.object().shape({
        //   uri: yup.string().when("sourceType", {
        //     is: (sourceType: any) => sourceType !== "API" || view?.type == "upload",
        //     then: yup.string(),
        //     otherwise: yup.string().required("Field is required"),
        //   }),
        // }),

        tableUrl: yup.string().when("sourceType", {
          is: (sourceType: any) => sourceType != "TABLE",
          then: yup.string(),
          otherwise: yup.string().required("Field is required"),
        }),
        columnCount: yup.string().when("sourceType", {
          is: (sourceType: any) => view?.type == "form",
          then: yup.string().required("Field is required"),
        }),
      }),
      config: yup.object().shape({
        ...(view?.type != "repeated-view" && {
          columns: yup.array().of(
            yup.object().shape({
              type: yup.string().required("Type is required"),
              name: yup.string().when("type", {
                is: (type: any) => type !== "Actions",
                then: yup.string().required("Field is required"),
              }),
              source: yup.string().when("type", {
                is: (type: any) => type !== "View Builder" && type !== "Actions",
                then: yup.string().required("Source is required"),
              }),
              viewBuilderId: yup.string().when("type", {
                is: (type: any) => type == "View Builder",
                then: yup.string().required("Field is required"),
              }),
              dateFormat: yup.string().when("type", {
                is: (type: any) => type == "Date",
                then: yup.string().required("Field is required"),
              }),
            })
          ),
        }),
        ...(view?.type != "form-builder" && {
          actions: yup.array().of(
            yup.object().shape({
              label: yup.string().required("Field is required"),
              source: yup.string().when("actionSourceType.type", {
                is: "API",
                then: yup.string().required("Field is required"),
              }),
              actionViewType: yup.string().when("actionSourceType.type", {
                is: (data: any) => data != "API" && layout === "stack" && data != "Download",
                then: yup.string().required("Field is required"),
              }),
              method: yup.string().when("actionSourceType.type", {
                is: "API",
                then: yup.string().required("Field is required").default("GET"),
              }),
              isDismissibleView: yup.string().when("actionSourceType.type", {
                is: "API",
                then: yup.string().required("Field is required"),
              }),
              actionSourceViewType: yup.object().when("actionSourceType.type", {
                is: "Views",
                then: yup.object().shape({
                  id: yup.string().required("Field is required"),
                  type: yup.string().required("Field is required"),
                }),
                otherwise: yup.object().nullable(),
              }),
              BXLogin: yup.object().when("actionSourceType.type", {
                is: "BuildX Login",
                then: yup
                  .object()
                  .shape({
                    emailField: yup.string().required("Field is required"),
                    passwordField: yup.string().required("Field is required"),
                  })
                  .required("Field is required"),
                otherwise: yup.object().shape({
                  emailField: yup.string(),
                  passwordField: yup.string(),
                }),
              }),
            })
          ),
        }),
        ...(view?.type == "repeated-view" && {
          repeatedViews: yup.array().of(
            yup.object().shape({
              viewBuilderId: yup.string().required("Field is required"),
              type: yup.string().required("Field is required"),
            })
          ),
        }),
        ...(view?.type == "repeated-view" && {
          repeatedViewLayout: yup.object().shape({
            xs: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
            sm: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
            md: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
            lg: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
            xl: yup
              .number()
              .typeError("The field should be a number")
              .min(1, "Number should be between 1-5")
              .max(5, "Number should be between 1-5")
              .required("Field is required"),
          }),
        }),
      }),
    })
    .required();

  const [isTemplateError, setIsTemplateError] = useState();

  const [tabValue, setTabValue] = useState(1);

  function applyDefaultActionConfig(item: any) {
    if (!item) {
      return null;
    }

    const updatedItem = _.cloneDeep(item);

    if (updatedItem.config?.isAction) {
      updatedItem.actionConfig = {
        ...updatedItem.actionConfig,
        actionSourceType: updatedItem.actionConfig?.actionSourceType?.id
          ? updatedItem.actionConfig.actionSourceType
          : { id: -1, type: "API" },
        showModal: updatedItem.actionConfig?.showModal || "No",
        modalSize: updatedItem.actionConfig?.modalSize || "lg",
        actionViewType: updatedItem.actionConfig?.actionViewType || "Modal",
        isDismissibleView: updatedItem.actionConfig?.isDismissibleView || "Yes",
        downloadType: "directUrl",
      };
    }

    if (updatedItem.children?.length > 0) {
      updatedItem.children = updatedItem.children.map((child: any) => applyDefaultActionConfig(child));
    }

    return updatedItem;
  }

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm<FieldValues>({
    defaultValues: {
      ...view,
      dataSource: {
        ...view?.dataSource,
        apiUrl: view?.dataSource?.apiUrl || view?.dataSource?.url,
        tableUrl: view?.dataSource?.tableUrl || view?.dataSource?.url,
        userInputUrl: view?.dataSource?.userInputUrl || view?.dataSource?.url,
        sourceType: view?.dataSource?.sourceType || (view?.type === "form" || view?.type === "form-builder" ? "NONE" : "API"),
        simple: view?.dataSource?.simple || "{\n}",
        columnCount: view?.dataSource?.columnCount || 3,
        formBuilder: view?.dataSource?.formBuilder?.map(item => applyDefaultActionConfig(item)),
      },
      config: {
        ...view?.config,
        actions: view?.config?.actions?.map((item: any) => ({
          ...item,
          actionSourceType: item.actionSourceType?.id ? item.actionSourceType : { id: -1, type: "API" },
          showModal: item?.showModal || "No",
          modalSize: item?.modalSize || "lg",
          actionViewType: item?.actionViewType || "Modal",
          isDismissibleView: item?.isDismissibleView || "Yes",
          downloadType: "directUrl",
          showHeader: item?.showHeader ?? true,
          isResetEnabled: item?.isResetEnabled ?? true,
        })),
        repeatedViews: view?.config?.repeatedViews?.length ? view?.config?.repeatedViews : [{ id: "repeated-view", type: "repeated-view" }],
        repeatedViewLayout: {
          ...view?.config?.repeatedViewLayout,
          xs: view?.config?.repeatedViewLayout?.xs || 1,
          sm: view?.config?.repeatedViewLayout?.sm || 1,
          md: view?.config?.repeatedViewLayout?.md || 1,
          lg: view?.config?.repeatedViewLayout?.lg || 1,
          xl: view?.config?.repeatedViewLayout?.xl || 1,
        },
      },
      info: {
        ...view?.info,
        visibility: view?.info?.visibility || "Visible",
        showApiMode: view?.info?.showApiMode || "Visible",
        paginationMode:
          view?.info?.paginationMode === "Pagination" || !view?.info?.paginationMode ? "Pagination Bar" : view?.info?.paginationMode,
      },
      uploadConfig: {
        ...view?.uploadConfig,
        uploadAllowedTypes: view?.uploadConfig?.uploadAllowedTypes || [],
        uploadUrlType: view?.uploadConfig?.uploadUrlType || "aws",
        isInput: view?.uploadConfig?.isInput || "No",
        fileNumber: view?.uploadConfig?.fileNumber || 1,
      },
    },
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onSaveData = async (values: any, openBuilder?: boolean) => {
    const data = _.cloneDeep(values);
    if (values.dataSource?.sourceType === "API" || values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.apiUrl;
    } else if (values.dataSource?.sourceType === "TABLE") {
      values.dataSource.url = values.dataSource.tableUrl;
    } else if (values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.userInputUrl;
    }

    if (data?.type === "form" && data?.dataSource?.template && !isTemplateError) {
      const componentNames: any = [];
      const generateForm = (template: any, path = "") => {
        if (_.isObject(template) as any) {
          _.forOwn(template, (value, key) => {
            if (_.isArray(value)) {
              value?.forEach((item, index) => {
                if (_.isObject(item) as any) {
                  generateForm(value, path);
                }
              });
            } else if (_.isObject(value)) {
              generateForm(value, path);
            } else {
              if (value?.[0] === "{" && value?.[value?.length - 1] === "}") {
                const newValue = value.substring(1, value.length - 1);
                const options = newValue.split(",");
                const viewName = options?.[4];
                if (viewName) {
                  componentNames.push(viewName);
                }
              }
            }
          });
        }
      };

      let code = {};
      try {
        code = JSON.parse(data?.dataSource?.template);
      } catch (e) {}

      generateForm(code);
      const templateComponentIds: any = [];
      const names = componentNames?.join(",");
      if (names) {
        const { data } = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/admin/component/list?names=${names}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        data?.items?.forEach((item: any) => {
          templateComponentIds.push(item?.id);
        });
      }
      values.dataSource.templateComponentIds = templateComponentIds;
      return onSave(values);
    }

    if (values.dataSource?.sourceType === "API" || values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.apiUrl;
    } else if (values.dataSource?.sourceType === "TABLE") {
      values.dataSource.url = values.dataSource.tableUrl;
    } else if (values.dataSource?.sourceType === "USER INPUT") {
      values.dataSource.url = values.dataSource.userInputUrl;
    }
    onSave(values, openBuilder);
  };

  const handleBuilderClick = () => {
    handleSubmit((values: any) => onSaveData(values, true))();
  };

  const modalTabs = [
    {
      name: "info",
      label: "General",
      condition: view?.type != "upload",
      errorField: "info",
      value: 1,
    },
    {
      name: "repeatedViews",
      label: "View Layout",
      condition: view?.type == "repeated-view",
      errorField: "repeatedViewLayout",
      value: 7,
    },
    {
      name: "dataSource",
      label: "Data source",
      condition: view?.type != "upload",
      errorField: "dataSource",
      value: 2,
    },
    {
      name: "columns",
      label: "Columns",
      condition: view?.type == "data-table",
      errorField: "columns",
      value: 3,
    },
    {
      name: "actions",
      label: "Actions",
      condition: view?.type != "upload" && view?.type != "api" && view?.type !== "form-builder",
      errorField: "actions",
      value: 4,
    },
    {
      name: "reorder",
      label: "Reorder",
      condition: view?.type === "data-table" || view?.type === "repeated-view",
      errorField: "reorder",
      value: 5,
    },
    {
      name: "help",
      label: "Help",
      condition: view?.type != "api",
      errorField: "help",
      value: 6,
    },
  ];

  return (
    <Box component='form' noValidate autoComplete='off'>
      {view?.type != "upload" && (
        <Box sx={{ bgcolor: "background.paper" }}>
          <Tabs value={tabValue} onChange={handleChange} textColor='primary' indicatorColor='primary' style={{ textTransform: "none" }}>
            {view?.type != "upload" &&
              modalTabs.map(
                ({ value, label, condition, name, errorField }) =>
                  condition && (
                    <Tab
                      label={
                        <>
                          {(!!errors?.[errorField] ||
                            !!(errors as any)?.config?.[errorField] ||
                            !!(errors as any)?.config?.repeatedViews) && (
                            <Badge color='error' variant='dot' style={{ position: "absolute", top: 10, right: 10 }} />
                          )}
                          <Typography fontWeight={"bold"}>{label}</Typography>
                        </>
                      }
                      sx={{ textTransform: "none" }}
                      value={value}
                    />
                  )
              )}
          </Tabs>
        </Box>
      )}

      <Grid container spacing={3}>
        {tabValue == 1 && (
          <>
            <Grid item xs={12}>
              <ViewInfo control={control} errors={errors} viewType={view?.type} watch={watch} setValue={setValue} app={app} />
            </Grid>
            {view?.type == "upload" && (
              <Grid item xs={12}>
                <UploadInfo
                  control={control}
                  errors={errors}
                  viewType={view?.type}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
                />
              </Grid>
            )}
          </>
        )}
        {view?.type !== "upload" && tabValue == 2 && (
          <Grid item xs={12}>
            <DataSource
              tables={tables?.filter(item => item?.id != view?.id)}
              setValue={setValue}
              getValues={getValues}
              value={getValues("dataSource")}
              control={control}
              watch={watch}
              errors={errors}
              views={views}
              view={view}
              viewType={view?.type}
              setIsTemplateError={setIsTemplateError}
              onBuilderClick={handleBuilderClick}
              appId={appId}
              collectionId={collectionId}
              pageId={pageId}
              viewId={viewId}
            />
          </Grid>
        )}
        {view?.type != "api" && (
          <Grid item xs={12}>
            <ViewConfigEditor
              views={views}
              layout={layout}
              watch={watch}
              view={view}
              setValue={setValue}
              getValues={getValues}
              control={control}
              errors={errors}
              appId={appId}
              collectionId={collectionId}
              pageId={pageId}
              setIsDirty={setIsDirty}
              tabValue={tabValue}
              reset={reset}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button
              onClick={
                !isTemplateError
                  ? handleSubmit((values: any) => onSaveData(values))
                  : () => {
                      enqueueSnackbarRef?.(isTemplateError, {
                        variant: "error",
                      });
                    }
              }
              variant={"contained"}
              aria-label={"save"}
            >
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};

type ViewConfigEditorProps = {
  appId?: string;
  collectionId?: string;
  pageId?: string;
  view?: UIElement;
  watch: any;
  control: any;
  errors: any;
  setValue: any;
  getValues: any;
  views: UIElement[] | undefined;
  layout?: string;
  setIsDirty?: any;
  tabValue?: any;
  reset: any;
};

export const getHeadersValue = (headers: any) => {
  if (!headers) return "";
};

const dataTypes = [
  "String",
  "Date Time",
  "Boolean",
  "Image",
  "Video",
  "Audio",
  "Link",
  "View Builder",
  // , "Component"
];

const dataTypesAppearance = new Map([
  ["Boolean", ["Switch", "Checkbox"]],
  //Has a details button
]);

const hasConfigure = ["Boolean", "Date Time", "String", "View Builder", "Link", "Video", "Actions"];
export const actionTypes = [
  { id: -1, type: "API" },
  { id: -2, type: "Download" },
  { id: -3, type: "Export CSV" },
  { id: -4, type: "Copy" },
  { id: -5, type: "Link" },
  { id: -6, type: "Views" },
  { id: -8, type: "Dismiss" },
  { id: -9, type: "BuildX Login" },
  { id: -10, type: "Add" },
  { id: -11, type: "Delete" },
  { id: -12, type: "Update" },
  { id: -13, type: "Refresh Views" },
  { id: -14, type: "Reset Form" },
  { id: -15, type: "Stepper Next" },
  { id: -16, type: "Stepper Previous" },
  { id: -17, type: "Select Stepper" },
  { id: -18, type: "Timer" },
  { id: -19, type: "Show Snackbar" },
  { id: -20, type: "Clean Dirty" },
  { id: -21, type: "Trigger" },
  { id: -22, type: "Visibility Toggle" },
  { id: -23, type: "Start Loading" },
  { id: -24, type: "End Loading" },
];

const ViewConfigEditor: FC<ViewConfigEditorProps> = ({
  appId,
  collectionId,
  pageId,
  view,
  control,
  errors,
  layout,
  setValue,
  getValues,
  watch,
  views,
  setIsDirty,
  tabValue,
  reset,
}) => {
  const [actionTabValue, setActionTabValue] = useState("api");

  const { fields, append, remove, update, move } = useFieldArray({
    control,
    name: "config.columns",
  });

  const {
    fields: actions,
    append: appendAction,
    remove: removeAction,
    move: moveAction,
  } = useFieldArray({
    control,
    name: view?.type == "form-builder" ? "dataSource.formBuilder" : "config.actions",
  });

  const {
    fields: repeatedViews,
    append: appendRepeatedView,
    remove: removeRepeatedView,
    move: moveRepeatedView,
  } = useFieldArray({
    control,
    name: "config.repeatedViews",
  });

  const data = views?.filter(item => item?.id != view?.id);
  const actionSourceTypes = [...actionTypes];
  data?.forEach((item: any) => actionSourceTypes.push({ ...item, _invisible: true }));
  const actionViews = [] as any;
  data?.forEach((item: any) => actionViews.push(item));

  const repeatedViewType = [
    {
      id: "repeated-view",
      type: "Custom Grid",
    },
    { id: "normal", type: "Normal" },
  ];

  const [isRefreshing, setIsRefreshing] = useState(false);
  const { setAppDescriptor, setSelectedAppId } = useBXContext();

  const openNewWindow = () => {
    const urlToNavigate = `/buildx/app?appId=${appId}&collectionId=${collectionId}&pageId=${pageId}`;
    window.open(urlToNavigate, "_blank");
  };

  const handleAppSelect = (item: any) => {
    return new Promise<void>((resolve, reject) => {
      setSelectedAppId(item);
      if (item?.templateConfig == null) {
        axiosServices.get("/application/" + item).then(({ data }) => {
          if (data?.appConfig) {
            data.appConfig = decompressData(data?.appConfig);
          }
          if (data?.templateConfig) {
            data.templateConfig = decompressData(data?.templateConfig);
          }
          setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
          resolve();
        });
      }
    });
  };

  const formBuilderViews = views?.filter(item => item?.type == "form-builder");

  const moveTableColumnElement = (dragIndex: any, hoverIndex: number) => {
    move(dragIndex, hoverIndex);
  };

  const moveTableActionElement = (dragIndex: any, hoverIndex: number) => {
    moveAction(dragIndex, hoverIndex);
  };

  const moveTableViewElement = (dragIndex: any, hoverIndex: number) => {
    moveRepeatedView(dragIndex, hoverIndex);
  };

  let i = 0;
  const getActionRows = (actions: any, _path?: any, currentIndex?: any) => {
    return actions?.map((action: any, index: number) => {
      if (action?.children?.length && view?.type == "form-builder") {
        return getActionRows(action?.children, `${_path ? `${_path}.children[${index}]` : `[${index}]`}`, currentIndex || index);
      }

      if (!action?.config?.isAction && view?.type === "form-builder") return;
      i = i + 1;
      const path = `dataSource.formBuilder${_path ? `${_path}.children[${index}]` : `[${index}]`}`;

      const prefixActionPath = view?.type === "form-builder" ? `${path}.actionConfig` : `config.actions[${index}]`;
      const prefixActionErrorPath =
        view?.type === "form-builder" ? _.get(errors, `${path}.actionConfig`) : errors?.config?.actions?.[index];

      const actionSourceType = watch(`${prefixActionPath}.actionSourceType`)?.type;

      const isView =
        actionSourceType != "API" &&
        actionSourceType != "Download" &&
        actionSourceType != "Export CSV" &&
        actionSourceType != "Copy" &&
        actionSourceType != "Link" &&
        actionSourceType != "Dismiss" &&
        actionSourceType != "BuildX Login" &&
        actionSourceType != "Add" &&
        actionSourceType != "Update" &&
        actionSourceType != "Delete";

      const actionSourceTypeCurrentValue = isView ? -6 : watch(`${prefixActionPath}.actionSourceType`)?.id;

      const ParentComponent: any = view?.type != "form-builder" ? DraggableRow : TableRow;
      const parentComponentProps =
        view?.type != "form-builder"
          ? {
              component: TableRow,
              key: action?.id,
              id: action.id,
              index: index,
              name: "table-columns",
              moveElement: moveTableActionElement,
            }
          : { key: action?.id, id: action.id };

      const refreshViewsList = watch(`${prefixActionPath}.refreshActionView`);
      // * Check the views list type for the refresh to backward compatibility with prev. version
      const _type = typeof refreshViewsList?.[0];

      const autocompleteDefaultValue = (() => {
        if (_type === "string") {
          return refreshViewsList?.map(name => {
            const { id } = (views?.find(view => view?.info?.name === name) as UIElement) || {};
            return {
              id: id,
              name,
            };
          });
        }

        if (!refreshViewsList || refreshViewsList?.length === 0) {
          return [];
        }

        return (
          (isArray(refreshViewsList)
            ? refreshViewsList.map(view => {
                const _view = views?.find(v => v?.id === view.id);
                return {
                  ...view,
                  name: _view?.info?.name,
                };
              })
            : [
                {
                  ...refreshViewsList,
                  name: views?.find(view => view?.id === view.id)?.info?.name,
                },
              ]) || []
        );
      })();

      return (
        <ParentComponent {...parentComponentProps}>
          <TableCell>{i}.</TableCell>
          <TableCell style={{ minWidth: 150 }}>
            <BXInput
              name={
                view?.type === "form-builder"
                  ? action?.props?.children
                    ? `${path}.props.children`
                    : `${path}.props.key`
                  : `${prefixActionPath}.label`
              }
              disabled={view?.type === "form-builder"}
              control={control}
              error={prefixActionErrorPath?.label}
              label={"Label"}
              draggable
              onDragStart={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </TableCell>
          <TableCell style={{ minWidth: 150 }}>
            <FormControl fullWidth>
              <BXInput
                name={`${prefixActionPath}.actionSourceType.id`}
                control={control}
                error={prefixActionErrorPath?.actionSourceType?.id}
                value={actionSourceTypeCurrentValue}
                select
                label={"Source"}
                id='demo-simple-select'
                onChange={event => {
                  const selectedId = event?.target.value;
                  const actionSource = actionSourceTypes.find((view: any) => view?.id === selectedId);

                  if (actionSource?.id == -6) {
                    setValue(`${prefixActionPath}.actionSourceType`, watch(`${prefixActionPath}.actionSourceViewType`));
                  } else {
                    setValue(`${prefixActionPath}.actionSourceType`, { type: actionSource?.type, id: actionSource?.id });
                  }

                  // Check if the selected id is -3
                  if (selectedId == "-3") {
                    setValue(`${prefixActionPath}.isGlobal`, true);
                    setValue(`${prefixActionPath}.isItem`, false);
                  }
                }}
              >
                {actionSourceTypes
                  .filter((item: any) => !(view?.type === "form-builder" && item?.type === "Export CSV"))
                  .map((item: any) => (
                    <MenuItem key={item?.id} value={item?.id} style={{ display: item?._invisible ? "none" : "block" }}>
                      {item?.info?.name || item?.type}
                    </MenuItem>
                  ))}
              </BXInput>
            </FormControl>
          </TableCell>

          <TableCell style={{ minWidth: 250 }}>
            <RHFIconPicker
              name={`${prefixActionPath}.iconConfig.icon`}
              objName={`${prefixActionPath}.iconConfig`}
              control={control}
              error={prefixActionErrorPath?.iconConfig?.icon}
              label={"Icon"}
              showCaption={false}
              setValue={setValue}
            />
          </TableCell>
          <TableCell width={50}>
            {watch(`${prefixActionPath}.actionSourceType`)?.type !== "Export CSV" && (
              <TabContext value={actionTabValue}>
                <BXModal
                  label={"Configure"}
                  buttonProps={{
                    variant: "outlined",
                    style: !!prefixActionErrorPath
                      ? {
                          borderColor: "red",
                        }
                      : {},
                  }}
                  onClose={() => {
                    setActionTabValue("api");
                  }}
                  title={"Configuration"}
                >
                  {(handleClose: Function) => {
                    return (
                      <>
                        {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                          <TabList
                            onChange={(event, newValue) => {
                              setActionTabValue(newValue);
                            }}
                          >
                            <Tab sx={{ textTransform: "none" }} label='API' value='api' />
                            {/* <Tab sx={{ textTransform: "none" }} label='Payload' value='payload' /> */}
                            <Tab sx={{ textTransform: "none" }} label='Messages' value='messages' />
                          </TabList>
                        )}
                        <TabPanel sx={{ width: "100%" }} value='api'>
                          <Grid container spacing={2.5}>
                            {isView && (
                              <Grid item xs={12}>
                                <FormControl fullWidth>
                                  <BXInput
                                    name={`${prefixActionPath}.actionSourceViewType.id`}
                                    control={control}
                                    error={prefixActionErrorPath?.actionSourceViewType?.id}
                                    select
                                    label={"Select View"}
                                    id='demo-simple-select'
                                    onChange={event => {
                                      const selectedId = event?.target.value;
                                      const view = actionViews.find((view: any) => view?.id === selectedId);
                                      setValue(`${prefixActionPath}.actionSourceViewType`, { type: view?.type, id: view?.id });
                                      setValue(`${prefixActionPath}.actionSourceType`, { type: view?.type, id: view?.id });
                                    }}
                                  >
                                    {actionViews.map((item: any) => (
                                      <MenuItem key={item?.id} value={item?.id}>
                                        {item?.info?.name || item?.type}
                                      </MenuItem>
                                    ))}
                                  </BXInput>
                                </FormControl>
                              </Grid>
                            )}
                            {layout === "stack" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "API" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Download" && (
                                <Grid item xs={12}>
                                  <FormControl fullWidth>
                                    <BXInput
                                      name={`${prefixActionPath}.actionViewType`}
                                      control={control}
                                      error={prefixActionErrorPath?.actionViewType}
                                      select
                                      label={"Navigation"}
                                    >
                                      {["Modal", "Stack"]?.map((item: any) => (
                                        <MenuItem key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </BXInput>
                                  </FormControl>
                                </Grid>
                              )}
                            {watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
                              <Tabs
                                value={watch(`${prefixActionPath}.downloadType`)}
                                onChange={(_, newValue) => {
                                  setValue(`${prefixActionPath}.downloadType`, newValue);
                                }}
                                aria-label='basic tabs example'
                              >
                                <Tab sx={{ textTransform: "none" }} label='Direct Url' value='directUrl' />
                                <Tab sx={{ textTransform: "none" }} label='API' value='api' />
                              </Tabs>
                            )}
                            {(watch(`${prefixActionPath}.actionSourceType`)?.type == "API" ||
                              (watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" &&
                                watch(`${prefixActionPath}.downloadType`) === "directUrl")) && (
                              <Grid item xs={12}>
                                <ApiInput
                                  watch={watch}
                                  getValues={getValues}
                                  setValue={setValue}
                                  error={prefixActionErrorPath}
                                  errorURL={prefixActionErrorPath?.source}
                                  apiLabel='Url'
                                  control={control}
                                  path={`${prefixActionPath}`}
                                  pathURL={`${prefixActionPath}.source`}
                                  OASElement={
                                    <OASSelector
                                      swaggerProps={{
                                        type: "action",
                                        isForm: view?.type === "form",
                                        template: watch("dataSource.template"),
                                        columns: watch("config.columns"),
                                        onSuccess: (values: any) => {
                                          setValue(`${prefixActionPath}.uri`, values.action.source);
                                          setValue(`${prefixActionPath}.source`, values.action.source);
                                          setValue(`${prefixActionPath}.method`, (values.action.method as string).toUpperCase());
                                          setValue(`${prefixActionPath}.body`, formatJSON(values.action.body));
                                          setValue(`${prefixActionPath}.headers`, values.action.headers);
                                        },
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            )}
                            {watch(`${prefixActionPath}.downloadType`) === "api" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
                                <Grid item container spacing={2}>
                                  <Grid item xs={12}>
                                    <BXInput
                                      label={"Endpoint"}
                                      name={`${prefixActionPath}.directUrl`}
                                      control={control}
                                      error={prefixActionErrorPath?.directUrl}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <BXInput
                                      label={"Key"}
                                      name={`${prefixActionPath}.downloadKey`}
                                      control={control}
                                      error={prefixActionErrorPath?.downloadKey}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                              <>
                                {/* <Grid item xs={12}>
                                  <BXInput
                                    label={"Method"}
                                    name={`${prefixActionPath}.method`}
                                    select
                                    control={control}
                                    error={prefixActionErrorPath?.method}
                                  >
                                    {["GET", "POST", "PUT", "PATCH", "DELETE"]?.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </BXInput>
                                </Grid> */}
                                {view?.type === "form-builder" && (
                                  <Grid item xs={12}>
                                    <BXInput
                                      label={"Response Root Key"}
                                      variant='outlined'
                                      control={control}
                                      name={`${prefixActionPath}.dataEntry`}
                                      error={prefixActionErrorPath?.dataEntry}
                                    />
                                  </Grid>
                                )}

                                <Grid item xs={12}>
                                  <FormControl>
                                    <BXSwitch
                                      name={`${prefixActionPath}.navigationAfterAction`}
                                      control={control}
                                      label={"Navigation after action"}
                                      error={prefixActionErrorPath?.refreshAfterAction}
                                    />
                                  </FormControl>
                                </Grid>

                                {watch(`${prefixActionPath}.navigationAfterAction`) && (
                                  <>
                                    <Grid item xs={12}>
                                      <BXInput
                                        label={"Navigation Link"}
                                        fullWidth
                                        name={`${prefixActionPath}.linkUrl`}
                                        control={control}
                                        error={prefixActionErrorPath?.linkUrl}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <BXInput
                                        label={"Open Link As"}
                                        name={`${prefixActionPath}.openLinkAs`}
                                        control={control}
                                        select
                                        error={prefixActionErrorPath?.openLinkAs}
                                      >
                                        {[
                                          { value: "_blank", label: "New Tab" },
                                          { value: "_self", label: "Same Tab" },
                                        ].map(option => (
                                          <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </BXInput>
                                    </Grid>
                                  </>
                                )}
                                <Grid item xs={12}>
                                  <FormControl>
                                    <BXSwitch
                                      name={`${prefixActionPath}.isResetEnabled`}
                                      control={control}
                                      label={"Reset form after action"}
                                      error={prefixActionErrorPath?.isResetEnabled}
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                            {watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
                              <Grid item xs={4}>
                                <BXSwitch
                                  name={`${prefixActionPath}.openUrl`}
                                  control={control}
                                  error={prefixActionErrorPath?.openUrl}
                                  label={"Open Url"}
                                />
                              </Grid>
                            )}
                            {watch(`${prefixActionPath}.actionSourceType`)?.type === "Copy" && (
                              <Grid item xs={12}>
                                <BXInput
                                  label={"Copy Text Source"}
                                  fullWidth
                                  name={`${prefixActionPath}.copySource`}
                                  control={control}
                                  error={prefixActionErrorPath?.copySource}
                                />
                              </Grid>
                            )}
                            {watch(`${prefixActionPath}.actionSourceType`)?.type === "BuildX Login" && (
                              <Grid item xs={12}>
                                <BXInput
                                  label={"Email Field"}
                                  fullWidth
                                  name={`${prefixActionPath}.BXLogin.emailField`}
                                  control={control}
                                  error={prefixActionErrorPath?.BXLogin?.emailField}
                                />
                              </Grid>
                            )}

                            {watch(`${prefixActionPath}.actionSourceType`)?.type === "BuildX Login" && (
                              <Grid item xs={12}>
                                <BXInput
                                  label={"Password Field"}
                                  fullWidth
                                  name={`${prefixActionPath}.BXLogin.passwordField`}
                                  control={control}
                                  error={prefixActionErrorPath?.BXLogin?.passwordField}
                                />
                              </Grid>
                            )}

                            {watch(`${prefixActionPath}.actionSourceType`)?.type === "Link" && (
                              <>
                                <Grid item xs={12}>
                                  <BXInput
                                    label={"Open Link As"}
                                    name={`${prefixActionPath}.openLinkAs`}
                                    control={control}
                                    select
                                    error={prefixActionErrorPath?.openLinkAs}
                                    defaultValue='_self'
                                  >
                                    {[
                                      { value: "_blank", label: "New Tab" },
                                      { value: "_self", label: "Same Tab" },
                                    ].map(option => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </BXInput>
                                </Grid>
                                <Grid item xs={12}>
                                  <BXInput
                                    label={"URL Link"}
                                    fullWidth
                                    name={`${prefixActionPath}.linkUrl`}
                                    control={control}
                                    error={prefixActionErrorPath?.linkUrl}
                                  />
                                </Grid>
                              </>
                            )}
                            {view?.type != "form" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Download" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Export CSV" && (
                                <Grid item xs={12}>
                                  <BXInput
                                    label={"Condition"}
                                    fullWidth
                                    name={`${prefixActionPath}.condition`}
                                    control={control}
                                    error={prefixActionErrorPath?.condition}
                                  />
                                </Grid>
                              )}
                            {(view?.type == "card-list" || view?.type == "image-grid" || view?.type == "form-builder") &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                                <Grid item xs={12}>
                                  <BXInput
                                    label={"Highlight Value"}
                                    fullWidth
                                    name={`${prefixActionPath}.highlightedValue`}
                                    control={control}
                                    error={prefixActionErrorPath?.highlightedValue}
                                  />
                                </Grid>
                              )}
                            {view?.type != "form" && watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                              <Grid item xs={12}>
                                <BXInput
                                  label={"Show Modal"}
                                  name={`${prefixActionPath}.showModal`}
                                  control={control}
                                  select
                                  error={prefixActionErrorPath?.showModal}
                                >
                                  {["Yes", "No"]?.map(item => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </BXInput>
                              </Grid>
                            )}
                            {watch(`${prefixActionPath}.actionSourceType`)?.type != "API" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Download" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Link" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Copy" &&
                              view?.type != "form" &&
                              watch(`${prefixActionPath}.actionViewType`) != "Stack" && (
                                <Grid item xs={12}>
                                  <BXInput
                                    label={"Modal Size"}
                                    name={`${prefixActionPath}.modalSize`}
                                    control={control}
                                    select
                                    error={prefixActionErrorPath?.modalSize}
                                  >
                                    {[
                                      { label: "Extra Small (xs)", value: "xs" },
                                      { label: "Small (sm)", value: "sm" },
                                      { label: "Medium (md)", value: "md" },
                                      { label: "Large (lg)", value: "lg" },
                                      { label: "Extra Large (xl)", value: "xl" },
                                    ]?.map(item => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </BXInput>
                                </Grid>
                              )}
                            {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                              <>
                                <Grid item xs={12}>
                                  <BXInput
                                    label={"Dismissible View"}
                                    name={`${prefixActionPath}.isDismissibleView`}
                                    control={control}
                                    select
                                    error={prefixActionErrorPath?.isDismissibleView}
                                  >
                                    {["Yes", "No"]?.map(item => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </BXInput>
                                </Grid>

                                <Grid item xs={12}>
                                  <FormControl>
                                    <BXSwitch
                                      name={`${prefixActionPath}.refreshAfterAction`}
                                      control={control}
                                      label={"Refresh after action"}
                                      error={(errors as any)?.config?.actions?.[index]?.refreshAfterAction}
                                    />
                                  </FormControl>
                                </Grid>

                                {watch(`${prefixActionPath}.refreshAfterAction`) && (
                                  <>
                                    <Grid item xs={12}>
                                      {/* <BXInput
                                        name={`${prefixActionPath}.refreshActionView.id`}
                                        control={control}
                                        error={prefixActionErrorPath?.refreshActionView?.id}
                                        select
                                        label={"View to refresh"}
                                        id='demo-simple-select'
                                        onChange={event => {
                                          const selectedId = event?.target.value;
                                          setValue(
                                            `${prefixActionPath}.refreshActionView`,
                                            actionViews.find((view: any) => view?.id === selectedId)
                                          );
                                        }}
                                      >
                                        {actionViews.map((item: any) => (
                                          <MenuItem key={item?.id} value={item?.id}>
                                            {item?.info?.name || item?.type}
                                          </MenuItem>
                                        ))}
                                      </BXInput> */}
                                      <Autocomplete
                                        defaultValue={autocompleteDefaultValue}
                                        multiple
                                        id='views-autocomplete'
                                        options={actionViews.map((view: any) => ({ id: view?.id, name: view?.info?.name }))}
                                        getOptionLabel={option => option?.name || ""}
                                        onChange={(e, value) => {
                                          setValue(`${prefixActionPath}.refreshActionView`, value);
                                        }}
                                        disableCloseOnSelect
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        renderOption={(props, option, { selected }) => {
                                          return (
                                            <li {...props} key={option?.id}>
                                              <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                                                checkedIcon={<CheckBoxIcon fontSize='small' />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option?.name}
                                            </li>
                                          );
                                        }}
                                        style={{ width: 500 }}
                                        renderInput={params => <TextField {...params} label='Views' placeholder='' />}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </>
                            )}
                            {view?.type != "form" && view?.type != "form-builder" && (
                              <Grid item xs={12}>
                                <FormControl>
                                  <BXSwitch
                                    name={`${prefixActionPath}.showButtonLabel`}
                                    control={control}
                                    label={"Show Button Label"}
                                    error={(errors as any)?.config?.actions?.[index]?.showButtonLabel}
                                  />
                                </FormControl>
                              </Grid>
                            )}
                            {watch(`${prefixActionPath}.actionSourceType`)?.type != "API" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Download" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Link" &&
                              watch(`${prefixActionPath}.actionSourceType`)?.type != "Copy" &&
                              watch(`${prefixActionPath}.actionViewType`) !== "Stack" &&
                              view?.type != "form-builder" && (
                                <Grid item xs={12}>
                                  <FormControl>
                                    <BXSwitch
                                      name={`${prefixActionPath}.showHeader`}
                                      control={control}
                                      label={"Show Modal Header"}
                                      error={(errors as any)?.config?.actions?.[index]?.showHeader}
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                            <Grid item xs={12}>
                              <FormControl>
                                {view?.type != "form-builder" && watch(`${prefixActionPath}.actionSourceType`)?.type != "Link" && (
                                  <BXSwitch
                                    name={`${prefixActionPath}.enableCSV`}
                                    control={control}
                                    label={"Enable CSV"}
                                    error={(errors as any)?.config?.actions?.[index]?.enableCSV}
                                  />
                                )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl>
                                {view?.type != "form-builder" && watch(`${prefixActionPath}.actionSourceType`)?.type != "Link" && (
                                  <BXSwitch
                                    name={`${prefixActionPath}.enableSelectedRows`}
                                    control={control}
                                    label={"Enable for Selected Rows"}
                                    error={(errors as any)?.config?.actions?.[index]?.enableSelectedRows}
                                  />
                                )}
                              </FormControl>
                            </Grid>

                            {watch(`${prefixActionPath}.enableCSV`) && (
                              <>
                                {watch(`${prefixActionPath}.actionSourceType`)?.type === "API" && (
                                  <Grid item xs={12}>
                                    <BXInput
                                      label={"Duration (ms)"}
                                      fullWidth
                                      name={`${prefixActionPath}.duration`}
                                      control={control}
                                      error={prefixActionErrorPath?.duration}
                                    />
                                  </Grid>
                                )}
                                {watch(`${prefixActionPath}.actionSourceType`)?.type === "API" && (
                                  <Grid item xs={12}>
                                    <BXInput
                                      label={"Max Calls"}
                                      fullWidth
                                      name={`${prefixActionPath}.maxCalls`}
                                      control={control}
                                      error={prefixActionErrorPath?.calls}
                                    />
                                  </Grid>
                                )}
                              </>
                            )}
                            {view?.type !== "repeated-view" && (
                              <Grid item xs={12}>
                                <FormControl>
                                  <Box display='flex' alignItems='center'>
                                    <Typography style={{ marginInlineEnd: "8px" }}>Level</Typography>
                                    <Switch
                                      defaultChecked={watch(`${prefixActionPath}.isGlobal`)}
                                      onChange={(_, value) => {
                                        setValue(`${prefixActionPath}.isGlobal`, value);
                                        setValue(`${prefixActionPath}.isItem`, !value);
                                        ActionColumn();
                                      }}
                                      aria-label={watch(`${prefixActionPath}.isGlobal`) ? "Global Level" : "Row Level"}
                                    />
                                    <Typography>
                                      {watch(`${prefixActionPath}.isGlobal`) ? "Show in action header" : "Show in each row"}
                                    </Typography>
                                  </Box>
                                </FormControl>
                              </Grid>
                            )}
                          </Grid>
                        </TabPanel>

                        <TabPanel sx={{ width: "100%" }} value='messages'>
                          <Grid container spacing={2.5}>
                            <StatusMessages actionIndex={index} control={control} namePrefix={prefixActionPath} />
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12}>
                              <BXSwitch
                                name={`${prefixActionPath}.dialog.enabled`}
                                control={control}
                                label={"Confirmation"}
                                error={(errors as any)?.config?.actions?.[index]?.dialog?.enabled}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <BXInput
                                label={"Message"}
                                variant='outlined'
                                multiline
                                control={control}
                                name={`${prefixActionPath}.dialog.message`}
                                error={(errors as any)?.config?.actions?.[index]?.dialog?.message}
                                disabled={!watch(`${prefixActionPath}.dialog.enabled`)}
                              />
                            </Grid>
                          </Grid>
                        </TabPanel>
                      </>
                    );
                  }}
                </BXModal>
              </TabContext>
            )}
          </TableCell>
          <TableCell>
            <IconButton
              onClick={() => {
                const newData = getValues();
                newData.config.actions = newData.config.actions?.filter((a: any, _index: number) => _index != index);
                reset(newData);
                ActionColumn();
              }}
            >
              <IconTrashX />
            </IconButton>
          </TableCell>
        </ParentComponent>
      );
    });
  };

  const ActionColumn = () => {
    if (view?.type === "data-table") {
      const configActions = watch("config.actions");
      const configColumns = watch("config.columns");
      const actionInEachRow = watch("config.actions").filter(action => action.isItem);

      if (configActions?.length && actionInEachRow?.length) {
        if (!configColumns.some(col => col.type === "Actions")) {
          append({ id: uuid(), name: "", type: "Actions" });
        }
      } else {
        const actionsColumnIndex = configColumns.findIndex(col => col.type === "Actions");
        if (actionsColumnIndex !== -1) {
          remove(actionsColumnIndex);
        }
      }
    }
  };

  const openView = viewBuilderId => {
    const url = `form-builder/${appId}/${collectionId}/${pageId}/${viewBuilderId}`;
    window.open(url, "_blank");
  };

  return (
    <Grid container spacing={3}>
      {view?.type === "data-table" && tabValue == 3 && (
        <Grid item style={{ width: "100%" }}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Column Label</TableCell>
                      <TableCell>Type</TableCell>
                      {/* <TableCell>Components</TableCell> */}
                      <TableCell>Data Source</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(fields) && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography textAlign={"center"}>No columns</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {fields?.map((col: any, index: number) => (
                      <DraggableRow
                        component={TableRow}
                        key={col?.id}
                        id={col.id}
                        index={index}
                        name='table-columns'
                        moveElement={moveTableColumnElement}
                      >
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell width={250}>
                          <BXInput
                            label={"Label"}
                            name={`config.columns[${index}].name`}
                            control={control}
                            error={errors?.config?.columns?.[index]?.name}
                            draggable
                            onDragStart={e => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          />
                        </TableCell>

                        <TableCell width={250}>
                          <FormControl fullWidth>
                            {col.type !== "Actions" && (
                              <BXInput
                                name={`config.columns[${index}].type`}
                                control={control}
                                error={errors?.config?.columns?.[index]?.type}
                                select
                                label={"Type"}
                                id='demo-simple-select'
                                onChange={() => {
                                  setValue(
                                    `config.columns[${index}].dataAppearance`,
                                    hasConfigure.includes(watch(`config.columns[${index}].type`))?.[0]
                                  );
                                }}
                              >
                                {dataTypes?.map(item => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </BXInput>
                            )}
                            {col.type === "Actions" && (
                              <BXInput
                                label={"Type"}
                                name={`config.columns[${index}].type`}
                                control={control}
                                error={errors?.config?.columns?.[index]?.type}
                                draggable
                                disabled
                                onDragStart={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              />
                            )}
                          </FormControl>
                        </TableCell>

                        {col.type !== "Actions" ? (
                          <TableCell>
                            {watch(`config.columns[${index}].type`) !== "View Builder" && (
                              <BXInput
                                label={"Source"}
                                name={`config.columns[${index}].source`}
                                control={control}
                                error={errors?.config?.columns?.[index]?.source}
                                draggable
                                disabled={watch(`config.columns[${index}].type`) === "View Builder"}
                                onDragStart={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              />
                            )}
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        <TableCell>
                          {hasConfigure.includes(watch(`config.columns[${index}].type`)) && (
                            <BXModal
                              label={"Configure"}
                              buttonProps={{
                                variant: "outlined",
                                style:
                                  errors?.config?.columns?.[index]?.dateFormat || errors?.config?.columns?.[index]?.viewBuilderId
                                    ? {
                                        borderColor: "red",
                                      }
                                    : {},
                              }}
                              onClose={() => {
                                setActionTabValue("api");
                              }}
                              title={"Configuration"}
                            >
                              {(handleClose: Function) => {
                                const selectedType = watch(`config.columns[${index}].type`);
                                return (
                                  <Grid container xs={12} padding={2} display={"flex"} flexDirection={"column"} gap={1}>
                                    <Grid container xs={12} gap={2}>
                                      {dataTypesAppearance.get(selectedType) && (
                                        <Grid xs={3} item>
                                          <BXInput
                                            name={`config.columns[${index}].dataAppearance`}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.dataAppearance}
                                            select
                                            label={"Data Appearance"}
                                            id='demo-simple-select'
                                            defaultValue={"Switch"}
                                          >
                                            {dataTypesAppearance.get(selectedType)?.map(item => (
                                              <MenuItem key={item} value={item}>
                                                {item}
                                              </MenuItem>
                                            ))}
                                          </BXInput>
                                        </Grid>
                                      )}
                                      {selectedType === "String" && (
                                        <BXSwitch
                                          name={`config.columns[${index}].allowWrap`}
                                          control={control}
                                          error={errors?.config?.columns?.[index]?.allowWrap}
                                          label={"Enable content wrapping"}
                                        />
                                      )}
                                      {selectedType === "Date Time" && (
                                        <>
                                          <Grid xs={3}>
                                            <BXInput
                                              name={`config.columns[${index}].dateFormat`}
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.dateFormat}
                                              placeholder='Ex. YYYY/MM/DD'
                                              label={"Date Format"}
                                            />
                                          </Grid>
                                          <Grid xs={12}>
                                            <BXSwitch
                                              name={`config.columns[${index}].isUserLocalTime`}
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.isUserLocalTime}
                                              label={"User local time"}
                                            />
                                          </Grid>
                                          {/* <Grid xs={12} container style={{ justifyContent: "center", alignContent: "center" }}>
                                            <Button
                                              variant='contained'
                                              onClick={() => {
                                                setValue(
                                                  `errors.config.columns[${index}].dataAppearance`,
                                                  dateValidator(watch(`config.columns[${index}].dataAppearance`))
                                                );
                                              }}
                                            >
                                              Done
                                            </Button>
                                          </Grid> */}
                                        </>
                                      )}

                                      {selectedType === "View Builder" && (
                                        <SelectViewComponent
                                          fieldName={`config.columns[${index}].viewBuilderId`}
                                          isRefreshing={isRefreshing}
                                          setIsRefreshing={setIsRefreshing}
                                          handleAppSelect={handleAppSelect}
                                          appId={appId}
                                          openView={openView}
                                          openNewWindow={openNewWindow}
                                          watch={watch}
                                          control={control}
                                          error={errors?.config?.columns?.[index]?.viewBuilderId}
                                          formBuilderViews={formBuilderViews}
                                          setValue={setValue}
                                        />
                                      )}
                                      {selectedType === "Link" && (
                                        <>
                                          <Grid item xs={12}>
                                            <BXInput
                                              label={"Open Link As"}
                                              name={`config.columns[${index}].openLinkAs`}
                                              control={control}
                                              select
                                              error={errors?.config?.columns?.[index]?.openLinkAs}
                                              defaultValue='_self'
                                            >
                                              {[
                                                { value: "_blank", label: "New Tab" },
                                                { value: "_self", label: "Same Tab" },
                                              ].map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </BXInput>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <BXInput
                                              label={"URL Link"}
                                              fullWidth
                                              name={`config.columns[${index}].linkUrl`}
                                              control={control}
                                              error={errors?.config?.columns?.[index]?.linkUrl}
                                            />
                                          </Grid>
                                        </>
                                      )}
                                      {selectedType === "Video" && (
                                        <>
                                          <BXSwitch
                                            name={`config.columns[${index}].allowHyperlink`}
                                            control={control}
                                            error={errors?.config?.columns?.[index]?.allowHyperlink}
                                            label={"Enable Link"}
                                          />
                                          {watch(`config.columns[${index}].allowHyperlink`) === true && (
                                            <Grid xs={6}>
                                              <RHFIconPicker
                                                name={`config.columns[${index}].videoHyperlinkIcon`}
                                                objName={`config.columns[${index}].iconConfig`}
                                                control={control}
                                                error={errors?.config?.columns?.[index]?.videoHyperlinkIcon}
                                                label={"Link Icon"}
                                                setValue={setValue}
                                              />
                                            </Grid>
                                          )}
                                        </>
                                      )}
                                    </Grid>

                                    <Grid container gap={1} mt={2}>
                                      <Grid item xs={4}>
                                        <BXInput
                                          name={`config.columns[${index}].customWidth`}
                                          type='number'
                                          control={control}
                                          error={errors?.config?.columns?.[index]?.customWidth}
                                          label={"Custom Width"}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <BXInput
                                          name={`config.columns[${index}].alignColumn`}
                                          control={control}
                                          select
                                          error={errors?.config?.columns?.[index]?.alignColumn}
                                          label={"Align Column"}
                                        >
                                          {["Left", "Right", "Center"]?.map((item: any) => (
                                            <MenuItem key={item} value={item.toLowerCase()}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </BXInput>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <BXSwitch
                                        name={`config.columns[${index}].allowCopy`}
                                        control={control}
                                        error={errors?.config?.columns?.[index]?.allowCopy}
                                        label={"Enable Copy Value"}
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }}
                            </BXModal>
                          )}
                        </TableCell>
                        {col.type !== "Actions" ? (
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                const newData = getValues();
                                newData.config.columns = newData.config.columns?.filter((a: any, _index: number) => _index != index);
                                reset(newData);
                              }}
                            >
                              <IconTrashX />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </DraggableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <Grid container justifyContent={"center"} alignItems='center' mb={2}>
              <Button
                variant='outlined'
                startIcon={<IconPlaylistAdd />}
                onClick={() => {
                  append({
                    id: uuid(),
                    type: "String",
                    dateFormat: "YYYY/MM/DD",
                    alignColumn: "left",
                    videoHyperlinkIcon: "Video",
                  });
                }}
              >
                Add Column
              </Button>
            </Grid>
          </Card>
        </Grid>
      )}
      {view?.type === "repeated-view" && tabValue == 7 && (
        <Grid item style={{ width: "100%" }}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={"bold"}>Number of Views in Display</Typography>
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.xs"}
                    error={errors?.config?.repeatedViewLayout?.xs}
                    control={control}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Extra Small (xs)"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.sm"}
                    error={errors?.config?.repeatedViewLayout?.sm}
                    control={control}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Small (sm)"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.md"}
                    control={control}
                    error={errors?.config?.repeatedViewLayout?.md}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Medium (md)"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.lg"}
                    error={errors?.config?.repeatedViewLayout?.lg}
                    control={control}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Large (lg)"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXInput
                    name={"config.repeatedViewLayout.xl"}
                    error={errors?.config?.repeatedViewLayout?.xl}
                    control={control}
                    type='number'
                    inputProps={{
                      min: 1,
                      max: 5,
                    }}
                    fullWidth
                    label={"Extra Large (xl)"}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Views in Layout</TableCell>
                      <TableCell>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(repeatedViews) && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography textAlign={"center"}>No views</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {repeatedViews?.map((col: any, index: number) => (
                      <DraggableRow
                        component={TableRow}
                        key={col?.id}
                        id={col.id}
                        index={index}
                        name='repeated-view'
                        moveElement={moveTableViewElement}
                      >
                        <TableCell>{index + 1}.</TableCell>

                        <TableCell width={700}>
                          <SelectViewComponent
                            fieldName={`config.repeatedViews[${index}].viewBuilderId`}
                            isRefreshing={isRefreshing}
                            setIsRefreshing={setIsRefreshing}
                            handleAppSelect={handleAppSelect}
                            appId={appId}
                            openView={openView}
                            openNewWindow={openNewWindow}
                            watch={watch}
                            control={control}
                            error={errors?.config?.repeatedViews?.[index]?.viewBuilderId}
                            formBuilderViews={formBuilderViews}
                            setValue={setValue}
                          />
                        </TableCell>

                        <TableCell width={400}>
                          <FormControl fullWidth>
                            <BXInput
                              name={`config.repeatedViews[${index}].type`}
                              control={control}
                              error={errors?.config?.repeatedViews?.[index]?.type}
                              select
                              label={"Type"}
                              disabled={watch(`config.repeatedViews[${index}].type`) == "repeated-view"}
                            >
                              {repeatedViewType?.map((item: any) => {
                                if (watch(`config.repeatedViews[${index}].type`) != "repeated-view" && item.id == "repeated-view") {
                                  return <></>;
                                }
                                return (
                                  <MenuItem key={item?.id} value={item?.id}>
                                    {item?.info?.name || item?.type}
                                  </MenuItem>
                                );
                              })}
                            </BXInput>
                          </FormControl>
                        </TableCell>

                        <TableCell align='right'>
                          {watch(`config.repeatedViews[${index}].type`) != "repeated-view" && (
                            <IconButton
                              onClick={() => {
                                const newData = getValues();
                                newData.config.repeatedViews = newData.config.repeatedViews?.filter(
                                  (a: any, _index: number) => _index != index
                                );
                                reset(newData);
                              }}
                            >
                              <IconTrashX />
                            </IconButton>
                          )}
                        </TableCell>
                      </DraggableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <Grid container justifyContent={"center"} alignItems='center' mb={2}>
              <Button variant='outlined' startIcon={<IconPlaylistAdd />} onClick={() => appendRepeatedView({ id: uuid(), type: "normal" })}>
                Add View
              </Button>
            </Grid>
          </Card>
        </Grid>
      )}

      {view?.type !== "upload" && view?.type !== "form-builder" && tabValue == 4 && (
        <Grid item overflow={"hidden"} width='100%'>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Action name</TableCell>
                      <TableCell width={300}>Source</TableCell>
                      {/* {layout === "stack" && <TableCell>Navigation</TableCell>} */}

                      <TableCell>Icon</TableCell>
                      {/* <TableCell width={300}>URL</TableCell> */}
                      {/* <TableCell>Method</TableCell> */}

                      {/* {view?.type == "card-list" && <TableCell>Highlight value</TableCell>} */}

                      {/* <TableCell>Modal Size</TableCell> */}
                      {/* <TableCell>Dismissible View</TableCell> */}
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.isEmpty(actions) && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Typography textAlign={"center"}>No action</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {getActionRows(actions)}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            {view?.type != "form-builder" && (
              <Grid container justifyContent={"center"} alignItems='center' mb={2}>
                <Button
                  variant='outlined'
                  startIcon={<IconActivity />}
                  onClick={() => {
                    appendAction({
                      id: uuid(),
                      type: "API",
                      showModal: "No",
                      isGlobal: true,
                      isItem: false,
                      isDismissibleView: "Yes",
                      actionSourceType: { id: -1, type: "API" },
                      downloadType: "directUrl",
                    });
                    ActionColumn();
                  }}
                >
                  Add Action
                </Button>
              </Grid>
            )}
          </Card>
        </Grid>
      )}
      {tabValue === 5 && (
        <Grid item container spacing={2} margin={2}>
          <Grid item xs={12}>
            <BXSwitch label={"Enable"} name={`config.order.enabled`} control={control} error={errors?.config?.order?.enabled} />
          </Grid>

          {watch("config.order.enabled") && (
            <Grid item xs={6}>
              <ApiInput
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                apiLabel='API URL'
                path={`config.order`}
                pathURL={`config.order.endpoint`}
                error={errors?.config?.order}
                errorURL={errors?.config?.order?.endpoint}
                control={control}
                OASElement={
                  <OASSelector
                    swaggerProps={{
                      onSuccess: (values: any, data: any) => {
                        setValue(`config.order.endpoint`, data?.path);
                        setValue(`config.order.uri`, data?.path);
                        setValue(`config.order.method`, (data?.method as string).toUpperCase());
                        setValue(`config.order.body`, formatJSON(JSON.stringify(data?.body || {})));
                        setValue(`config.order.headers`, values.headers);
                      },
                    }}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
      )}

      {tabValue === 6 && (
        <Grid item>
          <ReactMarkdown>
            {`
        Relative options:

          1. {this.data.id} to get the current id from active view
          2. {$.id} to get the id from form view
          3. {{envName}} you can get envName from env object
          5. {#.pagination.index} you can get index from pagination object
          6. {#.dnd.direction} you can get direction from dnd object
          7. {#.dnd.drag} you can get drag from dnd object
          8. {#.dnd.drop} you can get drop from dnd object
          9. {#.data.id} to get the data from previous view
          10. {#.userInputs.id} to get the user input value from previous view
          11. {#.data.items[0].id} to get the data value from previous view with specific value
          12. {#.#.#.data.id} to get the data value from previous views (three levels down )
        
        Absolute options:

          1. {viewX.data.items[0].id} to get the id from data object of specific view
          2. {viewX.data._selectedItem.id} to get the id from selected item in that view
          3. {viewX.userInputs.name} to get the name from user inputs object of that view
          4. {viewX.env.envName} you can get envName from env object`}
          </ReactMarkdown>
        </Grid>
      )}
    </Grid>
  );
};

export { CreateViewForm };
