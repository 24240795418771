import { Box, Grid, Switch, Tab, Tabs, TextField, Theme, Typography } from "@mui/material";
import { FC, memo, useContext } from "react";
import { HeightContext } from "./FormBuilderEditor";
import { ComponentEditor } from "./components";
import TabsComponentEditor from "./components/ComponentEditor/TabsComponentEditor";
import { TabPanelProps } from "./types";
import { getItemClosestProp } from "./utils";

const CanvasHeightField: FC<any> = ({ layoutBreak }) => {
  const { boxHeight } = useContext(HeightContext);
  return (
    <TextField
      InputLabelProps={{ style: { fontSize: "10px" } }}
      InputProps={{ style: { fontSize: "12px" } }}
      size='small'
      disabled
      fullWidth
      label={"Canvas Height"}
      value={getItemClosestProp(boxHeight, layoutBreak)}
    />
  );
};

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-2`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const RightSideMenuItems = memo(
  ({
    tab,
    handleChangeTab,
    layoutBreak,
    onDeleteItem,
    activeComponent,
    onChangeProp,
    onCanvasToggle,
    stepperEnabled,
    onStepperToggle,
    onFlexCanvasToggle,
    onCanvasFullHeightToggle,
    canvasEnabled,
    flexCanvasEnabled,
    canvasFullHeight,
    dynamicHeight,
    onDynamicHeightToggle,
    view,
    setView,
    appId,
    collectionId,
    pageId,
    multiLingual,
    setMultiLingual,
    views,
  }: any) => {
    const tabText = activeComponent?.config?.isContainTabs ? "Tabs Settings" : "Reorder Items";
    return (
      <Box sx={{ backgroundColor: (theme: Theme) => theme.palette.background.paper, borderRadius: "10px", width: "100%", height: "100%" }}>
        <Box sx={{ position: "sticky", top: "100px", zIndex: "1000", maxHeight: "calc(100vh - 100px)", overflow: "auto" }}>
          <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs scrollButtons='auto' variant='scrollable' value={tab} onChange={handleChangeTab} aria-label='basic tabs example'>
              <Tab sx={{ textTransform: "none", fontSize: "12px", textAlign: "center" }} label='View Properties' {...a11yProps(0)} />
              <Tab sx={{ textTransform: "none", fontSize: "12px", textAlign: "center" }} label='Canvas properties' {...a11yProps(1)} />
              {activeComponent?.config?.isContainTabs && (
                <Tab sx={{ textTransform: "none", fontSize: "12px", textAlign: "center" }} label={"Tabs Settings"} {...a11yProps(2)} />
              )}
            </Tabs>
          </Grid>

          <Box sx={{ overflowY: !!activeComponent ? "auto" : "hidden", overflowX: "hidden" }}>
            <TabPanel value={tab} index={0}>
              <ComponentEditor
                item={activeComponent}
                multiLingual={multiLingual}
                setMultiLingual={setMultiLingual}
                onDeleteItem={onDeleteItem}
                onChangeProp={onChangeProp}
                layoutBreak={layoutBreak}
                appId={appId}
                collectionId={collectionId}
                pageId={pageId}
                views={views}
                view={view}
                setView={setView}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container px={1} py={2} spacing={1}>
                {!!activeComponent && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        InputLabelProps={{ style: { fontSize: "12px" } }}
                        InputProps={{ style: { fontSize: "12px" } }}
                        size='small'
                        fullWidth
                        label={"Key"}
                      />
                    </Grid>
                    <Grid item xs={6} py={2}>
                      <TextField
                        InputLabelProps={{ style: { fontSize: "12px" } }}
                        InputProps={{ style: { fontSize: "12px" } }}
                        size='small'
                        fullWidth
                        label={"Name"}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={6} py={2}>
                  <CanvasHeightField layoutBreak={layoutBreak} />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Enable Stepper</Typography>
                  <Switch size='small' checked={stepperEnabled} onChange={onStepperToggle} inputProps={{ "aria-label": "controlled" }} />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Canvas Grid</Typography>
                  <Switch size='small' checked={canvasEnabled} onChange={onCanvasToggle} inputProps={{ "aria-label": "controlled" }} />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Dynamic Height</Typography>
                  <Switch
                    size='small'
                    checked={dynamicHeight}
                    onChange={onDynamicHeightToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Canvas Flex</Typography>
                  <Switch
                    size='small'
                    checked={flexCanvasEnabled}
                    onChange={onFlexCanvasToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid display='flex' marginInlineStart={"4px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Canvas Full Height</Typography>
                  <Switch
                    size='small'
                    checked={canvasFullHeight}
                    onChange={onCanvasFullHeightToggle}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            {activeComponent && activeComponent?.config?.isContainTabs && (
              <TabPanel value={tab} index={2}>
                <TabsComponentEditor
                  item={activeComponent}
                  activeComponent={activeComponent?.children[0]}
                  onChangeProp={onChangeProp}
                  setView={setView}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  appId={appId}
                />
              </TabPanel>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);
