import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  IconChartArea,
  IconComponents,
  IconCopy,
  IconFileExport,
  IconFileImport,
  IconList,
  IconPencil,
  IconPlus,
  IconTable,
  IconTrashX,
} from "@tabler/icons-react";
import _ from "lodash";
import React, { FC, Fragment, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery, useMutation } from "react-query";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import PositionedMenu from "src/components/BXUI/FormControls/Menu";
import BXModal from "src/components/BXUI/Modal";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import { queryClient } from "../../../../BXEngine/BXContext";
import { chartsNames } from "../FormBuilder/utils";
import { CreateAutoCompleteForm } from "./CreateAutoCompleteForm";
import { CreateChartForm } from "./CreateChartForm";
import { CreateSelectComponentForm } from "./CreateSelectComponent";
import { CreateTableForm } from "./CreateTableForm";

type ManageComponentsProps = {
  children?: React.ReactNode;
};

const mapComponent: { [id: string]: any } = {
  autoComplete: CreateAutoCompleteForm,
  formTable: CreateTableForm,
  chart: CreateChartForm,
  Select: CreateSelectComponentForm,
};

const columns: any[] = [
  // { id: "action", label: "Action", minWidth: 100},
  { id: "id", label: "ID", minWidth: 270 },
  { id: "name", label: "Name", minWidth: 270 },
  { id: "config.type", label: "Type", minWidth: 270 },
];

export const ManageComponents: FC<ManageComponentsProps> = ({ children }) => {
  const { palette } = useTheme();
  const [searchText, setSearchText] = useState("");

  const [component, setComponent] = useState<any>(undefined);

  let queryKey = ["component-list"];
  if (searchText.trim()) {
    queryKey.push(searchText.trim());
  }
  const { data, hasNextPage, fetchNextPage, isFetching, isError } = useInfiniteQuery(
    queryKey,
    ({ pageParam, queryKey }) => {
      if (queryKey[1] && queryKey[1].length < 3) {
        return {
          data: {
            items: [],
          },
        } as any;
      }
      return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/component", {
        params: {
          cursor: pageParam,
          keyword: searchText || undefined,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );

  const { mutate } = useMutation(
    (data: any) => {
      return axios.post(process.env.REACT_APP_HOST_API_KEY + "/api/admin/component", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      onSuccess: data1 => {
        enqueueSnackbarRef?.("Posted Successfully", {
          variant: "success",
        });

        queryClient.setQueryData(queryKey, ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [{ ...page1, data: { items: [data1.data, ...page1.data?.items] } }, ...rest],
          };
        });
      },
      onError: (error: any) => {
        enqueueSnackbarRef?.(error?.message || "Wrong Services", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: editComponent } = useMutation(
    (editedData: any) => {
      return axios.put(process.env.REACT_APP_HOST_API_KEY + `/api/admin/component/${editedData.id}`, editedData);
    },
    {
      onSuccess: (data1, editedData: any) => {
        enqueueSnackbarRef?.("Posted Successfully", {
          variant: "success",
        });

        queryClient.setQueryData(queryKey, ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [
              {
                ...page1,
                data: {
                  items: page1.data?.items.map((item: any) => (item.id === data1.data.id ? { ...editedData, ...data1.data } : item)),
                },
              },
              ...rest,
            ],
          };
        });
      },
    }
  );

  const { mutate: deleteComponent } = useMutation(
    (itemToDelete: any) => {
      return axios.delete(process.env.REACT_APP_HOST_API_KEY + `/api/admin/component/${itemToDelete?.id}`);
    },
    {
      onSuccess: (_, editedData: any) => {
        enqueueSnackbarRef?.("Deleted successfully", {
          variant: "success",
        });
        queryClient.setQueryData(["component-list"], ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [{ ...page1, data: { items: page1.data?.items.filter(item => item.id !== editedData?.id) } }, ...rest],
          };
        });
      },
    }
  );

  const onSubmit = (values: any) => {
    return mutate(values, {});
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const handleFileSelect = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = event => {
        const jsonString = event.target?.result?.toString();
        const parsedJson = JSON.parse(jsonString || "");
        parsedJson.name = parsedJson.name + " imported";
        onSubmit(parsedJson);
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleSelectClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".json";
    fileInput.addEventListener("change", handleFileSelect);
    fileInput.click();
  };

  const handleExportClick = (item: any) => {
    delete item.id;

    const filename = item.name;
    const jsonStr = JSON.stringify(item, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.click();
  };
  const entities = _.flatten(data?.pages?.map((p: any) => _.get(p.data, "items")));

  const Component = mapComponent[component?.type] || Fragment;
  return (
    <>
      <Grid container style={{ height: "100%" }}>
        <Grid style={{ height: "100%", padding: 10, paddingTop: 0 }} item md={12}>
          <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2 }}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid container marginBottom={1}>
            <Grid container flex={1} spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography fontSize={"20px"} color='textPrimary' fontWeight={600}>
                  Manage Components
                </Typography>
              </Grid>
            </Grid>

            <DialogActions>
              <LoadingButton
                variant='contained'
                startIcon={<IconFileImport size={18} />}
                onClick={handleSelectClick}
                sx={{ marginInlineEnd: 1 }}
              >
                Import Component
              </LoadingButton>
              <PositionedMenu
                items={[
                  {
                    icon: <IconList />,
                    label: "Autocomplete",
                    action: () => {
                      setComponent({
                        type: "autoComplete",
                        icon: <IconList />,
                        label: "Autocomplete",
                        add: true,
                      });
                    },
                  },
                  // {
                  //   icon: <IconSelect />,
                  //   label: "Select",
                  //   action: () => {
                  //     setComponent({
                  //       type: "Select",
                  //       icon: <IconSelect />,
                  //       label: "Select",
                  //       add: true,
                  //     });
                  //   },
                  // },
                  {
                    icon: <IconTable />,
                    label: "Form Table",
                    action: () => {
                      setComponent({
                        type: "formTable",
                        icon: <IconTable />,
                        label: "Form Table",
                        add: true,
                      });
                    },
                  },
                  {
                    icon: <IconChartArea />,
                    label: "chart",
                    action: () => {
                      setComponent({
                        type: "chart",
                        icon: <IconTable />,
                        label: "chart",
                        add: true,
                      });
                    },
                  },
                  // {
                  //   isDivider: true,
                  // },
                ]}
                buttonProps={{
                  variant: "contained",
                  style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                  startIcon: <IconPlus />,
                  fullWidth: true,
                }}
              >
                Component
              </PositionedMenu>

              <BXModal
                open={!!component?.add}
                onClose={() => {
                  setComponent(undefined);
                }}
                title={`Add ${component?.label}`}
                icon={component?.icon}
                label={"Add Component"}
                buttonProps={{
                  startIcon: component?.icon,
                  color: "secondary",
                  variant: "contained",
                  style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                  size: "small",
                }}
              >
                {(handleClose: Function) => {
                  return (
                    <Component
                      row={{
                        config: {
                          type: component?.type,
                        },
                      }}
                      onSave={(formData: any) => {
                        if (chartsNames.includes(formData?.type)) {
                          formData = { name: formData?.title, config: formData };
                        }
                        onSubmit(formData);
                        handleClose?.();
                      }}
                    />
                  );
                }}
              </BXModal>
            </DialogActions>
          </Grid>
          <Card style={{ padding: 24 }}>
            <Grid xs={12} item container spacing={2}>
              <Grid item spacing={2} xs={12} md={4} alignItems='center'>
                <TextField
                  size='small'
                  fullWidth
                  label={"Search"}
                  onChange={e => {
                    setSearchText(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <TableContainer style={{ backgroundColor: palette.background.paper }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ backgroundColor: palette.background.paper }}>Actions</TableCell>

                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align={"center"}>
                        No components yet
                      </TableCell>
                    </TableRow>
                  )}
                  {entities?.map((row: any) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                        <TableCell>
                          <DialogActions sx={{ padding: 0, justifyContent: "flex-start" }}>
                            <BXModal
                              title={`Edit ${row?.name || "component"}`}
                              icon={<IconPencil color={palette.primary.main} height={26} width={26} style={{ padding: 4 }} />}
                              withoutLabel
                              buttonProps={{
                                startIcon: <IconComponents />,
                                color: "primary",
                                size: "small",
                                variant: "contained",
                                onClick: () => {
                                  setComponent({
                                    type: row?.config?.type.includes("Chart") ? "chart" : row?.config?.type,
                                    icon: <IconComponents />,
                                  });
                                },
                              }}
                            >
                              {(handleClose: Function) => {
                                return (
                                  <Component
                                    row={row}
                                    data={row?.config}
                                    onSave={(formData: any) => {
                                      if (component?.type === "chart") {
                                        formData = { ...row, name: formData?.title, config: formData };
                                      }
                                      editComponent(formData);
                                      handleClose?.(true);
                                    }}
                                  />
                                );
                              }}
                            </BXModal>
                            <IconButton onClick={() => handleExportClick(row)}>
                              <IconFileExport size={20} />
                            </IconButton>
                            <BXModal
                              title={"Duplicate Component"}
                              icon={<IconCopy color={palette.primary.main} height={26} width={26} style={{ padding: 4 }} />}
                              withoutLabel
                              label={"Duplicate Component"}
                              buttonProps={{
                                startIcon: <IconComponents />,
                                color: "primary",
                                size: "small",
                                variant: "contained",
                                onClick: () => {
                                  setComponent({
                                    type: row?.config?.type,
                                    icon: <IconComponents />,
                                  });
                                },
                              }}
                            >
                              {(handleClose: Function) => {
                                return (
                                  <Component
                                    row={row}
                                    onSave={(formData: any) => {
                                      onSubmit(formData);
                                      handleClose?.();
                                    }}
                                  />
                                );
                              }}
                            </BXModal>
                            <Box marginInlineStart={1}>
                              <BXConfirmationDialog
                                title={"Are you sure you want to delete this component?"}
                                iconButton
                                buttonProps={{
                                  color: "error",
                                  children: <IconTrashX height={20} width={20} style={{ padding: 0 }} />,
                                }}
                                onConfirm={() => {
                                  deleteComponent(row);
                                }}
                              />
                            </Box>
                          </DialogActions>
                        </TableCell>
                        {columns.map(column => {
                          const value = _.get(row, column.id);
                          return (
                            <TableCell key={column.id}>
                              <Grid container alignItems={"center"}>
                                <Tooltip title={value}>
                                  <Typography
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: 250,
                                    }}
                                  >
                                    {value}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  {(isFetching || hasNextPage) && (
                    <TableRow ref={sentryRef} sx={{ width: "100%", justifyContent: "center" }}>
                      <TableCell colSpan={columns.length + 1 || 1} style={{ textAlign: "center" }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
