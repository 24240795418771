import { ReactNode, createContext, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeAppField } from "src/features/appState/appStateSlice";
import { useAppState } from "src/features/appState/hooks";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";

interface IBXPageContext {
  isDirtyPage: boolean;
}

const BXPageContext = createContext<IBXPageContext | null>(null);

export const BXPageProvider = ({ children, pageId }: { children: ReactNode; pageId: string }) => {
  const { getIsDirty } = useAppState();
  const dispatch = useDispatch();

  const isDirtyPage = getIsDirty(pageId);

  useEffect(() => {
    return () => {
      dispatch(removeAppField({ name: pageId }));
    };
  }, []);

  useCallbackPrompt(isDirtyPage);

  return <BXPageContext.Provider value={{ isDirtyPage }}>{children}</BXPageContext.Provider>;
};

export const useBXPageContext = () => {
  const context = useContext(BXPageContext);
  if (context === null) {
    throw new Error("useBXPageContext must be used within a BXPageProvider");
  }
  return context;
};
