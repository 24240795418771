import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Fade, IconButton, ListItem, Typography } from "@mui/material";
import { useMemo, useState } from "react";
export const CustomListItem = ({ option, collapsableContent, ...props }) => {
  const [open, setOpen] = useState(false);
  const content = useMemo(() => {
    return collapsableContent?.(option);
  }, [collapsableContent, option]);
  return (
    <ListItem
      {...props}
      style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingTop: 12, paddingBottom: 12, gap: 8 }}
    >
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
        <Typography fontWeight={"medium"}>{option.name}</Typography>
        {content && (
          <IconButton
            size='small'
            color='default'
            style={{ position: "absolute", right: 12, top: 6 }}
            onClick={e => {
              e.stopPropagation();
              setOpen(old => !old);
            }}
          >
            {open ? <ArrowDropUp fontSize='inherit' /> : <ArrowDropDown fontSize='inherit' />}
          </IconButton>
        )}
      </Box>

      {content && (
        <Fade in={open} exit={!open} mountOnEnter unmountOnExit>
          {/* Render all collapsable content */}
          {content}
        </Fade>
      )}
    </ListItem>
  );
};
