import MonacoEditor from "@monaco-editor/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import _ from "lodash";
import { FC, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useBXContext } from "src/BXEngine/BXContext";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXApp } from "src/types/BXAppType";
import { DataSourceType, ElementDataSource, UIElement } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import { decompressData } from "src/utils/services";
import OASSelector from "../../OASSelector";
import SelectViewComponent from "./SelectViewComponent";

type DataSourceProps = {
  value?: any;
  control: any;
  watch: any;
  errors: any;
  tables: UIElement[];
  setValue: any;
  getValues: any;
  viewType?: string;
  setIsTemplateError: any;
  views?: UIElement[] | undefined;
  view?: UIElement;
  onBuilderClick?: any;
  appId?: any;
  collectionId?: any;
  pageId: any;
  viewId: any;
};

// type Props = {
//   app?: BXApp;
//   collection?: BXAppCollection;
//   selectedData?: any;
//   onSelect: (data: any) => void;
// };

export const inputTypes = ["Input", "Switch"];

export const DataSource: FC<DataSourceProps> = ({
  value,
  tables,
  control,
  errors,
  setValue,
  getValues,
  watch,
  viewType,
  setIsTemplateError,
  views,
  view,
  onBuilderClick,
  appId,
  collectionId,
  pageId,
  viewId,
}) => {
  const viewsWithoutCurrentView = views?.filter(item => item?.id != view?.id);
  const { setAppDescriptor, setSelectedAppId } = useBXContext();

  const [isRefreshing, setIsRefreshing] = useState(false);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "dataSource.userInputs",
  });

  const [dataSource, setDataSource] = useState<ElementDataSource | undefined>({
    ...value,
    sourceType: value?.sourceType || "API",
    simple: value?.simple || "{\n}",
  });

  const dataSourceTypes: DataSourceType[] = ["API", "USER INPUT", "SIMPLE"];
  if (viewType == "form" || viewType == "form-builder") {
    if (viewType == "form") {
      dataSourceTypes.splice(2, 3);
    }
    if (viewType == "form-builder") {
      dataSourceTypes.splice(1, 1);
    }
    dataSourceTypes.unshift("NONE");
  }
  if (viewType == "api") {
    dataSourceTypes.splice(1, 1);
    dataSourceTypes.splice(2, 1);
  }

  const moveElement = (dragIndex: any, hoverIndex: number) => {
    move(dragIndex, hoverIndex);
  };

  const formBuilderViews = views?.filter(item => item?.type == "form-builder");

  const openNewWindow = () => {
    const urlToNavigate = `/buildx/app?appId=${appId}&collectionId=${collectionId}&pageId=${pageId}`;
    window.open(urlToNavigate, "_blank");
  };

  const handleAppSelect = (item: any) => {
    return new Promise<void>((resolve, reject) => {
      setSelectedAppId(item);
      if (item?.templateConfig == null) {
        axiosServices.get("/application/" + item).then(({ data }) => {
          if (data?.appConfig) {
            data.appConfig = decompressData(data?.appConfig);
          }
          if (data?.templateConfig) {
            data.templateConfig = decompressData(data?.templateConfig);
          }
          setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
          resolve();
        });
      }
    });
  };

  const openView = () => {
    const url = `form-builder/${appId}/${collectionId}/${pageId}/${watch(`dataSource.userInputFormBuilderId`)}`;
    window.open(url, "_blank");
  };
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12} marginBottom={2}>
            {dataSourceTypes.map((type: DataSourceType) => (
              <Controller
                key={type}
                control={control}
                name={`dataSource.sourceType`}
                render={({ field: { onChange, value } }) => {
                  return (
                    <ToggleButtonGroup color='primary' value={dataSource?.sourceType} exclusive>
                      <ToggleButton
                        style={{ marginInlineEnd: 12 }}
                        value={value}
                        onClick={() => {
                          setDataSource(old => ({
                            ...old,
                            sourceType: type,
                          }));
                          if (type != "TABLE") {
                            setValue("dataSource.table", undefined);
                          }
                          onChange(type);
                        }}
                        selected={value === type}
                        key={type}
                      >
                        {type == "SIMPLE" ? "Fixed Data" : type}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  );
                }}
              />
            ))}
          </Grid>
          {dataSource?.sourceType === "SIMPLE" && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name={`dataSource.simple`}
                render={({ field: { onChange, value } }) => (
                  <MonacoEditor
                    width='100%'
                    height='300px'
                    language='json'
                    theme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) : value}
                    options={{ colorDecorators: true }}
                    onChange={newValue => onChange(newValue)}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container spacing={2} mt={2}>
              {dataSource?.sourceType === "TABLE" && (
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name={`dataSource.table`}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <BXInput
                          name={"dataSource.table"}
                          control={control}
                          select
                          label='Table'
                          value={value?.id}
                          onChange={event => {
                            onChange(tables.find((table: any) => table?.id == event?.target.value));
                          }}
                        >
                          {!tables.length ? (
                            <MenuItem value=''>
                              <em>None</em>
                            </MenuItem>
                          ) : (
                            tables?.map(item => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.info?.name || item?.id}
                              </MenuItem>
                            ))
                          )}
                        </BXInput>
                      </FormControl>
                    )}
                  />
                </Grid>
              )}
              {dataSource?.sourceType === "USER INPUT" && (
                <Grid item xs={12} mb={2}>
                  <FormControl>
                    <BXSwitch
                      name={`dataSource.isApiCallInFirstLoad`}
                      control={control}
                      label={"Call Api in first load"}
                      error={errors?.dataSource?.isApiCallInFirstLoad}
                    />
                  </FormControl>
                </Grid>
              )}
              {dataSource?.sourceType != "SIMPLE" &&
                dataSource?.sourceType !== "NONE" &&
                (dataSource?.sourceType === "API" ||
                  (dataSource?.sourceType === "USER INPUT" && watch("dataSource.isApiCallInFirstLoad"))) && (
                  <>
                    <Grid item xs={6}>
                      <ApiInput
                        watch={watch}
                        error={errors?.dataSource?.payload}
                        errorURL={errors?.dataSource?.apiUrl}
                        apiLabel='API URL'
                        control={control}
                        path='dataSource.payload'
                        pathURL='dataSource.apiUrl'
                        OASElement={
                          <OASSelector
                            swaggerProps={{
                              type: viewType == "form" ? "form" : viewType == "form-builder" ? "form-builder" : "columns",
                              template: watch("dataSource.template"),
                              formBuilder: watch("dataSource.formBuilder"),
                              onSuccess: (values: any, data: any) => {
                                if (viewType == "form") {
                                  setValue("dataSource.template", values.template);
                                }
                                setValue("dataSource.url", data?.path);
                                setValue("dataSource.apiUrl", data?.path);
                                setValue("dataSource.payload.uri", data?.path);
                                setValue("dataSource.payload.method", (data?.method as string).toUpperCase());
                                setValue("dataSource.payload.body", formatJSON(JSON.stringify(data?.body)) || {});
                                setValue("dataSource.dataEntry", values.dataEntry);
                                setValue("config.columns", values.columns);
                              },
                            }}
                          />
                        }
                        getValues={getValues}
                        setValue={setValue}
                      />
                    </Grid>
                  </>
                )}

              {dataSource?.sourceType !== "NONE" &&
                !(viewType == "form" && dataSource?.sourceType == "TABLE") &&
                viewType != "api" &&
                viewType != "form-builder" &&
                !(dataSource?.sourceType === "USER INPUT" && !watch("dataSource.isApiCallInFirstLoad")) && (
                  <Grid item xs={6}>
                    <BXInput name={"dataSource.dataEntry"} control={control} fullWidth label={"Response Root Key"} />
                  </Grid>
                )}
              {dataSource?.sourceType === "API" && viewType != "form-builder" && (
                <>
                  <Grid item xs={6}>
                    <BXInput name={"dataSource.hasMoreKey"} control={control} fullWidth label={"Pagination Key"} />
                  </Grid>
                  <Grid item xs={6}>
                    <BXInput name={"dataSource.cursorKey"} control={control} fullWidth label={"Cursor Key"} />
                  </Grid>
                </>
              )}
              {(viewType == "card-list" || viewType == "image-grid") && (
                <Grid item xs={6}>
                  <BXInput name={"dataSource.gridKey"} control={control} fullWidth label={"Key"} />
                </Grid>
              )}
              {viewType == "form" && (
                <Grid item xs={6}>
                  <BXInput
                    name={"dataSource.columnCount"}
                    control={control}
                    error={errors?.dataSource?.columnCount}
                    fullWidth
                    label={"Column Count"}
                  />
                </Grid>
              )}

              {dataSource?.sourceType === "USER INPUT" && (
                <Grid item xs={12}>
                  <SelectViewComponent
                    fieldName={"dataSource.userInputFormBuilderId"}
                    isRefreshing={isRefreshing}
                    setIsRefreshing={setIsRefreshing}
                    handleAppSelect={handleAppSelect}
                    appId={appId}
                    openView={openView}
                    openNewWindow={openNewWindow}
                    watch={watch}
                    control={control}
                    error={errors?.dataSource?.userInputFormBuilderId}
                    formBuilderViews={formBuilderViews}
                    setValue={setValue}
                  />
                </Grid>
              )}

              {viewType == "form-builder" && (
                <Grid item xs={12} mt={1}>
                  <Typography mb={1}>Build your form :</Typography>
                  <Button onClick={onBuilderClick} variant='contained'>
                    Build View
                  </Button>
                </Grid>
              )}
              {viewType == "form" && (
                <Grid item xs={12}>
                  <Box display={"flex"} alignItems='center' marginY={1}>
                    <Typography>Form View Template:</Typography>
                    {process.env.REACT_APP_WITH_OAS == "true" && (
                      <OASSelector
                        swaggerProps={{
                          type: "form",
                          onlyModels: true,
                          onSuccess: (values: any) => {
                            setValue("dataSource.template", values.template);
                          },
                        }}
                      />
                    )}
                  </Box>

                  <Controller
                    control={control}
                    name={`dataSource.template`}
                    render={({ field: { onChange, value } }) => (
                      <MonacoEditor
                        height='350px'
                        language='json'
                        theme='vs-dark'
                        value={!_.isString(value) ? formatJSON(JSON.stringify(value)) || "" : value}
                        options={{ colorDecorators: true }}
                        onChange={(newValue = "") => {
                          try {
                            onChange(newValue);
                            JSON.parse(newValue);
                            setIsTemplateError();
                          } catch (e: any) {
                            setIsTemplateError(e?.message);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {viewType === "card-list" && (
                <>
                  <Grid item xs={6}>
                    <BXSwitch label='Play on hover' control={control} name='dataSource.playOnHover' />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Video Details</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <BXInput
                      name={"dataSource.videoTitle"}
                      control={control}
                      error={errors?.dataSource?.videoTitle}
                      fullWidth
                      label={"Title"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <BXInput
                      name={"dataSource.videoUsername"}
                      control={control}
                      error={errors?.dataSource?.videoUsername}
                      fullWidth
                      label={"Username"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <BXInput
                      name={"dataSource.videoSubTitle"}
                      control={control}
                      error={errors?.dataSource?.videoSubTitle}
                      fullWidth
                      label={"Sub Title"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <BXInput
                      name={"dataSource.videoUserImage"}
                      control={control}
                      error={errors?.dataSource?.videoUserImage}
                      fullWidth
                      label={"User Image"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              {viewType === "card-list" && (
                <Grid item container spacing={2} mt={1}>
                  <Grid item xs={12} marginInlineStart={1}>
                    <BXSwitch label='Split View' control={control} name='dataSource.splitViewConfig.enabled' />
                  </Grid>
                  <Grid item xs={6} marginInlineStart={1}>
                    <BXInput
                      name={`dataSource.splitViewConfig.viewId`}
                      control={control}
                      error={errors?.dataSource?.splitViewConfig?.viewId}
                      select
                      label={"View"}
                      disabled={!watch("dataSource.splitViewConfig.enabled")}
                    >
                      {viewsWithoutCurrentView?.map((item: any) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.info?.name || item?.type}
                        </MenuItem>
                      ))}
                    </BXInput>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
