/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-loop-func */
import { gql } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconX } from "@tabler/icons-react";
import _ from "lodash";
import Papa from "papaparse";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BXEngine } from "src/BXEngine";
import { apolloClient, replaceBaseUrl, useBXContext } from "src/BXEngine/BXContext";
import { BXIcon } from "src/components/BXUI/Icon";
import { resetLoading, setLoading } from "src/features/appState/appStateSlice";
import { useAppState } from "src/features/appState/hooks";
import { IViewState, UIElement } from "src/types/UIElement";
import { closeSnackbarRef, enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import {
  getAuthorizationHeader,
  getLastKeyFromObject,
  handleGetQueryData,
  handleRefetchQueries,
  reverseDataOfObjectValues,
} from "src/utils/generalUtils";
import { actionTypes } from "src/views/pages/BuildX/AppBuilder/forms/CreateViewForm";
import { getSharedViews, updateStepperGroupIndex } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import { BXConfirmationDialog } from "../AlertDialog/ConfirmationDialog";
import { triggerActionableComponent } from "../FormBuilder/RenderItems";
import BXModal from "../Modal";
import { ViewerModal } from "../viewerModal";
import CSVProgressDialog from "./CSVProgressDialog";
import CreatePayload from "./CreatePayload";

export const useReplaceDataPlaceholders = (props?: any) => {
  const { viewName } = props || {};

  const { viewsState, setViewsState, queriesStateGraphQL } = useBXContext();
  const { getValues, setValue, watch, getValue } = useAppState();

  const params = useParams();
  const location = useLocation();
  location.pathname = window.location.pathname;
  const searchParams = new URLSearchParams(location.search);

  const queryStrings = {};
  searchParams.forEach((value, key) => {
    queryStrings[key] = value;
  });

  const replaceDataPlaceholdersProps = {
    queriesStateGraphQL,
    viewsState,
    viewName,
    setViewsState,
    getValues,
    getValue,
    setValue,
    watch,
    location: { params, queryStrings, pathname: location.pathname },
  };

  return {
    replaceDataPlaceholders: props => replaceDataPlaceholders({ ...props, ...replaceDataPlaceholdersProps }),
    replaceDataPlaceholdersRecursively: props => replaceDataPlaceholdersRecursively({ ...props, ...replaceDataPlaceholdersProps }),
    updateDataPlaceholders: props => updateDataPlaceholders({ ...props, ...replaceDataPlaceholdersProps }),
  };
};

export const updateDataPlaceholders = (props: any) => {
  const { queryString, pageId, getValues, setValue, index: itemIndex, viewName, $action, $payload } = props;

  let result = queryString;

  if (typeof queryString === "boolean" || typeof queryString === "number") return queryString;
  const placeholders = queryString?.match(/\{(.*?)\}/g);

  placeholders?.forEach(function (placeholder: any) {
    let phText = "";

    const index = placeholder.split("").findIndex((key: string) => key == ".");
    phText = placeholder.substring(index + 1, placeholder.length - 1);

    if (phText.startsWith("state.")) {
      let preDefinedVariable = placeholder.substring(1, index);
      if (preDefinedVariable === "this") {
        preDefinedVariable = viewName;
      }
      phText = phText.replace("state.", "");

      if ($action === "DELETE") {
        phText = phText.replace("[*]", "");

        const key = `${pageId}.${preDefinedVariable}.${phText}`;

        const data = [...getValues(key)];

        if (Array.isArray(data)) {
          data.splice(itemIndex, 1);
        }

        setValue(`${key}.state`, data);
      } else if ($action === "ADD") {
        phText = phText.replace("[*]", `[${itemIndex}]`);
        const key = `${pageId}.${preDefinedVariable}.${phText}`;
        const data = [...getValues(key)];
        if (Array.isArray(data)) {
          data.push($payload);
        }
        setValue(`${key}.state`, data);
      } else if ($action === "UPDATE") {
        phText = phText.replace("[*]", `[${itemIndex}]`);
        const key = `${pageId}.${preDefinedVariable}.${phText}`;
        const currentValues = getValues(key);
        if (Array.isArray(currentValues)) {
          const updatedValues = [...currentValues];
          updatedValues[itemIndex] = $payload;
          setValue(`${key}.state`, updatedValues);
        } else {
          setValue(`${key}.state`, $payload);
        }
      }
    }
  });

  return result;
};

export const replaceDataPlaceholders = (props: any) => {
  const {
    queryString,
    item = {},
    viewsState,
    watch,
    getValue,
    pageId,
    formData,
    __data = {},
    fallback,
    actionResponse,
    dnd,
    env,
    pagination,
    multiLingual,
    validationRules,
    index: itemIndex,
    queriesStateGraphQL,
    viewName,
    ...rest
  } = props;

  let result = queryString;
  const mapObj = {
    data: item,
    pagination,
    actionResponse,
    dnd,
    ...rest,
  };

  if (typeof queryString === "boolean" || typeof queryString === "number" || queryString == undefined) return queryString;
  const placeholders = queryString.match(/\{(.*?)\}/g);

  placeholders?.forEach(function (placeholder: any) {
    let phText = "";
    let data;

    const index = placeholder.split("").findIndex((key: string) => key == ".");
    phText = placeholder.substring(index + 1, placeholder.length - 1);

    const currentStepperGroups = getValue?.(`${pageId}.${viewName}._BX_stepperGroups`);
    let componentPath = "";
    const stepperGroups = _.reduce(
      currentStepperGroups ?? {},
      (result, group) => {
        if (!group || typeof group !== "object") {
          return result;
        }

        const parsedSelectedIndex = parseInt(group?.selectedPage, 10);
        const selectedIndex = isNaN(parsedSelectedIndex)
          ? group?.pages?.findIndex(page => page.pageReference === group?.selectedPage) ?? -1
          : parsedSelectedIndex;

        const selectedPageRef = group?.pages?.[selectedIndex]?.pageReference;

        result[group?.groupName] = {
          index: selectedIndex,
          ref: selectedPageRef,
        };

        return result;
      },
      {}
    );

    data = mapObj;
    if (placeholder.startsWith("{this.dataComp.")) {
      phText = phText.replace(`dataComp.`, "");
      const key = phText.match(/^([^.]+)/)?.[0];
      phText = phText.replace(`${key}`, "");
      if (phText?.startsWith(".")) {
        phText = phText.replace(".", "");
      }
      componentPath = ".data";
      data = watch(`${pageId}.${viewName}.${key}`);

      if (!_.isNil(itemIndex)) {
        data = data?.[itemIndex];
      }
    }
    if (placeholder.startsWith("{this.response.")) {
      data = viewsState?.[`${pageId}-${viewName}`]?.response;
      phText = phText.replace(`response.`, "");
      componentPath = "";
    }
    if (placeholder.startsWith("{this.state.")) {
      let currentState = watch(`${pageId}.${viewName}`);
      data = { ...currentState, ...(stepperGroups || {}) };
      phText = phText.replace(`state.`, "");
      componentPath = ".state";
    } else if (placeholder.startsWith("{$.")) {
      data = formData?.();
    } else if (placeholder.startsWith("{%@.")) {
      const validationKey = phText;
      if (validationRules?.[validationKey]) {
        data = validationRules;
        phText = validationKey;
      }
    } else if (placeholder.startsWith("{i18n.")) {
      const translationKey = phText;
      const translationObject = multiLingual?.translations?.find((t: any) => t.key === translationKey);

      const defaultLanguage = multiLingual?.selectedLanguage;
      const selectedLanguage = defaultLanguage ? defaultLanguage : "";

      data = translationObject?.allLanguages;
      phText = selectedLanguage;
    } else if (placeholder.startsWith("{{_name")) {
      phText = placeholder.substring(2, placeholder.length - 1);
      data = { _name: env?.name };
    } else if (placeholder.startsWith("{{")) {
      phText = placeholder.substring(2, placeholder.length - 1);
      data = {
        ...env?.config?.variables,
        ...env?.upConfig?.variables,
      };
    } else if (placeholder.startsWith("{#.")) {
      const hashes = Object.keys(__data)?.reverse();
      phText = placeholder.substring(1, placeholder.length - 1);

      const currentHash = hashes?.find(hash => phText.startsWith(hash)) as any;

      if (!currentHash) return;

      const viewName = reverseDataOfObjectValues(__data)[currentHash];

      phText = phText.replace(currentHash, "");
      data = viewsState?.[`${pageId}-${viewName}`];

      if (phText.startsWith("data.")) {
        phText = phText.replace("data.", "");
        data = data?._selectedItem;
      }
    } else if (!placeholder.startsWith("{this.")) {
      let preDefinedVariable = placeholder.substring(1, index);
      if (placeholder.startsWith(`{${preDefinedVariable}.data._selectedItem`)) {
        phText = phText.replace("data._selectedItem.", "");
        data = viewsState?.[`${pageId}-${preDefinedVariable}`]?._selectedItem;
      } else if (phText.startsWith("dataComp.")) {
        let preDefinedVariable = placeholder.substring(1, index);

        phText = phText.replace(`dataComp.`, "");
        const key = phText.match(/^([^.]+)/)?.[0];
        phText = phText.replace(`${key}`, "");
        if (phText?.startsWith(".")) {
          phText = phText.replace(".", "");
        }
        componentPath = ".data";
        data = watch(`${pageId}.${preDefinedVariable}.${key}`);
      } else if (placeholder.startsWith(`{${preDefinedVariable}.data`)) {
        phText = phText.replace(`data.`, "");
        const queryKey = `${pageId}-${preDefinedVariable}`;
        const isGraphQL = !!queriesStateGraphQL?.[queryKey];
        const queryResult = handleGetQueryData({
          isGraphQL,
          queryKey,
          queriesStateGraphQL,
        });
        if (queryResult?.pages) {
          data = queryResult?.pages[queryResult?.pages?.length - 1];
        } else {
          data = queryResult;
        }
      } else if (phText.startsWith("state.subviews")) {
        let preDefinedVariable = placeholder.substring(1, index);
        phText = phText.replace("state.subviews", "");

        let subview = phText.substring(phText.indexOf(".") + 1);

        let finalData = watch(`${pageId}.${preDefinedVariable}`);

        if (subview) {
          finalData = {
            ...finalData,
            [preDefinedVariable]: finalData?.[preDefinedVariable]?.map(item => item?.[subview]),
          };
        }

        data = finalData;
        phText = `${subview}`;
      } else if (phText.startsWith("state.")) {
        componentPath = ".state";
        let preDefinedVariable = placeholder.substring(1, index);
        phText = phText.replace(`state.`, "");
        data = { ...watch(`${pageId}.${preDefinedVariable}`), ...(stepperGroups || {}) };
      } else {
        let preDefinedVariable = placeholder.substring(1, index);
        phText = phText.replace(`${preDefinedVariable}.`, "");
        data = viewsState?.[`${pageId}-${preDefinedVariable}`];
      }
    }

    phText = phText.replace("[*]", `[${itemIndex}]`);
    let replacedValue = null;
    if (phText.includes("[]")) {
      const prefix = phText.substring(0, phText.indexOf("[]"));
      const suffix = phText.substring(phText.indexOf("[].") + 3, phText.length);

      if (_.isArray(_.get(data, prefix))) {
        replacedValue = _.get(data, prefix)?.map(item => _.get(item, suffix));
      }
    } else {
      if (data && !phText) {
        replacedValue = data;
      } else {
        let newPhTextIndex = phText.indexOf(".");
        let newPhText = `${phText}${componentPath}`;
        if (newPhTextIndex > 0) {
          newPhText = `${phText.substring(0, newPhTextIndex)}${componentPath}${phText.substring(newPhTextIndex)}`;
        }
        replacedValue = !_.isNil(_.get(data, newPhText)) ? _.get(data, newPhText) : fallback;
      }
    }
    if (typeof replacedValue == "object") {
      result = replacedValue;
    } else {
      result = result.replace(placeholder.startsWith("{{") ? placeholder + "}" : placeholder, replacedValue);
    }
  });

  return result;
};

export const replaceDataPlaceholdersRecursively = ({
  item,
  viewsState,
  pageId,
  formData,
  __data,
  multiLingual,
  env,
  obj = {},
  ...rest
}: any) => {
  Object.keys(obj).forEach(key => {
    let template = obj?.[key];
    if (typeof obj[key] === "object" && obj[key] !== null) {
      return replaceDataPlaceholdersRecursively({
        obj: obj[key],
        item,
        viewsState,
        pageId,
        env,
        formData,
        multiLingual,
        __data,
        ...rest,
      });
    }
    obj[key] = replaceDataPlaceholders({
      queryString: template,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      multiLingual,
      env,
      ...rest,
    });
  });

  return obj;
};

export const formatJSON = (val = {}) => {
  try {
    const res = JSON.parse(val as any);
    return JSON.stringify(res, null, 2);
  } catch {
    return undefined;
  }
};

export const ActionButton: FC<{
  actionsMap?: any;
  action?: any;
  pageId?: any;
  onStateChange?: (newState: string) => void;
  disabled?: boolean;
  item: any;
  isUserInput?: boolean;
  views?: UIElement[];
  onSelectRow?: any;
  tableId: any;
  handleSubmit?: any;
  iconButton?: boolean;
  formData?: any;
  fullWidth?: boolean;
  variant?: string;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  isDismissibleView?: string;
  queryKeys?: any[];
  withBorder?: boolean;
  isResetEnabled?: boolean;
  viewName?: string;
  disableInternalAction?: boolean;
  onActionClick?: any;
  entries?: any;
  path?: string;
  selectedRows?: any;
  _key?: any;
  components?: any;
  index?: any;
  isChainMapped?: any;
  actionsKey?: any;
  interactionConfig?: any;
  tableAction?: any;
  isLoadingForEntireChain?: boolean;
  element?: any;
}> = props => {
  const {
    actionsMap = {},
    onStateChange = () => {},
    disabled = false,
    item,
    isUserInput,
    views,
    onSelectRow,
    pageId,
    tableId,
    handleSubmit,
    formData,
    parentIds,
    iconButton = true,
    fullWidth = false,
    variant = "contained",
    __data = {},
    closeModal = () => {},
    withBorder = true,
    queryKeys = [],
    viewName,
    disableInternalAction,
    onActionClick,
    entries,
    selectedRows,
    path,
    children,
    _key,
    index,
    isChainMapped,
    actionsKey,
    interactionConfig,
    tableAction,
    isLoadingForEntireChain,
    action,
    element,
  } = props;
  const [open, setOpen] = useState(false);
  const [jsonProgress, setJsonProgress] = useState<any[]>([]);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [proceedToNextAction, setProceedToNextAction] = useState<any>({});
  const [actionModal, setActionModal] = useState<any>();
  const [showModalForAction, setShowModalForAction] = useState<boolean>(false);

  const [isViewActionTriggered, setIsViewActionTriggered] = useState<boolean>(false);
  const [actionView, setActionView] = useState<any>();
  const [selectedView, setSelectedView] = useState<any>();

  let cancelWorkflow = false;
  let validationError = false;
  const { palette } = useTheme();
  const navigate = useNavigate();
  const ref = useRef<any>({});
  const { resetState, removeDirty, watch, setValue } = useAppState();

  const {
    currentApp,
    currentProfileId,
    viewsState,
    setViewStacks,
    queriesStateGraphQL,
    setViewsState,
    loadingViews,
    setLoadingViews,
    loginToApp,
    registerAppDevice,
    fqdnApp,
  } = useBXContext();

  const dispatch = useDispatch();

  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively, updateDataPlaceholders } = useReplaceDataPlaceholders({ viewName });

  useEffect(() => {
    const componentRef = viewsState?.[`${pageId}-${viewName}`]?.refs?.[_key] || ref;
    componentRef.current.handleActionMapper = executeChain;

    setViewsState(prev => {
      const data = { ...prev };

      _.set(data, `${pageId}-${viewName}.refs[${_key}]`, componentRef);
      return data;
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  //Condition to evaluate for action button in table
  const data = replaceDataPlaceholders({
    queryString: tableAction?.condition,
    item,
    viewsState,
    pageId,
    __data,
    env: currentApp?.env,
    selectedRows,
    index,
  });

  try {
    if (!eval(data) && !_.isEmpty(data)) return <></>;
  } catch (error) {
    return <></>;
  }

  const handleCustomMessage = (action, keyMessage, statusMessage) => {
    let statusMessagesIndex;
    let mapValuesObjectsMessages;
    let condition;
    let customMessage;

    if (keyMessage) {
      statusMessagesIndex = action?.statusMessages?.findIndex((item: any) => item?.key == keyMessage);

      if (statusMessagesIndex !== -1 && action.statusMessages[statusMessagesIndex]) {
        mapValuesObjectsMessages = action.statusMessages[statusMessagesIndex].mapValuesObjectsMessage.values || [];
        condition = action.statusMessages[statusMessagesIndex].mapValuesObjectsMessage.condition || "equal";
        customMessage = mapValuesObjectsMessages.default;

        if (mapValuesObjectsMessages) {
          if (condition === "equal" && mapValuesObjectsMessages[statusMessage]) {
            customMessage = mapValuesObjectsMessages[statusMessage];
          } else if (condition === "startWith") {
            const matchingKey = Object.keys(mapValuesObjectsMessages).find(key => statusMessage.startsWith(key));
            if (matchingKey) {
              customMessage = mapValuesObjectsMessages[matchingKey];
            }
          }
        }
      }
    }

    return customMessage;
  };

  const handleOnSuccess = async (action, response, variables) => {
    const { data, request, status } = response;
    setLoadingViews((prev: any) => {
      const updatedViews = { ...prev };
      updatedViews[`${pageId}-${viewName}`] = false;
      return updatedViews;
    });
    if (_key) {
      setViewsState((prev: any) => {
        _.set(prev, `${pageId}-${viewName}.response.${_key}`, { ...data, requestStatusCode: status });

        return prev;
      });
    }

    if (tableAction?.action && action?.isResetEnabled) {
      resetState(`${pageId}.${viewName}`);
    }

    if (variables?.isCsv) {
      variables?.then?.();
      setJsonProgress(prevProgress =>
        prevProgress.map(item => {
          if (item.id === variables?.csvRow?.id) {
            return { ...item, status: "Success" };
          }
          return item;
        })
      );
      onStateChange("normal");
      return;
    }

    const findKey = action?.statusMessages?.find((item: any) => item?.key == request?.status);
    const statusMessage = findKey?.value;
    const keyMessage = findKey?.key;
    const statusMessageReplace = replaceDataPlaceholders({
      queryString: statusMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: data,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    let customMessage = handleCustomMessage(action, keyMessage, statusMessageReplace);

    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: data,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    if (action?.showSnackbar ?? true) {
      enqueueSnackbarRef?.(customMessage || statusMessageReplace || "Posted Successfully", {
        variant: "success",
      });
    }

    if (action?.isDismissibleView != "No") {
      closeModal?.(data, false);
    }
    const isGraphQL = action?.isGraphQL || false;
    const key = `${pageId}-${viewName}`;
    const viewConfig = views?.find(view => view?.info?.viewName === viewName);
    if (viewConfig && viewConfig.dataSource?.sourceType != "NONE") {
      handleRefetchQueries({
        isGraphQL,
        queriesStateGraphQL,
        key,
        queryKeys,
      });
    }

    if (parentIds) {
      parentIds.forEach((id: any) => {
        handleRefetchQueries({
          isGraphQL,
          queriesStateGraphQL,
          key,
          queryKeys: [id],
        });
      });
    }

    if (tableAction?.action && action?.refreshActionView) {
      handleRefreshViews(tableAction?.action);
    }

    onStateChange("normal");
    // success: remove item from data
    if (action?.method.toLowerCase() === "delete") {
    } else if (action?.method.toLowerCase() === "post") {
      // update record because it is an item action
    } else if (action?.method.toLowerCase() === "put") {
      // update record because it is an item action
    } else if (action?.method.toLowerCase() === "patch") {
      // update record because it is an item action
    }
  };

  const handleOnError = (action, error, variables) => {
    setLoadingViews((prev: any) => {
      const updatedViews = { ...prev };
      updatedViews[`${pageId}-${viewName}`] = false;
      return updatedViews;
    });
    if (_key) {
      setViewsState((prev: any) => {
        _.set(prev, `${pageId}-${viewName}.response.${_key}`, error);
        return prev;
      });
    }

    if (variables?.isCsv) {
      variables?.then?.();
      setJsonProgress(prevProgress =>
        prevProgress.map(item => {
          if (item.id === variables?.csvRow?.id) {
            return {
              ...item,
              status: "Error",
              __error: error,
            };
          }
          return item;
        })
      );
      onStateChange("normal");
      return;
    }
    onStateChange("normal");
    const findKey = action?.statusMessages?.find((item: any) => item?.key == error?.requestStatusCode);
    const statusMessage = findKey?.value;
    const keyMessage = findKey?.key;
    const errorMessage = replaceDataPlaceholders({
      queryString: statusMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: error,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    let customMessage = handleCustomMessage(action, keyMessage, errorMessage);
    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: error,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    if (action?.showSnackbar ?? true) {
      enqueueSnackbarRef?.(
        customMessage ||
          errorMessage ||
          error?.response?.data?.error ||
          JSON.parse(error?.response?.config?.data || "{}")?.errorMessage ||
          "Wrong Services",
        {
          variant: "error",
        }
      );
    }
  };

  const onAction = async (action, variables) => {
    const isGraphQL = action?.isGraphQL || false;
    setLoadingViews((prev: any) => {
      const updatedViews = { ...prev };
      updatedViews[`${pageId}-${viewName}`] = true;
      return updatedViews;
    });
    setIsLoading(true);
    const token = localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);
    onStateChange("loading");
    const url = replaceBaseUrl(action?.source, currentApp);
    let data = undefined;

    const actionBody = isGraphQL ? action?.graphqlVariables : action?.body;

    const processedBody = replaceDataPlaceholdersRecursively({
      obj: JSON.parse(action?.showModal == "Yes" ? actionBody : actionBody || "{}"),
      viewsState,
      pageId,
      item,
      formData,
      __data,
      env: currentApp?.env,
      csvRow: variables?.csvRow,
      selectedRows,
      fallback: null,
      index,
    });

    const requestHeaders = {
      ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
      ...replaceDataPlaceholdersRecursively({
        obj: action?.showModal == "Yes" ? action?.headers : action?.headers,
        viewsState,
        pageId,
        item,
        formData,
        __data,
        env: currentApp?.env,
        csvRow: variables?.csvRow,
        selectedRows,
        index,
      }),
    };

    if (handleSubmit) {
      data = actionBody && processedBody;
    } else {
      data = actionBody && processedBody;
    }
    let response = {} as any;
    try {
      if (isGraphQL) {
        const gqlQuery = gql`
          ${action?.graphqlQuery}
        `;
        const isMutation = action?.graphQLMethod === "MUTATION" && action?.graphqlQuery?.includes("mutation");
        const operationFunction = (isMutation ? apolloClient.mutate : apolloClient.query) as any;
        const operationKey = isMutation ? "mutation" : "query";
        const graphQLContext = {
          uri: url,
          headers: requestHeaders,
        };
        response = await operationFunction({
          [operationKey]: gqlQuery,
          variables: data,
          context: graphQLContext,
        });
        if (variables?.onSuccess) {
          variables?.onSuccess(response?.data, variables);
        } else {
          handleOnSuccess(action, response, variables);
        }
      } else {
        response = await axios.request({
          url: replaceDataPlaceholders({
            queryString: url,
            item,
            viewsState,
            formData,
            pageId,
            __data,
            csvRow: variables?.csvRow,
            index,
          }),
          method: action?.method?.toLowerCase?.(),
          headers: requestHeaders,
          data,
          params: {
            ...(!!variables?.csvRow ? { skipError: !!variables?.csvRow } : {}),
          },
        });
        if (variables?.onSuccess) {
          variables?.onSuccess(response?.data, variables);
        } else {
          handleOnSuccess(action, response, variables);
        }
      }
    } catch (error) {
      cancelWorkflow = true;
      handleOnError(action, error, variables);
    } finally {
      setIsLoading(false);
      setLoadingViews((prev: any) => {
        const updatedViews = { ...prev };
        updatedViews[`${pageId}-${viewName}`] = false;
        return updatedViews;
      });
    }
  };

  const handleSelectClick = action => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".csv, .xlsx";
    fileInput.addEventListener("change", e => handleFileSelect(action, e));
    fileInput.click();
  };

  const handleFileSelect = (action, event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      if (selectedFile.name.endsWith(".csv") || selectedFile.name.endsWith(".xlsx")) {
        convertXlsxAndCsvToJson(action, selectedFile);
      } else {
        console.error("Unsupported file format");
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setJsonProgress([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const convertXlsxAndCsvToJson = async (action, file: File) => {
    const duration = action?.duration;

    const delayFactor = ~~duration || 50;

    const maxCalls = ~~action?.maxCalls || 1;

    const handleOpenLogic = async (jsonArray: any[], delayFactor: number) => {
      handleOpen();

      const totalRequests = jsonArray.length - 1;
      let currentIndex = 0;

      let activeThreads = maxCalls;
      const makeRequest = () => {
        let isOpen = false;
        setOpen(prev => {
          isOpen = prev;
          return prev;
        });
        if (!isOpen) return;

        const handleNextRequest = async () => {
          await new Promise(resolve => setTimeout(resolve, delayFactor));
          currentIndex += 1;
          if (totalRequests < currentIndex) {
            activeThreads -= 1;
            if ((activeThreads === 0 && action?.showSnackbar) ?? true) {
              const successMessage = "CSV Processing Completed Successfully!";
              enqueueSnackbarRef?.(successMessage, {
                variant: "success",
              });
            }
            return;
          }
          makeRequest();
        };
        setJsonProgress((prevProgress: any[]) => [jsonArray[currentIndex], ...prevProgress]);
        onAction(action, { csvRow: jsonArray[currentIndex], isCsv: true, then: handleNextRequest });
      };

      for (let index = 0; index < maxCalls; index++) {
        if (totalRequests < currentIndex) return;
        makeRequest();
        currentIndex += 1;
      }
    };

    const reader = new FileReader();

    reader.onload = async event => {
      if (event.target && event.target.result instanceof ArrayBuffer) {
        const data = event.target.result;
        const workbook = XLSX.read(new Uint8Array(data), { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        let jsonArray: any[] = [];

        if (file.name.endsWith(".xlsx")) {
          jsonArray = XLSX.utils.sheet_to_json(worksheet).map((row: any) => ({
            __id: uuid(),
            ...row,
            status: "Pending",
          }));
          handleOpenLogic([...jsonArray], delayFactor);
        } else if (file.name.endsWith(".csv")) {
          Papa.parse(file, {
            complete: async (result: any) => {
              if (result.data && result.data.length > 1) {
                const keys: string[] = result.data[0];

                const statusIndex = keys.indexOf("status");
                if (statusIndex !== -1) {
                  keys.splice(statusIndex, 1);
                  keys.push("status");
                }

                for (let i = 1; i < result.data.length - 1; i++) {
                  const rowData: any[] = result.data[i];
                  const obj: any = {
                    __id: uuid(),
                  };
                  keys.forEach((key, j) => {
                    obj[key] = rowData[j];
                  });
                  obj.status = "Pending";
                  jsonArray.push(obj);
                }
                handleOpenLogic([...jsonArray], delayFactor);
              } else {
                console.error("Error reading file");
              }
            },
          });
        }
      } else {
        console.error("Error reading file");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const downloadCSV = () => {
    if (entries?.length === 0) {
      return;
    }

    const keys = Object.keys(entries[0]);

    const csvContent = [keys.join(","), ...entries.map((entry: any) => keys.map(key => entry[key]).join(","))].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  const handleActionClick = async action => {
    if (loadingViews[`${pageId}-${viewName}`]) return;
    if (action.enableCSV && action?.actionSourceType?.id !== -3) {
      handleSelectClick(action);
      return;
    }

    if (action?.actionSourceType?.id === -3) {
      downloadCSV();
      return;
    }

    if (action?.dialog?.enabled && !openConfirmation) {
      return setOpenConfirmation(true);
    }

    if (handleSubmit) {
      const { isError, values } = handleSubmit();
      if (isError) {
        cancelWorkflow = true;
        validationError = true;
        return;
      }

      if (disableInternalAction) {
        await onActionClick(values, action);
      } else {
        await onAction(action, {});
      }
    } else {
      await onAction(action, {});
    }
  };

  const moveGroupStepperIndexBy = (offset: number, _action: any) => {
    setValue(
      `${pageId}.${viewName}._BX_stepperGroups`,
      updateStepperGroupIndex(watch(`${pageId}.${viewName}._BX_stepperGroups`) || {}, _action?.stepperGroupReference, "UPDATE", offset)
    );
  };

  const setStepperIndex = (index: number, _action: any) => {
    setValue(
      `${pageId}.${viewName}._BX_stepperGroups`,
      updateStepperGroupIndex(watch(`${pageId}.${viewName}._BX_stepperGroups`) || {}, _action?.stepperGroupReference, "SET", index)
    );
  };

  const handleSetStepperIndex = _action => {
    setStepperIndex(_action?.stepperIndex, _action);
  };

  const handleStepperPrevious = _action => {
    moveGroupStepperIndexBy(-1, _action);
  };
  const handleStepperNext = _action => {
    moveGroupStepperIndexBy(1, _action);
  };

  const addItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "ADD",
      $payload: {},
    });
  };

  const trigger = async (action, e, viewsState, wait?: any) => {
    const triggerSelf = action.triggerSelf;
    const key = triggerSelf ? _key : action.triggerKey;

    await new Promise<void>(resolve => {
      setTimeout(async () => {
        if (wait) {
          await triggerActionableComponent(e, pageId, viewName, key, viewsState);
        } else {
          triggerActionableComponent(e, pageId, viewName, key, viewsState);
        }
        resolve();
      }, 0);
    });
  };

  const deleteItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "DELETE",
    });
  };

  const updateItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "UPDATE",
      $payload: replaceDataPlaceholders({
        queryString: action.updatedValue,
        item,
        viewsState,
        formData,
        pageId,
        __data,
        index,
      }),
    });
  };

  const cleanDirty = action => {
    const inputReference = action?.dataReference ?? "";
    let name = "";

    if (inputReference.startsWith("{this")) {
      if (inputReference === "{this}") {
        name = `${pageId}.${viewName}`;
      } else if (inputReference === "{this._page}") {
        name = `${pageId}`;
      } else {
        const placeholder = inputReference.slice("{this.".length, -1);
        name = `${pageId}.${viewName}.${placeholder}`;
      }
    } else if (inputReference.startsWith("{")) {
      const placeholder = inputReference.slice(1, -1);
      name = `${pageId}.${viewName}.${placeholder}`;
    }
    if (name) {
      removeDirty(name);
    }
  };

  const timerAction = async action => {
    const timer = Number(action?.timerValue);
    const sec = timer * 1000;
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, sec);
    });
  };

  const handleCopyClick = action => {
    const copySourceValue =
      replaceDataPlaceholders({
        queryString: action.copySource,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      }) || "";

    if (copySourceValue) {
      navigator.clipboard
        .writeText(copySourceValue)
        .then(() => {
          if (action?.showSnackbar ?? true) {
            enqueueSnackbarRef("Copied!", { variant: "success" });
          }
        })
        .catch(err => {
          cancelWorkflow = true;
          if (action?.showSnackbar ?? true) {
            enqueueSnackbarRef("Failed to copy!", { variant: "error" });
          }
        });
    }
  };
  const showSnackbar = action => {
    type VerticalPosition = "top" | "bottom";
    type HorizontalPosition = "left" | "center" | "right";
    const customMessage = replaceDataPlaceholders({
      queryString: action.snackbarMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      env: currentApp?.env,
      selectedRows,
      index,
    });

    const duration = action.snackbarDuration || currentApp?.appConfig?.snackbar?.duration;
    const durationInMs = Number(duration) * 1000;
    const customVariant = action.snackbarVariant || currentApp?.appConfig?.snackbar?.variant;
    const position = action?.snackbarPosition || currentApp?.appConfig?.snackbar?.position;
    const actionButton = action.snackbarActionButton || currentApp?.appConfig?.snackbar?.closeButton;
    const preventDuplicate = action.snackbarPreventDuplicate;
    const [vertical, horizontal] = position.split("-") as [VerticalPosition, HorizontalPosition];

    enqueueSnackbarRef?.(customMessage, {
      variant: customVariant,
      anchorOrigin: {
        vertical,
        horizontal,
      },
      ...(duration && { autoHideDuration: durationInMs }),
      ...(actionButton !== "Without Button" && {
        action: key => (
          <Button
            onClick={() => {
              closeSnackbarRef(key);
            }}
          >
            {actionButton == "IconX" && <IconX color='white' size={18} />}
            {actionButton == "Dismiss" && <Typography color={"white"}>Dismiss</Typography>}
          </Button>
        ),
      }),
      preventDuplicate,
    });
  };

  const handleLinkClick = async action => {
    if (!action?.navigationAfterAction && action?.actionSourceType?.type !== "Link") return;
    const linkUrlValue =
      replaceDataPlaceholders({
        queryString: action?.linkUrl,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      }) || "";

    if (linkUrlValue) {
      if (linkUrlValue.startsWith("/")) {
        const pathPrefix = fqdnApp ? "" : currentApp?.slug;
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              resolve(navigate(`${pathPrefix}${linkUrlValue}`));
            } catch (error) {
              reject(error);
            }
          }, 0);
        });
      } else {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              resolve(window.open(linkUrlValue, action?.openLinkAs || "_blank"));
            } catch (error) {
              reject(error);
            }
          }, 0);
        });
      }
    } else {
      cancelWorkflow = true;
      if (action?.showSnackbar ?? true) {
        enqueueSnackbarRef("Invalid URL!", { variant: "error" });
      }
    }
  };

  const handleRefreshViews = async action => {
    const isGraphQL = action?.isGraphQL || false;

    const _refreshList =
      typeof action?.refreshActionView?.[0] === "string"
        ? action?.refreshActionView.map(name => {
            const { id } = views?.find(view => view?.info?.name === name) as UIElement;
            return {
              id: id,
              name,
            };
          })
        : action?.refreshActionView;

    const refreshViewsIds = new Set(_refreshList.map(v => v?.id));
    const refreshViews = views?.filter(view => refreshViewsIds.has(view?.id));

    refreshViews?.forEach(async refreshView => {
      const refreshViewName = refreshView?.info?.viewName;
      const refreshKey = `${pageId}-${refreshViewName}`;

      await handleRefetchQueries({
        isGraphQL,
        queriesStateGraphQL,
        key: refreshKey,
        queryKeys: refreshKey,
      });
    });
  };

  const downloadFile = (url, showSnackbar) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", blob.type);
        document.body.appendChild(link);
        link.click();
      })
      .catch(e => {
        cancelWorkflow = true;
        if (showSnackbar) {
          enqueueSnackbarRef("Failed to download!", { variant: "error" });
        }
      });
    return;
  };

  const handleDownloadClick = async action => {
    if (action?.downloadType === "api") {
      onAction(action, {
        onSuccess: data => {
          let url = data;
          if (action?.downloadKey) {
            url = data?.[action?.downloadKey];
          }
          downloadFile(url, action?.showSnackbar ?? true);
        },
      });
      return;
    }

    downloadFile(action?.directUrl, action?.showSnackbar ?? true);
  };

  const handleStackClick = (action, e) => {
    e.stopPropagation();
    onSelectRow?.(item, true);
    setViewStacks(
      prev =>
        [
          ...prev,
          <BXEngine
            path={[path, selectedView?.info?.name].join(".")}
            auth={{}}
            page={{ views, layout: selectedView, id: pageId } as any}
            layout={[{ ...(selectedView as any), type: action?.actionSourceType?.type }]}
            isVisible
            selectedViewId={item.id}
            __data={{
              ...__data,
              [(getLastKeyFromObject(__data) || "") + "#."]: viewName,
            }}
            closeModal={closeModal}
            parentIds={[...parentIds, ...queryKeys]}
          />,
        ] as UIElement[]
    );
  };

  const handleLoginAction = async action => {
    const email = replaceDataPlaceholders({
      queryString: action?.BXLogin?.emailField,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: "",
    });

    const password = replaceDataPlaceholders({
      queryString: action?.BXLogin?.passwordField,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: "",
    });

    await registerAppDevice?.(currentApp?.id || fqdnApp?.id || "", fqdnApp?.appConfig?.auth?.deviceApi, fqdnApp);
    await loginToApp?.(
      currentApp?.id || fqdnApp?.id || "",
      email,
      password,
      undefined,
      undefined,
      () => {},
      undefined,
      fqdnApp?.appConfig?.auth?.authApi,
      fqdnApp
    );
  };

  const handleStartLoading = () => {
    //Change loading state for page and view
    dispatch(setLoading({ keys: { page: pageId, view: `${pageId}.${viewName}` } }));
  };

  const handleEndLoading = () => {
    //Change loading state for page and view
    dispatch(resetLoading({ keys: { page: pageId, view: `${pageId}.${viewName}` } }));
  };

  const handleVisibilityToggle = action => {
    const keyReference = action.keyReference;
    if (keyReference) {
      setViewsState(prevState => {
        const viewRef = `${pageId}-${viewName}`;
        const newState: IViewState = {
          ...prevState,
          state: prevState.state,
          visibility: {
            ...prevState.visibility,
            [viewRef]: {
              ...prevState.visibility?.[viewRef],
              [keyReference]: {
                toggle: !prevState.visibility?.[viewRef]?.[keyReference]?.toggle,
                display: action.displayRef ? "hidden" : "none",
              },
            },
          },
        };

        return newState;
      });
    }
  };

  const handleActionMapper = async (action, e, viewsState) => {
    const isPureAction = actionTypes?.find(actionType => actionType?.id === action?.actionSourceType?.id);
    const isViewAction = !isPureAction;
    if (isViewAction) {
      const _views = views?.concat(getSharedViews(currentApp));
      setSelectedView(_views?.find(view => view?.id === action?.actionSourceType?.id));
      setActionView(action);
      setIsViewActionTriggered(true);
      await new Promise(resolve => {
        setProceedToNextAction({
          currentAction: action,
          confirm: () => {
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            resolve(false);
          },
        });
      });
      return;
    }

    if (action?.actionSourceType?.type === "API" && action?.dialog?.enabled) {
      const resolvedTitle = replaceDataPlaceholders({
        queryString: action?.dialog?.message,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      });

      setActionModal({
        action: action,
        title: resolvedTitle,
      });

      setOpenConfirmation(true);
      await new Promise(resolve => {
        setProceedToNextAction({
          currentAction: action,
          title: resolvedTitle,
          confirm: () => {
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            resolve(false);
          },
        });
      });
      return;
    }

    if (action?.actionSourceType?.type === "API" && action?.showModal === "Yes") {
      setActionModal(action);
      setShowModalForAction(true);
      await new Promise(resolve => {
        setProceedToNextAction({
          currentAction: action,
          confirm: () => {
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            resolve(false);
          },
        });
      });
      return;
    }

    if (isLoading || disabled) return;
    switch (action?.actionSourceType?.type) {
      case "API":
        await handleActionClick(action);
        break;
      case "Views": {
        setSelectedView(views?.find(view => view?.id === action?.actionSourceType?.id));
        setActionView(action);
        setIsViewActionTriggered(true);
        await new Promise(resolve => {
          setProceedToNextAction({
            currentAction: action,
            confirm: () => {
              resolve(true);
            },
            cancel: () => {
              cancelWorkflow = true;
              resolve(false);
            },
          });
        });
        break;
      }
      case "Download":
        await handleDownloadClick(action);
        break;
      case "Copy":
        handleCopyClick(action);
        break;
      case "Show Snackbar":
        showSnackbar(action);
        break;
      case "Link":
        handleLinkClick(action);
        break;
      case "BuildX Login":
        await handleLoginAction(action);
        break;
      case "Stack":
        handleStackClick(action, e);
        break;
      case "Visibility Toggle":
        handleVisibilityToggle(action);
        break;
      case "Export CSV":
        handleActionClick(action);
        break;
      case "Add item":
        addItem(action);
        break;
      case "Delete item":
        deleteItem(action);
        break;
      case "Update item":
        updateItem(action);
        break;
      case "Trigger":
        if (!action.triggerSelf && action.triggerAwait && action.triggerKey !== _key) {
          const wait = true;
          await trigger(action, e, viewsState, wait);
        } else {
          trigger(action, e, viewsState);
        }
        break;
      case "Clean Dirty":
        cleanDirty(action);
        break;
      case "Timer":
        await timerAction(action);
        break;
      case "Dismiss": {
        closeModal?.(null);
        break;
      }
      case "Start Loading":
        handleStartLoading();
        break;
      case "End Loading":
        handleEndLoading();
        break;
      case "Stepper Previous": {
        handleStepperPrevious?.(action);
        break;
      }
      case "Stepper Next": {
        handleStepperNext?.(action);
        break;
      }
      case "Select Stepper": {
        handleSetStepperIndex?.(action);
        break;
      }
      case "Refresh Views": {
        handleRefreshViews(action);
        break;
      }
      case "Reset Form": {
        resetState(`${pageId}.${viewName}`);
        break;
      }
      default:
    }
  };

  async function executeChain(e: any, _viewsState?: any) {
    let actions: any[] = actionsMap?.default || [];
    // reset the cancel work flow error
    cancelWorkflow = false;

    // reset .response for the whole workflow action
    if (_key) {
      setViewsState((prev: any) => {
        _.set(prev, `${pageId}-${viewName}.response.${_key}`, undefined);
        return prev;
      });
    }

    let mappedActionKey: string;

    if (!isChainMapped) {
      mappedActionKey = "default";
      actions = actionsMap?.default || [];
      if (props?.action && _.isEmpty(actionsMap)) {
        actions = [{ actionConfig: props?.action }, ...actions];
      }
      if (tableAction?.action) {
        actions = [{ actionConfig: tableAction?.action }, ...actions];
      }
    } else {
      const resolvedActionsKey = replaceDataPlaceholders({
        queryString: actionsKey,
        item,
        viewsState,
        pageId,
        formData,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      });
      mappedActionKey = resolvedActionsKey;

      const condition = element?.config?.conditionActionMapKey || "equal";
      if (condition === "equal" && actionsMap?.[resolvedActionsKey]) {
        actions = actionsMap?.[resolvedActionsKey];
      } else if (condition === "startWith") {
        const matchingKey = Object.keys(actionsMap).find(key => resolvedActionsKey?.startsWith(key));
        if (matchingKey) {
          actions = actionsMap?.[matchingKey];
        }
      }
    }

    actions = actions.filter(action => Object.keys(action?.actionConfig)?.length !== 0);
    if (isLoadingForEntireChain?.[mappedActionKey]) {
      actions = actions.filter(action => !action?.actionConfig?.actionSourceType?.type?.includes("Loading"));
      handleStartLoading?.();
    }
    for (let i = 0; i < actions?.length; i++) {
      const action = actions?.[i]?.actionConfig;

      const actionCondition = replaceDataPlaceholders({
        queryString: action.condition,
        item,
        viewsState,
        pageId,
        formData,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      });

      try {
        if (!eval(actionCondition) && !_.isEmpty(actionCondition)) {
          continue;
        }
      } catch (error) {
        console.error("Expression throws an exception");
      }

      await handleActionMapper(action, e, _viewsState || viewsState);

      // //TODO: Extract validation to a separate action
      // if (validationError) {
      //   return;
      // }

      if (cancelWorkflow && (action.abort ?? true)) {
        // enqueueSnackbarRef?.("Workflow aborted", { variant: "error" });
        handleEndLoading?.();
        return;
      }
    }

    if (isLoadingForEntireChain?.[mappedActionKey]) {
      handleEndLoading?.();
    }

    // enqueueSnackbarRef?.("Workflow executed successfully", { variant: "success" });
  }

  const renderActionableContent = () => {
    return typeof children === "function" ? children({ actionHandlerMapper: executeChain, isLoading }) : children;
  };

  const onClickHandler = e => {
    //For old action version
    if (!interactionConfig) {
      executeChain(e);
      return;
    }

    const selfTriggers = interactionConfig?.filter(interaction => interaction.type === "OnClick" && interaction?.triggerSelf);
    selfTriggers?.forEach(_ => executeChain(e));
  };

  const renderActionableComponent = () => {
    return children ? (
      <span style={{ display: "contents" }} onClick={onClickHandler}>
        {renderActionableContent()}
      </span>
    ) : (
      <Tooltip title={tableAction?.label}>
        {iconButton ? (
          <IconButton onClick={executeChain} disabled={disabled}>
            <BXIcon icon={tableAction?.icon} width={16} height={16} color={"currentColor"} />
          </IconButton>
        ) : (
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            aria-label={tableAction?.label}
            variant={variant as any}
            disabled={disabled}
            onClick={executeChain}
            startIcon={tableAction?.icon && <BXIcon icon={tableAction?.icon} width={20} height={20} color={palette.text.primary} />}
            fullWidth={fullWidth}
            style={!withBorder ? { borderWidth: 0, justifyContent: "flex-start" } : {}}
          >
            <Typography color={palette.text.primary}>{tableAction?.label}</Typography>
          </LoadingButton>
        )}
      </Tooltip>
    );
  };

  // if (!action || _.isEmpty(action) || action?.actionSourceType?.id === -7) return (renderActionableContent() as any) || null;
  // const isAPI = _.isNaN(action?.actionSourceType?.type) || !action?.actionSourceType?.type || action?.actionSourceType?.type == "API";

  const isAPI = false;

  return (
    <>
      {isAPI && <CSVProgressDialog open={open} handleClose={handleClose} jsonData={jsonProgress} />}

      {actionModal && (
        <BXConfirmationDialog
          open={openConfirmation}
          title={actionModal?.title || "Are you sure?"}
          iconButton
          onConfirm={async () => {
            await handleActionClick(proceedToNextAction?.currentAction);
            proceedToNextAction.confirm();
            setOpenConfirmation(false);
            setActionModal(null);
          }}
          onCancel={() => {
            proceedToNextAction.cancel();
            setOpenConfirmation(false);
            setActionModal(null);
          }}
        />
      )}

      {actionModal && (
        <BXModal
          open={showModalForAction}
          buttonComponent={children}
          label={actionModal?.label}
          icon={<BXIcon icon={actionModal?.icon} width={14} height={14} color={"currentColor"} />}
          buttonProps={{
            variant: "contained",
            startIcon: <BXIcon icon={actionModal?.icon} width={14} height={14} color={"currentColor"} />,
          }}
          title={actionModal?.label}
          withoutLabel={iconButton}
          onClose={() => {
            setShowModalForAction(false);
            setActionModal(null);
            proceedToNextAction?.cancel();
          }}
        >
          {(handleClose: Function) => {
            const isGraphQL = actionModal?.isGraphQL || false;

            const actionBody = isGraphQL ? actionModal?.graphqlVariables : actionModal?.body;

            const resolvedBody = replaceDataPlaceholdersRecursively({
              obj: JSON.parse(actionModal?.showModal === "Yes" ? actionBody : actionBody || "{}"),
              viewsState,
              pageId,
              item,
              formData,
              __data,
              env: currentApp?.env,
              selectedRows,
              index,
            });

            const resolvedHeaders = replaceDataPlaceholdersRecursively({
              obj: actionModal?.headers,
              viewsState,
              pageId,
              item,
              formData,
              __data,
              env: currentApp?.env,
              selectedRows,
              index,
            });

            const uri = replaceDataPlaceholders({
              queryString: actionModal?.source,
              item,
              viewsState,
              formData,
              pageId,
              __data,
              index,
            });
            return (
              <CreatePayload
                payload={{
                  ...(isGraphQL
                    ? { graphqlVariables: JSON.stringify(resolvedBody), graphqlQuery: actionModal?.graphqlQuery }
                    : { body: JSON.stringify(resolvedBody) }),
                  headers: resolvedHeaders,
                  uri,
                  method: actionModal?.method,
                  graphQLMethod: actionModal?.graphQLMethod,
                  isGraphQL: actionModal?.isGraphQL,
                }}
                onSave={async (payload: any) => {
                  proceedToNextAction.action = {
                    ...proceedToNextAction.action,
                    ...(isGraphQL ? { graphqlVariables: payload?.graphqlVariables } : { body: payload?.body }),
                    headers: payload?.headers,
                    source: payload?.uri,
                    method: actionModal?.method,
                    graphQLMethod: actionModal?.graphQLMethod,
                  };

                  await onAction(proceedToNextAction?.action, {});
                  setActionModal(null);
                  proceedToNextAction?.confirm();
                }}
                label={actionModal?.label || "Save"}
                onClose={() => handleClose()}
                isLoading={isLoading}
                disabled={disabled}
              />
            );
          }}
        </BXModal>
      )}
      {renderActionableComponent()}
      {isViewActionTriggered && (
        <ViewerModal
          action={actionView}
          viewName={viewName}
          actionSourceType={actionView?.actionSourceType}
          item={item}
          pageId={pageId}
          selectedView={selectedView}
          openView={isViewActionTriggered}
          onSelectRow={onSelectRow}
          views={views}
          withoutLabel={iconButton}
          iconColor='currentColor'
          buttonComponent={children}
          isUserInput={isUserInput}
          showHeader={!!actionView?.showHeader}
          buttonProps={{
            style: iconButton
              ? {
                  // backgroundColor: palette.primary.main, padding: 6
                }
              : { ...(!withBorder && { borderWidth: 0, justifyContent: "flex-start" }) },
            fullWidth,
            variant,
          }}
          modalSize={actionView?.modalSize}
          __data={__data}
          id={tableId}
          parentIds={[...parentIds, ...queryKeys]}
          clearActionView={() => {
            proceedToNextAction.confirm();
            setIsViewActionTriggered(false);
            setActionView(null);
            setSelectedView(null);
          }}
        />
      )}
    </>
  );
};
