import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXPageType } from "src/types/BXPageType";
import { FormBuilderEditor } from "src/views/pages/BuildX/FormBuilder/FormBuilderEditor";
import { useBXBuilderContext } from "../../../../BXEngine/BXBuilderContext";

type FormBuilderEditorModalProps = {
  params?: {
    appId?: string;
    collectionId?: string;
    pageId?: string;
    viewId?: string;
    templateId?: string;
  };
  pageBuilderMode?: boolean;
  page?: BXPageType;
  onSave?: (data: any) => Promise<any>;
};
export const PageBuilder: FC<FormBuilderEditorModalProps> = () => {
  const [page, setPage] = useState<BXPageType | null>(null);
  const { appId, collectionId, pageId } = useParams();
  const { apps, editPage } = useBXBuilderContext();
  const { currentApp } = useBXContext();
  const _currentApp = currentApp;

  const navigate = useNavigate();
  const openNewWindow = useCallback(() => {
    const urlToNavigate = `/buildx/app?appId=${appId}&collectionId=${collectionId}&pageId=${pageId}&pageBuilder=1`;
    navigate(urlToNavigate);
  }, []);

  const handleBackClick = () => {
    openNewWindow();
  };

  useEffect(() => {
    const app = apps?.find(app => app?.id === appId);
    const collection = app?.templateConfig?.collections?.find(collection => collection?.id == collectionId);

    const page = collection?.pages?.find(page => page?.id === pageId);
    setPage(page!);

    return () => {};
  }, [apps]);

  const onSave = useCallback(
    async (data, onSuccess) => {
      if (!appId || !collectionId || !pageId) return;

      if (!page) return;

      editPage?.(appId, collectionId, pageId, data, onSuccess);
    },
    [apps, page]
  );
  if (!page) return null;
  return (
    <FormBuilderEditor
      onSave={onSave}
      params={{ _currentApp, pageId, appId, collectionId }}
      pageBuilderMode
      page={page}
      onBackClick={handleBackClick}
    />
  );
};
