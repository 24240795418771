import axios from "axios";

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_HOST_API_KEY_API,
  timeout: 100000,
});

axiosServices.defaults.headers.common.Authorization = "Bearer " + localStorage.getItem("accessToken");
axiosServices.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

// // interceptor for http - ToDo
// axios.interceptors.request.use(
//     (request) => {
//         // Edit request config
//         return request;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

axiosServices.interceptors.response.use(
  response => response,
  error => {
    // if (error?.response?.status == 401) {
    //   if (!JSON.parse(error.response.config.data || "{}")?.isLogin && !error.response.config?.params?.skipInterceptors) {
    //     localStorage.clear();
    //     window.location.href = window.location.origin;
    //   }
    // }

    return Promise.reject(
      (error?.response && error?.response?.data && { requestStatusCode: error?.request?.status, ...error.response.data }) ||
        "Wrong Services"
    );
  }
);

export default axiosServices;
