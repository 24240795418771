import { Backdrop } from "@mui/material";
import React, { CSSProperties, MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";

interface PopoverProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  style?: CSSProperties;
  x: number;
  y: number;
}

const Popover: React.FunctionComponent<PopoverProps> = ({ children, open, onClose, style = {}, x, y }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverOverlayStyleRef = useRef<CSSProperties | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      const popoverWidth = 210; 
      const viewportWidth = window.innerWidth;
      
      let leftPosition = x;
      if (x + popoverWidth > viewportWidth) {
        leftPosition = Math.max(viewportWidth - popoverWidth, 0);
      }
  
      const popoverOverlayStyle: CSSProperties = {
        position: "absolute",
        top: y,
        left: leftPosition,
        zIndex: 10,
        backgroundColor: theme.palette.background.paper,
        minWidth: "210px", 
        borderRadius: "10px",
        margin: "0 0 0 -40px",
      };
  
      popoverOverlayStyleRef.current = popoverOverlayStyle;
  
      setIsOpen(true);
    }
  }, [open, x, y]);
  

  const popoverContentStyle: CSSProperties = {};

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    onClose();
    setIsOpen(false);
  };

  return (
    <Backdrop open={open} sx={{ opacity: 0, backgroundColor: "transparent", zIndex: 9999 }} onClick={handleClick}>
      {open && isOpen && popoverOverlayStyleRef.current && (
        <div style={popoverOverlayStyleRef.current}>
          <div style={popoverContentStyle}>{children}</div>
        </div>
      )}
    </Backdrop>
  );
};

export default Popover;
