import { Box, Typography } from "@mui/material";

const BXLayout = ({ $pageOutlet, $config, __config, metaData, ...rest }) => {
  if (__config) {
    return $pageOutlet;
  }

  return (
    <Box {...rest}>
      <Box style={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <Typography fontWeight={"bold"} fontSize={"24px"}>
          Page
        </Typography>
      </Box>
    </Box>
  );
};

export { BXLayout };
