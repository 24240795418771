import { Portal } from "@mui/material";
import React, { createElement, useEffect, useRef, useState } from "react";
import { isPageScrollable } from "src/views/pages/BuildX/FormBuilder/utils";

const ResizeElement = ({
  width: initialWidth,
  height: initialHeight,
  stepper = 10,
  children,
  onResize,
  isParent,
  isDragging,
  disableHeight,
  zoomFactor = 1,
  isRTL,
  boxPosition,
  scrollToButton,
  isCanvas,
}: {
  width?: number;
  height?: number;
  stepper?: number;
  onResize?: any;
  children: React.ReactNode;
  isParent?: any;
  isDragging?: any;
  zoomFactor: any;
  disableHeight?: boolean;
  isRTL?: boolean;
  scrollToButton?: boolean;
  boxPosition?: any;
  isCanvas?: any;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<any>({});

  useEffect(() => {
    // Get the position values on every render
    if (ref.current) {
      const { top, left, width, height } = ref?.current?.getBoundingClientRect();

      // Get the scroll offset of the parent element (if it has a scrollbar) or the document
      const scrollX =
        window.pageXOffset !== undefined
          ? window.pageXOffset
          : (document.documentElement || document.body.parentNode || document.body).scrollLeft;
      const scrollY =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (document.documentElement || document.body.parentNode || document.body).scrollTop;

      const handleSize = 5;
      // Calculate the bottom and right positions relative to the ref element, considering the scroll offset
      const bottom = window.innerHeight - top * zoomFactor - height * zoomFactor - handleSize - scrollY;

      const zoomWidth = isRTL ? 0 : width * zoomFactor;
      const right = window.innerWidth - left * zoomFactor - zoomWidth - handleSize - (!!scrollY || isPageScrollable() ? handleSize : 0);

      // Calculate the bottom and right positions relative to the ref element
      if (position?.bottom != bottom || position?.right != right) {
        setPosition(prev => ({ ...prev, bottom, right }));
      }
    }
  }, [isDragging, children]);

  const handleMouseDown = (axis: "x" | "y" | "both") => (e: React.MouseEvent<HTMLDivElement>) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = ref.current?.clientWidth || 0;
    const startHeight = ref.current?.clientHeight || 0;
    let newWidth = startWidth;
    let newHeight = startHeight;
    let isScrolling = false;
    let autoScrollOffset = 0;
    const handleMouseMove = (e: MouseEvent) => {
      const dx = (e.clientX - startX) / zoomFactor;
      const dy = (e.clientY + autoScrollOffset - startY) / zoomFactor;

      if (axis === "x" || axis === "both") {
        newWidth = startWidth + Math.round(((isRTL ? -1 : 1) * dx) / stepper) * stepper;

        if (boxPosition) {
          newWidth = newWidth <= boxPosition?.width ? newWidth : boxPosition?.width;
        }
      }

      if (axis === "y" || axis === "both") {
        const _newHeight = startHeight + Math.round(dy / stepper) * stepper;

        newHeight = document.body.clientHeight - 1 == e.clientY ? newHeight + stepper : _newHeight;

        if (boxPosition) {
          newHeight = newHeight <= boxPosition?.height ? newHeight : boxPosition?.height;
        }
      }
      if (ref.current) {
        const { top, left, width, height } = ref?.current?.getBoundingClientRect();
        if (!disableHeight) {
          ref.current.style.width = newWidth + "px";
        }
        ref.current.style.height = newHeight + "px";

        const handleSize = 5;

        const scrollY =
          window.pageYOffset !== undefined
            ? window.pageYOffset
            : (document.documentElement || document.body.parentNode || document.body).scrollTop;

        const bottom = window.innerHeight - top * zoomFactor - newHeight * zoomFactor - handleSize - scrollY;

        const zoomWidth = isRTL ? 0 : width * zoomFactor;

        const right = window.innerWidth - left * zoomFactor - zoomWidth - handleSize - (!!scrollY || isPageScrollable() ? handleSize : 0);

        setPosition(prev => ({
          ...prev,
          bottom: newHeight < 1 ? prev?.bottom : bottom,
          ...(!disableHeight && { right: newWidth < 1 ? prev?.right : right }),
          width: newWidth,
          height: newHeight,
        }));
        if (scrollToButton) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }

      if (isCanvas) {
        if (e.clientY >= window.innerHeight - 10) {
          if (!isScrolling) {
            isScrolling = true;
            handleAutoScroll();
          }
        } else {
          isScrolling = false;
        }
      }
    };

    const handleAutoScroll = () => {
      if (isScrolling) {
        autoScrollOffset += 10;
        window.scrollBy(0, 310);
        newHeight += 10;

        if (ref.current) {
          ref.current.style.height = newHeight + "px";
        }

        window.requestAnimationFrame(handleAutoScroll);
      }
    };

    const handleMouseUp = () => {
      onResize?.({ width: newWidth, height: newHeight });
      if (ref.current) {
        ref.current.style.removeProperty("width");
        ref.current.style.removeProperty("height");
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      isScrolling = false;
    };

    window.addEventListener("mousemove", handleMouseMove as any);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const _children = React.Children?.map(children, child => child);
  const firstChild = _children?.[0] as React.ReactElement;
  return (
    <>
      {createElement(
        firstChild?.type || "div",
        { ...firstChild?.props, ref },
        <>
          {firstChild?.props?.children}
          {!isDragging && (
            <Portal>
              <div
                style={{
                  position: "absolute",
                  bottom: position?.bottom,
                  right: position?.right,
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                  borderRadius: "50%",
                  zIndex: 999,
                  backgroundColor: "white",
                  cursor: isRTL ? "nesw-resize" : "nwse-resize",
                }}
                onMouseDown={e => {
                  e.preventDefault(); // Prevent the default drag behavior
                  handleMouseDown("both")(e);
                }}
                onDragStart={e => e.preventDefault()} // Prevent the drag start event
              />
            </Portal>
          )}
        </>
      )}
    </>
  );
};

export { ResizeElement };
