import { Theme, Tooltip, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import {
  IconCopy,
  IconExchange,
  IconEye,
  IconEyeOff,
  IconFileExport,
  IconLanguage,
  IconLayoutBoardSplit,
  IconLock,
  IconPencil,
  IconRefresh,
  IconTools,
  IconTrash,
  IconUser,
  IconWorld,
  IconX,
} from "@tabler/icons-react";
import { useState } from "react";
import { BXIcon } from "src/components/BXUI/Icon";
import { useDnD } from "src/hooks/useDnD";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import MoveViewsModal from "./MoveViewsModal";
import Popover from "./Popover";
// style constant
const useStyles = makeStyles((theme: Theme) => ({
  rowContainer: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:hover $dotsIconStyle": {
      display: "block",
    },
  },
  dotsIconStyle: {
    display: "none",
  },
  iconStyle: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));
const BuilderRow = ({
  item,
  index,
  headerName,
  isHidden,
  withEye,
  moveApp,
  updateApp,
  withMove,
  type,
  setSelectedRow,
  selectedRow,
  onConfigClick,
  onUserClick,
  onTrashClick,
  onEditClick,
  onViewBuilderClick,
  onLayoutClick,
  onMoveClick,
  onSSOClick,
  onLanguageClick,
  onDuplicateClick,
  onExportClick,
  handleSyncClick,
  isShared,
}: any) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { palette } = useTheme();
  const [x, setX] = useState(644);
  const [y, setY] = useState(266);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false); // New state variable
  const { ref, opacity, handlerId } = useDnD({
    id: item?.id,
    index,
    name: headerName,
    moveCard: moveApp,
    updateCard: (dragIndex, hoverIndex) => updateApp(dragIndex, hoverIndex),
  });
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleClick = (event: any) => {
    event.stopPropagation();
    setSelectedRow(item);
    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    const x = rect.left + window.scrollX;
    const y = rect.top + window.scrollY;
    setX(x);
    setY(y);
    setIsPopoverOpen(true); // Open the popover
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false); // Close the popover
  };
  const handleMoveClick = () => {
    setIsModalOpen(true);
  };
  const open = Boolean(anchorEl);

  const iconActions = [
    { title: "", component: (props: any) => <IconX {...props} />, condition: true },
    { title: "", component: () => <Divider orientation='vertical' />, condition: true },
    { title: "Edit", component: (props: any) => <IconPencil {...props} onClick={onEditClick} />, condition: onEditClick },
    {
      title: "View Builder",
      component: (props: any) => <IconTools {...props} onClick={onViewBuilderClick} />,
      condition: onViewBuilderClick && item?.type === "form-builder",
    },
    { title: "Lock", component: (props: any) => <IconLock {...props} onClick={onConfigClick} />, condition: onConfigClick },
    { title: "DNS", component: (props: any) => <IconWorld {...props} onClick={onSSOClick} />, condition: onSSOClick },
    { title: "Languages", component: (props: any) => <IconLanguage {...props} onClick={onLanguageClick} />, condition: onLanguageClick },
    { title: "Layout", component: (props: any) => <IconLayoutBoardSplit {...props} onClick={onLayoutClick} />, condition: onLayoutClick },
    { title: "Users", component: (props: any) => <IconUser {...props} onClick={onUserClick} />, condition: onUserClick },
    { title: "Duplicate", component: (props: any) => <IconCopy {...props} onClick={onDuplicateClick} />, condition: onDuplicateClick },
    { title: "Export", component: (props: any) => <IconFileExport {...props} onClick={onExportClick} />, condition: onExportClick },
    { title: "Sync", component: (props: any) => <IconRefresh {...props} onClick={handleSyncClick} />, condition: handleSyncClick },
    { title: "Move", component: (props: any) => <IconExchange {...props} onClick={handleMoveClick} />, condition: withMove },
    { title: "Remove", component: (props: any) => <IconTrash {...props} onClick={onTrashClick} />, condition: onTrashClick },
  ];

  return (
    <>
      {withMove && <MoveViewsModal open={isModalOpen} setOpen={setIsModalOpen} item={item} viewType={type} handleMoveClick={onMoveClick} />}
      <div ref={isShared ? null : ref} style={{ opacity }} data-handler-id={handlerId}>
        <Grid
          xs
          container
          bgcolor={selectedRow?.id == item?.id ? palette.primary.light : ""}
          marginInlineEnd={2}
          borderRadius={"10px"}
          padding={1}
          flexWrap='nowrap'
          className={classes.rowContainer}
          onClick={e => {
            setSelectedRow(item);
          }}
        >
          <Grid item>
            <BXIcon
              icon={item?.iconConfig?.icon || item?.icon}
              url={
                (item?.iconConfig?.visibility === "PUBLIC" && item?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                  ? item?.iconConfig?.url
                  : item?.iconConfig?.icon) || item?.icon
              }
              visibility={item?.iconConfig?.visibility}
              height={30}
              width={30}
              fallBack={item?.fallBack || "Folder"}
            />
          </Grid>
          <Grid item xs paddingInlineStart={2}>
            <Grid item>
              <Typography
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
                flex={1}
                fontSize={"14px"}
                marginBottom={0.5}
              >
                {item?.title}
              </Typography>
            </Grid>
            {item?.subTitle && (
              <Grid item>
                <Typography
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                  fontSize={"12px"}
                >
                  {item?.subTitle}
                </Typography>
              </Grid>
            )}
          </Grid>
          {withEye && <Grid item>{isHidden ? <IconEyeOff color='gray' /> : <IconEye />}</Grid>}
          {!isShared && (
            <Grid onClick={handleClick} item className={classes.dotsIconStyle} marginInlineStart={1}>
              <svg width='30' height='30' viewBox='-1 -1 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M15 9.4375V9.5625' stroke={palette.text.primary} stroke-width='3' stroke-linecap='round' stroke-linejoin='round' />
                <path
                  d='M15 15.5625V15.6875'
                  stroke={palette.text.primary}
                  stroke-width='3'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M15 21.6875V21.8125'
                  stroke={palette.text.primary}
                  stroke-width='3'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  d='M10 1H20V-1H10V1ZM29 10V20H31V10H29ZM20 29H10V31H20V29ZM1 20V10H-1V20H1ZM10 29C5.02944 29 1 24.9706 1 20H-1C-1 26.0751 3.92487 31 10 31V29ZM29 20C29 24.9706 24.9706 29 20 29V31C26.0751 31 31 26.0751 31 20H29ZM20 1C24.9706 1 29 5.02944 29 10H31C31 3.92487 26.0751 -1 20 -1V1ZM10 -1C3.92487 -1 -1 3.92487 -1 10H1C1 5.02944 5.02944 1 10 1V-1Z'
                  fill={palette.primary.main}
                />
              </svg>
            </Grid>
          )}
        </Grid>
      </div>
      <Popover open={open} onClose={handleClose} x={x} y={y}>
        <Box onClick={handleClose} border={`1px solid ${palette.primary.main}`} borderRadius={"10px"}>
          <Grid container spacing={1.5} padding={1}>
            {iconActions.map(({ title, component: Icon, condition }, index) =>
              condition !== undefined
                ? condition && (
                    <Tooltip title={title}>
                      <Grid key={index} item>
                        <Icon className={classes.iconStyle} />
                      </Grid>
                    </Tooltip>
                  )
                : null
            )}
          </Grid>
        </Box>
      </Popover>
    </>
  );
};
export default BuilderRow;
