import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import _ from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useAppState } from "src/features/appState/hooks";
import { ElementBaseProps } from "src/types/UIElement";
import { replaceDataPlaceholdersRecursively } from "../DataTable/ActionButton";
import { UserInput } from "../UserInput";
import { RenderItems } from "./RenderItems";

type FormBuilderProps = {
  actions?: any[];
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
    disableDirtyOption?: boolean;
  };
  views: any;
  __data?: any;
  pageId?: string;
  path?: string;
} & ElementBaseProps;

const FormBuilder: FC<FormBuilderProps> = props => {
  const {
    id = "",
    height,
    dataSource,
    info,
    views,
    __data = {},
    closeModal,
    parentIds = [],
    toolTip,
    pageId,
    disableInternalAction,
    onActionClick,
    data,
    path,
    handleSelectRow,
    pageOutlet,
    index,
    disablePageDirty,
    stepperGroups,
    disabled,
  } = props;
  const [formBuilderState, setFormBuilderState] = useState(dataSource?.formBuilder);
  const [currentActionEndpoint, setCurrentActionEndpoint] = useState("");
  const [dataEntry, setDataEntry] = useState("");
  const localViews = views?.filter((view: any) => view.id != id);
  const userInputView = views?.find((view: any) => view?.id === dataSource?.userInputFormBuilderId);
  const selectedDataEntry = dataSource?.sourceType == "USER INPUT" ? dataEntry : dataSource?.dataEntry;
  const { getValue: _getValues, setValue } = useAppState();

  const { viewsState, currentApp, multiLingual } = useBXContext();

  const queryKeys = [`${pageId}-${info?.viewName}`];

  const { data: apiData, refetch } = useValue({
    queryKeys,
    __data,
    viewId: id,
    limit: dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    dataEntry: selectedDataEntry,
    pageId,
    endpoint: currentActionEndpoint,
    options: {
      enabled: dataSource?.sourceType == "API" || dataSource?.sourceType == "SIMPLE",
    },
    dataSource: dataSource,
    viewName: info?.viewName,
  });

  const item = dataSource?.sourceType != "NONE" ? (selectedDataEntry ? _.get(apiData, selectedDataEntry as any) : apiData) : data;

  let code = {};
  try {
    code = JSON.parse(dataSource?.template);
  } catch (e) {}

  const findCustomComponentIds = (obj: any) => {
    const isCustomComponent = !!obj?.config?.customComponent;
    const hasChildren = obj?.children?.length > 0;

    if (hasChildren) {
      const childrenIds = obj?.children.flatMap(findCustomComponentIds).filter(Boolean);
      return isCustomComponent ? [obj?.config?.customComponentId, ...childrenIds] : childrenIds;
    }

    return isCustomComponent ? [obj?.config?.customComponentId] : [];
  };

  const customComponents = dataSource?.formBuilder?.map(findCustomComponentIds);
  const customComponentIds = _.flattenDeep(customComponents);

  const { data: componentData, isLoading } = useQuery(
    [["components", id]],
    async ({ pageParam }) => {
      const { data } = await axios.get(`${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${customComponentIds?.join(",")}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      const ids: any = {};
      data?.items?.forEach((item: any) => {
        ids[item?.id] = item;
      });
      return ids;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!customComponentIds?.length,
    }
  );
  const referenceStepperGroups = useMemo(() => {
    return replaceDataPlaceholdersRecursively({
      obj: _.cloneDeep(stepperGroups),
      item,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: null,
    });
  }, [stepperGroups, item, viewsState, pageId, __data, currentApp?.env]);

  useEffect(() => {
    const key = `${pageId}.${info?.viewName}._BX_stepperGroups`;

    setValue(key, referenceStepperGroups, true);
  }, [item]);

  const getValues = (path?: string) => {
    return _getValues(`${pageId}.${info?.viewName}${path ? `.${path}.` : ""}.state`);
  };

  if (isLoading) return <CircularProgress />;

  return (
    <PermissibleRender isAllowed path={path} action={["VIEW"]}>
      {({ permitted }) => {
        return (
          permitted && (
            <>
              {dataSource?.sourceType == "USER INPUT" && (
                <Box width='100%'>
                  <UserInput
                    queryKeys={queryKeys}
                    pageId={pageId}
                    setCurrentActionEndpoint={setCurrentActionEndpoint}
                    userInputView={userInputView}
                    __data={__data}
                    refetch={refetch}
                    views={views}
                    setDataEntry={setDataEntry}
                    viewName={info?.viewName}
                  />
                </Box>
              )}
              <Box
                sx={{
                  position: "relative",
                  height: dataSource?.formBuilderConfig?.isCanvasFullHeight
                    ? "100%"
                    : dataSource?.formBuilderConfig?.isDynamicHeight
                    ? "auto"
                    : height,
                  boxSizing: "border-box",
                }}
              >
                <RenderItems
                  data={formBuilderState}
                  setFormBuilderState={setFormBuilderState}
                  localViews={localViews}
                  views={views}
                  pageId={pageId}
                  index={index}
                  getValues={getValues}
                  __data={__data}
                  parentIds={parentIds}
                  closeModal={closeModal}
                  toolTip={toolTip}
                  info={info}
                  item={item}
                  currentApp={currentApp}
                  multiLingual={multiLingual}
                  viewsState={viewsState}
                  queryKeys={queryKeys}
                  dataSource={dataSource}
                  disableInternalAction={disableInternalAction}
                  onActionClick={onActionClick}
                  componentData={componentData}
                  path={path}
                  handleSelectRow={handleSelectRow}
                  pageOutlet={pageOutlet}
                  disabled={disabled}
                  isDisabledDirtyField={info?.disableDirtyOption}
                  disablePageDirty={disablePageDirty}
                  referenceStepperGroups={referenceStepperGroups}
                />
              </Box>
            </>
          )
        );
      }}
    </PermissibleRender>
  );
};

export default FormBuilder;
