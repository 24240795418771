import { FC, useCallback } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
type Action = "*" | "VIEW" | "WRITE";
type PermissibleRenderProps = {
  path?: string;
  action: Action[];
  isAllowed?: boolean;
  children?: (params: { permitted: boolean }) => React.ReactNode;
};
export const PermissibleRender: FC<PermissibleRenderProps> = ({ path, action, children, isAllowed = false }) => {
  const { checkPermissions, unprotectedPages } = useBXContext();

  const checkUnprotected = useCallback(() => {
    const pagePath = path?.split(".").slice(0, 3).join(".");
    return unprotectedPages.current.has(pagePath as string);
  }, []);

  if (!path || checkUnprotected()) return <>{children?.({ permitted: true })}</>;
  const permission = checkPermissions(
    action.map(ac => ({ path, action: ac })),
    isAllowed
  );

  return (
    <>
      {children?.({
        permitted: Boolean(permission),
      })}
    </>
  );
};
