import { ClockPickerView } from "@mui/lab";
import { TimePicker } from "@mui/x-date-pickers";
import { FC, useMemo } from "react";

export function constructTimeView(enabledList) {
  const views: ClockPickerView[] = ["hours", "minutes", "seconds"];
  const result: string[] = [];
  enabledList.forEach((item, index) => {
    if (item) {
      result.push(views[index]);
    }
  });

  return result as ClockPickerView[];
}

export const CustomTimePicker: FC<any> = componentProps => {
  const { config = undefined, $config = undefined, value, ...props } = componentProps;

  const componentConfig = $config ? $config : config;
  const {
    hoursEnabled = true,
    minutesEnabled = true,
    secondsEnabled = false,
    hoursStep = 1,
    minutesStep = 5,
    secondsStep = 5,
    ampm = true,
  } = componentConfig;

  const defaultValue = useMemo(() => {
    if (!props?.defaultValue) {
      return null;
    }

    const [hours = 0, minutes = 0, seconds = 0] = props.defaultValue.split(":").map(Number);

    const defaultValue = new Date();
    defaultValue.setHours(hours);
    defaultValue.setMinutes(minutes);
    defaultValue.setSeconds(seconds);
    defaultValue.setMilliseconds(0);

    return defaultValue;
  }, [props?.defaultValue]);

  return (
    <TimePicker
      {...props}
      defaultValue={defaultValue}
      onChange={(value: string) => {
        const formattedTime = new Date(value).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: ampm,
        });

        props?.onChange(formattedTime);
      }}
      views={constructTimeView([hoursEnabled, minutesEnabled, secondsEnabled])}
      timeSteps={{
        hours: hoursStep,
        minutes: minutesStep,
        seconds: secondsStep,
      }}
      ampm={!ampm}
    />
  );
};
