import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import BXModal from "src/components/BXUI/Modal";
import useAuth from "src/hooks/useAuth";

export default function CreateTemplateModal({
  open,
  isLoading,
  defaultName,
  defaultType,
  templateID,
  defaultVisibility,
  isUpdate,
  isExport,
  onClose,
  openViewBuilder,
  onSubmit,
}: {
  defaultName?: string;
  defaultType?: string;
  defaultVisibility?: string;
  open?: boolean;
  templateID?: string;
  isLoading?: boolean;
  isUpdate?: boolean;
  isExport?: boolean;
  openViewBuilder?: () => void;
  onClose?: () => void;
  onSubmit?: (any: any, template: any) => void;
}) {
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const handleChange = (event): any => {
    const value = event.target.value;
    setName(value);
    if (value.trim() === "") {
      setError(true);
      setHelperText("name name is required");
    } else {
      setError(false);
      setHelperText("");
    }
  };
  return (
    <>
      <BXModal open={open} onClose={onClose} title={(isUpdate ? "Update" : isExport ? "Export" : "Add New") + " Template"}>
        <Grid
          container
          spacing={3}
          pt={3}
          component={"form"}
          onSubmit={async (e: any) => {
            e.preventDefault();
            if (name.trim() === "") {
              setError(true);
              setHelperText("name name is required");
            } else {
              let formData: any = Object.fromEntries(new FormData(e.target).entries());
              if (isUpdate || isExport) formData = { name: formData.name };
              onSubmit?.(formData, templateID);
              onClose?.();
            }
          }}
        >
          <Grid item xs={4}>
            <TextField
              type='text'
              name='name'
              fullWidth
              label='Template name'
              defaultValue={defaultName}
              onChange={handleChange}
              error={error}
              helperText={helperText}
            />
          </Grid>
          {user?.organization?.id === "default" && (
            <Grid item xs={4}>
              <TextField
                select
                disabled={isUpdate || isExport}
                name='visibility'
                fullWidth
                defaultValue={defaultVisibility || "PRIVATE"}
                label='Template visibility'
              >
                {["PUBLIC", "PRIVATE"].map(variant => (
                  <MenuItem key={variant} value={variant}>
                    {variant}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={4}>
            <TextField
              select
              disabled={isUpdate || isExport}
              name='type'
              fullWidth
              defaultValue={defaultType || "VIEW"}
              label='Template type'
            >
              {["VIEW", "APPLICATION", "PAGE"].map(variant => (
                <MenuItem key={variant} value={variant}>
                  {variant}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Box gap={2} display='flex' justifyContent='center' alignItems='center'>
              <LoadingButton loading={isLoading} variant='contained' type='submit'>
                {isUpdate ? "Update Template" : isExport ? "Export Template" : "Create Template"}
              </LoadingButton>
              {isUpdate && !isExport && (
                <Button variant='outlined' onClick={openViewBuilder}>
                  {"View Builder"}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </BXModal>
    </>
  );
}
