import { Box, Button, Grid, IconButton, Switch, Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";

import _, { debounce } from "lodash";
import { useEffect } from "react";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXIcon } from "src/components/BXUI/Icon";
import DraggableRow from "../AppBuilder/forms/DraggableRow";
import { updateStepperGroupsChildren } from "./utils";

const EMPTY_VIEW = { pageReference: "" };

interface ViewProps {
  onSwitchChange: (e: any) => void;
  isSelected: boolean;
  onDelete: () => void;
  disabled: boolean;
  control: any;
  name: string;
}

const Page = ({ control, name, onSwitchChange, isSelected, onDelete, disabled }: ViewProps) => (
  <Grid container item spacing={1} alignItems='center' justifyContent='flex-start' minWidth='326px'>
    <Grid item xs={1}>
      <BXIcon icon='Menu' height={20} width={20} style={{ cursor: "grab" }} />
    </Grid>
    <Grid item xs={7}>
      <BXInput
        name={`${name}.pageReference`}
        control={control}
        label='Page reference'
        fullWidth
        disabled={disabled}
        size='small'
        InputLabelProps={{ style: { fontSize: "12px" } }}
        InputProps={{ style: { fontSize: "12px" } }}
      />
    </Grid>
    <Grid item xs={2}>
      <Switch checked={isSelected} onChange={e => onSwitchChange(e.target.checked)} size='small' />
    </Grid>
    <Grid item xs={2}>
      <IconButton onClick={onDelete}>
        <BXIcon icon={"Trash"} width={16} height={16} color={"red"} />
      </IconButton>
    </Grid>
  </Grid>
);

interface ViewsType {
  [groupName: string]: {
    pages: {
      pageReference: string;
      componentId?: string;
      isChildren?: boolean;
    }[];
  };
}

const Group = ({ control, groupName, setValue, watch, deleteComponent }) => {
  const {
    fields: pages,
    append: addView,
    remove,
    move,
  } = useFieldArray<ViewsType>({
    control,
    name: `${groupName}.pages`,
  });

  const setPreviewIndex = index => {
    setValue(`${groupName}.previewIndex`, index);
  };
  const onAddView = () => {
    addView(EMPTY_VIEW);
  };

  const previewIndex = watch(`${groupName}.previewIndex`) || 0;

  return (
    <Grid container item spacing={1} gap={2} minWidth='326px'>
      <Grid container item xs={12} alignItems='center' justifyContent='space-between'>
        <Grid>
          <Typography fontSize='12px'>Enable Looping</Typography>
        </Grid>
        <Grid>
          <BXSwitch name={`${groupName}.enableLooping`} control={control} label={""} size='small' />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <BXInput
          name={`${groupName}.selectedPage`}
          control={control}
          label='index or reference'
          fullWidth
          size='small'
          InputLabelProps={{ style: { fontSize: "12px" } }}
          InputProps={{ style: { fontSize: "12px" } }}
        />
      </Grid>

      {pages.map((view, index) => (
        <DraggableRow component={Box} key={view.id} id={view.id} index={index} name='stepper-pages' moveElement={move}>
          <Page
            control={control}
            name={`${groupName}.pages[${index}]`}
            isSelected={previewIndex === index}
            disabled={!!view?.isChildren}
            onSwitchChange={isSelected => {
              if (isSelected) {
                setPreviewIndex(index);
              }
            }}
            onDelete={() => {
              if (previewIndex === index) {
                setPreviewIndex(index === 0 ? 0 : index - 1);
              }
              if (view?.isChildren) {
                deleteComponent(view?.componentId);
              }
              remove(index);
            }}
          />
        </DraggableRow>
      ))}
      <Grid item alignItems='center'>
        <Button onClick={() => onAddView()} variant='contained' style={{ height: "30px" }}>
          Add Page
        </Button>
      </Grid>
    </Grid>
  );
};

const StepperConfiguration = ({ setView, groupKey, stepperGroup, deleteComponent, stepperId }) => {
  const { control, getValues, setValue, watch } = useForm({
    defaultValues: {
      stepperGroup: stepperGroup ?? { groupName: groupKey, previewIndex: 0, selectedPage: 0, pages: [] },
    },
  });

  useEffect(() => {
    const updateView = debounce(() => {
      setView(prev => {
        const formValues = getValues("stepperGroup");
        _.set(formValues, "groupName", groupKey);
        return {
          ...prev,
          stepperGroups: {
            ...updateStepperGroupsChildren(prev?.formBuilder, prev?.stepperGroups),
            [stepperId]: formValues,
          },
        };
      });
    }, 150);

    updateView();

    const subscription = watch(updateView);

    return () => {
      subscription?.unsubscribe();
    };
  }, [watch, getValues, setView, groupKey]);

  return (
    <Grid container spacing={2} p={2} gap={2} overflow='auto'>
      <Group control={control} groupName={`stepperGroup`} setValue={setValue} watch={watch} deleteComponent={deleteComponent} />
    </Grid>
  );
};
export default StepperConfiguration;
