import { Grid, Switch, TextField, Typography } from "@mui/material";
import React from "react";

const CustomErrorMessage = ({
  textFieldProps,
  handleToggleProp,
  validationType,
  validationTitle,
  validationTypeString,
}) => {
  return (
    <React.Fragment>
      <Grid container display='flex' item xs={12} justifyContent='space-between' alignItems='center'>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "12px" }}>{validationTitle}</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"end"}>
          <Switch
            size='small'
            checked={!!validationType}
            onChange={handleToggleProp(validationTypeString)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
      </Grid>
      {validationType && (
        <Grid container xs={12} mt={1} mb={1} alignItems='center'>
          <TextField
            {...textFieldProps}
            size='small'
            type='text'
            InputLabelProps={{ style: { fontSize: "12px" } }}
            InputProps={{ style: { fontSize: "12px" } }}
            sx={{ width: "100%" }}
            label={`Custom Error Message - ${validationTitle}`}
          />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default CustomErrorMessage;
