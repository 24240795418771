import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import MonacoEditor from "@monaco-editor/react";
import { InputAdornment, MenuItem } from "@mui/material";
import { IconTrashX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { BXInput, BXSwitch } from "../FormControls";
import { formatJSON } from "./ActionButton";

export const payloadModalTabs = ["Key/Value", "JSON"];

const CreatePayload: FC<any> = props => {
  const {
    payload: _payload = {},
    onSave,
    onChange,
    onClose,
    isLoading,
    disabled,
    label,
    defaultTab,
    error,
    path = "data",
    pathURL,
    control: _control,
    setValue: _setValue,
    getValues: _getValues,
    watch: _watch,
    viewType,
    OASElement,
  } = props || {};

  const hookFormValues = useForm({
    defaultValues: {
      data: _payload,
      isGraphQL: _payload?.isGraphQL,
      uri: _payload?.uri,
      method: _payload?.method,
      graphQLMethod: _payload?.graphQLMethod,
    },
    reValidateMode: "onChange",
  });

  const control = _control || hookFormValues.control;
  const watch = _watch || hookFormValues.watch;
  const getValues = _getValues || hookFormValues.getValues;
  const setValue = _setValue || hookFormValues.setValue;

  const [payload, setPayload] = useState(_payload);

  useEffect(() => {
    setPayload(_payload);
  }, [_payload]);

  const [selectedTab, setSelectedTab] = useState(defaultTab || payloadModalTabs[0]);

  let canShowForm = true;
  let errorFormMessage: any = "";
  let jsonError: any = "";

  useEffect(() => {
    if (_control) {
      setValue(`${path}.uri`, getValues(`${pathURL}`));

      if (!getValues(`${path}.method`)) {
        setValue(`${path}.method`, "GET");
      }

      if (!getValues(`${path}.graphQLMethod`)) {
        setValue(`${path}.graphQLMethod`, "QUERY");
      }
    }
  }, []);

  useEffect(() => {
    if (!jsonError) {
      onChange?.(payload || {});
    }
  }, [payload]);

  try {
    _.forOwn(JSON.parse(payload?.body || "{}"), value => {
      if (_.isObject(value)) {
        canShowForm = false;
        errorFormMessage = "The Json has nested object";
        return false;
      } else {
        canShowForm = true;
      }
    });
  } catch (e: any) {
    canShowForm = false;
    jsonError = e?.message;
    errorFormMessage = e?.message;
  }

  return (
    <>
      <Box mb={2}>
        <Box mb={2}>
          <Typography mb={2}>Headers:</Typography>
          <>
            {_.map(payload?.headers, (value, key) => {
              return (
                <Grid container spacing={2} mb={2}>
                  <Grid item xs>
                    <TextField
                      size='small'
                      fullWidth
                      label={"Key"}
                      value={key}
                      onChange={e => {
                        const oldJson: any = payload?.headers;
                        let newJson: any = {};
                        if (Number(e.target.value)) return;

                        _.forOwn(oldJson, (oldValue, oldKey) => {
                          if (oldKey == key) {
                            newJson[e.target.value] = oldValue;
                          } else {
                            newJson[oldKey] = oldValue;
                          }
                        });

                        setPayload((prev: any) => ({
                          ...prev,
                          headers: newJson,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      size='small'
                      fullWidth
                      label={"Value"}
                      value={value}
                      onChange={e => {
                        const oldJson: any = payload?.headers;

                        let newJson: any = {};
                        _.forOwn(oldJson, (oldValue, oldKey) => {
                          if (key == oldKey) {
                            newJson[oldKey] = e.target.value;
                          } else {
                            newJson[oldKey] = oldValue;
                          }
                        });
                        setPayload((prev: any) => ({
                          ...prev,
                          headers: newJson,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => {
                        const headers = { ...payload?.headers };
                        delete headers[key];
                        setPayload((prev: any) => ({
                          ...prev,
                          headers,
                        }));
                      }}
                    >
                      <IconTrashX color='red' />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
            <Button
              variant='outlined'
              onClick={() => {
                const headers = { ...payload?.headers };
                headers[""] = "";

                setPayload((prev: any) => ({
                  ...prev,
                  headers,
                }));
              }}
            >
              Add row
            </Button>
          </>
          <Box marginY={1}>
            <Divider />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} xs={12}>
        <BXSwitch sx={{ ml: 2 }} label='GraphQL' control={control} error={error?.isGraphQL} name={`${path}.isGraphQL`} />
        {!watch(`${path}.isGraphQL`) && (
          <Grid item xs={3}>
            <BXInput variant='outlined' select label={"Method"} control={control} name={`${path}.method`} error={error?.method}>
              {["GET", "POST", "PUT", "PATCH", "DELETE"].map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </BXInput>
          </Grid>
        )}

        {watch(`${path}.isGraphQL`) && (
          <Grid item xs={3}>
            <BXInput
              variant='outlined'
              select
              label={"Method"}
              control={control}
              name={`${path}.graphQLMethod`}
              error={error?.graphQLMethod}
            >
              {["QUERY", "MUTATION"].map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </BXInput>
          </Grid>
        )}

        <Grid item xs={9} marginBottom={2}>
          <BXInput
            name={`${path}.uri`}
            control={control}
            fullWidth
            error={error?.uri}
            label={"Endpoint"}
            onChange={event => {
              setValue(`${pathURL}`, event.currentTarget.value);
            }}
            InputProps={
              process.env.REACT_APP_WITH_OAS == "true"
                ? {
                    endAdornment: <InputAdornment position='end'>{OASElement}</InputAdornment>,
                  }
                : undefined
            }
          />
        </Grid>
        {watch(`${path}.isGraphQL`) && (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6} fontWeight={"bold"}>
              Query
            </Grid>
            <Grid item xs={6} fontWeight={"bold"}>
              Variables
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name={`${path}.graphqlQuery`}
                render={({ field: { onChange, value } }) => (
                  <MonacoEditor
                    width='100%'
                    height='250px'
                    language='graphql'
                    theme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) : value}
                    options={{ colorDecorators: true }}
                    onChange={newValue => onChange(newValue)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name={`${path}.graphqlVariables`}
                render={({ field: { onChange, value } }) => (
                  <MonacoEditor
                    width='100%'
                    height='250px'
                    language='json'
                    theme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) : value}
                    options={{ colorDecorators: true }}
                    onChange={newValue => onChange(newValue)}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {watch(`${path}.method`) !== "GET" && !watch(`${path}.isGraphQL`) && (
        <Box marginBottom={2}>
          <Box marginBottom={2}>
            {payloadModalTabs.map(type => (
              <ToggleButtonGroup color='primary' value={selectedTab} exclusive>
                <ToggleButton
                  style={{ marginInlineEnd: 12 }}
                  value={selectedTab}
                  onClick={() => {
                    setSelectedTab(type);
                  }}
                  selected={selectedTab === type}
                  key={type}
                >
                  {type}
                </ToggleButton>
              </ToggleButtonGroup>
            ))}
          </Box>
          {!watch(`${path}.isGraphQL`) && (
            <>
              <Box mb={2}>
                <Typography>Body:</Typography>
              </Box>
              {selectedTab == "JSON" ? (
                <MonacoEditor
                  width='100%'
                  height='400px'
                  language='json'
                  theme='vs-dark'
                  value={payload?.body}
                  options={{ colorDecorators: true }}
                  onChange={newValue =>
                    setPayload((old: any) => ({
                      ...old,
                      body: newValue,
                    }))
                  }
                />
              ) : (
                <>
                  {canShowForm ? (
                    <>
                      {_.map(JSON.parse(payload?.body || "{}"), (value, key) => {
                        return (
                          <Grid container spacing={2} mb={2}>
                            <Grid item xs>
                              <TextField
                                size='small'
                                fullWidth
                                label={"Key"}
                                value={key}
                                onChange={e => {
                                  const oldJson: any = JSON.parse(payload?.body || "{}");
                                  // const isKeyExist = _.find(oldJson, (_value, _key) => _key == e.target.value);
                                  if (Number(e.target.value)) return;

                                  let newJson: any = {};
                                  _.forOwn(oldJson, (oldValue, oldKey) => {
                                    if (key == oldKey) {
                                      newJson[e.target.value] = oldValue;
                                    } else {
                                      newJson[oldKey] = oldValue;
                                    }
                                  });
                                  setPayload((prev: any) => ({
                                    ...prev,
                                    body: formatJSON(JSON.stringify(newJson)),
                                  }));
                                }}
                              />
                            </Grid>
                            <Grid item xs>
                              <TextField
                                size='small'
                                fullWidth
                                label={"Value"}
                                value={value}
                                onChange={e => {
                                  const oldJson: any = JSON.parse(payload?.body || "{}");

                                  let newJson: any = {};
                                  _.forOwn(oldJson, (oldValue, oldKey) => {
                                    if (key == oldKey) {
                                      newJson[oldKey] = e.target.value;
                                    } else {
                                      newJson[oldKey] = oldValue;
                                    }
                                  });
                                  setPayload((prev: any) => ({
                                    ...prev,
                                    body: formatJSON(JSON.stringify(newJson)),
                                  }));
                                }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                onClick={() => {
                                  const Json = JSON.parse(payload?.body || "{}");
                                  delete Json[key];
                                  setPayload((prev: any) => ({
                                    ...prev,
                                    body: formatJSON(JSON.stringify(Json)),
                                  }));
                                }}
                              >
                                <IconTrashX color='red' />
                              </IconButton>
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Button
                        variant='outlined'
                        onClick={() => {
                          const Json = JSON.parse(payload?.body || "{}");
                          Json[""] = "";
                          setPayload((prev: any) => ({
                            ...prev,
                            body: formatJSON(JSON.stringify(Json)),
                          }));
                        }}
                      >
                        Add row
                      </Button>
                    </>
                  ) : (
                    <Typography color='red'>{errorFormMessage}</Typography>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      )}
      {/* Modal Actions */}
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            variant={"contained"}
            aria-label={"save"}
            disabled={disabled}
            onClick={() => {
              if (jsonError) {
                enqueueSnackbarRef?.(jsonError, {
                  variant: "error",
                });
              } else {
                onSave(payload ? (_control ? payload : { ...watch("data"), body: payload?.body, headers: payload?.headers }) : {});
              }
            }}
          >
            {label}
          </LoadingButton>
        </DialogActions>
      </Grid>
    </>
  );
};

export default CreatePayload;
