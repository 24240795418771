import { Box, Typography } from "@mui/material";
import Sidebar from "src/layout/MainLayout/Sidebar";

const BXSideBar = ({ $pageOutlet, $config, __config, metaData, $drawerOpen, $drawerToggle, ...rest }) => {
  if (__config) {
    return <Sidebar drawerOpen={$drawerOpen} drawerToggle={$drawerToggle} builderMode={true} />;
  }

  return (
    <Box {...rest}>
      <Box
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"} fontSize={"24px"}>
          SideBar
        </Typography>
      </Box>
    </Box>
  );
};

export { BXSideBar };
