import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IconTrashX } from "@tabler/icons-react";
import { useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";
import { interactionableComponents } from "./ComponentEditor";

const requiredMessage = "Field is required";
const interactionTypes = [
  {
    value: "OnClick",
    label: "On Click",
    forAll: true,
  },
  {
    value: "OnChange",
    label: "On Change",
    forAll: false,
  },
  {
    value: "OnKeyPress",
    label: "On Key Press",
    forAll: false,
  },
];

const keysList = ["Enter", "Tab", "Space", "Ctrl+A", "Ctrl+C", "Ctrl+V", "Shift", "Alt", "Delete", "Escape", "Backspace"];

export const InteractionConfig = ({ item, onSave }) => {
  const schema = yup.object().shape({
    interactionConfig: yup
      .array()
      .of(
        yup.object().shape({
          type: yup.string(),
          triggerSelf: yup.boolean(),
          actionableKey: yup.string().when("triggerSelf", {
            is: false,
            then: yup.string().required(requiredMessage),
            otherwise: yup.string(),
          }),
          key: yup.string(),
        })
      )
      .required(requiredMessage),
  });

  const { handleSubmit, formState, control, watch } = useForm<FieldValues>({
    defaultValues: {
      interactionConfig: item?.interactionConfig,
    },
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray<FieldValues>({
    control,
    name: "interactionConfig",
  });

  const errors = formState?.errors as any;
  const isDirty = formState?.isDirty;
  useCallbackPrompt(isDirty);

  useEffect(() => {
    if (!item?.interactionConfig) {
      append({
        type: "OnClick",
        triggerSelf: true,
        actionableKey: "",
        key: "Enter",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredInteractionTypes = interactionTypes.filter(
    interaction => interaction.forAll || interactionableComponents.includes(item?.type)
  );

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Interaction Type</TableCell>
              <TableCell>Key Pressed</TableCell>
              <TableCell>Trigger Self</TableCell>
              <TableCell>Actionable Key</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>
                  <BXInput name={`interactionConfig.${index}.type`} control={control} select label='Interaction Type'>
                    {filteredInteractionTypes.map(interaction => (
                      <MenuItem key={interaction.value} value={interaction.value}>
                        {interaction.label}
                      </MenuItem>
                    ))}
                  </BXInput>
                </TableCell>

                <TableCell>
                  {watch(`interactionConfig.${index}.type`) === "OnKeyPress" && (
                    <BXInput name={`interactionConfig.${index}.key`} control={control} select label='Key Pressed'>
                      {keysList.map(keystroke => (
                        <MenuItem key={keystroke} value={keystroke}>
                          {keystroke}
                        </MenuItem>
                      ))}
                    </BXInput>
                  )}
                </TableCell>

                <TableCell>
                  <BXSwitch name={`interactionConfig.${index}.triggerSelf`} control={control} />
                </TableCell>

                <TableCell>
                  <BXInput
                    name={`interactionConfig.${index}.actionableKey`}
                    control={control}
                    label='Actionable Key Reference'
                    error={errors?.interactionConfig?.[index]?.actionableKey}
                    disabled={watch(`interactionConfig.${index}.triggerSelf`)}
                  />
                </TableCell>

                <TableCell>
                  <IconButton onClick={() => remove(index)}>
                    <IconTrashX color='red' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid item xs={12} style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
        <Button
          variant='contained'
          color='primary'
          onClick={() =>
            append({
              type: "OnClick",
              triggerSelf: true,
              actionableKey: "",
              key: "Enter",
            })
          }
        >
          Add Event
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button variant='contained' onClick={handleSubmit(onSave)}>
            Save
          </Button>
        </Box>
      </Grid>
    </>
  );
};
