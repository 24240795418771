import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";

type UploadInfoProps = {
  viewType?: string;
  control: any;
  errors: any;
  setValue: any;
  getValues: any;
  watch: any;
};

const uploadTypes = ["Image", "Audio", "Video"];

const UploadInfo: FC<UploadInfoProps> = ({ viewType, control, errors, setValue, getValues, watch }) => {
  const { palette } = useTheme();

  return (
    <Card>
      <CardHeader title={`Upload config`} style={{ paddingBottom: 0 }} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={"uploadConfig.uploadAllowedTypes"}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <InputLabel
                    error={!!errors?.uploadConfig?.uploadAllowedTypes}
                    style={{ backgroundColor: palette.background.paper }}
                    id='demo-multiple-checkbox-label'
                  >
                    Allowed Types
                  </InputLabel>
                  <Select
                    labelId='demo-multiple-checkbox-label'
                    id='demo-multiple-checkbox'
                    multiple
                    value={value}
                    error={!!errors?.uploadConfig?.uploadAllowedTypes}
                    onChange={(event: any) => {
                      const {
                        target: { value },
                      } = event;
                      onChange(typeof value === "string" ? value?.split(",") : value);
                    }}
                    input={<OutlinedInput label='Tag' />}
                    renderValue={selected => selected.join(", ")}
                  >
                    {uploadTypes.map(name => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={value?.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.uploadConfig?.uploadAllowedTypes && (
                    <FormHelperText error>{errors?.uploadConfig?.uploadAllowedTypes.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <BXInput
                name={`uploadConfig.isInput`}
                error={errors?.uploadConfig?.isInput}
                control={control}
                select
                label={"Is Input"}
                id='demo-simple-select'
              >
                {["Yes", "No"]?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </BXInput>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name={"uploadConfig.uploadAllowedTypes"}
              render={({ field: { onChange, value } }) => (
                <FormControl>
                  <RadioGroup
                    defaultValue={getValues("uploadConfig.uploadUrlType")}
                    onChange={(e, newValue) => {
                      setValue(`uploadConfig.uploadUrlType`, newValue);
                    }}
                    sx={{
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel value={"aws"} control={<Radio />} label='AWS' />
                    <FormControlLabel value={"custom"} control={<Radio />} label='Custom' />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          {watch("uploadConfig.uploadUrlType") == "aws" ? (
            <>
              <Grid item xs={6}>
                <BXInput
                  variant='outlined'
                  required
                  label={"Signed Url"}
                  control={control}
                  name={"uploadConfig.signedUrl"}
                  error={errors?.uploadConfig?.signedUrl}
                />
              </Grid>
              <Grid item xs={6}>
                <BXInput
                  variant='outlined'
                  required
                  label={"Finalized Url"}
                  control={control}
                  name={"uploadConfig.finalizedUrl"}
                  error={errors?.uploadConfig?.finalizedUrl}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={6}>
              <BXInput
                variant='outlined'
                required
                label={"Custom Url"}
                control={control}
                name={"uploadConfig.customUrl"}
                error={errors?.uploadConfig?.customUrl}
              />
            </Grid>
          )}
          <Grid item container>
            <Grid item xs={4}>
              <BXSwitch label='Multiple files' control={control} name='uploadConfig.multipleFiles' />
            </Grid>
            <Grid item xs={4}>
              <BXSwitch label='Crop image' control={control} name='uploadConfig.isCropImage' />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <BXInput
              variant='outlined'
              required
              label={"Number of files"}
              control={control}
              type='number'
              name={"uploadConfig.fileNumber"}
              onChange={e => {
                setValue("uploadConfig.fileNumber", Number(e.target.value) < 1 ? 1 : e.target.value);
              }}
              error={errors?.uploadConfig?.fileNumber}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UploadInfo;
