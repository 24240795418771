// theme constant
export const gridSpacing = 3;
export const drawerWidth = 275;
export const appDrawerWidth = 320;

export const defaultUserListLimit = 20;
export const defaultContestListLimit = 20;

export const defaultAvatarPhotoName = "preview.png";

export const API = {
  base: {
    asset: "/admin/asset",
    athlete: "/admin/athlete",
    burn: "/admin/burn",
    mint: "/admin/mint",
    quiz: "/admin/quiz",
    release: "/admin/release",
    role: "/admin/role",
    user: "/admin/user",
    recent: "zoomie/list/recent",
  },
  sub: {
    allReleases: "all",
    create: "create",
    currentRelease: "set-current",
    generate: "generate",
    reponses: "responses",
    reveal: "reveal",
    toggleEnabled: "enabled",
    toggleFeatured: "featured",
    toggleFlagged: "flagged",
    update: "update",
    openRelease: "open",
    closeRelease: "close",
  },
};

export const GenderList = [
  {
    value: "MALE",
    label: "MALE",
  },
  {
    value: "FEMALE",
    label: "FEMALE",
  },
  {
    value: "OTHER",
    label: "OTHER",
  },
  {
    value: "NOT_SPECIFIED",
    label: "NOT SPECIFIED",
  },
];

export const UserRoleList = [
  {
    value: "BX-ADMIN",
    label: "BX-Admin",
  },
  {
    value: "ADMIN",
    label: "Admin",
  },
  // {
  //     value: 'ATHLETE',
  //     label: 'Athlete'
  // },
  {
    value: "USER",
    label: "User",
  },
];

export const AthleteRoleValue = "ATHLETE";

export const AssetClassList = [
  {
    value: "ROOKIE",
    label: "ROOKIE",
  },
  {
    value: "BRONZE",
    label: "BRONZE",
  },
  {
    value: "SILVER",
    label: "SILVER",
  },
  {
    value: "GOLD",
    label: "GOLD",
  },
  {
    value: "EPIC",
    label: "EPIC",
  },
  {
    value: "LEGENDARY",
    label: "LEGENDARY",
  },
];

export const ArtStyleList = [
  {
    value: "GLASS",
    label: "GLASS",
  },
  {
    value: "GLOWING STEEL",
    label: "GLOWING STEEL",
  },
  {
    value: "GLOWING RECORD",
    label: "GLOWING RECORD",
  },
  {
    value: "ICE",
    label: "ICE",
  },
  {
    value: "FIRE",
    label: "FIRE",
  },
  {
    value: "WATER",
    label: "WATER",
  },
  {
    value: "LEGENDARY",
    label: "LEGENDARY",
  },
];

export const PackAssetClassList = [
  {
    value: "STARTER",
    label: "STARTER",
  },
  {
    value: "BOOSTER",
    label: "BOOSTER",
  },
  {
    value: "RARE",
    label: "RARE",
  },
];
export const AssetMediaType = {
  video: "VIDEO",
  image: "IMAGE",
};
export const Strings = {
  powercard: "POWERCARD",
  powerpack: "POWERPACK",
  exclusivepack: "EXCLUSIVE",
};

export const Mode = {
  edit: "edit",
  add: "add",
};
export const ApiStrings = {
  powercard: "POWERCARD",
  powerpack: "POWERPACK",
  exclusivepack: "EXCLUSIVE",
  pblbasepack: "PBLBASEPACK",
  pblwinnerpack: "PBLWINNERPACK",
  pblteampack: "PBLTEAMPACK",
};

export const ReleaseStatus = {
  pending: "PENDING",
  open: "OPEN",
  closed: "CLOSED",
};

export const AlertModes = {
  error: "error",
  success: "success",
  warning: "warning",
};

export const Messages = {
  exclPackCreateSuccess: "The exclusive pack has been created successfully",
  exclPackUpdSuccess: "The exclusive pack has been updated successfully",
  powerCardCreateSuccess: "The power card has been created successfully",
  powerCardUpdSuccess: "The power card has been updated successfully",
  powerPackCreateSuccess: "The power pack has been created successfully",
  powerPackUpdSuccess: "The power pack has been updated successfully",
  userCreateSuccess: "The user has been created successfully",
  userUpdateSuccess: "The user has been updated successfully",
  userRoleUpdateSuccess: "The user roles have been updated successfully",
  userRevealSuccess: "The user has been revealed successfully",
  releaseCreateSuccess: "The release has been created successfully",
  releaseUpdateSuccess: "The release has been updated successfully",
  releaseActiveSuccess: "The release has been activated successfully",
  quizCreateSuccess: "The quiz has been created successfully",
  quizUpdSuccess: "The quiz has been updated successfully",
  commonSuccess: "The action was performed successfully",
  zoomieUpdSuccess: "The zoomie has been updated successfully",
};

export const ViewsIcons: any = {
  "data-table": { title: "Table", icon: "Table" },
  "card-list": { title: "Grid", icon: "LayoutGrid" },
  "image-grid": { title: "Grid", icon: "LayoutGrid" },
  form: { title: "Form", icon: "Forms" },
  api: { title: "Api", icon: "Api" },
  upload: { title: "Upload", icon: "Upload" },
  "form-builder": { title: "View Builder", icon: "Tools" },
  "repeated-view": { title: "Repeated View", icon: "Grid4x4" },
};

export const viewIcon = (type: string): string => {
  return ViewsIcons[type]?.icon;
};

export const viewTitle = (type: string): string => {
  return ViewsIcons[type]?.title || type;
};

export const languagesConstant = [
  { code: "ab", name: "abkhazian" },
  { code: "aa", name: "afar" },
  { code: "af", name: "afrikaans" },
  { code: "ak", name: "akan" },
  { code: "sq", name: "albanian" },
  { code: "am", name: "amharic" },
  { code: "ar", name: "arabic" },
  { code: "an", name: "aragonese" },
  { code: "hy", name: "armenian" },
  { code: "as", name: "assamese" },
  { code: "av", name: "avaric" },
  { code: "ae", name: "avestan" },
  { code: "ay", name: "aymara" },
  { code: "az", name: "azerbaijani" },
  { code: "bm", name: "bambara" },
  { code: "ba", name: "bashkir" },
  { code: "eu", name: "basque" },
  { code: "be", name: "belarusian" },
  { code: "bn", name: "bengali" },
  { code: "bh", name: "bihari languages" },
  { code: "bi", name: "bislama" },
  { code: "bs", name: "bosnian" },
  { code: "br", name: "breton" },
  { code: "bg", name: "bulgarian" },
  { code: "my", name: "burmese" },
  { code: "ca", name: "catalan, valencian" },
  { code: "km", name: "central khmer" },
  { code: "ch", name: "chamorro" },
  { code: "ce", name: "chechen" },
  { code: "ny", name: "chichewa, chewa, nyanja" },
  { code: "zh", name: "chinese" },
  { code: "cu", name: "church slavonic, old bulgarian, old church slavonic" },
  { code: "cv", name: "chuvash" },
  { code: "kw", name: "cornish" },
  { code: "co", name: "corsican" },
  { code: "cr", name: "cree" },
  { code: "hr", name: "croatian" },
  { code: "cs", name: "czech" },
  { code: "da", name: "danish" },
  { code: "dv", name: "divehi, dhivehi, maldivian" },
  { code: "nl", name: "dutch, flemish" },
  { code: "dz", name: "dzongkha" },
  { code: "en", name: "english" },
  { code: "eo", name: "esperanto" },
  { code: "et", name: "estonian" },
  { code: "ee", name: "ewe" },
  { code: "fo", name: "faroese" },
  { code: "fj", name: "fijian" },
  { code: "fi", name: "finnish" },
  { code: "fr", name: "french" },
  { code: "ff", name: "fulah" },
  { code: "gd", name: "gaelic, scottish gaelic" },
  { code: "gl", name: "galician" },
  { code: "lg", name: "ganda" },
  { code: "ka", name: "georgian" },
  { code: "de", name: "german" },
  { code: "ki", name: "gikuyu, kikuyu" },
  { code: "el", name: "greek (modern)" },
  { code: "kl", name: "greenlandic, kalaallisut" },
  { code: "gn", name: "guarani" },
  { code: "gu", name: "gujarati" },
  { code: "ht", name: "haitian, haitian creole" },
  { code: "ha", name: "hausa" },
  { code: "he", name: "hebrew" },
  { code: "hz", name: "herero" },
  { code: "hi", name: "hindi" },
  { code: "ho", name: "hiri motu" },
  { code: "hu", name: "hungarian" },
  { code: "is", name: "icelandic" },
  { code: "io", name: "ido" },
  { code: "ig", name: "igbo" },
  { code: "id", name: "indonesian" },
  { code: "ia", name: "interlingua (international auxiliary language association)" },
  { code: "ie", name: "interlingue" },
  { code: "iu", name: "inuktitut" },
  { code: "ik", name: "inupiaq" },
  { code: "ga", name: "irish" },
  { code: "it", name: "italian" },
  { code: "ja", name: "japanese" },
  { code: "jv", name: "javanese" },
  { code: "kn", name: "kannada" },
  { code: "kr", name: "kanuri" },
  { code: "ks", name: "kashmiri" },
  { code: "kk", name: "kazakh" },
  { code: "rw", name: "kinyarwanda" },
  { code: "kv", name: "komi" },
  { code: "kg", name: "kongo" },
  { code: "ko", name: "korean" },
  { code: "kj", name: "kwanyama, kuanyama" },
  { code: "ku", name: "kurdish" },
  { code: "ky", name: "kyrgyz" },
  { code: "lo", name: "lao" },
  { code: "la", name: "latin" },
  { code: "lv", name: "latvian" },
  { code: "lb", name: "letzeburgesch, luxembourgish" },
  { code: "li", name: "limburgish, limburgan, limburger" },
  { code: "ln", name: "lingala" },
  { code: "lt", name: "lithuanian" },
  { code: "lu", name: "luba-katanga" },
  { code: "mk", name: "macedonian" },
  { code: "mg", name: "malagasy" },
  { code: "ms", name: "malay" },
  { code: "ml", name: "malayalam" },
  { code: "mt", name: "maltese" },
  { code: "gv", name: "manx" },
  { code: "mi", name: "maori" },
  { code: "mr", name: "marathi" },
  { code: "mh", name: "marshallese" },
  { code: "ro", name: "moldovan, moldavian, romanian" },
  { code: "mn", name: "mongolian" },
  { code: "na", name: "nauru" },
  { code: "nv", name: "navajo, navaho" },
  { code: "nd", name: "northern ndebele" },
  { code: "ng", name: "ndonga" },
  { code: "ne", name: "nepali" },
  { code: "se", name: "northern sami" },
  { code: "no", name: "norwegian" },
  { code: "nb", name: "norwegian bokmål" },
  { code: "nn", name: "norwegian nynorsk" },
  { code: "ii", name: "nuosu, sichuan yi" },
  { code: "oc", name: "occitan (post 1500)" },
  { code: "oj", name: "ojibwa" },
  { code: "or", name: "oriya" },
  { code: "om", name: "oromo" },
  { code: "os", name: "ossetian, ossetic" },
  { code: "pi", name: "pali" },
  { code: "pa", name: "panjabi, punjabi" },
  { code: "ps", name: "pashto, pushto" },
  { code: "fa", name: "persian" },
  { code: "pl", name: "polish" },
  { code: "pt", name: "portuguese" },
  { code: "qu", name: "quechua" },
  { code: "rm", name: "romansh" },
  { code: "rn", name: "rundi" },
  { code: "ru", name: "russian" },
  { code: "sm", name: "samoan" },
  { code: "sg", name: "sango" },
  { code: "sa", name: "sanskrit" },
  { code: "sc", name: "sardinian" },
  { code: "sr", name: "serbian" },
  { code: "sn", name: "shona" },
  { code: "sd", name: "sindhi" },
  { code: "si", name: "sinhala, sinhalese" },
  { code: "sk", name: "slovak" },
  { code: "sl", name: "slovenian" },
  { code: "so", name: "somali" },
  { code: "st", name: "sotho, southern" },
  { code: "nr", name: "south ndebele" },
  { code: "es", name: "spanish, castilian" },
  { code: "su", name: "sundanese" },
  { code: "sw", name: "swahili" },
  { code: "ss", name: "swati" },
  { code: "sv", name: "swedish" },
  { code: "tl", name: "tagalog" },
  { code: "ty", name: "tahitian" },
  { code: "tg", name: "tajik" },
  { code: "ta", name: "tamil" },
  { code: "tt", name: "tatar" },
  { code: "te", name: "telugu" },
  { code: "th", name: "thai" },
  { code: "bo", name: "tibetan" },
  { code: "ti", name: "tigrinya" },
  { code: "to", name: "tonga (tonga islands)" },
  { code: "ts", name: "tsonga" },
  { code: "tn", name: "tswana" },
  { code: "tr", name: "turkish" },
  { code: "tk", name: "turkmen" },
  { code: "tw", name: "twi" },
  { code: "ug", name: "uighur, uyghur" },
  { code: "uk", name: "ukrainian" },
  { code: "ur", name: "urdu" },
  { code: "uz", name: "uzbek" },
  { code: "ve", name: "venda" },
  { code: "vi", name: "vietnamese" },
  { code: "vo", name: "volap_k" },
  { code: "wa", name: "walloon" },
  { code: "cy", name: "welsh" },
  { code: "fy", name: "western frisian" },
  { code: "wo", name: "wolof" },
  { code: "xh", name: "xhosa" },
  { code: "yi", name: "yiddish" },
  { code: "yo", name: "yoruba" },
  { code: "za", name: "zhuang, chuang" },
  { code: "zu", name: "zulu" },
];
