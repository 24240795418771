import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { IconExchange } from "@tabler/icons-react";
import { useState } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import axiosServices from "src/utils/axios";
import { decompressData } from "src/utils/services";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item?: any;
  viewType?: string;
  handleMoveClick?: any;
};

const MoveViewsModal = (props: Props) => {
  const { item: currentRow, open, setOpen, handleMoveClick, viewType } = props || {};
  const { allowedApps, setAppDescriptor } = useBXContext();

  const [selectedApp, setSelectedApp] = useState<BXApp | undefined>();
  const [selectedCollection, setSelectedCollection] = useState<BXAppCollection | undefined>();
  const [selectedPage, setSelectedPage] = useState<BXPageType | undefined>();

  const handleOpen = (e: any) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedApp(undefined);
    setSelectedCollection(undefined);
    setSelectedPage(undefined);
  };

  const onDone = () => {
    handleMoveClick?.(handleClose, selectedApp?.id, selectedCollection?.id, selectedPage?.id, currentRow);
  };

  const isMoveButtonDisabled =
    (viewType == "view" && !selectedPage) || (viewType == "page" && !selectedCollection) || (viewType == "collection" && !selectedApp);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      closeAfterTransition
      keepMounted={false}
      BackdropProps={{
        timeout: 500,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle display={"flex"} alignItems='center'>
        <IconExchange />
        <Typography marginInlineStart={1} id='modal-modal-title' fontSize={16} fontWeight={800}>
          Move
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingTop: 20 }}>
        <Grid container spacing={2}>
          {/* Apps */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label={"Apps"}
              value={selectedApp?.id || ""}
              onChange={e => {
                const app = allowedApps.find(app => app?.id === e.target.value);
                if (app?.templateConfig == null) {
                  axiosServices.get("/application/" + app?.id).then(({ data }) => {
                    if (data?.appConfig) {
                      data.appConfig = decompressData(data?.appConfig);
                    }
                    if (data?.templateConfig) {
                      data.templateConfig = decompressData(data?.templateConfig);
                    }
                    setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
                    setSelectedApp(data);
                  });
                } else {
                  setSelectedApp(app);
                }

                setSelectedCollection(undefined);
                setSelectedPage(undefined);
              }}
            >
              {allowedApps?.map(item => {
                if (item?.templateConfig?.collections?.find(collection => collection?.id == currentRow?.id)) return null;
                return (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          {/* Collections */}
          {selectedApp && (viewType === "page" || viewType === "view") && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label={"Collections"}
                value={selectedCollection?.id || ""}
                onChange={e => {
                  setSelectedPage(undefined);
                  setSelectedCollection(selectedApp?.templateConfig?.collections?.find(collection => collection?.id === e.target.value));
                }}
              >
                {selectedApp?.templateConfig?.collections?.map(item => {
                  if (item?.pages?.find(page => page?.id == currentRow?.id)) return null;

                  return (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          )}

          {/* Views */}
          {selectedCollection && viewType == "view" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label={"Pages"}
                value={selectedPage?.id || ""}
                onChange={e => {
                  setSelectedPage(selectedCollection?.pages?.find(page => page?.id === e.target.value));
                }}
              >
                {selectedCollection?.pages?.map(item => {
                  if (item?.views?.find(view => view?.id == currentRow?.id)) return null;
                  return (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <Button onClick={onDone} disabled={isMoveButtonDisabled} variant={"contained"} color={"primary"} aria-label={"cancel"}>
            Move
          </Button>
          <Button onClick={handleClose} variant={"outlined"} color={"primary"} aria-label={"cancel"}>
            Close
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default MoveViewsModal;
