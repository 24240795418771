/* eslint-disable react-hooks/rules-of-hooks */
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import { removeDirtyFlags, resetAppState, setAppError, setAppValue, validationRegistry } from "./appStateSlice";

// Custom hook to provide state management functions
export const useAppState = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Function to get the value of a specific field
  const getValue = (name: string) => {
    const state = store.getState();
    return _.get(state.appState.values, name);
  };

  // Function to get all values from the app state
  const getValues = () => {
    const state = store.getState();
    return state.appState.values;
  };

  // Function to set the value of a specific field
  const setValue = (name: string, value: any, isDisabledDirtyField?: boolean) => {
    dispatch(setAppValue({ name, value, isDisabledDirtyField }));
  };

  const removeDirty = (name: string) => {
    dispatch(removeDirtyFlags({ name }));
  };

  // Function to watch a specific field's value dynamically or the entire state
  const watch = (name?: string) => {
    try {
      return useSelector((state: RootState) => {
        if (name) {
          // If name is provided, return the nested value
          return _.get(state.appState.values, name);
        } else {
          // If name is not provided, return the entire values object
          return state.appState.values;
        }
      });
    } catch {
      const state = store.getState();
      if (name) {
        return _.get(state.appState.values, name);
      } else {
        return state.appState.values;
      }
    }
  };

  // Function to get all errors from the app state
  const getErrors = () => {
    const state = store.getState();

    return state.appState.errors;
  };

  const runAllValidations = () => {
    const values = getValues();

    let isNotValid = false;
    Object.keys(validationRegistry).forEach(name => {
      const validate = validationRegistry[name];

      if (validate) {
        for (const ruleKey in validate) {
          if (validate.hasOwnProperty(ruleKey)) {
            const rule = validate[ruleKey];
            const result = rule(_.get(values, name));
            if (result !== true && result != undefined) {
              isNotValid = true;
              dispatch(setAppError({ name, error: result as string }));
              break;
            } else {
              dispatch(setAppError({ name, error: "" }));
            }
          }
        }
      }
    });

    return isNotValid;
  };

  // Function to handle form submission
  const handleSubmit = () => {
    if (runAllValidations()) {
      return { isError: true }; // Do nothing if there are errors
    }

    // If no errors, proceed with the callback
    const values = getValues();

    return { values };
  };

  // Function to get all dirty fields from the app state
  const getDirtyFields = (name?: string) => {
    try {
      return useSelector((state: RootState) => {
        if (name) {
          // If name is provided, return the nested value
          return _.get(state.appState.dirtyFields, name);
        } else {
          // If name is not provided, return the entire dirtyFields object
          return state.appState.dirtyFields;
        }
      });
    } catch {
      const state = store.getState();
      if (name) {
        return _.get(state.appState.dirtyFields, name);
      } else {
        return state.appState.dirtyFields;
      }
    }
  };

  const getIsDirty = (name?: string) => {
    const dirtyFields = getDirtyFields(name);

    const checkNestedDirty = (fields: any): boolean => {
      if (typeof fields === "boolean") {
        return fields; // If it's a boolean, return its value directly
      }
      for (const key in fields) {
        if (typeof fields[key] === "object") {
          if (checkNestedDirty(fields[key])) {
            return true;
          }
        } else if (fields[key] === true) {
          return true;
        }
      }
      return false;
    };

    return checkNestedDirty(dirtyFields);
  };

  // Function to reset the entire app state
  const resetState = (name?: string) => {
    dispatch(resetAppState({ name }));
  };

  return {
    getValue,
    getValues,
    setValue,
    watch,
    handleSubmit,
    runAllValidations,
    getErrors,
    getDirtyFields,
    getIsDirty,
    resetState,
    removeDirty,
  };
};
