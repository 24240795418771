import { Chip, FormControl, FormHelperText, Grid, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { FC, useLayoutEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { replaceBaseUrl, useBXContext } from "src/BXEngine/BXContext";
import { getAuthorizationHeader } from "src/utils/generalUtils";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  clearIndicator: {
    marginInlineEnd: 5,
  },
}));

const BXAutoComplete: FC<any> = props => {
  const { id, pageId, info, isRequired, control, inputValidationName, error, componentData, onLayout = () => {} } = props;
  const { name, config, disableApis = false } = componentData || {};

  const {
    endpoint,
    key,
    dataEntry,
    autoCompleteValues,
    suggestedValues: _suggestionList,
    sourceType,
    readOnly,
    freeSolo,
    multipleSelect,
    suggestionList,
    suggestionSourceType,
    suggestionEndpoint,
    suggestionDataEntry,
  } = config || {};

  const classes = useStyles();
  const { viewsState, currentApp, getAuth, envs } = useBXContext();
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const [searchText, setSearchText] = useState("");
  const [suggestedValues, setSuggestedValues] = useState(_suggestionList);

  let querykey = [id];

  if (searchText.trim()) {
    querykey.push(searchText.trim());
  }
  const { token } = getAuth(currentApp?.id!) || {};

  const { data: apiData, isFetching } = useQuery(
    querykey,
    ({ pageParam }) => {
      const url = replaceBaseUrl(endpoint, currentApp);

      return axios.get(
        replaceDataPlaceholders({
          queryString: url,
          item: { "this.value": searchText },
          viewsState,
          pageId,
          env: currentApp?.env,
        }),

        {
          params: {
            limit: 20,
            cursor: pageParam,
          },
          headers: {
            ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
          },
        }
      );
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !disableApis && searchText?.length > 2 && sourceType === "API",
    }
  );

  const { isFetching: isSuggestionFetching } = useQuery(
    [id, "suggestion-values"],
    () => {
      const url = replaceBaseUrl(suggestionEndpoint, currentApp);

      return axios.get(url, {
        headers: {
          ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
        },
      });
    },
    {
      onSuccess: suggestionData => {
        setSuggestedValues(suggestionData?.data?.[suggestionDataEntry]);
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !disableApis && suggestionSourceType === "API",
    }
  );

  const loading = isFetching;

  const options =
    sourceType === "LIST"
      ? searchText?.length < 1 && suggestedValues && !suggestionList
        ? suggestedValues
        : autoCompleteValues
      : searchText?.length < 3 && suggestedValues && !suggestionList
      ? key && freeSolo && typeof suggestedValues?.[0] == "string"
        ? suggestedValues.map((value: any) => ({ [key]: value }))
        : suggestedValues
      : searchText?.length > 2
      ? apiData?.data?.[dataEntry]
      : [];

  const getOptionLabel = !freeSolo ? (option: any) => (key ? option[key] : option) : undefined;

  const renderTags = freeSolo
    ? (value: readonly string[], getTagProps: any) =>
        value.map((option: string, index: number) => <Chip variant='outlined' label={option} {...getTagProps({ index })} />)
    : undefined;

  const autoCompleteOptions = (freeSolo ? ((key ? options?.map((option: any) => option?.[key]) : options) as any) : options) || [];

  const myRef = useRef<any>(null);

  useLayoutEffect(() => {
    const height = myRef.current?.offsetHeight || 800; //TODO Defect here throws an exception
    const width = myRef.current?.offsetWidth;
    onLayout?.({ width, height });
  }, [suggestedValues]);

  if (!props.componentData) return <Typography color='red'>Missing Component !!</Typography>;
  // @ts-ignore
  return control ? (
    <Controller
      control={control}
      name={inputValidationName!}
      rules={{
        required: isRequired,
      }}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <Autocomplete
            options={autoCompleteOptions}
            readOnly={readOnly}
            multiple={!!multipleSelect}
            onClose={() => setSearchText("")}
            defaultValue={!!multipleSelect ? value || [] : freeSolo ? value?.[key] : value || null}
            value={!!multipleSelect ? value || [] : freeSolo ? value?.[key] : value || null}
            freeSolo={freeSolo}
            renderTags={renderTags}
            getOptionLabel={getOptionLabel}
            loading={loading}
            filterSelectedOptions={!!multipleSelect}
            classes={{
              clearIndicator: classes.clearIndicator,
            }}
            isOptionEqualToValue={(option, value) => {
              if (key && !freeSolo) {
                return option[key] == value[key];
              } else {
                return option == value;
              }
            }}
            onChange={(event: any, newValue: any) => {
              // setSearchText("");
              onChange(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  ...((!freeSolo || multipleSelect) && !!multipleSelect && { value: searchText }),
                }}
                label={name || "Label"}
                onChange={e => setSearchText(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                }}
              />
            )}
          />
          {error && <FormHelperText error>{error?.message}</FormHelperText>}

          {!!suggestionList && suggestedValues?.length && !isSuggestionFetching && (
            <Grid container direction='row' spacing={1} mt={2} flexWrap='wrap'>
              {suggestedValues?.map((item: any) => {
                const isExist = !!multipleSelect
                  ? value?.length && value?.find((_item: any) => (_item?.[key] || _item) == (item?.[key] || item))
                  : (value?.[key] || value) == (item?.[key] || item);
                return (
                  <Grid item key={item?.[key] || item}>
                    <Chip
                      disabled={readOnly}
                      sx={{ cursor: "pointer" }}
                      label={item?.[key] || item}
                      variant={isExist ? "filled" : "outlined"}
                      onClick={() => {
                        if (readOnly) return;
                        if (!!multipleSelect) {
                          if (isExist) {
                            onChange(value?.filter((_item: any) => (_item?.[key] || _item) != (item?.[key] || item)));
                          } else {
                            onChange([...value, item]);
                          }
                        } else {
                          onChange(item);
                        }
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </FormControl>
      )}
    />
  ) : (
    <div ref={myRef}>
      <Autocomplete
        options={autoCompleteOptions}
        readOnly={readOnly}
        multiple={!!multipleSelect}
        onClose={() => setSearchText("")}
        freeSolo={freeSolo}
        renderTags={renderTags}
        getOptionLabel={getOptionLabel}
        loading={loading}
        filterSelectedOptions={!!multipleSelect}
        classes={{
          clearIndicator: classes.clearIndicator,
        }}
        isOptionEqualToValue={(option, value) => {
          if (key && !freeSolo) {
            return option[key] == value[key];
          } else {
            return option == value;
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              ...((!freeSolo || multipleSelect) && !!multipleSelect && { value: searchText }),
            }}
            label={name || "Label"}
            onChange={e => setSearchText(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
            }}
          />
        )}
      />
      {!!suggestionList && suggestedValues?.length && !isSuggestionFetching && (
        <Grid container direction='row' spacing={1} mt={2} flexWrap='wrap'>
          {suggestedValues?.map((item: any) => {
            return (
              <Grid item key={item?.[key] || item}>
                <Chip disabled={readOnly} sx={{ cursor: "pointer" }} label={item?.[key] || item} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default BXAutoComplete;
