/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BXMapValues } from "./BXMapValues";
import { ChainActionsConfig } from "./ChainActionsConfig";

export const MapChainActions = ({ item, view, views, appId, collectionId, pageId, handleConfigChange, onSave, onChangeProp }) => {
  const { isChainMapped, actionsKey, isLoadingForEntireChain } = item?.config?.actionMappingConfig || {};
  const { actionsMap } = item;

  const [selectedValue, setSelectedValue] = useState<string>("");

  useEffect(() => {
    //If item has no actions map, configure a new one and insert old action on it.
    if (!item?.actionsMap) {
      const newItem = {
        ...item,
        actionsMap: {
          default: item?.actionConfig ? [{ actionConfig: { ...item?.actionConfig } }] : [],
        },
      };

      setSelectedValue("default");
      onChangeProp?.(newItem);
    } else {
      setSelectedValue("default");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentActions = [...(actionsMap?.[selectedValue] || [])];

  const onDeleteAction = index => {
    const updatedActions = [...item?.actionsMap[selectedValue]];
    updatedActions.splice(index, 1);

    const newItem = {
      ...item,
      actionsMap: {
        ...item?.actionsMap,
        [selectedValue]: updatedActions,
      },
    };

    onChangeProp?.(newItem);
  };

  function addNewChain(value) {
    setSelectedValue(value);
    const newItem = {
      ...item,
      actionsMap: {
        ...item?.actionsMap,
        [value]: [],
      },
    };

    onChangeProp?.(newItem);
  }

  function copyFromChain(value, source) {
    const newItem = {
      ...item,
      actionsMap: {
        ...item?.actionsMap,
        [value]: [...(source?.actions as [])],
      },
    };

    onChangeProp?.(newItem);
  }

  function copyFromDefault(value) {
    const defaultActions = item?.actionsMap?.default || [];
    const newItem = {
      ...item,
      actionsMap: {
        ...item?.actionsMap,
        [value]: [...defaultActions],
      },
    };

    onChangeProp?.(newItem);
    setSelectedValue(value);
  }

  function deleteChain(value) {
    const updatedActionsMap = { ...actionsMap };
    delete updatedActionsMap[value];
    onChangeProp?.({
      ...item,
      actionsMap: updatedActionsMap,
    });
    setSelectedValue("default");
  }
  const [condition, setCondition] = useState(item?.config?.conditionActionMapKey || "equal");
  const handleConditionChange = (newCondition: string) => {
    setCondition(newCondition);
    const newItem = {
      ...item,
      config: {
        ...item?.config,
        conditionActionMapKey: newCondition,
      },
    };
    onChangeProp?.(newItem);
  };

  return (
    <>
      <Grid container display={"flex"} flexDirection={"column"} spacing={3}>
        <Grid item xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"}>
          <Typography sx={{ fontSize: "17px" }}>Map values</Typography>
          <Switch
            size='medium'
            checked={!!isChainMapped}
            onChange={e => {
              handleConfigChange("actionMappingConfig.isChainMapped", "boolean")(e);
              if (!e.target.checked) {
                setSelectedValue("default");
              }
            }}
            inputProps={{ "aria-label": "map-chains" }}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"}>
          {!!isChainMapped && (
            <BXMapValues
              initialKey={actionsKey}
              targetMap={actionsMap}
              queryString='actionMappingConfig.actionsKey'
              handleKeyChange={handleConfigChange}
              setSelectedValue={setSelectedValue}
              addNewValue={addNewChain}
              copyFromDefault={copyFromDefault}
              copyFromValue={copyFromChain}
              deleteValue={deleteChain}
              condition={condition}
              setCondition={setCondition}
              handleConditionChange={handleConditionChange}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <ChainActionsConfig
            key={selectedValue}
            selectedValue={selectedValue}
            isLoadingForEntireChain={isLoadingForEntireChain}
            onLoadingChange={e => {
              handleConfigChange(`actionMappingConfig.isLoadingForEntireChain.[${selectedValue}]`, "boolean")(e);
            }}
            views={views}
            view={view}
            item={item}
            appId={appId}
            collectionId={collectionId}
            pageId={pageId}
            onDeleteAction={onDeleteAction}
            onSave={newChain => {
              const newItem = {
                ...item,
                actionsMap: {
                  ...item.actionsMap,
                  [selectedValue]: [...newChain],
                },
                interactionConfig: item?.interactionConfig || [
                  {
                    type: "OnClick",
                    triggerSelf: true,
                    actionableKey: "",
                    key: "Enter",
                  },
                ],
              };

              onChangeProp?.(newItem);
            }}
            actions={currentActions}
          />
        </Grid>
        <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Button variant={"outlined"} onClick={onSave}>
            Done
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
