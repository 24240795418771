import { Box, Card, CardContent, Grid, MenuItem } from "@mui/material";
import { FC, useRef } from "react";
import Sound1 from "src/assets/sounds/notification-sound-1.mp3";
import Sound2 from "src/assets/sounds/notification-sound-2.mp3";
import Sound3 from "src/assets/sounds/notification-sound-3.mp3";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXApp } from "src/types/BXAppType";
import { getSharedViews } from "../../FormBuilder/utils";

type ViewInfoProps = {
  viewType?: string;
  control: any;
  errors: any;
  watch: any;
  setValue?: any;
  app?: BXApp;
};

export const visibilityData = ["Visible", "Hidden"];
const paginationData = ["Infinite Scroll", "Pagination Bar"];

const ViewInfo: FC<ViewInfoProps> = ({ viewType, control, errors, watch, setValue, app }) => {
  const _views = getSharedViews(app); //TODO: use Populate later
  const soundRef1 = useRef(null);
  const soundRef2 = useRef(null);
  const soundRef3 = useRef(null);

  const getHeaderName = () => {
    switch (viewType) {
      case "data-table":
        return "Table";
      case "image-grid":
        return "Image";
      case "card-list":
        return "Grid";
      case "form":
        return "Form";
      case "upload":
        return "Upload";
      case "api":
        return "API";
      case "form-builder":
        return "View Builder";
      default:
        return "Table";
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BXInput
              control={control}
              name={"info.name"}
              error={errors?.info?.name}
              fullWidth
              label={"View Title"}
              onChange={e => {
                const value = e.currentTarget.value.replace(/[^0-9a-zA-Z\-]/g, "-");
                setValue("info.viewName", value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <BXInput
              control={control}
              name={"info.viewName"}
              error={errors?.info?.viewName}
              fullWidth
              label={"Reference Name"}
              onChange={e => {
                const value = e.currentTarget.value.replace(/[^0-9a-zA-Z\-]/g, "-");
                setValue("info.viewName", value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <BXInput control={control} name={"info.visibility"} error={errors?.info?.visibility} select fullWidth label={"Visibility"}>
              {visibilityData?.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </BXInput>
          </Grid>
          {viewType != "form" && viewType != "form-builder" && viewType != "upload" && viewType != "api" && (
            <Grid item xs={6}>
              <BXInput
                control={control}
                name={"info.showApiMode"}
                error={errors?.info?.showApiMode}
                select
                fullWidth
                label={"Show API Mode"}
              >
                {visibilityData?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </BXInput>
            </Grid>
          )}

          {(viewType == "data-table" || viewType == "card-list" || viewType == "image-grid" || viewType == "repeated-view") && (
            <Grid item xs={6}>
              <BXInput
                control={control}
                name={"info.paginationMode"}
                error={errors?.info?.paginationMode}
                select
                fullWidth
                label={"Pagination Mode"}
              >
                {paginationData?.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </BXInput>
            </Grid>
          )}
          {(viewType == "data-table" || viewType == "repeated-view") && (
            <Grid item xs={6}>
              <BXInput
                control={control}
                name={"info.emptyStateView"}
                error={errors?.info?.emptyStateView}
                select
                fullWidth
                label={"Empty State View"}
              >
                {_views?.map(view => (
                  <MenuItem key={view?.id} value={view?.id}>
                    {view?.info?.name}
                  </MenuItem>
                ))}
              </BXInput>
            </Grid>
          )}

          {viewType != "api" && viewType != "form-builder" && (
            <Grid item xs={6}>
              <Box display='flex' alignItems='center'>
                <BXSwitch control={control} name={"info.enableMultipleSelection"} label={"Enable Multiple Select"} />
              </Box>
            </Grid>
          )}

          {viewType == "repeated-view" && (
            <Grid item xs={6}>
              <BXSwitch
                control={control}
                name={"info.isPercentageHeight"}
                error={errors?.info?.isPercentageHeight}
                label={"Fixed Height"}
              />
            </Grid>
          )}
          {viewType === "form-builder" && (
            <Grid item xs={6}>
              <BXSwitch
                control={control}
                name={"info.disableDirtyOption"}
                error={errors?.info?.disableDirtyOption}
                label={"Disable Dirty Form"}
              />
            </Grid>
          )}

          {viewType != "form-builder" && (
            <>
              <Grid item xs={6}>
                <BXSwitch control={control} name={"info.hideViewName"} error={errors?.info?.hideViewName} label={"Hide View Name"} />
              </Grid>
              <Grid item xs={6}>
                <BXSwitch
                  control={control}
                  name={"info.hidePaginationBar"}
                  error={errors?.info?.hidePaginationBar}
                  label={"Hide Pagination Bar"}
                />
              </Grid>
              <Grid item xs={6}>
                <BXSwitch
                  control={control}
                  name={"info.hideRefreshButton"}
                  error={errors?.info?.hideRefreshButton}
                  label={"Hide Refresh Button"}
                />
              </Grid>
            </>
          )}

          {viewType == "card-list" && (
            <>
              <Grid item xs={12}>
                <BXSwitch control={control} name={"info.autoRefresh"} error={errors?.info?.autoRefresh} label={"Auto Refresh"} />
              </Grid>
              <Grid item xs={5} marginInlineStart={2}>
                <BXInput
                  control={control}
                  name={"info.timer"}
                  error={errors?.info?.timer}
                  label={`Timer in (sec)`}
                  type='number'
                  disabled={!watch("info.autoRefresh")}
                />
              </Grid>
              <Grid item xs={5} marginInlineStart={2}>
                <BXInput
                  control={control}
                  name={"info.notificationMessage"}
                  error={errors?.info?.notificationMessage}
                  label={`Notification Message`}
                  disabled={!watch("info.autoRefresh")}
                />
              </Grid>
              <Grid item xs={5} marginInlineStart={2}>
                <BXInput
                  control={control}
                  name={"info.notificationSound"}
                  error={errors?.info?.notificationSound}
                  select
                  label={"Notification Sound"}
                  disabled={!watch("info.autoRefresh")}
                >
                  {[
                    { label: "Sound 1", value: "sound1", ref: soundRef1 },
                    { label: "Sound 2", value: "sound2", ref: soundRef2 },
                    { label: "Sound 3", value: "sound3", ref: soundRef3 },
                  ]?.map(item => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      onClick={() => {
                        (item.ref.current as any).play();
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </BXInput>
              </Grid>
              <audio ref={soundRef1} src={Sound1} />
              <audio ref={soundRef2} src={Sound2} />
              <audio ref={soundRef3} src={Sound3} />
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ViewInfo;
