import _ from "lodash";
import { BXApp } from "src/types/BXAppType";
import { compareVersions } from "src/utils/generalUtils";

export function migrateTo1_0_0(app: BXApp) {
  const iterateValues = (obj: any, path?: string) => {
    _.forEach(obj, (value, key) => {
      const currentPath = path ? path + `[${key}]` : key;
      if (_.isObject(value)) {
        iterateValues(value, currentPath);
      } else {
        if (typeof value === "string") {
          obj[key] = value.replace(/\{(?!\s*\})([^{},$]+)\}/g, (match, p1) => {
            if (p1.includes("#")) {
              const hashes = p1?.split(".")?.[0]?.split("")?.join(".");
              return `{${hashes}.data${p1?.replace(p1?.split(".")?.[0], "")}}`;
            } else {
              return `{this.data.${p1.split(".").join(".")}}`;
            }
          });
        }
      }
    });
  };
  iterateValues(app);

  app?.templateConfig?.collections?.map(collection => {
    collection?.pages?.map(page => {
      page?.views?.map(view => {
        if (view?.info) {
          view.info.viewName = view.info?.name?.replace(/[^0-9a-zA-Z\-]/g, "-");
        }
      });
    });
  });
  _.set(app, "templateConfig.appVersion", "1.0.0");
}

export function migrateTo1_0_1(app: BXApp) {
  _.set(app, "appConfig.auth.authApi", {
    method: "POST",
    headers: {
      authorization: "Bearer {this.device.accessToken}",
    },
    body: '{\n    "email": "{this.login.email}",\n    "password": "{this.login.password}",\n    "recaptcha": "{this.login.recaptcha}"\n}',
    uri: app?.appConfig?.auth?.authApi as string,
  });

  _.set(app, "appConfig.auth.deviceApi", {
    method: "POST",
    body: '{\n    "appVersion": "{this.config.appVersion}",\n    "deviceType": "{this.config.deviceType}",\n    "deviceUDID": "{this.config.deviceUDID}",\n    "osVersion": "{this.config.osVersion}",\n    "timeZone": "{this.config.timeZone}"\n}',
    uri: app?.appConfig?.auth?.deviceApi as string,
  });

  _.set(app, "templateConfig.appVersion", "1.0.1");
}

const migrations: any = {
  "1.0.0": migrateTo1_0_0,
  "1.0.1": migrateTo1_0_1,
};

export const handleMigrateApp = (app: BXApp) => {
  const appVersion = app?.templateConfig?.appVersion!;

  const migrationsKeys = Object.keys(migrations);
  const versionKeys = !appVersion ? migrationsKeys : migrationsKeys.filter(version => compareVersions(version, appVersion) > 0);

  for (let i = 0; i < versionKeys.length; i++) {
    const version = versionKeys[i];
    const migrationFunction = migrations[version];
    migrationFunction(app);
  }
  return app;
};
