import { InputAdornment } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { IconLanguage } from "@tabler/icons-react";
import { FC, useEffect, useState } from "react";
import BXModal from "src/components/BXUI/Modal";
import axiosServices from "src/utils/axios";
import { ManageLanguages } from "../forms/ManageLanguages";

type TranslationInputProps = TextFieldProps & {
  label: string;
  value?: any;
  error?: any;
  appId?: string;
  multiLingual?: any;
  setMultiLingual?: any;
  selectedRow?: any;
  setSelectedRow?: any;
};

export const TranslationInput: FC<TranslationInputProps> = ({
  label,
  value,
  error,
  onChange,
  variant = "outlined",
  required = false,
  appId,
  multiLingual,
  setMultiLingual,
  selectedRow,
  setSelectedRow,

  ...rest
}) => {
  const selectable = true;
  const translations = multiLingual?.translations;
  const [isManualUpdate, setIsManualUpdate] = useState(false);

  useEffect(() => {
    if (isManualUpdate) {
      return;
    }
    if (translations && value) {
      const keyWithoutTemplate = value.replace(/\{i18n\.(.*?)\}/, "$1");
      const index = translations.findIndex(translation => translation.key === keyWithoutTemplate);
      if (index !== -1) {
        setSelectedRow(index);
      } else {
        setSelectedRow(-1);
      }
    }
  }, [value, multiLingual, setSelectedRow]);
  return (
    <>
      <TextField
        size='small'
        variant={variant}
        onChange={onChange}
        label={label}
        fullWidth
        value={value}
        onFocus={() => setIsManualUpdate(true)}
        onBlur={() => setIsManualUpdate(false)}
        InputLabelProps={{ style: { fontSize: "12px" } }}
        InputProps={{
          style: { fontSize: "12px" },
          endAdornment: (
            <InputAdornment position='end'>
              <BXModal
                label={"Translations"}
                showIconHeader={false}
                icon={<IconLanguage size={10} />}
                maxWidth='lg'
                title={"Translations"}
                withoutLabel
              >
                {(handleClose: Function) => {
                  return (
                    <ManageLanguages
                      multiLingual={multiLingual}
                      selectable={selectable}
                      activeTab={2}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                      onSave={async data => {
                        try {
                          await axiosServices.put(`/admin/multilingual/app/${appId}`, data);
                          setMultiLingual(data);
                          const hasNewTranslations = data.translations.length > translations.length;
                          if (!hasNewTranslations) {
                            handleClose?.(true);
                          }
                        } catch (error) {
                          console.error("Error updating data:", error);
                        }
                      }}
                      onCancel={() => {
                        handleClose(false, () => {});
                      }}
                    />
                  );
                }}
              </BXModal>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
