import axios, { AxiosPromise, AxiosResponse } from "axios";
import _ from "lodash";
import React, { Dispatch, FC, createContext, useContext, useState } from "react";
import { BXApp, BXAppCollection } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import { UIElement } from "src/types/UIElement";
import axiosServices from "src/utils/axios";
import { getAuthorizationHeader, splitTemplateConfig } from "src/utils/generalUtils";
import { compressData, decompressData } from "src/utils/services";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { v4 as uuid } from "uuid";
import { useBXContext } from "./BXContext";

const enqueueSuccessMessage = (context: string) => {
  enqueueSnackbarRef?.(`${context} Saved Successfully`, {
    variant: "success",
    anchorOrigin: {
      horizontal: "right",
      vertical: "bottom",
    },
  });
};

export function addCommonTemplate(app: BXApp) {
  const sharedViews: BXPageType = {
    id: uuid(),
    title: "Shared Views",
    name: "Shared Views",
    views: [],
    isShared: true,
  };

  const sharedCollection: BXAppCollection = {
    id: uuid(),
    name: "Shared Collection",
    pages: [sharedViews],
    isShared: true,
  };

  app.templateConfig = {
    ...app.templateConfig,
    collections: [...(app?.templateConfig?.collections || []), sharedCollection],
  };

  return;
}

type BXContextProps = {
  setExpandedPage: Dispatch<any>;
  expandedPage?: BXPageType | null;
  apps: BXApp[];
  setApps: React.Dispatch<React.SetStateAction<BXApp[]>>;
  addApp: (app: BXApp) => void;
  saveAsTemplate: (data: { config: any; type: string; name: string; visibility: string }, templateId?: string) => Promise<any>;
  saveAsMedia: (data: { config: any; type: string; name: string; visibility: string }, mediaId?: string) => Promise<any>;
  getTemplateById: (templateId: string) => AxiosPromise<any>;
  getMediaById: (mediaId: string) => AxiosPromise<any>;
  getTemplateByHistoryId: (historyId: string) => AxiosPromise<any>;
  getTemplateList: () => AxiosPromise<any>;
  deleteApp: (app: BXApp) => void;
  editApp: (id: string, app: BXApp, onSuccess?: () => void, onError?: () => void, addSharedCollectionFlag?: boolean) => void;
  editApps: (app: BXApp[]) => void;
  reorderApps: (appId: string, targetAppId: string, position: "AFTER" | "BEFORE") => void;
  addCollection: (appId: string, collection: BXAppCollection, onSuccess?: () => void) => void;
  editCollection: (appId: string, collectionId: string, collection: BXAppCollection) => void;
  deleteCollection: (appId: string, collectionId: string, onSuccess?: () => void) => void;
  addPage: (appId: string, collectionId: string, page: BXPageType, onSuccess?: () => void) => void;
  editPage: (appId: string, collectionId: string, pageID: string, page: BXPageType, onSuccess?: () => void) => void;
  deletePage: (appId: string, collectionId: string, pageID: string, onSuccess?: () => void) => void;
  addView: (appId: string, collectionId: string, pageId: string, view: UIElement, onSuccess?: () => void) => void;
  addViews: (appId: string, collectionId: string, pageId: string, view: UIElement[], onSuccess?: () => void) => void;
  editView: (
    appId: string,
    collectionId: string,
    pageId: string,
    viewId: string,
    view: UIElement,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  deleteView: (appId: string, collectionId: string, pageId: string, viewId: string, onSuccess?: () => void) => void;
  deleteViews: (appId: string, collectionId: string, pageId: string, viewIds: string[], onSuccess?: () => void) => void;
};
export const BXBuilderContext = createContext<BXContextProps>({
  setExpandedPage: _.noop,
  expandedPage: null,
  setApps: () => {},
  apps: [],
  addApp: () => {},
  editApp: () => {},
  editApps: () => {},
  reorderApps: () => {},
  addCollection: () => {},
  editCollection: () => {},
  saveAsTemplate: async () => {},
  saveAsMedia: async () => {},
  getTemplateById: async (templateId: string) => {
    return { data: undefined } as AxiosResponse<any>;
  },
  getMediaById: async (mediaId: string) => {
    return { data: undefined } as AxiosResponse<any>;
  },
  getTemplateByHistoryId: async (historyId: string) => {
    return { data: undefined } as AxiosResponse<any>;
  },
  getTemplateList: async () => {
    return { data: undefined } as AxiosResponse<any>;
  },
  deleteCollection: () => {},
  addPage: () => {},
  editPage: () => {},
  deletePage: () => {},
  addView: () => {},
  addViews: () => {},
  editView: () => {},
  deleteView: () => {},
  deleteViews: () => {},
  deleteApp: (app: BXApp) => {},
});

/**
 *
 * @param children
 * @constructor
 */
export const BXBuilderContextProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [expandedPage, setExpandedPage] = useState<BXPageType | null>(null);
  const { appDescriptor: apps, setAppDescriptor: setApps, setCurrentApp, currentApp } = useBXContext();
  const addApp = (app: BXApp) => {
    addCommonTemplate(app);

    const payload = {
      ...app,
      appConfig: compressData(app?.appConfig),
      templateConfig: compressData(app?.templateConfig),
      upTemplateConfig: compressData(splitTemplateConfig(app)),
    };

    return axiosServices.post("/admin/application", payload).then(value => {
      enqueueSuccessMessage("Application");
      setApps((old: BXApp[]) => {
        const addedApp = {
          ...value.data,
          appConfig: decompressData(value?.data?.appConfig),
          templateConfig: decompressData(value?.data?.templateConfig),
        };
        if (!old?.length) {
          setCurrentApp(addedApp);
        }
        return [...old, addedApp];
      });
    });
  };

  const deleteApp = (app: BXApp) => {
    return axiosServices.delete(`/admin/application/${app.id}`).then(value => {
      setApps((old: BXApp[]) => old.filter(oldApp => oldApp.id !== app.id));
      if (app.id == currentApp?.id) {
        setCurrentApp(apps?.[0]);
      }
    });
  };

  const editApp = (id: string, app: BXApp, onSuccess?: () => void, onError?: () => void, addSharedCollectionFlag?: boolean) => {
    if (addSharedCollectionFlag) {
      addCommonTemplate(app);
    }

    const payload = {
      ...app,
      appConfig: compressData(app?.appConfig),
      templateConfig: compressData(app?.templateConfig),
      upTemplateConfig: compressData(splitTemplateConfig(app)),
    };

    return axiosServices
      .put(`/admin/application/${id}`, payload)
      .then(value => {
        enqueueSuccessMessage("Application");
        setApps((apps: BXApp[]) => {
          const updatedApps = apps.map(oldApp => (oldApp.id === id ? { ...app, version: value.data?.version } : oldApp));
          return _.cloneDeep(updatedApps);
        });
        if (app.id == currentApp?.id) {
          setCurrentApp(_.cloneDeep(app));
        }
        setTimeout(() => {
          onSuccess?.();
        }, 0);
      })
      .catch(e => onError?.());
  };

  const editApps = (apps: BXApp[]) => {
    const payloadApps = apps.map(app => {
      const payload = {
        ...app,
        appConfig: compressData(app?.appConfig),
        templateConfig: compressData(app?.templateConfig),
        upTemplateConfig: compressData(splitTemplateConfig(app)),
      };

      return payload;
    });

    const promises = payloadApps.map(app => axiosServices.put(`/admin/application/${app.id}`, app));

    Promise.all(promises).then(values => {
      enqueueSuccessMessage("Application");
      setApps(values.map(value => value.data));
    });
  };

  const reorderApps = (appId: string, targetAppId: string, position: "AFTER" | "BEFORE") => {
    return axiosServices.post(`/admin/application/${appId}/reorder?targetAppId=${targetAppId}&position=${position}`).catch(e => {});
  };

  const addCollection = (appId: string, collection: BXAppCollection, onSuccess?: () => void) => {
    try {
      const appToEdit = apps.find(app => app.id === appId)!;
      let data = appToEdit;

      if (Array.isArray(appToEdit?.templateConfig?.collections)) {
        const sharedCollections = appToEdit!.templateConfig!.collections?.filter(col => col.isShared);
        const appCollections = appToEdit!.templateConfig!.collections?.filter(col => !col.isShared);
        const _collections = [...appCollections, collection, ...sharedCollections];

        data = {
          ...appToEdit,
          templateConfig: {
            ...appToEdit.templateConfig,
            collections: _collections,
          },
        };
      } else {
        _.set(data, "templateConfig.collections", [collection]);
      }

      editApp(appId, data, onSuccess);
    } catch (e) {}
  };

  const editCollection = (
    appId: string,
    collectionId: string,
    collection: BXAppCollection,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    setApps((apps: BXApp[]) => {
      const appToEdit = apps.find(app => app.id === appId);
      if (appToEdit) {
        // add to the collection
        if (Array.isArray(appToEdit?.templateConfig?.collections)) {
          const data: BXApp = {
            ...appToEdit,
            templateConfig: {
              ...appToEdit.templateConfig,
              collections: appToEdit.templateConfig!.collections.map(coll => (coll.id === collectionId ? collection : coll)),
            },
          };
          editApp(appId, data, onSuccess, onError);
        }
      }
      return apps;
    });
  };

  const deleteCollection = (appId: string, collectionId: string, onSuccess?: () => void) => {
    const appToEdit = apps.find(app => app.id === appId);
    if (appToEdit) {
      // add to the collection
      if (Array.isArray(appToEdit?.templateConfig?.collections)) {
        const data: BXApp = {
          ...appToEdit,
          templateConfig: {
            ...appToEdit.templateConfig,
            collections: appToEdit.templateConfig!.collections.filter(coll => coll.id !== collectionId),
          },
        };
        editApp(appId, data, onSuccess);
      }
    }
  };

  const addPage = (appId: string, collectionId: string, page: BXPageType, onSuccess?: () => void) => {
    let collectionToEdit = apps.find(app => app.id === appId)?.templateConfig?.collections?.find(coll => coll.id === collectionId) || null;
    if (collectionToEdit) {
      editCollection(appId, collectionId, { ...collectionToEdit, pages: [page, ...collectionToEdit.pages] }, onSuccess);
    }
  };

  const editPage = (
    appId: string,
    collectionId: string,
    pageId: string,
    page: BXPageType,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    setApps((apps: BXApp[]) => {
      let collectionToEdit =
        apps.find(app => app.id === appId)?.templateConfig?.collections?.find(coll => coll.id === collectionId) || null;
      if (collectionToEdit) {
        editCollection(
          appId,
          collectionId,
          {
            ...collectionToEdit,
            pages: collectionToEdit.pages.map((p: BXPageType) => (p.id === pageId ? page : p)),
          },
          onSuccess,
          onError
        );
      }
      return apps;
    });
  };

  const deletePage = (appId: string, collectionId: string, pageId: string, onSuccess?: () => void) => {
    let collectionToEdit = apps.find(app => app.id === appId)?.templateConfig?.collections?.find(coll => coll.id === collectionId) || null;
    if (collectionToEdit) {
      editCollection(
        appId,
        collectionId,
        {
          ...collectionToEdit,
          pages: collectionToEdit.pages.filter((p: BXPageType) => p.id !== pageId),
        },
        onSuccess
      );
    }
  };

  const addView = (appId: string, collectionId: string, pageId: string, view: UIElement, onSuccess?: () => void) => {
    let pageToEdit =
      apps
        .find(app => app.id === appId)
        ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
        ?.pages?.find(page => page.id === pageId) || null;
    if (pageToEdit) {
      editPage(appId, collectionId, pageId, { ...pageToEdit, views: [...(pageToEdit?.views || []), view] }, onSuccess);
    }
  };

  const addViews = (appId: string, collectionId: string, pageId: string, views: UIElement[], onSuccess?: () => void) => {
    let pageToEdit =
      apps
        .find(app => app.id === appId)
        ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
        ?.pages?.find(page => page.id === pageId) || null;
    if (pageToEdit) {
      editPage(appId, collectionId, pageId, { ...pageToEdit, views: [...(pageToEdit?.views || []), ...views] }, onSuccess);
    }
  };

  const editView = (
    appId: string,
    collectionId: string,
    pageId: string,
    viewId: string,
    view: UIElement,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    setApps((apps: BXApp[]) => {
      let pageToEdit =
        apps
          .find(app => app.id === appId)
          ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
          ?.pages?.find(page => page.id === pageId) || null;
      if (pageToEdit) {
        const data = {
          ...pageToEdit,
          views: pageToEdit?.views.map(v => (v.id === viewId ? view : v)),
        };
        editPage(appId, collectionId, pageId, data, onSuccess, onError);
      }
      return apps;
    });
  };

  const deleteView = (appId: string, collectionId: string, pageId: string, viewId: string, onSuccess?: () => void) => {
    let pageToEdit =
      apps
        .find(app => app.id === appId)
        ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
        ?.pages?.find(page => page.id === pageId) || null;
    if (pageToEdit) {
      const data = {
        ...pageToEdit,
        views: pageToEdit.views.filter(v => v.id !== viewId),
      };
      editPage(appId, collectionId, pageId, data, onSuccess);
    }
  };

  const deleteViews = (appId: string, collectionId: string, pageId: string, viewIds: string[], onSuccess?: () => void) => {
    let pageToEdit =
      apps
        .find(app => app.id === appId)
        ?.templateConfig?.collections?.find(coll => coll.id === collectionId)
        ?.pages?.find(page => page.id === pageId) || null;
    if (pageToEdit) {
      const data = {
        ...pageToEdit,
        views: pageToEdit.views.filter(v => !viewIds?.includes(v.id)),
      };
      editPage(appId, collectionId, pageId, data, onSuccess);
    }
  };

  function saveAsTemplate(data: { config: any; type: string; name: string; visibility: string }, templateId?: string) {
    if (templateId) {
      return axiosServices.put(`/template/${templateId}`, data);
    }
    return axiosServices.post(`/admin/template`, data);
  }

  function getTemplateById(templateId: string) {
    return axiosServices.get(`/admin/template/${templateId}`);
  }

  function getTemplateByHistoryId(historyId: string) {
    return axiosServices.get(`/admin/template-history/${historyId}`);
  }

  function getTemplateList() {
    return axiosServices.get(`/admin/template`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
    });
  }

  function saveAsMedia(data: { config: any; type: string; name: string; visibility: string }, mediaId?: string) {
    if (mediaId) {
      return axiosServices.put(`/admin/media/${mediaId}`, data);
    }
    return axiosServices.post(`/admin/media`, data);
  }

  function getMediaById(mediaId: string) {
    const token = localStorage.getItem("accessToken") ?? localStorage.getItem("accessToken-device");
    return axios.get(`/media/${mediaId}`, {
      headers: {
        ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
      },
    });
  }

  return (
    <BXBuilderContext.Provider
      value={{
        expandedPage,
        setExpandedPage,
        apps,
        getTemplateById,
        getMediaById,
        getTemplateByHistoryId,
        setApps,
        saveAsTemplate,
        saveAsMedia,
        addApp,
        editApp,
        editApps,
        reorderApps,
        deleteApp,
        addCollection,
        getTemplateList,
        editCollection,
        deleteCollection,
        addPage,
        editPage,
        deletePage,
        addView,
        addViews,
        editView,
        deleteView,
        deleteViews,
      }}
    >
      {children}
    </BXBuilderContext.Provider>
  );
};

/**
 * consumer of BX Context
 */
export const useBXBuilderContext = () => useContext(BXBuilderContext);
