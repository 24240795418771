import { Box, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { IconCheck, IconDotsVertical, IconHome } from "@tabler/icons-react";
import React, { useCallback, useState } from "react";
import { setParents } from "../utils";
import ComponentsTreeItem from "./ComponentsTreeItem";
import { ComponentsTreeProps } from "./TreeTypes";

const ComponentsTree: React.FC<ComponentsTreeProps> = ({
  children,
  view,
  setView,
  activeComponent,
  onDiscardEdit,
  handleDeleteItem,
  handleSelectComponent,
  handleCopyItems,
  handlePasteItems,
  selectedItemsId,
  setSelectedItemsId,
  openStates,
  setOpenStates,
  onChangePropConfigTreeMenu,
}) => {
  const [isCanvasClicked, setIsCanvasClicked] = useState(false);
  const [lastSelectedItemId, setLastSelectedItemId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const configurationTreeMenuItems = [{ label: "Sync Canvas with Tree", value: "sync" }];

  const handleToggleOpen = useCallback(
    (itemId: string) => {
      setOpenStates(prev => {
        const newState = { ...prev, [itemId]: !prev[itemId] };
        return newState;
      });
    },
    [openStates]
  );

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleConfigurationTreeMenuItemsClick = (item: string) => {
    if (item === "sync") {
      handleSyncCanvasWithTree();
    }
    closeMenu();
  };

  const handleSyncCanvasWithTree = () => {
    if (onChangePropConfigTreeMenu) {
      onChangePropConfigTreeMenu();
    }
  };

  const moveItem = useCallback(
    (draggedIds: string[], targetId: string, direction: "top" | "bottom" | "middle") => {
      if (draggedIds.includes(targetId)) {
        return;
      }

      setView(prev => {
        const formBuilder = prev?.dataSource?.formBuilder || [];

        const findBlock = (blocks: any[], id: string): any | null => {
          for (let block of blocks) {
            if (block.id === id) return block;
            if (block.children) {
              const found = findBlock(block.children, id);
              if (found) return found;
            }
          }
          return null;
        };

        const removeBlocks = (blocks: any[], ids: string[]): any[] => {
          return blocks.reduce<any[]>((acc, block) => {
            if (ids.includes(block.id)) return acc;
            if (block.children && block.children.length) {
              block.children = removeBlocks(block.children, ids);
            }
            acc.push(block);
            return acc;
          }, []);
        };

        const insertBlocksAtPosition = (
          blocks: any[],
          targetId: string,
          newBlocks: any[],
          position: "top" | "bottom" | "middle"
        ): any[] => {
          return blocks.flatMap(block => {
            if (block.id === targetId) {
              if (position === "top") {
                return [...newBlocks, block];
              }

              if (position === "bottom") {
                return [block, ...newBlocks];
              }

              if (position === "middle") {
                return [{ ...block, children: [...(block.children || []), ...newBlocks] }];
              }
            }

            if (block.children) {
              return [{ ...block, children: insertBlocksAtPosition(block.children, targetId, newBlocks, position) }];
            }

            return [block];
          });
        };

        let blocksToMove = draggedIds.map(id => findBlock(formBuilder, id)).filter(Boolean) as any[];
        const selectedIdsSet = new Set(draggedIds);
        blocksToMove = blocksToMove.filter(block => {
          if (block.children) {
            block.children = block.children.filter((child: any) => !selectedIdsSet.has(child.id));
          }
          return true;
        });

        if (blocksToMove.length === 0) return formBuilder;

        const newBlocksWithoutDragged = removeBlocks(formBuilder, draggedIds);
        const updatedFormBuilder = insertBlocksAtPosition(newBlocksWithoutDragged, targetId, blocksToMove, direction);

        return {
          ...prev,
          dataSource: {
            ...prev.dataSource,
            formBuilder: setParents(updatedFormBuilder),
          },
        };
      });
    },
    [setView]
  );
  const selectItemsBetween = (startId: string, endId: string, items: any[], openStates: { [key: string]: boolean }): string[] => {
    const selectedIds: string[] = [];
    let isBetween = false;
    let foundBoth = false;

    const traverse = (components: any[]) => {
      for (const component of components) {
        if (foundBoth) return;
        const isOpen = openStates[component.id];

        if (component.id === startId || component.id === endId) {
          selectedIds.push(component.id);
          if (isBetween || startId === endId) {
            foundBoth = true;
            return;
          }
          isBetween = !isBetween;
        } else if (isBetween) {
          selectedIds.push(component.id);
        }

        if (component.children && isOpen) {
          traverse(component.children);
          if (foundBoth) return;
        }
      }
    };

    traverse(items);
    return selectedIds;
  };

  const handleSelectComponentSingleAndMulti = useCallback(
    (selectedItem: any, event?: React.MouseEvent) => {
      setIsCanvasClicked(false);

      setSelectedItemsId(prevSelectedItems => {
        const id = selectedItem?.id;
        if (!id) return prevSelectedItems;

        if (event?.shiftKey && lastSelectedItemId) {
          const itemsBetween = selectItemsBetween(lastSelectedItemId, id, children, openStates);
          setLastSelectedItemId(id);
          return Array.from(new Set([...prevSelectedItems, ...itemsBetween]));
        }

        if (event?.ctrlKey || event?.metaKey) {
          const isAlreadySelected = prevSelectedItems.includes(id);

          if (isAlreadySelected) {
            return prevSelectedItems.filter(existingId => existingId !== id);
          }

          setLastSelectedItemId(id);
          return [...prevSelectedItems, id];
        }

        setLastSelectedItemId(id);
        return [id];
      });
    },
    [setSelectedItemsId, lastSelectedItemId, children, openStates]
  );

  const handleCanvasClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsCanvasClicked(true);
    setSelectedItemsId([]);
    onDiscardEdit();
  };

  return (
    <Box width='100%' flexDirection='column' display='flex' sx={{ position: "relative" }}>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{
          padding: "8px",
          backgroundColor: isCanvasClicked ? theme.palette.primary.light : "rgba(210, 215, 231, 0.10)",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: isCanvasClicked ? theme.palette.primary.light : "rgba(210, 215, 231, 0.25)",
          },
        }}
        onClick={handleCanvasClick}
      >
        <Box display={"flex"} alignItems='center' justifyContent='space-between'>
          <IconHome style={{ marginRight: "8px", color: theme?.palette?.primary[200] }} />
          <Typography sx={{ fontSize: "14px", color: theme?.palette?.primary[200] }}>Canvas </Typography>
        </Box>
        <IconButton onClick={openMenu}>
          <IconDotsVertical width={16} height={16} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          PaperProps={{
            style: {
              padding: 4,
            },
          }}
        >
          {configurationTreeMenuItems.map(item => (
            <MenuItem
              key={item.value}
              sx={{
                fontSize: "12px",
                paddingLeft: "2px",
                paddingRight: "6px",
              }}
              onClick={() => handleConfigurationTreeMenuItemsClick(item.value)}
            >
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <ListItemIcon sx={{ minWidth: "25px !important" }}>
                  {item.value === "sync" && view?.dataSource?.formBuilderConfig?.isSyncTreeWithCanvasEnabled && (
                    <IconCheck style={{ color: theme.palette.primary.main }} size={20} />
                  )}
                </ListItemIcon>
                <Typography sx={{ fontSize: "12px" }}>{item.label}</Typography>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Grid>

      <Grid sx={{ paddingLeft: "16px", position: "relative", zIndex: 2 }}>
        {children?.map((item, index) => (
          <ComponentsTreeItem
            key={item.id}
            componentItem={item}
            moveItem={moveItem}
            children={children}
            handleDeleteItem={handleDeleteItem}
            activeComponent={activeComponent}
            setIsCanvasClicked={setIsCanvasClicked}
            handleCopyItems={handleCopyItems}
            handleSelectComponentSingleAndMulti={handleSelectComponentSingleAndMulti}
            handlePasteItems={handlePasteItems}
            view={view}
            setView={setView}
            selectedItemsId={selectedItemsId}
            setSelectedItemsId={setSelectedItemsId}
            isCanvasClicked={isCanvasClicked}
            handleSelectComponent={handleSelectComponent}
            openStates={openStates}
            isOpen={openStates[item.id]}
            handleToggleOpen={handleToggleOpen}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default ComponentsTree;
