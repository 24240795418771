import { FormControlLabel, Switch } from "@mui/material";
import { FC } from "react";
import { CustomSwitchProps } from "./types";

export const CustomSwitch: FC<CustomSwitchProps> = props => {
  const defaultChecked = String(props?.defaultValue) === "true" ? true : false;
  const e = (event: any) => {
    if (props?.onChange) {
      props?.onChange(event.target.checked, event);
    }
  };
  return (
    <FormControlLabel
      control={<Switch {...props} defaultChecked={defaultChecked} onChange={e} />}
      label={props?.label}
      required={false}
      style={{ marginLeft: "0px", maxHeight: "100%" }}
    />
  );
};
