import * as TablerIcons from "@tabler/icons-react";
import { IconProps } from "@tabler/icons-react";
import axios from "axios";
import { FC } from "react";
import { useQuery } from "react-query";
import { ReactComponent as IconGrid4x4 } from "../../../assets/svgs/grid-4x4.svg";

type BXIconProps = {
  fallBack?: string;
  icon?: string;
  url?: string;
  visibility?: string;
} & IconProps;

const fetchMedia = async (mediaType: string | undefined, mediaName: string | undefined, token: string | null) => {
  if (!mediaType || !mediaName) {
    return {
      items: [],
    };
  }

  const response = await axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/media", {
    params: {
      keyword: mediaName || undefined,
      type: mediaType || undefined,
      strategy: "summary",
    },
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

export const BXIcon: FC<BXIconProps> = ({ icon, fallBack, height = 30, width, url, visibility, ...rest }) => {
  const match = icon?.match(/^([^:]+):\/\/(.+?)(\.[a-z0-9]+)?$/i);
  const isHttpUrl = icon?.toLowerCase().match(/http[s]*:\/\//);
  const mediaType = match?.[1];
  const mediaName = match?.[2];
  const mediaExtension = match?.[3]?.substring(1);
  const accessToken = localStorage.getItem("accessToken");
  const deviceToken = localStorage.getItem("accessToken-device");
  const token = accessToken || deviceToken;
  const validExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "mp3", "wav", "ogg", "mp4", "webm", "svg"];
  const isValidExtension = validExtensions.includes(mediaExtension as any);

  const { data } = useQuery(["media", mediaType, mediaName], () => fetchMedia(mediaType, mediaName, token), {
    refetchOnMount: false,
    enabled: visibility === "PRIVATE" && !isHttpUrl,
  });

  const items = data?.items || [];
  const imageUrlFetchedValue = items?.length > 0 && items[0]?.name === mediaName ? items[0]?.url : null;

  const loadIcon = iconName => {
    if (!iconName) return null;
    return TablerIcons?.[`Icon${iconName}`] || null;
  };

  const IconComponent: FC<IconProps> = loadIcon(icon) ?? loadIcon(fallBack);
  if (visibility === "PUBLIC" && url && mediaType && mediaName && isValidExtension) {
    return <img src={url} height={height} width={width} alt={mediaName} />;
  }

  if (imageUrlFetchedValue) {
    return <img src={imageUrlFetchedValue} height={height} width={width} alt={mediaName} />;
  }

  if (isHttpUrl) {
    return <img src={icon} height={height} width={width} />;
  }

  if (IconComponent) {
    return <IconComponent size={height || 24} {...rest} />;
  }

  if (fallBack === "Grid4x4") {
    return <IconGrid4x4 height={height} width={width} />;
  }

  return null;
};
