import CssBaseline from "@mui/material/CssBaseline";

// routing
import Routes from "src/routes";

// stores

// defaultTheme

// project imports
import NavigationScroll from "src/layout/NavigationScroll";

// auth provider
import { LoadingApps } from "src/components/BXUI/NoApps/LoadingApps";
import { JWTProvider, registerDeviceToken } from "src/contexts/JWTContext";
import { BXContextProvider, useBXContext } from "./BXEngine/BXContext";
import SnackbarUtilsConfigure from "./utils/SnackbarUtilsConfigurator";
// ==============================|| APP ||============================== //

//ci
import { StyledEngineProvider } from "@mui/styled-engine";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axiosServices from "src/utils/axios";
import { StateProvider } from "./contexts/StateProvider";
import PageError from "./PageError";
import { appColor } from "./themes/colors";
import ThemeProviderWrapper from "./themes/ThemeProviderWrapper";
import CustomSnackbarProvider from "./utils/CustomSnackbarProvider";
import { decompressData } from "./utils/services";

const whiteListHosts = ["localhost:3000", process.env.REACT_APP_ADMIN_HOST];

const splitHost = window.location.host.split(".");
const orgName = splitHost[0];
splitHost.shift();
const hostWithoutOrgName = splitHost.join(".");

export const isHostWithoutOrgAvailable = whiteListHosts.includes(hostWithoutOrgName);
export const isHostAvailable = whiteListHosts.includes(window.location.host) || isHostWithoutOrgAvailable;
const CheckFQDN = ({ setIsLoading, setError, setFQDNApp }: any) => {
  const { setFqdnApp } = useBXContext();

  const configureApps = async () => {
    await registerDeviceToken();
    axiosServices
      .get("/app/fqdn", {
        params: {
          fqdn: window.location.origin,
          skipInterceptors: true,
        },
      })
      .then(({ data }) => {
        if (data?.appConfig) {
          data.appConfig = decompressData(data?.appConfig);
        }
        if (data?.upTemplateConfig) {
          data.upTemplateConfig = decompressData(data?.upTemplateConfig);
        }

        setFQDNApp(data);
        const { background, paper, textPrimary, primaryLight, primaryMain } = data?.appConfig?.theme || {};
        if (background) appColor.background = background;
        if (paper) appColor.paper = paper;
        if (textPrimary) appColor.textPrimary = textPrimary;
        if (primaryLight) appColor.primaryLight = primaryLight;
        if (primaryMain) appColor.primaryMain = primaryMain;
        if (data.name) {
          document.title = data.name;
        }
        if (data?.icon) {
          const favicon = document.getElementById("favicon") as any;
          favicon.href = data.icon;
        }
        setFqdnApp(data);
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      !isHostAvailable &&
      (process.env.REACT_APP_WITH_ADMIN_LOGIN ? window.location.pathname != "/admin/login" && !localStorage.getItem("admin-login") : true)
    ) {
      configureApps();
    } else {
      document.title = process.env.REACT_APP_NAME!;

      setIsLoading(false);
    }
  }, []);

  return <></>;
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loginFlag, setLoginFlag] = useState<any>();
  const [fqdnApp, setFQDNApp] = useState<any>();
  const [error, setError] = useState<any>();

  const onFocus = () => {
    if (
      !isHostAvailable &&
      (process.env.REACT_APP_WITH_ADMIN_LOGIN ? window.location.pathname != "/admin/login" && !localStorage.getItem("admin-login") : true)
    ) {
      document.title = fqdnApp?.name || process.env.REACT_APP_NAME!;
    } else {
      document.title = process.env.REACT_APP_NAME!;
    }
  };

  const getOrgDetails = async () => {
    setIsLoading(true);
    await registerDeviceToken();
    axiosServices
      .get(`/organization/domain/${orgName}`)
      .then(({ data }) => {
        if (data?.id) {
          localStorage.setItem("orgId", data?.id);
        }
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (fqdnApp || isHostAvailable || localStorage.getItem("admin-login")) {
      window.addEventListener("focus", onFocus);
    }
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [fqdnApp]);

  useEffect(() => {
    if (isHostWithoutOrgAvailable) {
      getOrgDetails();
    }
  }, []);

  if (error) {
    return <PageError error={error} />;
  }

  return (
    <StateProvider>
      <BXContextProvider setLoginFlag={setLoginFlag}>
        {isLoading ? (
          <CheckFQDN setIsLoading={setIsLoading} setError={setError} setFQDNApp={setFQDNApp} />
        ) : (
          <JWTProvider loginFlag={loginFlag}>
            <StyledEngineProvider injectFirst>
              <CustomSnackbarProvider>
                <ThemeProviderWrapper>
                  <CssBaseline />

                  <DndProvider backend={HTML5Backend}>
                    <NavigationScroll>
                      <>
                        <SnackbarUtilsConfigure />
                        <LoadingApps />
                        <Routes />
                      </>
                    </NavigationScroll>
                  </DndProvider>
                </ThemeProviderWrapper>
              </CustomSnackbarProvider>
            </StyledEngineProvider>
          </JWTProvider>
        )}
      </BXContextProvider>
    </StateProvider>
  );
};

export default App;
