import { Divider } from "@mui/material";
import _ from "lodash";
import { FC } from "react";
import { BXEngine } from "src/BXEngine";
import { useBXContext } from "src/BXEngine/BXContext";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";

const UserInput: FC<any> = props => {
  const {
    queryKeys,
    pageId,
    setCurrentActionEndpoint,
    setDataEntry,
    userInputView,
    __data,
    refetch,
    views,
    setCurrentAction,
    viewName,
    hideViewName,
  } = props;

  const { currentApp, viewsState, setViewsState } = useBXContext();
  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({
    viewName: userInputView?.info?.viewName,
  });
  return (
    <>
      {!hideViewName && <Divider sx={{ marginTop: 1 }} />}
      <BXEngine
        auth={{}}
        path={props.path}
        pageId={pageId}
        isVisible
        page={{ views, layout: userInputView, id: pageId } as any}
        layout={[{ ...(userInputView as any), type: userInputView?.type }]}
        __data={__data}
        disableInternalAction={true}
        onActionClick={(userInputsValues: any, action: any) => {
          setCurrentActionEndpoint(
            replaceDataPlaceholders({
              queryString: action?.source,
              viewsState,
              pageId,
              __data,
              env: currentApp?.env,
              formData: () => userInputsValues,
              fallback: "",
              viewName: userInputView?.info?.viewName,
            })
          );
          setViewsState((prev: any) => {
            const data = prev;
            data[queryKeys[0]] = {
              ...data[queryKeys[0]],
              userInputs: userInputsValues,
            };
            return data;
          });
          setTimeout(() => {
            refetch({ queryKey: [userInputsValues] });
          }, 0);
          setDataEntry(action?.dataEntry);

          setCurrentAction?.(
            replaceDataPlaceholdersRecursively({
              obj: _.cloneDeep(action),
              viewsState,
              pageId,
              __data,
              env: currentApp?.env,
              formData: () => userInputsValues,
              fallback: "",
              viewName: userInputView?.info?.viewName,
            })
          );
        }}
      />
    </>
  );
};

export default UserInput;
