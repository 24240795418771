import { yupResolver } from "@hookform/resolvers/yup";
import MonaciEditor from "@monaco-editor/react";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, FormHelperText, Grid, InputLabel, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { FC, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";

type CreatePolicyFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  editing?: boolean;
  policy?: any;
  height?: string | number;
  withoutPassword?: boolean;
  onlyPassword?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};
export const CreatePolicyForm: FC<CreatePolicyFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  policy,
  editing = false,
  height,
  withoutPassword = false,
  onlyPassword = false,
  setIsDirty,
}) => {
  const schema = yup
    .object({
      name: yup.string().required(),
      id: yup.string(),
      orgId: yup.string(),
      description: yup.string(),
      statements: yup.string(),
    })
    .required();

  const defaultValues = {
    id: policy?.id,
    name: policy?.name,
    orgId: policy?.orgId,
    description: policy?.description,
    statements: JSON.stringify(policy?.statements),
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    getValues,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: editing
      ? defaultValues
      : {
          statements: JSON.stringify([
            {
              action: ["*"],
              effect: "ALLOW",
              resources: ["*"],
            },
          ]),
        },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useCallbackPrompt(isDirty);
  const [codeEditorErrors, setCodeEditorErrors] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState<string>("code");
  return (
    <>
      <DialogContent>
        <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
          <Grid container spacing={3} height={height}>
            <Grid item xs={12}>
              <BXInput
                name={"name"}
                control={control}
                label='Policy name'
                variant='outlined'
                error={errors.name}
                id={"bx-policy-create-name-input"}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <BXInput
                name={"description"}
                control={control}
                label='Policy description'
                variant='outlined'
                error={errors.description}
                id={"bx-policy-create-description-input"}
                multiline
                rows={2}
              />
            </Grid>
            <Grid container padding={3}>
              <Grid item xs={12} marginBottom={2}>
                <InputLabel>Policy statements</InputLabel>
              </Grid>
              <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
                  <Tabs
                    value={tabIndex}
                    onChange={(e, value) => {
                      setTabIndex(value);
                    }}
                  >
                    <Tab value={"code"} label='Policy Editor' />
                    <Tab value={"help"} label='Help' />
                  </Tabs>
                </Box>
                <TabPanel value={"code"} style={{ width: "100%" }}>
                  <MonaciEditor
                    value={typeof getValues().statements === "string" ? getValues().statements : JSON.stringify(getValues().statements)}
                    onChange={(value, e) => {
                      setValue("statements", value);
                    }}
                    height={200}
                    onValidate={markers => {
                      setCodeEditorErrors(markers.map(marker => marker.message).join(", "));
                    }}
                    onMount={editor => {
                      setTimeout(function () {
                        editor?.getAction("editor.action.formatDocument")?.run();
                      }, 300);
                    }}
                    language='json'
                    theme='vs-dark'
                    beforeMount={monaco => {
                      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                        validate: true,
                        schemaValidation: "error",
                        schemas: [
                          {
                            uri: "http://myserver/foo-schema.json",
                            fileMatch: ["*"],
                            schema: {
                              id: "Statements",
                              type: "array",
                              description: "List if statements",
                              items: {
                                id: "Statement",
                                type: "object",
                                properties: {
                                  action: {
                                    id: "StatementActions",
                                    type: "array",
                                    items: {
                                      id: "StatementAction",
                                      type: "string",
                                      pattern: /^[a-zA-Z0-9./*]+$/,
                                    },
                                    uniqueItems: true,
                                    minItems: 1,
                                  },
                                  effect: {
                                    id: "StatementEffect",
                                    type: "string",
                                    enum: ["ALLOW", "DENY"],
                                  },
                                  resources: {
                                    id: "StatementResources",
                                    type: "array",
                                    items: {
                                      id: "StatementResource",
                                      type: "string",
                                      pattern: /^[a-zA-Z0-9./*-\s]+$/,
                                      message: {
                                        pattern: "Action cannot be an empty string",
                                      },
                                    },
                                    uniqueItems: true,
                                    minItems: 1,
                                  },
                                },
                                required: ["action", "effect", "resources"],
                                additionalProperties: false,
                              },
                            },
                          },
                        ],
                      });
                    }}
                  />
                  {codeEditorErrors && <FormHelperText error>{codeEditorErrors}</FormHelperText>}
                </TabPanel>
                <TabPanel value={"help"} style={{ width: "100%" }}>
                  <ul>
                    <li>
                      Give permission to user to perform all actions on all resources
                      <code>{JSON.stringify([{ effect: "ALLOW", action: ["*"], resources: ["*"] }], null, 2)}</code>
                    </li>
                    <li>
                      Deny permission
                      <code>
                        {JSON.stringify(
                          [
                            {
                              action: ["*"],
                              effect: "DENY",
                              resources: ["Zoomie Staging.users.all-users.test-canvas.flex", "Zoomie Staging.users.all-users.test-video"],
                            },
                          ],
                          null,
                          2
                        )}
                      </code>
                    </li>
                  </ul>
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ paddingInlineStart: 24, paddingInlineEnd: 24, paddingTop: 0 }}>
        <Button disabled={Boolean(codeEditorErrors)} onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
