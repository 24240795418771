export const ErrorList = [
    {
        key: 'FILE_DISCARD_ERROR',
        desc: 'Discarding file due to unknown error'
    },
    {
        key: 'FILE_COUNT_LIMIT_ERROR',
        desc: 'Maximum number of allowed uploads reached'
    },
    {
        key: 'FETCH_PRESIGN_URL_ERROR',
        desc: 'Failed to fetch Presigned File URL'
    },
    {
        key: 'PUT_S3_UPLOAD_ERROR',
        desc: 'Failed to PUT file to S3 via URL'
    },
    {
        key: 'POST_FINAL_URL_ERROR',
        desc: 'Failed to fetch final Asset URL'
    },
    {
        key: 'FILE_UPLOAD_ERROR',
        desc: 'Failed to upload new Asset'
    },
    {
        key: 'API_INVALID_PREFETCH_URI_RESPONSE',
        desc: 'Invalid Prefetch Signing Response'
    },
    {
        key: 'API_INVALID_S3_UPLOAD_RESPONSE',
        desc: 'Invalid S3 Upload Response Received'
    },
    {
        key: 'API_INVALID_FINAL_URI_RESPONSE',
        desc: 'Invalid final URI Response Received'
    }
];

export const ErrorDefault = {
    key: 'UNKNOWN_ERROR',
    desc: 'Some unknown error occured',
    isDefaultFallback: true
};

export const ErrorHelper = {
    findError: (key: string): { [key: string]: any } => ErrorList.find((error) => error.key === key) || ErrorDefault,

    throwError: (key: string, value?: string) => {
        throw ErrorHelper.getError(key, value);
    },

    getError: (key: string = '', value?: string) => {
        const obj = ErrorHelper.findError(key);
        if (obj?.isDefaultFallback) {
            obj.desc = value || obj.desc;
        }
        return new CustomError(obj.desc, obj.key);
    },

    getDefaultMsg: (): string => ErrorDefault.desc,

    findErrorKey: (key: string): string => ErrorList.find((error) => error.key === key)?.key || ErrorDefault.key,
    findErrorDesc: (key: string): string => ErrorList.find((error) => error.key === key)?.desc || ErrorDefault.desc
};

export class CustomError extends Error {
    constructor(message: string, name?: string) {
        super(message);
        this.name = name || 'Custom Exception';
        this.message = message;
    }
}
