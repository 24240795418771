import { useCallback } from "react";
import { BXPermissionList } from "src/BXEngine/types";
import { checkPermissions as checkPermissionsFunc } from "./check-permissions.function";
import { UseCheckPermissions } from "./models/use-check-permissions";

export function useCheckPermissions(getCurrentPermissions: BXPermissionList): UseCheckPermissions {
  const checkPermissions = useCallback(
    (permissions, isAllowed) => {
      const result = checkPermissionsFunc(getCurrentPermissions, permissions, isAllowed);
      return result;
    },
    [getCurrentPermissions]
  );
  return { checkPermissions };
}
