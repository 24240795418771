import React from "react";
import ReactDOM from "react-dom";

// third party
import { BrowserRouter } from "react-router-dom";

// load mock apis
// import '_mockApis';
// project imports
import { Provider } from "react-redux";
import App from "src/App";
import config from "src/config";
import * as serviceWorker from "src/serviceWorker";

import axios from "src/utils/axios";
import "./index.css";

// style + assets
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import store from "./store/store";

// ==============================|| REACT DOM RENDER  ||============================== //

// ci
axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common["Content-Type"] = "application/json";

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
