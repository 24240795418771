/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Divider, TextField, Theme, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FC, createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useNavigate, useParams } from "react-router-dom";
import { AcceptsTargetTypes } from "src/BXEngine";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { useBXContext } from "src/BXEngine/BXContext";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { v4 as uuid } from "uuid";
import { useWindowSize } from "../../../../hooks/useScreenSize";
import BoxComponent from "./BoxComponent";

import axios, { AxiosResponse } from "axios";
import _, { isArray } from "lodash";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { CustomDragLayer } from "./CustomDragLayer";
import { LeftSideMenuItems } from "./LeftSideMenuItems";
import { RightSideMenuItems } from "./RightSideMenuComponent";
import ViewBuilderHeader from "./ViewBuilderHeader";
import { ContainerGrid, CustomMediaCard } from "./components";
import { CustomAccordion } from "./components/CustomAccordion";
import { CustomAutocomplete } from "./components/CustomAutocomplete";
import { CustomCheckbox } from "./components/CustomCheckboxGroup/CustomCheckboxGroup";
import { CustomContainer } from "./components/CustomContainer";
import { CustomIconButton } from "./components/CustomIconButton";
import { CustomImage } from "./components/CustomImage";
import { DatePicker } from "./components/DatePicker";

import { LoadingButton } from "@mui/lab";
import { BXSelect } from "src/components/BXUI/SelectInput";
import { ReactMouseSelect, TFinishSelectionCallback } from "src/components/ReactMouseSelect";
import { ResizeElement } from "src/components/ResizeElement";
import { BXApp, ILayout } from "src/types/BXAppType";
import { BXPageType } from "src/types/BXPageType";
import { toCamelCase, updateElementsIds } from "src/utils/generalUtils";
import { BXLayout } from "./BXLayout";
import { BXNavBar } from "./BXNavBar";
import { BXSideBar } from "./BXSideBar";
import { BXView } from "./BXView";
import ResizableContainer from "./ResizableContainer";
import { CircularChart } from "./components/CircularChart";
import { ColorPicker } from "./components/ColorPicker";
import { ColumnChart } from "./components/ColumnChart";
import { CustomAvatar } from "./components/CustomAvatar/CustomAvatar";
import { CustomChip } from "./components/CustomChip";
import { CustomRadio } from "./components/CustomRadioGroup";
import { CustomSwitch } from "./components/CustomSwitch";
import { CustomTabs } from "./components/CustomTabs";
import { Typography } from "./components/CustomTypography";
import { DateTimePicker } from "./components/DateTimePicker";
import { FileUploadInput } from "./components/FileUploadInput";
import { JsonViewer } from "./components/JsonViewer";
import { MarkdownViewer } from "./components/MarkdownViewer";
import { PieChart } from "./components/PieChart";
import { StepperNavigator } from "./components/StepperNavigator";
import { CustomTimePicker } from "./components/TimePicker/CustomTimePicker";
import { ComponentItemType } from "./types";
import {
  addChildrenToObjectId,
  addFlexCanvas,
  findIdsInNestedArrays,
  findObjectsByIds,
  flattenList,
  getDirectParentChildren,
  getItemClosestProp,
  getLastElementPositionX,
  getLastElementPositionY,
  getNewPosition,
  getRootParent,
  isParentFlex,
  isParentGrid,
  layoutBreakPointsValues,
  populateViews,
  removeChildrenFromObject,
  removeFlexCanvas,
  setParents,
  snapToStepperGrid,
  updateChildrenAtLevelN,
  updateStepperGroupsChildren,
} from "./utils";

export const MUI_COMPONENTS: any = {
  TextField: styled(TextField)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  Button: styled(LoadingButton)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
    overflow: hidden;
  `,
  CustomIconButton: styled(CustomIconButton)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  CustomChip: styled(CustomChip)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  CustomTabs: styled(CustomTabs)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  CustomAccordion: styled(CustomAccordion)`
    min-width: 0 !important;
    min-height: 0 !important;
    padding: 0;
  `,
  Typography: styled(Typography)``,
  Avatar: styled(CustomAvatar)``,
  Divider: styled(Divider)``,
  CustomSwitch: styled(CustomSwitch)``,
  CustomCheckbox: styled(CustomCheckbox)``,
  CustomRadio: styled(CustomRadio)``,
  CustomImage: styled(CustomImage)``,
  CustomAutocomplete: styled(CustomAutocomplete)``,
  CustomMediaCard: styled(CustomMediaCard)``,
  FileUploadInput: styled(FileUploadInput)``,
  CustomContainer: styled(CustomContainer)``,
  FlexContainer: styled(CustomContainer)``,
  GridContainer: styled(CustomContainer)``,
  StepperContainer: styled(CustomContainer)``,
  DatePicker: styled(DatePicker)``,
  DateTimePicker: styled(DateTimePicker)``,
  TimePicker: styled(CustomTimePicker)``,
  PieChart: styled(PieChart)``,
  ColumnChart: styled(ColumnChart)``,
  CircularChart: styled(CircularChart)``,
  Select: styled(BXSelect)``,
  JsonViewer: styled(JsonViewer)``,
  StepperNavigator: styled(StepperNavigator)``,
  MarkdownViewer: styled(MarkdownViewer)``,
  ColorPicker: styled(ColorPicker)``,
  BXView: styled(BXView)``,
  BXLayout: styled(BXLayout)``,
  BXSideBar: styled(BXSideBar)``,
  BXNavBar: styled(BXNavBar)``,
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: { position: "fixed", top: 24, zIndex: 1100 },
  },
}));

type FormBuilderEditorProps = {
  params?: {
    _currentApp?: BXApp;
    appId?: string;
    collectionId?: string;
    pageId?: string;
    viewId?: string;
    templateId?: string;
    historyId?: any;
    layoutId?: any;
  };
  layout?: ILayout;
  pageBuilderMode?: boolean;
  appBuilderMode?: boolean;
  page?: BXPageType;
  onSave?: (data: any, onSuccess?: () => void) => Promise<any>;
  onBackClick?: () => void;
};

export const HeightContext = createContext({ boxHeight: {} });
const FormBuilderEditor: FC<FormBuilderEditorProps> = ({
  params,
  layout,
  pageBuilderMode = false,
  appBuilderMode = false,
  page,
  onSave,
  onBackClick,
}) => {
  const { apps, editView, getTemplateById, getTemplateByHistoryId, saveAsTemplate } = useBXBuilderContext();
  const paramsValues = useParams();
  const popoverMode = Number(new URLSearchParams(useLocation().search).get("popoverMode")) || 0;
  const { appId, collectionId, pageId, viewId, templateId, historyId, layoutId } = params || paramsValues;

  const navigate = useNavigate();
  const classes = useStyles();
  const [view, setView] = useState<any>([]);
  const [views, setViews] = useState<any>([]);
  const [cursor, setCursor] = useState<number>(-1);
  const [boxSize, setBoxSize] = useState<any>({});
  const [activeComponent, setActiveComponent] = useState<any>();
  const [boxHeight, setBoxHeight] = useState<any>(view?.height || {});
  const [boxWidth, setBoxWidth] = useState<any>(view?.width || { xs: 600 });
  const [layoutBreak, setLayoutBreak] = useState<any>("lg");
  const [hasLayoutReset, setHasLayoutReset] = useState<boolean>(false);
  const factorsRef = useRef<any>({});
  const [isKeyboardActive, setIsKeyboardActive] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [innerComponentDropping, setInnerComponentDropping] = useState(false);
  const [canDrops, setCanDrop] = useState(false);
  const boxRef = useRef<any>();
  const [value, setValue] = useState(0);
  const [leftTabValue, setLeftTabValue] = useState(0);
  const [keyCode, setKeyCode] = useState<string>();
  const [keyboardStep, setKeyboardStep] = useState(1);
  const [mouseStep, setMouseStep] = useState(10);
  const [selectedItemsId, setSelectedItemsId] = useState<string[]>([]);
  const [copyItems, setCopyItems] = useState<any[]>([]);
  const [hiddenPercentage, setHiddenPercentage] = useState(0);
  const [multiLingual, setMultiLingual] = useState<any>();
  const [zoom, setZoom] = useState(1);
  const zoomRef = useRef<any>(1);
  const boxSizeRef = useRef<any>({});
  const layoutBreakRef = useRef<any>("xs");
  const isBackOrForward = useRef<any>(false);
  const historyRef = useRef<any[]>([]);
  const saveButtonClickedRef = useRef(false);
  const [openStates, setOpenStates] = useState({});

  const { currentApp, isRTL } = useBXContext();

  const dynamicHeight = !!view?.dataSource?.formBuilderConfig?.isDynamicHeight;
  const flexCanvasEnabled = view?.dataSource?.formBuilderConfig?.isFlexCanvasEnabled;
  const stepperEnabled = !!view?.dataSource?.formBuilderConfig?.isStepperEnabled;
  const canvasEnabled = !!view?.dataSource?.formBuilderConfig?.isCanvasGrid;
  const canvasFullHeight = !!view?.dataSource?.formBuilderConfig?.isCanvasFullHeight;
  const SyncTreeWithCanvasEnabled = !!view?.dataSource?.formBuilderConfig?.isSyncTreeWithCanvasEnabled;

  const { width, scrollX, scrollY } = useWindowSize();

  useEffect(() => {
    if (!isBackOrForward.current && !saveButtonClickedRef.current && view.config) {
      historyRef.current = historyRef.current.slice(0, cursor + 1);
      historyRef.current.push(_.cloneDeep(view));

      setCursor(prevCursor => prevCursor + 1);
    } else {
      isBackOrForward.current = false;
    }
  }, [view]);

  const stepBack = useCallback(() => {
    if (cursor > 0) {
      setCursor(prevCursor => {
        const newCursor = prevCursor - 1;
        isBackOrForward.current = true;
        setView(_.cloneDeep(historyRef.current[newCursor]));
        return newCursor;
      });
    }
  }, [cursor]);

  const stepForward = useCallback(() => {
    if (cursor < historyRef.current.length - 1) {
      setCursor(prevCursor => {
        const newCursor = prevCursor + 1;
        isBackOrForward.current = true;
        setView(_.cloneDeep(historyRef.current[newCursor]));
        return newCursor;
      });
    }
  }, [cursor]);

  useEffect(() => {
    if (appId) {
      axios.get(`${process.env.REACT_APP_WITH_BUCKET_ENDPOINT}/i18n/${appId}.json`).then(({ data }) => {
        setMultiLingual(data);
      });
    }
  }, []);

  useEffect(() => {
    if (currentApp) {
      const _views = populateViews(currentApp, collectionId, pageId);
      setViews(_views);
    }
  }, [currentApp]);

  useEffect(() => {
    setTimeout(() => {
      const boxDimensions = boxRef.current?.getBoundingClientRect();
      const _boxSize = {
        width: boxDimensions?.width,
        height: boxDimensions?.height,
        y: boxDimensions?.y - scrollX,
        x: boxDimensions?.x - scrollX,
      };
      setBoxSize(_boxSize);
      boxSizeRef.current = _boxSize;
    }, 0);

    const zoomValue = parentContainerRef.current?.clientWidth
      ? parentContainerRef.current?.clientWidth / layoutBreakPointsValues[layoutBreak]
      : 1;

    // const zoomValue =
    //   parentContainerRef.current?.clientWidth && parentContainerRef.current?.clientWidth < layoutBreakPointsValues[layoutBreak]
    //     ? parentContainerRef.current?.clientWidth / layoutBreakPointsValues[layoutBreak]
    //     : 1;

    setZoom(zoomValue);
    zoomRef.current = zoomValue;
  }, [layoutBreak, width, boxHeight, scrollX, scrollY]);
  const handleCanvasToggle = useCallback(() => {
    setView((prev: any) => {
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilderConfig: {
            ...prev?.dataSource?.formBuilderConfig,
            isCanvasGrid: !prev?.dataSource?.formBuilderConfig?.isCanvasGrid,
          },
        },
      };
    });
  }, []);

  const onStepperToggle = useCallback(() => {
    setView((prev: any) => {
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilderConfig: {
            ...prev?.dataSource?.formBuilderConfig,
            isStepperEnabled: !prev?.dataSource?.formBuilderConfig?.isStepperEnabled,
          },
        },
      };
    });
  }, []);

  const handleChangePropConfigTreeMenu = useCallback(() => {
    setView(prev => ({
      ...prev,
      dataSource: {
        ...prev.dataSource,
        formBuilderConfig: {
          ...prev.dataSource.formBuilderConfig,
          isSyncTreeWithCanvasEnabled: !prev?.dataSource?.formBuilderConfig?.isSyncTreeWithCanvasEnabled,
        },
      },
    }));
  }, []);

  const handleFlexCanvasToggle = () => {
    if (!flexCanvasEnabled) {
      addFlexCanvas(setView, elements, boxSize, boxHeight, layoutBreak);
    } else {
      removeFlexCanvas(setView);
    }
    setView((prev: any) => {
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilderConfig: {
            ...prev?.dataSource?.formBuilderConfig,
            isFlexCanvasEnabled: !prev?.dataSource?.formBuilderConfig?.isFlexCanvasEnabled,
          },
        },
      };
    });
  };

  const handleDynamicHeightToggle = useCallback(() => {
    setView((prev: any) => {
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilderConfig: {
            ...prev?.dataSource?.formBuilderConfig,
            isDynamicHeight: !prev?.dataSource?.formBuilderConfig?.isDynamicHeight,
          },
        },
      };
    });
  }, []);

  const handleCanvasFullHeightToggle = useCallback(() => {
    setView((prev: any) => {
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilderConfig: {
            ...prev?.dataSource?.formBuilderConfig,
            isCanvasFullHeight: !prev?.dataSource?.formBuilderConfig?.isCanvasFullHeight,
          },
        },
      };
    });
  }, []);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }, []);

  const handleChangeLeftTab = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setLeftTabValue(newValue);
  }, []);

  const elements = view?.dataSource?.formBuilder;

  useEffect(() => {
    if (apps?.length && appId && collectionId && pageId && viewId) {
      const app = apps?.find(app => app?.id === appId);
      const collection = app?.templateConfig?.collections?.find(collection => collection?.id == collectionId);

      const page = collection?.pages?.find(page => page?.id === pageId);
      let view = page?.views?.find(view => view?.id === viewId);
      const viewWithParents = {
        ...view,
        dataSource: { ...view?.dataSource, formBuilder: setParents(view?.dataSource?.formBuilder as any[]) },
      };
      setView(viewWithParents);
    }

    if (pageBuilderMode) {
      const viewWithParents = {
        ...page,
        type: "form-builder",
        dataSource: { ...page?.dataSource, formBuilder: setParents(page?.dataSource?.formBuilder as any[]) },
      };
      setView(viewWithParents);
    }

    if (appBuilderMode) {
      const viewWithParents = {
        ...layout,
        type: "form-builder",
        dataSource: { ...layout?.dataSource, formBuilder: setParents(layout?.dataSource?.formBuilder as any[]) },
      };
      setView(viewWithParents);
    }
  }, [currentApp, apps?.length]);

  const moveMultipleBoxes = (data: any) => {
    const elementsIds = data?.map((el: any) => el?.item?.id) || [];
    const oldElements = view?.dataSource?.formBuilder;
    const newElements = data?.map((el: any) => {
      const { item, ...rest } = el || {};
      const newItem = { ...item, ...rest };
      if (newItem?.id) {
        return newItem;
      } else {
        const camelCaseType = newItem?.type ? toCamelCase(newItem.type) : "";

        return {
          id: uuid(),
          ...newItem,
          props: {
            ...newItem?.props,
            id: `${camelCaseType}${view?.dataSource?.formBuilder?.length || 0}`,
            key: `${camelCaseType}${view?.dataSource?.formBuilder?.length || 0}`,
            testId: "",
          },
        };
      }
    });

    const updatedElements = oldElements?.map((oldElement: any) => {
      const matchingNewElement = newElements.find((newElement: any) => newElement.id === oldElement.id);
      return matchingNewElement ? { ...matchingNewElement } : { ...oldElement };
    });

    const remainingNewElements = newElements?.filter((newElement: any) => {
      return !updatedElements.some((updatedElement: any) => updatedElement.id === newElement.id);
    });

    const finalElements = [...updatedElements, ...remainingNewElements];

    setView((prev: any) => {
      const oldObject = elementsIds?.[0] && removeChildrenFromObject(prev?.dataSource?.formBuilder, elementsIds);

      const newFormBuilder = setParents(elementsIds?.[0] ? [...oldObject, ...newElements] : finalElements);
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: newFormBuilder,
        },
        stepperGroups: updateStepperGroupsChildren(newFormBuilder, prev?.stepperGroups),
      };
    });
  };

  const moveMultipleKeyboardBoxes = (data: any) => {
    let oldObject = view?.dataSource?.formBuilder;
    data?.map((el: any) => {
      if (isParentFlex(el) || isParentGrid(el)) return;

      const { item, ...rest } = el || {};

      const newItem = { ...item, ...rest };
      if (newItem?.id) {
        oldObject = removeChildrenFromObject(oldObject, [newItem?.id]);
        oldObject = newItem?.config?.parent?.id
          ? addChildrenToObjectId(oldObject, newItem?.config?.parent?.id, [newItem])
          : [...oldObject, newItem];
      } else {
        const camelCaseType = newItem?.type ? toCamelCase(newItem.type) : "";

        const newId = uuid();
        const addedItem = {
          id: newId,
          ...newItem,
          props: {
            ...newItem?.props,
            id: `${camelCaseType}${newId}`,
            key: `${camelCaseType}${newId}`,
            testId: "",
          },
        };
        oldObject = [...oldObject, addedItem];
      }
    });

    const newFormBuilder = setParents(oldObject);
    setView((prev: any) => {
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: newFormBuilder,
        },
        stepperGroups: updateStepperGroupsChildren(newFormBuilder, prev?.stepperGroups),
      };
    });
  };
  const moveBox = useCallback(
    (data: any) => {
      const { item, ...rest } = data || {};

      const newItem = { ...item, ...rest };
      setView((prev: any) => {
        let elements: any[] = [];
        if (newItem?.id) {
          elements = prev?.dataSource?.formBuilder?.map((el: any, index: any) => (el.id === newItem.id ? { ...newItem } : el));
        } else {
          const camelCaseType = newItem?.type ? toCamelCase(newItem.type) : "";

          elements = [
            ...(prev?.dataSource?.formBuilder || []),
            {
              id: uuid(),
              ...newItem,
              props: {
                ...newItem?.props,
                id: `${camelCaseType}${view?.dataSource?.formBuilder?.length || 0}`,
                key: `${camelCaseType}${view?.dataSource?.formBuilder?.length || 0}`,
              },
            },
          ];
        }
        return {
          ...prev,
          dataSource: {
            ...prev?.dataSource,
            formBuilder: setParents(elements),
          },
        };
      });
    },
    [view?.dataSource?.formBuilder?.length]
  );

  const getCanvasDimension = () => {
    setTimeout(() => {
      const _layoutBreak = layoutBreakRef.current || layoutBreak;

      const newHeight = getLastElementPositionY(elements, boxSizeRef.current, boxHeight, _layoutBreak);
      const newWidth = getLastElementPositionX(elements, boxSizeRef.current, boxWidth, _layoutBreak);

      setBoxHeight(newHeight);
      setBoxWidth(newWidth);

      setView((prev: any) => {
        prev.height = newHeight;
        prev.width = newHeight;

        return prev;
      });
    }, 300);
  };

  const canDropComponent = (monitor?: DropTargetMonitor<any, unknown>, item?: any, newPosition?: any) => {
    const components = monitor?.getItem()?.length ? monitor?.getItem() : monitor ? [monitor?.getItem()] : item?.length ? item : [item];

    const isValid = components?.every((el: any) => {
      let dy, dx;
      const component = el?.config;
      if (monitor && el?.id) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const itemX = (getItemClosestProp(el?.leftPercentage, layoutBreak)?.replace("%", "") / 100) * boxSize?.width || newPosition?.x;
        const itemY = getItemClosestProp(el?.top, layoutBreak) || newPosition?.y;
        const itemWidth =
          (Number(getItemClosestProp(component?.widthPercentage, layoutBreak)?.replace("%", "")) / 100) * boxSize?.width ||
          component?.defaultWidth;
        const itemHeight = Number(getItemClosestProp(component?.heightPx, layoutBreak)) || component?.defaultHeight;
        dx = (newPosition?.x || itemX) + delta?.x! > 0 && boxSize?.width > itemX + delta?.x + itemWidth;
        dy = (newPosition?.y || itemY) + delta?.y! > 0 && boxSize?.height > itemY + delta?.y + itemHeight;
      } else {
        const delta = monitor ? monitor.getSourceClientOffset() : document.getElementById(el?.id)?.getBoundingClientRect();
        dx =
          boxSize?.x < Number(delta?.x + (newPosition?.x || 0)) &&
          Number(delta?.x + (newPosition?.x || 0)) <
            boxSize?.x +
              boxSize?.width -
              ((Number(getItemClosestProp(component?.widthPercentage, layoutBreak)?.replace("%", "")) / 100) * boxSize?.width ||
                component?.defaultWidth);
        dy =
          boxSize?.y < Number(delta?.y + (newPosition?.y || 0)) &&
          Number(delta?.y + (newPosition?.y || 0)) <
            boxSize?.y + boxSize?.height - (Number(getItemClosestProp(component?.heightPx, layoutBreak)) || component?.defaultHeight);
      }
      return dx && dy;
    });

    setCanDrop(isValid);
    return isValid;
  };
  const moveBoxFunction = (item: any, monitor?: any, newPosition?: any, step: number = 1, isKeyboard: boolean = false) => {
    if (historyId) {
      return;
    }
    if (!isOverCurrent && !isKeyboard) {
      return;
    }

    const delta = monitor ? monitor.getSourceClientOffset() : document.getElementById(item?.id)?.getBoundingClientRect();
    if (!delta && !isKeyboard) return undefined;
    let translateX = 0;
    let translateY = 0;
    let top = 0;
    let left = 0;
    const components = item?.length ? item : [item];
    const newComponents = setParents(components);
    const updateElementConfigAndProps = (item: any) => {
      let updatedProps;
      if (item?.type === "CustomCheckbox" || item?.type === "CustomRadio") {
        updatedProps = {
          ...item?.props,
          groupName: item?.type === "CustomCheckbox" ? "checkbox-group" : "radio-group",
          singleValue: false,
          required: false,
          isChildContainerGroup: false,
          customRequiredMessageContent: "",
        };
      } else {
        updatedProps = item?.props;
      }

      return { updatedProps };
    };
    const newComponentMove = newComponents?.map((el: any) => {
      const realItem = el?.id && document.getElementById(el?.id)?.getBoundingClientRect();
      const parentRealItem = el?.config?.parent?.id && document.getElementById(el?.config?.parent?.id)?.getBoundingClientRect();
      const itemWidth = realItem?.width || el?.config?.defaultWidth;

      if (!isKeyboard && el?.id) {
        translateX = monitor?.getDifferenceFromInitialOffset()?.x / zoom;
        translateY = monitor?.getDifferenceFromInitialOffset()?.y / zoom;
        const leftValue = Number(translateX) + realItem?.x - Number(boxSize?.x);
        left = isRTL ? boxSize.width - itemWidth - leftValue : leftValue;
        top = Number(translateY) + realItem?.y - Number(boxSize?.y);
      } else if (parentRealItem && !delta) {
        translateX = ((realItem?.x - (parentRealItem?.x || 0) + (newPosition?.x || 0)) / step) * step - 1;
        translateY = ((realItem?.y - (parentRealItem?.y || 0) + (newPosition?.y || 0)) / step) * step - 1;
        const leftValue = Number(translateX + (factorsRef.current?.x || 0));
        left = isRTL ? boxSize.width - itemWidth - leftValue : leftValue;
        top = Number(translateY + (factorsRef.current?.y || 0)) + Number(el?.config?.draggingOffSet || 0);
      } else {
        translateX = (((Number(delta?.x) || realItem?.x) + (newPosition?.x || 0)) / (item?.isSideMenu ? zoom : 1) / step) * step;
        translateY = (((Number(delta?.y) || realItem?.y) + (newPosition?.y || 0)) / (item?.isSideMenu ? zoom : 1) / step) * step;
        const leftValue = Number(translateX + (factorsRef.current?.x || 0)) - Number(boxSize?.x);
        left = isRTL ? boxSize.width - itemWidth - leftValue : leftValue;
        top = Number(translateY + (factorsRef.current?.y || 0)) - Number(boxSize?.y) + Number(el?.config?.draggingOffSet || 0);
      }

      if (stepperEnabled) {
        [left, top] = snapToStepperGrid(left, top);
      }

      const leftRelative = left <= 0 ? 0 : left + itemWidth >= boxSize?.width ? boxSize?.width - itemWidth : left;
      const leftPercentage = (leftRelative / (parentRealItem?.width || boxSize.width)) * 100 + "%";

      const topRelative =
        top <= 0
          ? 0
          : top + (realItem?.height || el?.config?.defaultHeight) >= (parentRealItem?.height || boxSize?.height)
          ? (parentRealItem?.height || boxSize?.height) - (realItem?.height || el?.config?.defaultHeight)
          : top;

      const PWidth = (itemWidth / (parentRealItem?.width || boxSize?.width)) * 100;
      const percentageWidth = isNaN(PWidth) || PWidth > 100 ? "100%" : PWidth.toFixed(1) + "%";

      const PHeight = ((realItem?.height || el?.config?.defaultHeight) / (parentRealItem?.height || boxSize?.height)) * 100;
      const percentageHeight = isNaN(PHeight) || PHeight > 100 ? "100%" : PHeight.toFixed(1) + "%";

      const hasCustom = el?.config?.hasCustom;

      const { updatedProps } = updateElementConfigAndProps(el);
      return {
        item: el,
        left: { ...el?.left, xs: hasCustom ? el.left?.xs : leftRelative, [layoutBreak]: leftRelative },
        top: { ...el?.top, xs: hasCustom ? el.top?.xs : topRelative, [layoutBreak]: topRelative },
        leftPercentage: {
          ...el?.leftPercentage,
          xs: hasCustom ? el.leftPercentage?.xs : leftPercentage,
          [layoutBreak]: leftPercentage,
        },
        config: {
          ...el?.config,
          hasCustom: layoutBreak === "xs" ? true : el?.config?.hasCustom,
          widthPx: {
            ...el?.config?.widthPx,
            xs: hasCustom ? el?.config?.widthPx?.xs : Math.round(realItem?.width || el?.config?.defaultWidth),
            [layoutBreak]: Math.round(realItem?.width || el?.config?.defaultWidth),
          },
          heightPx: {
            ...el?.config?.heightPx,
            xs: hasCustom ? el?.config?.heightPx?.xs : Math.round(realItem?.height || el?.config?.defaultHeight),
            [layoutBreak]: Math.round(realItem?.height || el?.config?.defaultHeight),
          },
          widthPercentage: {
            ...el?.config?.widthPercentage,
            xs: el?.config?.widthPercentage?.xs || percentageWidth,
            [layoutBreak]: percentageWidth,
          },
          heightPercentage: {
            ...el?.config?.heightPercentage,
            xs: el?.config?.widthPercentage?.xs || percentageHeight,
            [layoutBreak]: percentageHeight,
          },
        },
        props: updatedProps,
      };
    });
    isKeyboard ? moveMultipleKeyboardBoxes(newComponentMove) : moveMultipleBoxes(newComponentMove);

    return { changeLevel: "Form" };
  };

  const [{ isOverCurrent, canDrop }, drop] = useDrop({
    accept: AcceptsTargetTypes,
    drop: (draggedItem, monitor) => {
      if (monitor?.isOver?.()) moveBoxFunction(draggedItem, monitor, undefined, mouseStep);
    },
    // canDrop: (item, monitor) => canDropComponent(monitor),
    collect: monitor => ({
      isOverCurrent: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  drop(boxRef);
  const handleBackButton = useCallback(() => {
    const popoverModeValue = popoverMode === 1 ? 1 : 0;
    if (onBackClick) {
      return onBackClick();
    }
    if (!!templateId || !!historyId) {
      navigate(`/buildx/templates`);
    } else {
      navigate(`/buildx/app?appId=${appId}&collectionId=${collectionId}&pageId=${pageId}&viewId=${viewId}&popoverMode=${popoverModeValue}`);
    }
  }, []);

  const handleChangeLayout = (value: string | null) => {
    setLayoutBreak(value);
    layoutBreakRef.current = value;
    getCanvasDimension();
  };

  const handleChangeComponentProps = useCallback((newItem: any) => {
    const selectedValue = newItem?.selectedType;
    setView((prev: any) => {
      const formBuilder = prev?.dataSource?.formBuilder || [];
      if (!newItem?.config?.parent) {
        const elements = prev?.dataSource?.formBuilder?.map((el: any) => (el.id === newItem.id ? newItem : el));
        const newFormBuilder = setParents(elements);
        return {
          ...prev,
          dataSource: {
            ...prev?.dataSource,
            formBuilder: newFormBuilder,
          },
          stepperGroups: updateStepperGroupsChildren(newFormBuilder, prev?.stepperGroups),
        };
      }
      const directParentChildren = getDirectParentChildren(formBuilder, newItem.config.parent);
      const { level } = getRootParent(newItem) as any;
      const childIndex = directParentChildren.findIndex(el => el.id === newItem.id);
      if (childIndex === -1) return prev;
      const coppiedStateArray = [...directParentChildren];
      coppiedStateArray[childIndex] = newItem;

      const newFormBuilder = updateChildrenAtLevelN(
        prev.dataSource.formBuilder,
        newItem.id,
        newItem.config.parent.id,
        level - 1,
        coppiedStateArray
      );
      return {
        ...prev,
        dataSource: {
          ...prev.dataSource,
          formBuilder: newFormBuilder,
        },
        stepperGroups: updateStepperGroupsChildren(newFormBuilder, prev?.stepperGroups),
      };
    });
  }, []);

  const handleChangeViewProps = useCallback((newItem: any) => {
    setView((prev: any) => {
      const newFormBuilder = setParents(prev?.dataSource?.formBuilder);
      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: newFormBuilder,
        },
        stepperGroups: updateStepperGroupsChildren(newFormBuilder, prev?.stepperGroups),
      };
    });
  }, []);

  const handleDeleteItem = useCallback((itemIds: any[]) => {
    if (itemIds.length === 1) {
      setView((prev: any) => {
        const newFormBuilder = setParents(setParents(removeChildrenFromObject(prev?.dataSource?.formBuilder, [itemIds[0]])));

        return {
          ...prev,
          dataSource: {
            ...prev?.dataSource,
            formBuilder: newFormBuilder,
          },
          stepperGroups: updateStepperGroupsChildren(newFormBuilder, prev?.stepperGroups),
        };
      });
    } else if (itemIds.length > 1) {
      setView((prev: any) => {
        const newFormBuilder = setParents(removeChildrenFromObject(prev?.dataSource?.formBuilder, itemIds));
        return {
          ...prev,
          dataSource: {
            ...prev?.dataSource,
            formBuilder: newFormBuilder,
          },
          stepperGroups: updateStepperGroupsChildren(newFormBuilder, prev?.stepperGroups),
        };
      });
    }
  }, []);

  const handleCopyItems = (viewDataSource: any, activeComponent: any | undefined, selectedItemsId: string[]) => {
    const elements = findObjectsByIds(viewDataSource?.formBuilder || [], selectedItemsId.length > 0 ? selectedItemsId : [activeComponent]);
    const flattedItems: any[] = [];
    elements?.filter((item: any) => item?.children)?.forEach((item: any) => flattedItems.push(...item?.children));

    const duplicateItems = findIdsInNestedArrays(flattedItems, selectedItemsId);

    const copyItems = _.cloneDeep(
      findObjectsByIds(viewDataSource?.formBuilder, selectedItemsId.length > 0 ? selectedItemsId : [activeComponent])?.filter(
        (item: any) => !duplicateItems?.includes(item?.id)
      )
    );
    setCopyItems(copyItems);
  };

  const handlePasteItems = () => {
    const minTopXs = copyItems.reduce(
      (min, obj) => {
        const topXs = obj.top.xs;
        const leftXs = obj.left.xs;
        return {
          top: topXs < min.top ? topXs : min.top,
          left: leftXs < min.left ? leftXs : min.left,
        };
      },
      {
        top: Number.POSITIVE_INFINITY,
        left: Number.POSITIVE_INFINITY,
      }
    );

    const newCopyElement = copyItems.map((el: any, index: number) => {
      const camelCaseType = el?.type ? toCamelCase(el.type) : "";
      const { optionMap, ...otherObj } = el;
      const randomId = uuid();
      const newId = `${camelCaseType}${view?.dataSource?.formBuilder?.length + index + 1 || 0}`;

      const updatedOptionMap = optionMap
        ? Object.keys(optionMap).reduce((acc, key) => {
            const optionEl = optionMap[key];
            acc[key] = {
              ...optionEl,
              id: randomId,
              top: { xs: optionEl.top.xs - minTopXs.top + (hiddenPercentage + 50) },
              left: { xs: optionEl.left.xs + 1 - minTopXs.left },
              leftPercentage: {
                xs: ((optionEl.left.xs + 1 - minTopXs.left) / boxSize.width) * 100 + "%",
              },
              props: {
                ...optionEl?.props,
                id: `${camelCaseType}${view?.dataSource?.formBuilder?.length + index + 1 || 0}`,
                key: `${camelCaseType}${view?.dataSource?.formBuilder?.length + index + 1 || 0}`,
              },
              children: Array.isArray(optionEl?.children) ? updateElementsIds(optionEl.children, newId) : [],
            };
            return acc;
          }, {})
        : {};

      const isCheckbox = el?.type === "CustomCheckbox";
      const isRadio = el?.type === "CustomRadio";
      return {
        ...el,
        id: randomId,
        top: { xs: el.top.xs - minTopXs.top + (hiddenPercentage + 50) },
        left: { xs: el.left.xs + 1 - minTopXs.left },
        leftPercentage: { xs: ((el.left.xs + 1 - minTopXs.left) / boxSize.width) * 100 + "%" },
        ...(Object.keys(updatedOptionMap).length > 0 && { optionMap: updatedOptionMap }),
        props: {
          ...el?.props,
          id: newId,
          key: newId,
          // ...((isCheckbox || isRadio) && { isChildContainerGroup: false, required: false }),
          // ...(isCheckbox && { groupName: "checkbox-group" }),
          // ...(isRadio && { groupName: "radio-group" }),
        },
        children: el?.children?.length ? updateElementsIds(el.children, newId) : [],
      };
    });

    const removeDuplicatesById = (array: any[]): any[] => {
      const seen = new Set();
      return array.filter(item => {
        if (seen.has(item.id)) {
          return false;
        } else {
          seen.add(item.id);
          return true;
        }
      });
    };

    const updateChildrenRecursively = (components: any[], selectedIds: string[]): any[] => {
      return components.flatMap(component => {
        if (selectedIds.includes(component.id)) {
          if (component.children) {
            const updatedChildren = [...component.children, ...newCopyElement];
            return [{ ...component, children: updatedChildren }];
          } else {
            return [component, ...newCopyElement];
          }
        } else if (component.children) {
          const updatedChild = updateChildrenRecursively(component.children, selectedIds);
          return [{ ...component, children: updatedChild }];
        } else {
          return [component];
        }
      });
    };

    setView((prev: any) => {
      const itemsIdToUse = selectedItemsId.length > 0 ? selectedItemsId : [activeComponent];
      let updatedFormBuilder = updateChildrenRecursively(prev.dataSource.formBuilder, itemsIdToUse);

      updatedFormBuilder = removeDuplicatesById(updatedFormBuilder);
      return {
        ...prev,
        dataSource: {
          ...prev.dataSource,
          formBuilder: updatedFormBuilder,
        },
      };
    });

    setSelectedItemsId(newCopyElement.map(el => el.id));
    setCopyItems([]);
  };
  const initializeStates = items => {
    const states = {};

    const initialize = elements => {
      elements?.forEach(item => {
        states[item.id] = true;

        if (item.children && item.children.length > 0) {
          initialize(item.children);
        }
      });
    };

    initialize(items);
    return states;
  };

  const updateParentStates = (item, prevStates) => {
    if (!item?.config?.parent?.id) {
      return prevStates;
    }
    const newStates = { ...prevStates, [item.config.parent.id]: true };
    return updateParentStates(item.config.parent, newStates);
  };

  const handleSelectComponent = useCallback(
    (item: any) => {
      if (historyId) {
        return;
      }
      setIsKeyboardActive(true);
      setValue(item?.config?.selectParent ? 2 : 0);
      if (keyCode === "Select") {
        let newSelectedItems = [item?.id, activeComponent];
        if (flexCanvasEnabled) {
          newSelectedItems = newSelectedItems?.filter(id => id != view?.dataSource?.formBuilder?.[0]?.id);
        }
        setSelectedItemsId(prev => [...prev, ...newSelectedItems]);
        setActiveComponent(undefined);
      } else {
        if (item?.config?.parent?.id && SyncTreeWithCanvasEnabled) {
          setOpenStates(prev => updateParentStates(item, prev));
        }
        if (item.config.selectParent) {
          setActiveComponent(item?.config?.parent?.id);
        } else {
          setActiveComponent(item?.id);
        }
        setSelectedItemsId([]);
      }
    },
    [activeComponent, flexCanvasEnabled, keyCode, view?.dataSource?.formBuilder?.[0]?.id]
  );

  const { mutateAsync, data: templateData } = useMutation({ mutationFn: getTemplateById, mutationKey: "getTemplateById" });
  const { mutateAsync: getTemplateFromHistory } = useMutation({
    mutationFn: getTemplateByHistoryId,
    mutationKey: "getTemplateByHistoryId",
  });
  const { mutateAsync: saveTemplate, isLoading } = useMutation({ mutationFn: (d: any) => saveAsTemplate(d, templateId) });

  async function onUpdateTemplate(data: any) {
    try {
      await saveTemplate({
        ...data,
        config: {
          formBuilder: isArray(view?.dataSource?.formBuilder) ? [...view?.dataSource?.formBuilder] : [],
          dynamicHeight: dynamicHeight,
          flexCanvas: flexCanvasEnabled,
          canvasFullHeight,
        },
      });
      enqueueSnackbarRef?.("Posted Successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbarRef?.("Save Failed Due To missing Component Key or Id", {
        variant: "error",
      });
    }
  }

  async function onExportTemplate(data: any, _: any) {
    try {
      await saveTemplate({
        type: "VIEW",
        name: data?.name,
        visibility: "PUBLIC",
        config: {
          formBuilder: isArray(view?.dataSource?.formBuilder) ? [...view?.dataSource?.formBuilder] : [],
          dynamicHeight: dynamicHeight,
          flexCanvas: flexCanvasEnabled,
          canvasFullHeight,
        },
      });
      enqueueSnackbarRef?.("Exported Successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbarRef?.("Export Failed due to missing Component Key or ID", {
        variant: "error",
      });
    }
  }
  useEffect(() => {
    (async function () {
      if (!templateId) return;
      try {
        const { data: templateConfig } = (await mutateAsync(templateId)) as AxiosResponse<any>;

        setView((prev: any) => ({
          ...prev,
          dataSource: {
            ...prev.dataSource,
            formBuilder: isArray(templateConfig?.config?.formBuilder) ? templateConfig?.config?.formBuilder : [],
            formBuilderConfig: {
              flexCanvas: !!templateConfig?.config?.flexCanvas,
              isDynamicHeight: !!templateConfig?.config?.dynamicHeight,
              isCanvasFullHeight: !!templateConfig?.config?.canvasFullHeight,
            },
          },
        }));
      } catch (error) {}
    })();
  }, [templateId]);

  useEffect(() => {
    (async function () {
      if (!historyId) return;
      try {
        const { data: templateConfig } = (await getTemplateFromHistory(historyId)) as AxiosResponse<any>;

        setView((prev: any) => ({
          ...prev,
          dataSource: {
            ...prev.dataSource,
            formBuilder: isArray(templateConfig?.config?.formBuilder) ? templateConfig?.config?.formBuilder : [],
            formBuilderConfig: {
              flexCanvas: !!templateConfig?.config?.flexCanvas,
              isDynamicHeight: !!templateConfig?.config?.dynamicHeight,
              isCanvasFullHeight: !!templateConfig?.config?.canvasFullHeight,
            },
          },
        }));
      } catch (error) {}
    })();
  }, [historyId]);

  const handleBuilderSave = useCallback(() => {
    const elementError = view?.dataSource?.formBuilder?.find((item: any) => !item?.props?.id || !item?.props?.key);
    if (elementError) {
      handleSelectComponent(elementError);
      enqueueSnackbarRef?.("Save Failed Due To missing Component Key or ID", {
        variant: "error",
      });

      return;
    }
    const updatedFormBuilder = view?.dataSource?.formBuilder?.map((item: any) => {
      if (item?.optionMap && item?.selectedType) {
        const restOfItem = { ...item };
        delete restOfItem.optionMap;
        return {
          ...item,
          optionMap: {
            ...item.optionMap,
            [item.selectedType]: { ...restOfItem },
          },
        };
      }
      return item;
    });

    const updatedHeights = Object.entries(view.height).map(([key, value]) => [key, Number(value)]);
    const updatedView = {
      ...view,
      dataSource: {
        ...view.dataSource,
        formBuilder: updatedFormBuilder,
      },
      height: Object.fromEntries(updatedHeights),
    };
    if (flexCanvasEnabled) {
      _.set(updatedView, "dataSource.formBuilder[0].config.heightPx", Object.fromEntries(updatedHeights));
    }
    setIsSaving(true);
    if (onSave) {
      onSave?.(updatedView, () => {
        setIsSaving(false);
        enqueueSnackbarRef?.("Posted Successfully", {
          variant: "success",
        });
        saveButtonClickedRef.current = false;
      });
    } else {
      editView?.(
        appId!,
        collectionId!,
        pageId!,
        viewId!,
        updatedView,
        () => {
          setIsSaving(false);

          saveButtonClickedRef.current = false;
        },
        () => {
          setIsSaving(false);
        }
      );
    }
  }, [appId, collectionId, editView, handleSelectComponent, pageId, view, viewId]);

  const handleCloseEdit = useCallback(() => {
    setActiveComponent(undefined);
    setValue(1);
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Delete" || e.key === "." || e.key === "Backspace") {
        setKeyCode("Delete");
      }
      if (e.key === "Shift") {
        setKeyboardStep(10);
        setMouseStep(step => (step === 1 ? 10 : 1));
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "c") {
        setKeyCode("copy");
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "v") {
        setKeyCode("paste");
      }
      if (e.ctrlKey || e.metaKey || e.key === "Control") {
        setKeyCode("Select");
      }
      if (getNewPosition(e.key, keyboardStep)) {
        setKeyCode(e.key);
      }
      if ((e.ctrlKey || e.metaKey) && e.key === "z" && !e.shiftKey) {
        e.preventDefault();
        stepBack();
      }
      if ((e.ctrlKey && e.key === "y") || (e.metaKey && e.shiftKey && e.key === "z")) {
        e.preventDefault();
        stepForward();
      }
      return true;
    },
    [keyboardStep, stepBack, stepForward]
  );
  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === "Shift") {
      setKeyboardStep(1);
    }
    setKeyCode(undefined);
  }, []);
  useEffect(() => {
    const isFlexCanvas =
      view?.dataSource?.formBuilder?.[0]?.id === activeComponent && view?.dataSource?.formBuilder?.[0]?.config?.flexCanvas;

    if (isFlexCanvas) return;

    if (isKeyboardActive) {
      if (keyCode && keyCode !== "Delete" && keyCode !== "Select" && keyCode !== "copy" && keyCode !== "paste" && !flexCanvasEnabled) {
        const item = findObjectsByIds(view?.dataSource?.formBuilder, [activeComponent]);
        canDropComponent(undefined, selectedItems?.length ? selectedItems : item, getNewPosition(keyCode, keyboardStep)) &&
          moveBoxFunction(selectedItems?.length ? selectedItems : item, null, getNewPosition(keyCode, keyboardStep), keyboardStep, true);
      }
      if (keyCode === "Delete") {
        if (selectedItemsId.length) {
          handleDeleteItem(selectedItemsId);
        } else if (activeComponent) {
          handleDeleteItem([activeComponent]);
        }
      }
      if (keyCode === "copy") {
        handleCopyItems(view?.dataSource, activeComponent, selectedItemsId);
      }
      if (keyCode === "paste") {
        handlePasteItems();
      }
    }
  }, [keyCode, selectedItemsId.length]);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    document.body.addEventListener("keyup", handleKeyUp);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  }, [activeComponent, stepBack, stepForward]);

  const handleDynamicLayout = (values: any, item: any, options?: any) => {
    if (options?.skip) return;
    const newItem = {
      ...item,
      config: {
        ...item?.config,
        defaultWidth: values?.width,
        defaultHeight: values?.height,
        heightPx: {
          xs: values?.height,
          xl: values?.height,
        },
      },
    };
    setView((prev: any) => {
      let elements = [];

      elements = prev?.dataSource?.formBuilder?.map((el: any, index: any) => (el.id === newItem.id ? { ...newItem } : el));

      return {
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: setParents(elements),
        },
      };
    });
  };

  const handleCustomComponentSelect = useCallback(
    (data: any) => {
      const item: any = {
        type: data?.config?.type,
        name: data?.name,
        props: data,
        left: 10,
        top: 10,
        configData: {
          ...data?.config,
        },
        config: {
          defaultWidth: data?.config?.defaultWidth || 150,
          defaultHeight: data?.config?.defaultHeight || 50,
          heightPx: {
            xs: data?.config?.defaultHeight || 50,
          },
          widthPx: {
            xs: data?.config?.defaultWidth || 150,
          },
          fixedWidth: !!data?.config?.defaultWidth || false,
          isPercentageHeight: false,
          controlledComponent: true,
          disableResizeHeight: false,
          customComponent: true,
          BxComponent: true,
          customComponentId: data?.id,
        },
      };

      const left = 10;
      const top = 10;
      const leftPercentage = (left / boxSize.width) * 100 + "%";

      const PWidth = boxSize?.width * 100;
      const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

      const PHeight = boxSize?.height * 100;
      const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

      moveBox({
        item,
        left: { ...item?.left, [layoutBreak]: left },
        top: { ...item?.top, [layoutBreak]: top },
        leftPercentage: { ...item?.leftPercentage, [layoutBreak]: leftPercentage },
        widthPercentage: { ...item?.widthPercentage, [layoutBreak]: percentageWidth },
        heightPercentage: { ...item?.heightPercentage, [layoutBreak]: percentageHeight },
      });
      return undefined;
    },
    [boxSize.width, boxSize.height, layoutBreak, moveBox]
  );

  const handleViewSelect = useCallback(
    (data: any) => {
      const item: any = {
        type: ComponentItemType.BXView,
        props: {
          sx: {
            width: "100%",
            height: "100%",
            backgroundColor: "primary.main",
          },
        },
        left: 0,
        top: 0,
        config: {
          defaultWidth: 150,
          defaultHeight: 400,
          heightPx: {
            xs: 400,
            xl: 400,
          },
          widthPercentage: {
            xs: "100%",
          },
          viewRef: {
            id: data?.value,
          },
          fixedWidth: false,
          isPercentageHeight: false,
          disableResizeHeight: false,
        },
      };

      const left = 0;
      const top = 0;
      const leftPercentage = (left / boxSize.width) * 100 + "%";

      const PWidth = boxSize?.width * 100;
      const percentageWidth = PWidth > 100 ? "100%" : PWidth + "%";

      const PHeight = boxSize?.height * 100;
      const percentageHeight = PHeight > 100 ? "100%" : PHeight + "%";

      moveBox({
        item,
        left: { ...item?.left, [layoutBreak]: left },
        top: { ...item?.top, [layoutBreak]: top },
        leftPercentage: { ...item?.leftPercentage, [layoutBreak]: leftPercentage },
        widthPercentage: { ...item?.widthPercentage, [layoutBreak]: percentageWidth },
        heightPercentage: { ...item?.heightPercentage, [layoutBreak]: percentageHeight },
      });
      return undefined;
    },
    [boxSize.width, boxSize.height, layoutBreak, moveBox]
  );

  const borderSelectionContainer = document.body;
  const containerRef = useRef<HTMLElement>(null);
  const parentContainerRef = useRef<HTMLElement>(null);

  const handleStartSelection = () => {
    setSelectedItemsId([]);
    setValue(1);
    setActiveComponent(undefined);
  };
  const finishSelection: TFinishSelectionCallback = (items, e) => {
    const selectedIds = items.map(item => item?.getAttribute("data-id") || "");
    setView((prev: any) => {
      const filteredIds: any = [];
      prev?.dataSource?.formBuilder?.forEach((el: any) => {
        if (selectedIds.includes(el?.id)) {
          filteredIds.push(el?.id);
        }
      });
      setSelectedItemsId(filteredIds);
      return prev;
    });

    setActiveComponent(undefined);
    setValue(0);
  };

  const selectedItems = elements
    ?.filter((el: any) => selectedItemsId.includes(el?.id))
    ?.map((el: any) => ({ ...el, boxPosition: boxSize, level: 1 }));

  const [{ isDragging, difference }, drag, preview] = useDrag({
    type: "Group",
    item: selectedItems,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      difference: monitor.getDifferenceFromInitialOffset(),
    }),
  });

  useLayoutEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [isDragging, difference]);

  const handleCanvasNotFocused = () => {
    setIsKeyboardActive(false);
  };
  const handleCanvasFocused = (e: any) => {
    e.stopPropagation();
    setIsKeyboardActive(true);
  };

  useEffect(() => {
    // calculate how much hidden from the canvas board
    const handleScroll = () => {
      const div = containerRef.current;
      if (div) {
        const rect = div.getBoundingClientRect();
        const { top } = rect;
        if (top < 0) {
          setHiddenPercentage(Math.abs(top));
        } else {
          setHiddenPercentage(0);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getCanvasDimension();
    if (elements?.length > 0 && Object.keys(openStates).length === 0) {
      setOpenStates(initializeStates(elements));
    }
  }, [elements]);

  const containerElements = useMemo(() => flattenList(view?.dataSource?.formBuilder), [view?.dataSource?.formBuilder]);

  const activeComponentMemo = useMemo(
    () => containerElements?.find((item: any) => item?.id === activeComponent),
    [activeComponent, containerElements]
  );

  const handleLayoutChange = useCallback((item: any) => (values: any, options?: any) => handleDynamicLayout(values, item, options), []);

  const heightContextValue = useMemo(() => ({ boxHeight }), [JSON.stringify(boxHeight)]);

  const makeAllChildrenUnique = (currentArrOfElements: any, parent: { type: any; id: any } = { type: null, id: null }) => {
    // this function take an array of elements and make a new unique id for all elements inside and returns the new elements ass an array

    if (!currentArrOfElements) return null;

    let returnedElementsArr = [...currentArrOfElements];

    for (let i = 0; i < currentArrOfElements.length; i++) {
      let newId = uuid();
      returnedElementsArr[i] = {
        ...returnedElementsArr[i],
        id: newId,
        config: { ...returnedElementsArr[i]?.config, parent: parent?.id !== null ? parent : null },
      };

      if (returnedElementsArr[i]?.children) {
        returnedElementsArr[i].children = makeAllChildrenUnique(returnedElementsArr[i].children, {
          type: returnedElementsArr[i]?.type,
          id: newId,
        });
      }
    }

    return returnedElementsArr;
  };

  const addTemplateToConfig = (template: any, replaceAll: boolean) => {
    if (!Array.isArray(template?.config?.formBuilder)) template.config.formBuilder = [];

    const isTemplateCanvasFlex = template?.config?.flexCanvas;

    template = template?.config?.formBuilder;
    if (isTemplateCanvasFlex) {
      const newId = uuid();
      template = [
        {
          ...template[0],
          id: newId,
          type: "FlexContainer",
          config: {
            ...template[0]?.config,
            isNotResizable: false,
            flexCanvas: false,
            isDynamicHeight: view?.dataSource?.formBuilderConfig?.isDynamicHeight || template?.config?.dynamicHeight,
            parent: flexCanvasEnabled ? { id: view?.dataSource?.formBuilder?.[0]?.id, type: "FlexContainer" } : null,
          },
          children: makeAllChildrenUnique(template[0]?.children, { id: newId, type: "FlexContainer" }),
          left: { xs: 0 },
          top: { xs: 0 },
        },
      ];
    } else {
      template = makeAllChildrenUnique(
        template,
        flexCanvasEnabled ? { id: view?.dataSource?.formBuilder?.[0]?.id, type: "FlexContainer" } : { type: null, id: null }
      );
    }

    if (flexCanvasEnabled) {
      setView((prev: any) => ({
        ...prev,
        dataSource: {
          ...prev?.dataSource,
          formBuilder: [
            {
              ...prev?.dataSource?.formBuilder?.[0],
              children: replaceAll ? [...template] : [...(prev?.dataSource?.formBuilder?.[0]?.children || []), ...template],
            },
          ],
        },
      }));
    } else {
      setView((prevView: any) => ({
        ...prevView,
        dataSource: {
          ...prevView?.dataSource,
          formBuilder: replaceAll ? [...template] : [...(prevView?.dataSource?.formBuilder || []), ...template],
        },
      }));
    }

    const selectedItemsIdArray = template?.map((obj: { id: any }) => obj.id);
    setSelectedItemsId(selectedItemsIdArray);
  };

  return (
    <>
      <Box onClick={handleCanvasNotFocused} height='100%'>
        <HeightContext.Provider value={heightContextValue}>
          <Box display='flex' gap={2} className={classes.root} sx={{ left: 24 }}>
            <CustomDragLayer
              selectedItemsId={selectedItemsId}
              elements={elements}
              factorsRef={factorsRef}
              isStepperEnabled={stepperEnabled}
              canDrop={isOverCurrent && canDrop}
              layoutBreak={layoutBreak}
              boxPosition={boxSize}
              zoomFactor={zoom}
            />
            <ViewBuilderHeader
              isLoading={isLoading}
              isEmptyView={!(view?.dataSource?.formBuilder?.length !== 0)}
              layoutBreak={layoutBreak}
              templateData={templateData}
              template={templateId}
              formBuilder={view?.dataSource?.formBuilder || []}
              isEditingTemplate={!!templateId}
              isHistory={!!historyId}
              isSaving={isSaving}
              view={view}
              setView={setView}
              viewName={view?.title}
              handleBackButton={handleBackButton}
              handleChangeLayout={handleChangeLayout}
              handleBuilderSave={handleBuilderSave}
              addTemplate={addTemplateToConfig}
              onUpdateTemplate={onUpdateTemplate}
              onExportTemplate={onExportTemplate}
              setHasLayoutReset={setHasLayoutReset}
            />
          </Box>
          <Box display={"flex"} gap={2} justifyContent='center' height='100%'>
            <Box onClick={handleCanvasFocused} display='flex' justifyContent='center' height='100%' marginTop={"-6px"}>
              {!historyId && (
                <ResizableContainer
                  minWidth={230}
                  maxWidth={350}
                  position='right'
                  onResizeFinished={() => {
                    getCanvasDimension();
                  }}
                >
                  <LeftSideMenuItems
                    isRTL={isRTL}
                    layoutBreak={layoutBreak}
                    activeComponent={activeComponentMemo}
                    onChangeProp={handleChangeComponentProps}
                    onChangeViewProps={handleChangeViewProps}
                    onDiscardEdit={handleCloseEdit}
                    tab={value}
                    onCanvasToggle={handleCanvasToggle}
                    onFlexCanvasToggle={handleFlexCanvasToggle}
                    flexCanvasEnabled={flexCanvasEnabled}
                    canvasEnabled={canvasEnabled}
                    onDeleteItem={handleDeleteItem}
                    onCustomComponentSelect={handleCustomComponentSelect}
                    onViewSelect={handleViewSelect}
                    dynamicHeight={dynamicHeight}
                    onDynamicHeightToggle={handleDynamicHeightToggle}
                    view={view}
                    setView={setView}
                    views={views}
                    appBuilderMode={appBuilderMode}
                    pageBuilderMode={pageBuilderMode}
                    elements={elements}
                    handleSelectComponent={handleSelectComponent}
                    handleCopyItems={handleCopyItems}
                    handleDeleteItem={handleDeleteItem}
                    selectedItemsId={selectedItemsId}
                    setSelectedItemsId={setSelectedItemsId}
                    handlePasteItems={handlePasteItems}
                    leftTabValue={leftTabValue}
                    handleChangeLeftTab={handleChangeLeftTab}
                    openStates={openStates}
                    setOpenStates={setOpenStates}
                    onChangePropConfigTreeMenu={handleChangePropConfigTreeMenu}
                  />
                </ResizableContainer>
              )}
            </Box>

            <Box
              ref={parentContainerRef}
              flex={1}
              display='flex'
              justifyContent='center'
              marginTop={"6px"}
              paddingBottom={2}
              data-testId='canvas-drop-layer'
            >
              <Box
                onClick={handleCanvasFocused}
                ref={containerRef}
                sx={{
                  position: "relative",
                  justifyContent: "center",
                  width: "100%",
                  // width:
                  //   parentContainerRef.current?.clientWidth &&
                  //   parentContainerRef.current?.clientWidth < layoutBreakPointsValues[layoutBreak]
                  //     ? "100%"
                  //     : layoutBreakPointsValues[layoutBreak],
                  height: "100%",
                  overflow: "hidden",
                  zoom: zoom,
                  minHeight: getItemClosestProp(boxHeight, layoutBreak),
                }}
              >
                <ResizeElement disableHeight={true} zoomFactor={zoom} isRTL={isRTL} scrollToButton isCanvas={true}>
                  <Box
                    sx={{
                      position: "relative",
                      justifyContent: "center",
                      width: "100%",
                      overflow: "hidden",
                      height: "100%",
                      background: canDrop && isOverCurrent ? "rgba(255, 222, 0, 0.2)" : "unset",
                    }}
                    id='canvas-box'
                    ref={drop}
                  >
                    <ContainerGrid
                      layoutBreak={layoutBreak}
                      canvasEnabled={canvasEnabled}
                      boxWidth={boxWidth}
                      parentContainerRef={parentContainerRef}
                      zoom={zoom}
                    />
                    <Box ref={boxRef} height='100%'>
                      {!!elements?.length &&
                        elements?.map((item: any, index: number) => {
                          return (
                            <BoxComponent
                              key={`${item?.id}`}
                              isRTL={isRTL}
                              selectedItems={selectedItems}
                              item={item}
                              itemIndex={index}
                              className={"mouse-select__selectable"}
                              layoutBreak={layoutBreak}
                              index={index}
                              selectedItemsId={selectedItemsId}
                              boxPosition={boxSize}
                              // canDrag={!(selectedItemsId?.length || item?.children?.some((child: any) => child?.id === activeComponent))}
                              setView={setView}
                              setInnerComponentDropping={setInnerComponentDropping}
                              onLayout={handleLayoutChange(item)}
                              activeComponent={activeComponent}
                              setActiveComponent={handleSelectComponent}
                              viewLength={view?.dataSource?.formBuilder?.length}
                              stepperGroups={view?.stepperGroups}
                              formMoveBoxFunction={moveBoxFunction}
                              zoomFactor={zoom}
                              views={page?.views}
                            />
                          );
                        })}
                      {!!selectedItems?.length && (
                        <Box className={"mouse-select__selectable"}>
                          <Box sx={{ opacity: isDragging ? 0 : 1 }} ref={drag}>
                            {selectedItems?.map((item: any, index: number) => {
                              return (
                                <BoxComponent
                                  key={`${index}`}
                                  isRTL={isRTL}
                                  active={selectedItemsId.includes(item?.id)}
                                  canDrag={
                                    !(selectedItemsId?.length || item?.children?.some((child: any) => child?.id === activeComponent))
                                  }
                                  selectedItems={selectedItems}
                                  selectedItemsId={selectedItemsId}
                                  itemIndex={index}
                                  item={{ ...item, children: [] }}
                                  layoutBreak={layoutBreak}
                                  index={index}
                                  className={"mouse-select__selectable"}
                                  boxPosition={boxSize}
                                  setView={setView}
                                  setInnerComponentDropping={setInnerComponentDropping}
                                  onLayout={handleLayoutChange(item)}
                                  activeComponent={activeComponent}
                                  setActiveComponent={handleSelectComponent}
                                  viewLength={view?.dataSource?.formBuilder?.length}
                                  formMoveBoxFunction={moveBoxFunction}
                                  zoomFactor={zoom}
                                  views={views}
                                  stepperGroups={view?.stepperGroups}
                                />
                              );
                            })}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </ResizeElement>

                {!historyId && (
                  <ReactMouseSelect
                    containerRef={containerRef}
                    portalContainer={borderSelectionContainer}
                    itemClassName='mouse-select__selectable'
                    sensitivity={5}
                    tolerance={5}
                    notStartWithSelectableElements={true}
                    startSelectionCallback={handleStartSelection}
                    finishSelectionCallback={finishSelection}
                    zoomFactor={zoom}
                  />
                )}
              </Box>
            </Box>

            <Box display='flex' justifyContent='center' height='100%' marginTop={"-6px"}>
              {!historyId && (
                <ResizableContainer
                  minWidth={230}
                  maxWidth={350}
                  position='left'
                  onResizeFinished={() => {
                    getCanvasDimension();
                  }}
                >
                  <RightSideMenuItems
                    layoutBreak={layoutBreak}
                    activeComponent={activeComponentMemo}
                    onChangeProp={handleChangeComponentProps}
                    onChangeViewProps={handleChangeViewProps}
                    onDiscardEdit={handleCloseEdit}
                    tab={value}
                    appId={appId}
                    collectionId={collectionId}
                    pageId={pageId}
                    multiLingual={multiLingual}
                    setMultiLingual={setMultiLingual}
                    handleChangeTab={handleChangeTab}
                    onCanvasToggle={handleCanvasToggle}
                    onFlexCanvasToggle={handleFlexCanvasToggle}
                    flexCanvasEnabled={flexCanvasEnabled}
                    canvasEnabled={canvasEnabled}
                    stepperEnabled={stepperEnabled}
                    onStepperToggle={onStepperToggle}
                    onDeleteItem={handleDeleteItem}
                    onCustomComponentSelect={handleCustomComponentSelect}
                    dynamicHeight={dynamicHeight}
                    onDynamicHeightToggle={handleDynamicHeightToggle}
                    canvasFullHeight={canvasFullHeight}
                    onCanvasFullHeightToggle={handleCanvasFullHeightToggle}
                    views={views}
                    view={view}
                    setView={setView}
                  />
                </ResizableContainer>
              )}
            </Box>
          </Box>
        </HeightContext.Provider>
      </Box>
    </>
  );
};
export { FormBuilderEditor };
