// material-ui
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Popover from "@mui/material/Popover";
import makeStyles from "@mui/styles/makeStyles";

// project imports
// import SearchSection from './SearchSection';
// import LocalizationSection from './LocalizationSection';
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
// import NotificationSection from './NotificationSection';
// assets
import { Typography, useTheme } from "@mui/material";
import { IconMenu2 } from "@tabler/icons-react";
import _ from "lodash";
import { FC, MouseEventHandler, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { BXIcon } from "src/components/BXUI/Icon";
import { adminPaths, formBuilderPath } from "src/config";
import useAuth from "src/hooks/useAuth";
import { BXAppCollection } from "src/types/BXAppType";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { useBXContext } from "../../../BXEngine/BXContext";
import LanguagesSection from "./LanguagesSection";

// style constant
const useStyles = makeStyles((theme: any) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    // ...theme.typography.commonAvatar,
    // ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    backgroundColor: theme.palette.background.default + " !important",
    color: theme.palette.text.primary + " !important",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  boxContainer: {
    // width: "228px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
  handleLeftDrawerToggle?: () => void;
}

const AppSelector: FC = () => {
  const {
    allowedApps,
    currentApp: _currentApp,
    setCurrentApp,
    setIsAdministrationMode,
    isAdministrationMode,
    setIsSwitchingMode,
  } = useBXContext();

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { palette } = useTheme();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith("/buildx")) {
      if (isAdministrationMode == true) {
        setIsAdministrationMode(false);
      }
    }
  }, [location]);

  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLDivElement> = event => {
    if (!localStorage.getItem("admin-login") && !isHostAvailable) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (allowedApps.length == 0 || isAdministrationMode) return <></>;

  const currentApp = allowedApps.find(app => app?.id == _currentApp?.id) || allowedApps?.[0];

  return (
    <>
      <Box
        // aria-describedby={id}
        onClick={handleClick}
        // variant={"contained"}
        sx={{
          height: 35,
          position: "relative",
          backgroundColor: palette.background.default,
          borderRadius: 1,
          justifyContent: "space-between",
          color: palette.text.primary,
          padding: currentApp?.appConfig?.isOnlyPhoto ? 0 : "6px 16px",
          marginInlineStart: currentApp?.appConfig?.isOnlyPhoto ? 2 : 1,
          cursor: "pointer",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <BXIcon
          icon={
            (currentApp?.iconConfig?.visibility === "PUBLIC" && currentApp?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
              ? currentApp?.iconConfig?.url
              : currentApp?.iconConfig?.icon) || currentApp?.icon
          }
          fallBack={"Box"}
          width={currentApp?.appConfig?.isOnlyPhoto ? "100%" : "unset"}
          height={currentApp?.appConfig?.isOnlyPhoto ? "100%" : 30}
        />
        {!currentApp?.appConfig?.isOnlyPhoto && (
          <Typography fontWeight={600} fontSize={16}>
            {(() => {
              if (_.isNil(currentApp) || _.isEmpty(currentApp)) {
                return "Go to app";
              }
              return currentApp?.name;
            })()}
          </Typography>
        )}
      </Box>
      <Popover
        id={"bx-app-select"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box paddingX={1} paddingY={2} maxHeight={300}>
          {allowedApps.map(c => (
            <Box key={c.name} onClick={handleClose} mb={0.5}>
              <Button
                fullWidth
                style={{
                  color: palette.text.primary,
                  justifyContent: "flex-start",
                  border: `0.5px solid ${c.name === currentApp?.name ? palette.primary.main : "black"}`,
                  paddingInlineStart: 12,
                  paddingInlineEnd: 12,
                }}
                onClick={() => {
                  setIsSwitchingMode(false);
                  setCurrentApp(c);
                  setIsAdministrationMode(false);
                  const firstCollectionToHavePages = c.templateConfig?.collections?.find(
                    (collection: BXAppCollection) => collection.pages.length > 0
                  );
                  const landingPageUri = c?.appConfig?.pageUri;
                  const collectionSlug = firstCollectionToHavePages?.slug;
                  const firstPageSlug = firstCollectionToHavePages?.pages?.filter(page => page?.info?.visibility !== "Hidden")?.[0]?.slug;
                  const path = `${c.slug}${landingPageUri ?? `/${collectionSlug}/${firstPageSlug}`}`;
                  if (firstPageSlug) navigate({ pathname: path.replaceAll(/\/+/g, "/") });
                  else navigate({ pathname: `${c.slug}`.replaceAll(/\/+/g, "/") });
                }}
                startIcon={
                  <BXIcon
                    icon={
                      (c?.iconConfig?.visibility === "PUBLIC" && c?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                        ? c?.iconConfig?.url
                        : c?.iconConfig?.icon) || c?.icon
                    }
                    fallBack={"Box"}
                    size={32}
                  />
                }
              >
                {c.name}
              </Button>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
  const classes = useStyles();
  const { isSuperAdmin } = useAuth();
  const location = useLocation();
  const isAdministrationPath = adminPaths.includes(location.pathname);
  const isFormBuilderPath = location.pathname.startsWith(formBuilderPath);

  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        {/* <Box component='span' sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <div className={"vertical-divider"} /> */}

        {!_.isNil(handleLeftDrawerToggle) &&
          !window.location.pathname.startsWith("/buildx/form-builder") &&
          !window.location.pathname.startsWith("/buildx/page-builder") && (
            <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
              <Avatar variant='rounded' className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color='inherit'>
                <IconMenu2 stroke={1.5} size='1.3rem' />
              </Avatar>
            </ButtonBase>
          )}
        {!window.location.pathname.startsWith("/buildx/form-builder") && !window.location.pathname.startsWith("/buildx/page-builder") && (
          <Box marginInlineStart={2}>
            <AppSelector />
          </Box>
        )}
      </div>

      {/* header search */}
      {/* <SearchSection theme="light" /> */}
      <div className={classes.grow} />

      <div className={classes.grow} />

      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      <LocalizationSection />
            </Box> */}

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        {/* Mange Languages Section */}
        {!isAdministrationPath && !isFormBuilderPath && <LanguagesSection />}

        {/* notification & profile */}
        {/* <NotificationSection /> */}
        <ProfileSection />
      </Box>
      {/* mobile header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
