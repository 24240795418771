import { Box } from "@mui/material";
import _ from "lodash";
import { useAppState } from "src/features/appState/hooks";
import { updateStepperGroupIndex } from "../../utils";

export const StepperNavigator = props => {
  const { watch, setValue } = useAppState();

  const { config, $config, stepperGroups: _stepperGroups, metaData } = props;
  const { pageId, viewName } = metaData || {};

  const isInBuilder = Boolean($config);
  const stepperGroups = isInBuilder ? _stepperGroups : watch(`${pageId}.${viewName}._BX_stepperGroups`);

  const {
    groupReference,
    bulletsClickable,
    orientation,
    bulletHoverOpacity,
    bulletHoverColor,
    selectedBulletShape,
    selectedBulletColor,
    bulletDisplay,
    unselectedBulletOpacity,
    unselectedBulletColor,
    unselectedBulletShape,
    selectedBulletOpacity,
    selectedBulletWidth,
    selectedBulletHeight,
    unselectedBulletHeight,
    unselectedBulletWidth,
  } = $config?.navigator || config?.navigator;

  const selectedGroup = _.find(stepperGroups, group => group.groupName === groupReference);
  const pages = selectedGroup?.pages || [{}];
  const selectedPageIndex = _.isFinite(+selectedGroup?.selectedPage)
    ? +selectedGroup.selectedPage
    : Math.max(
        0,
        pages?.findIndex(page => page.pageReference === selectedGroup?.selectedPage)
      );

  const currentIndex = (isInBuilder ? selectedGroup?.previewIndex : selectedPageIndex) || 0;

  const setStepperIndex = (index: number) => {
    if (isInBuilder) return;
    setValue(`${pageId}.${viewName}._BX_stepperGroups`, updateStepperGroupIndex(stepperGroups, groupReference, "SET", index));
  };

  return (
    <Box
      display='flex'
      gap={3}
      maxHeight='fit-content'
      flexWrap='wrap'
      justifyContent='center'
      alignItems='center'
      flexDirection={orientation === "vertical" ? "column" : "row"}
      padding={1}
      sx={{ transition: "all 0.3s" }}
    >
      {pages.map((_item, index) => {
        const isSelected = bulletDisplay === "showSelectedOnly" ? index === currentIndex : index <= currentIndex;

        return (
          <Box
            key={index}
            onClick={() => bulletsClickable && setStepperIndex(index)}
            sx={{
              transition: "all 0.2s",
              opacity: isSelected ? selectedBulletOpacity : unselectedBulletOpacity,
              width: index === currentIndex ? selectedBulletWidth : unselectedBulletWidth,
              height: index === currentIndex ? selectedBulletHeight : unselectedBulletHeight,
              bgcolor: isSelected ? selectedBulletColor : unselectedBulletColor,
              borderRadius: isSelected ? selectedBulletShape : unselectedBulletShape,
              cursor: bulletsClickable ? "pointer" : "default",
              "&:hover": {
                opacity: bulletHoverOpacity,
                bgcolor: bulletHoverColor,
              },
            }}
          />
        );
      })}
    </Box>
  );
};
