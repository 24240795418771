import Avatar from "@mui/material/Avatar";
import * as icons from "@tabler/icons-react";
import axios from "axios";
import { FC } from "react";
import { useQuery } from "react-query";
import { AvatarProps } from "./types";

export const CustomAvatar: FC<AvatarProps> = props => {
  const { src, iconConfig, config, __config, metaData, ...otherProps } = props;
  const match = iconConfig?.icon?.match(/^([^:]+):\/\/(.+)$/);
  const imageName = match?.[2];
  const accessToken = localStorage.getItem("accessToken");
  const deviceToken = localStorage.getItem("accessToken-device");
  const token = accessToken || deviceToken;

  const { data } = useQuery(
    ["media", imageName],
    async () => {
      const response = await axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/media", {
        params: {
          keyword: imageName || undefined,
          strategy: "summary",
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    },
    { enabled: iconConfig?.visibility === "PRIVATE" && !!imageName, refetchOnMount: false }
  );

  const imageUrl = data?.items.length > 0 && data.items[0]?.name === imageName ? data.items[0]?.url : iconConfig?.url;
  const IconComponent = icons[`Icon${iconConfig?.icon}`];

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {IconComponent ? (
        <IconComponent size={"100%"} />
      ) : imageUrl ? (
        <Avatar src={imageUrl} style={{ width: "100%", height: "100%", position: "absolute" }} {...otherProps} />
      ) : (
        <Avatar
          src={require("src/assets/images/icons/avatar.svg")}
          style={{ width: "100%", height: "100%", position: "absolute" }}
          {...otherProps}
        />
      )}
    </div>
  );
};
