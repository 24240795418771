import { PaletteMode } from "@mui/material";

const config = {
  dev: {
    hostURL: process.env.REACT_APP_HOST_API_KEY + "/",
    hostAPI: process.env.REACT_APP_HOST_API_KEY_API,
    hostPort: "",
  },
  prod: {
    hostURL: process.env.REACT_APP_HOST_API_KEY + "/",
    hostAPI: process.env.REACT_APP_HOST_API_KEY_API,
    hostPort: "",
  },
};

export const adminPaths = [
  "/buildx/app",
  "/buildx/users",
  "/buildx/policies",
  "/buildx/roles",
  "/buildx/media",
  "/buildx/oas",
  "/buildx/env",
  "/buildx/component",
  "/buildx/migrations",
  "/buildx/templates",
];

export const formBuilderPath = "/buildx/form-builder";
export const pageBuilderPath = "/buildx/page-builder";

export const appVersion = "1.0.1";

const env = process.env.REACT_APP_NODE_ENV === "development" ? "dev" : "dev";

const { hostURL, hostAPI: hostApi, hostPort: portApi } = config[env];
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}`;

const configData: {
  hostURL: string;
  hostApi?: string;
  baseURLApi: string;
  appVersion: string;
  deviceType: string;
  basename: string;
  defaultPath: string;
  fontFamily: string;
  borderRadius: number;
  outlinedFilled: boolean;
  theme: PaletteMode;
  presetColor: string;
  i18n: string;
  rtlLayout: boolean;
  jwt: {
    secret: string;
    timeout: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  auth0: {
    client_id: string;
    domain: string;
  };
} = {
  // basename: only at build time to set, and Don&apos;t add '/' at end off BASENAME for breadcrumbs, also Don&apos;t put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  hostURL,
  hostApi,
  baseURLApi,
  appVersion: "1.0.2x",
  deviceType: "WEB",
  basename: "",
  defaultPath: "/",
  fontFamily: `'Poppins', sans-serif`,
  borderRadius: 1,
  outlinedFilled: true,
  theme: "dark", // light, dark
  presetColor: "theme5", // default, theme1, theme2, theme3, theme4, theme5, theme6
  // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  i18n: "en",
  rtlLayout: false,
  jwt: {
    secret: "SECRET-KEY",
    timeout: "1 days",
  },
  firebase: {
    apiKey: "AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM",
    authDomain: "berry-material-react.firebaseapp.com",
    projectId: "berry-material-react",
    storageBucket: "berry-material-react.appspot.com",
    messagingSenderId: "901111229354",
    appId: "1:901111229354:web:a5ae5aa95486297d69d9d3",
    measurementId: "G-MGJHSL8XW3",
  },
  auth0: {
    client_id: "HvYn25WaEHb7v5PBT7cTYe98XATStX3r",
    domain: "demo-localhost.us.auth0.com",
  },
};

export default configData;
