import { Button, CircularProgress, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { IconCircleCheck, IconClockHour4, IconExclamationCircle, IconRefresh, IconTrashX } from "@tabler/icons-react";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import { BXInput } from "src/components/BXUI/FormControls";
import axiosServices, { default as axios } from "src/utils/axios";
import { decompressData } from "src/utils/services";
import CopyTextField from "../components/CopyTextField";

const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case "ADDITIONAL_VERIFICATION_REQUIRED":
      return "Additional verification is required for this domain.";
    case "DOMAIN_NOT_ALLOWED":
      return "The requested domain is not allowed for certificate issuance.";
    case "DOMAIN_VALIDATION_DENIED":
      return "Domain ownership validation denied. Please contact the domain owner for assistance with validation.";
    case "INVALID_PUBLIC_DOMAIN":
      return "The specified domain is not a valid public domain or is not reachable by ACM's validation system.";
    case "NO_AVAILABLE_CONTACTS":
      return "No contact information found for domain validation. Verify contact information for the domain.";
    case "OTHER":
      return "An unspecified error occurred.";
    case "PCA_ACCESS_DENIED":
      return "Permission denied for Private Certificate Authority. Ensure proper permissions are in place.";
    case "PCA_INVALID_ARGS":
      return "Invalid arguments for the Private Certificate Authority. Review and correct the request parameters.";
    case "PCA_INVALID_DURATION":
      return "Invalid certificate duration for the ACM Private CA service. Ensure the certificate duration is within valid limits.";
    case "PCA_INVALID_STATE":
      return "Invalid state for the Private Certificate Authority. Verify the state of the Private CA service.";
    case "PCA_LIMIT_EXCEEDED":
      return "A limit within the ACM Private CA service has been exceeded, such as the number of certificates, CAs, or the rate of requests. Review your usage to stay within service limits.";
    case "PCA_NAME_CONSTRAINTS_VALIDATION":
      return "Name constraints in the certificate authority prevented issuance. Check certificate authority settings and constraints.";
    case "PCA_RESOURCE_NOT_FOUND":
      return "A specified resource, such as the certificate authority, could not be found by the ACM Private CA service. Verify the existence of the specified resource.";
    case "CCA_ERROR":
      return "An unspecified error related to AWS Certificate Manager's Certificate Authority occurred during the certificate operation. Review the certificate authority configuration.";
    case "PCA_INVALID_ARN":
      return "The Amazon Resource Name (ARN) provided for the Private Certificate Authority is not valid or does not correspond to a valid PCA. Check the provided ARN.";
    case "PCA_REQUEST_FAILED":
      return "Certificate request to the Private Certificate Authority has failed. This could be due to various reasons, such as misconfiguration of the PCA or issues with the request parameters.";
    case "SLR_NOT_FOUND":
      return "Required Service Linked Role (SLR) not found or inaccessible. Ensure the SLR exists and has the necessary permissions.";
    default:
      return "An unknown error occurred.";
  }
};

const ManageDNSForm = ({ app, handleClose }: any) => {
  const { palette } = useTheme();

  const [currentStep, setCurrentStep] = useState("");
  const [currentApp, setCurrentApp] = useState<any>({});
  const [isEditSSO, setIsEditSSO] = useState(false);
  const [isError, setIsError] = useState<any>({ step: "", errorMessage: "", reason: undefined });
  const [enableRefetching, setEnableRefetching] = useState(false);
  const [fqdnStatus, setFqdnStatus] = useState<any>({});
  const [onDelete, setOnDelete] = useState<boolean | undefined>(false);
  const [FQDN, setFQDN] = useState<any>(undefined);
  const [isNext, setIsNext] = useState<any>(true);

  const {
    control,
    watch,
    setError,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      fqdn: app?.fqdn || "",
    },
  });

  useEffect(() => {
    axiosServices.get("/application/" + app?.id).then(({ data: newApp }) => {
      if (newApp?.appConfig) {
        newApp.appConfig = decompressData(newApp?.appConfig);
      }
      if (newApp?.templateConfig) {
        newApp.templateConfig = decompressData(newApp?.templateConfig);
      }
      setCurrentApp(newApp);
      reset({ fqdn: newApp?.fqdn || "" });
      if (newApp?.fqdn) {
        axios
          .post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/application/${newApp?.id}/fqdn-status`)
          .then(({ data }) => {
            setFqdnStatus(data);
            if (validateCerAndDis(data)) {
              setCurrentStep("info");
            } else if (data?.distributionStatus === "CREATED") {
              setCurrentStep("distribution");
            } else if (data?.certificateStatus === "CERTIFICATE_ISSUED" && data?.distributionStatus !== "CREATED") {
              setCurrentStep("certificate");
            } else if (data?.certificateStatus === "CERTIFICATE_PENDING_VALIDATION") {
              setCurrentStep("certificate");
            } else setCurrentStep("fqdn");
          })
          .catch(() => {
            setCurrentStep("certificate");
          });
      } else {
        setCurrentStep("fqdn");
      }
    });
  }, []);

  useEffect(() => {
    setIsError({ step: "", errorMessage: "", reason: undefined });
    if (currentStep === "fqdn") {
      setEnableRefetching(false);
    } else if (currentStep === "certificate") {
      setEnableRefetching(true);
    } else if (currentStep === "distribution") {
      setEnableRefetching(true);
    } else if (currentStep === "info") {
      setEnableRefetching(false);
    }
  }, [currentStep]);

  const { mutateAsync: updateAppFqdn, isLoading: loadingFqdn } = useMutation({
    mutationFn: () =>
      axios
        .put(process.env.REACT_APP_HOST_API_KEY + `/api/admin/application/${currentApp?.id}/fqdn`, {
          fqdn: watch("fqdn") || "",
          version: currentApp?.version,
        })
        .then(res => {
          setCurrentApp(prev => ({ ...prev, version: res.data.version }));
        }),
  });

  const { mutateAsync: deleteAppFqdn, isLoading: loadingDeletingFqdn } = useMutation({
    mutationFn: () =>
      axios
        .put(process.env.REACT_APP_HOST_API_KEY + `/api/admin/application/${currentApp?.id}/fqdn`, {
          fqdn: "",
          version: currentApp?.version,
        })
        .then(res => {
          setCurrentApp(prev => ({ ...prev, version: res.data.version }));
        }),
  });

  const { mutateAsync: validateFQDN, isLoading: loadingValidationFqdn } = useMutation({
    mutationFn: () => axios.post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/application/${currentApp?.id}/fqdn-status`),
  });

  const { mutateAsync: createDis, isLoading: loadingCreateDis } = useMutation({
    mutationFn: () => axios.post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/application/${currentApp?.id}/distribution`),
  });
  useQuery(
    ["fqdn-status", currentApp?.id],
    () => axios.post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/application/${currentApp?.id}/fqdn-status`),
    {
      onSuccess: data => {
        if (currentStep === "certificate") {
          setFqdnStatus(data?.data);
          if (data?.data?.certificateStatus === "CERTIFICATE_ISSUED") {
            setEnableRefetching(false);
            return;
          }
        } else if (currentStep === "distribution") {
          setFqdnStatus(data?.data);
          if (validateCerAndDis(data?.data)) {
            setEnableRefetching(false);
            return;
          }
        }
      },
      onError: (err: any) => {
        setEnableRefetching(false);
        setIsError({ step: currentStep, errorMessage: err.errorMessage, reason: err?.context?.reason });
      },

      enabled: enableRefetching,
      refetchInterval: enableRefetching ? 5000 : false,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  const validateCerAndDis = (data: any = fqdnStatus) => {
    if (data?.distributionStatus === "DEPLOYED" && data?.distributionEnabled && data?.certificateStatus === "CERTIFICATE_ISSUED") {
      return true;
    }
    return false;
  };

  const handleNext = async () => {
    setIsNext(true);
    setIsError({ step: "", errorMessage: "", reason: undefined });
    if (currentStep === "fqdn") {
      try {
        if (watch("fqdn") === "") {
          setError("fqdn", { message: "Field is required" });
          return;
        }
        await updateAppFqdn();
        setFqdnStatus({});

        setCurrentStep("certificate");
        setFQDN(watch("fqdn"));
        setIsEditSSO(false);
      } catch (error: any) {
        setError("fqdn", { message: error.errorMessage });
      }
    } else if (currentStep === "certificate") {
      try {
        if (fqdnStatus?.certificateStatus !== "CERTIFICATE_ISSUED") return;
        setCurrentStep("distribution");
        if (fqdnStatus?.distributionStatus !== "CREATED" && fqdnStatus?.distributionStatus !== "DEPLOYED") {
          setFqdnStatus({});
          await createDis();
        }
      } catch (err: any) {
        setIsError({ step: "distribution", errorMessage: err.errorMessage, reason: err?.context?.reason });
        setEnableRefetching(false);
      }
    } else if (currentStep === "distribution") {
      if (!validateCerAndDis()) return;
      setCurrentStep("info");
    }
  };

  const handleBack = () => {
    setIsError({ step: "", errorMessage: "", reason: undefined });
    setIsNext(false);
    if (currentStep === "fqdn" && isEditSSO) {
      setCurrentStep("info");
    } else if (currentStep === "certificate") {
      setCurrentStep("fqdn");
    } else if (currentStep === "distribution") {
      setCurrentStep("certificate");
    }
  };

  const handleUpdate = async () => {
    if (currentStep === "distribution" && isError.step === "distribution") {
      try {
        await createDis();
        setIsError({ step: "", errorMessage: "", reason: undefined });
        const certificateResponse = await validateFQDN();
        setFqdnStatus(certificateResponse?.data);
      } catch (err: any) {
        setIsError({ step: "distribution", errorMessage: err.errorMessage, reason: err?.context?.reason });
      }
    } else if (currentStep === "certificate" && isError.step === "certificate") {
      try {
        const certificateResponse = await validateFQDN();
        setFqdnStatus(certificateResponse?.data);
        setIsError({ step: "", errorMessage: "", reason: undefined });
      } catch (err: any) {
        setIsError({ step: "certificate", errorMessage: err.errorMessage, reason: err?.context?.reason });
      }
    } else {
      const certificateResponse = await validateFQDN();
      setFqdnStatus(certificateResponse?.data);
    }
  };
  const handleDone = () => {
    handleClose();
  };

  const variants = {
    hidden: isNext ? { opacity: 0.5, x: 200 } : { opacity: 0.5, x: -200 },
    enter: { opacity: 1, x: 0 },
    exit: isNext ? { opacity: 0.5, x: -200 } : { opacity: 0.5, x: 200 },
  };

  let isNextEnabled = false;
  let isNextLoading = false;

  if (currentStep === "fqdn") {
    isNextEnabled = true;
    isNextLoading = loadingFqdn;
  }

  if (currentStep === "certificate" && fqdnStatus?.certificateStatus === "CERTIFICATE_ISSUED") {
    isNextEnabled = true;
    isNextLoading = loadingCreateDis;
  }

  if (currentStep === "distribution" && validateCerAndDis()) {
    isNextEnabled = true;
  }

  const isBackEnabled = currentStep === "fqdn" ? (isEditSSO ? true : false) : true;

  return (
    <>
      <BXConfirmationDialog
        open={onDelete}
        title={"Are you sure you want to delete this item?"}
        iconButton
        buttonProps={{ color: "error", children: <IconTrashX /> }}
        onConfirm={() => {
          deleteAppFqdn().then(() => {
            setValue("fqdn", "");
            setCurrentStep("fqdn");
            setIsEditSSO(false);
          });
        }}
        isLoading={loadingDeletingFqdn}
        onCancel={() => setOnDelete(false)}
      />
      <AnimatePresence>
        {["fqdn", "certificate", "distribution", "info"].includes(currentStep) ? (
          <Grid xs={12} container>
            <Grid
              container
              xs={12}
              direction='column'
              alignItems='center'
              justifyContent='center'
              height={"90%"}
              overflow={"hidden"}
              paddingY={3}
            >
              {currentStep === "fqdn" && (
                <motion.div
                  variants={variants}
                  initial='hidden'
                  animate='enter'
                  exit='exit'
                  transition={{ type: "spring", duration: 1 }}
                  style={{ width: "100%" }}
                >
                  <Grid container xs={12} alignItems='center' justifyContent='center' spacing={2}>
                    <Grid item xs={7}>
                      <BXInput name={"fqdn"} variant='outlined' label={"Domain name"} control={control} error={errors?.fqdn} />
                    </Grid>
                  </Grid>
                </motion.div>
              )}
              {currentStep === "certificate" && (
                <motion.div
                  variants={variants}
                  initial='hidden'
                  animate='enter'
                  exit='exit'
                  transition={{ type: "spring", duration: 1 }}
                  style={{ width: "100%" }}
                >
                  {isError.step !== "certificate" ? (
                    fqdnStatus?.cname ? (
                      <Grid container xs={12} alignItems='center' justifyContent='center' flexDirection={"row"} rowSpacing={2} paddingX={2}>
                        <Grid item>
                          <Typography textAlign={"center"} fontSize={"20px"} marginBottom={1}>
                            Enter the values in your DNS
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <CopyTextField text={FQDN || currentApp?.fqdn} label={"Domain name"} />
                        </Grid>
                        <Grid item xs={12}>
                          <CopyTextField text={"CNAME"} label={"Type"} />
                        </Grid>
                        <Grid item xs={12}>
                          <CopyTextField text={fqdnStatus?.cname} label={"CNAME Name"} />
                        </Grid>
                        <Grid item xs={12}>
                          <CopyTextField text={fqdnStatus?.cnameValue} label={"CNAME Value"} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container xs={12} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                        <Typography variant='h3' marginBottom={2}>{`Please wait`}</Typography>
                        <CircularProgress size='20px' />
                      </Grid>
                    )
                  ) : (
                    <Grid container xs={12} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                      <Typography variant='h3' marginBottom={2}>
                        {isError.errorMessage}
                      </Typography>

                      {isError?.reason && (
                        <Typography variant='h5' marginBottom={2} padding={2} textAlign={"center"}>
                          {getErrorMessage(isError.reason)}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  <Grid container xs={12} marginTop={2} justifyContent={"space-between"} alignItems={"center"} paddingX={2}>
                    <Typography textAlign={"center"} alignContent={"center"}>
                      {`Status : `}
                      {isError.step !== "certificate" ? (
                        fqdnStatus?.certificateStatus === "CERTIFICATE_ISSUED" ? (
                          <>
                            <IconCircleCheck color={"green"} size={21} style={{ verticalAlign: "middle", marginInlineEnd: 2 }} />
                            success
                          </>
                        ) : (
                          <>
                            <IconClockHour4 color={"orange"} size={21} style={{ verticalAlign: "middle", marginInlineEnd: 4 }} />
                            {fqdnStatus?.cname ? `Pending validation` : `Pending`}
                          </>
                        )
                      ) : (
                        <>
                          <IconExclamationCircle color={"red"} size={21} style={{ verticalAlign: "middle", marginInlineEnd: 2 }} />
                          Failed
                        </>
                      )}
                    </Typography>
                    <IconButton style={{ backgroundColor: palette.grey[100] }} onClick={() => handleUpdate()}>
                      {loadingValidationFqdn ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                    </IconButton>
                  </Grid>
                </motion.div>
              )}
              {currentStep === "distribution" && (
                <motion.div
                  variants={variants}
                  initial='hidden'
                  animate='enter'
                  exit='exit'
                  transition={{ type: "spring", duration: 1 }}
                  style={{ width: "100%" }}
                >
                  <Grid container xs={12} alignItems='center' justifyContent='center' flexDirection={"column"} paddingX={2}>
                    <Grid container xs={12} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                      {isError.step !== "distribution" ? (
                        !fqdnStatus?.distributionDomainName ? (
                          <>
                            <Typography variant='h3' marginBottom={2}>{`Please wait`}</Typography>
                            <CircularProgress size='20px' />
                          </>
                        ) : (
                          <>
                            <Typography
                              variant='h2'
                              marginBottom={2}
                              textAlign={"center"}
                            >{`Make your domain points at the following Distribution domain name`}</Typography>
                            <CopyTextField
                              text={fqdnStatus?.distributionDomainName}
                              label={"Distribution domain name"}
                              dynamicLabelWidth={true}
                            />
                          </>
                        )
                      ) : (
                        <>
                          <Typography variant='h3' marginBottom={2}>
                            {isError.errorMessage}
                          </Typography>
                          {isError?.reason && (
                            <Typography variant='h5' marginBottom={2} padding={2} textAlign={"center"}>
                              {getErrorMessage(isError.reason)}
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>

                    <Grid container xs={12} marginTop={2} justifyContent={"space-between"} alignItems={"center"}>
                      <Typography>
                        {`Status : `}
                        {isError.step !== "distribution" ? (
                          fqdnStatus?.distributionStatus === "DEPLOYED" && fqdnStatus?.distributionEnabled ? (
                            <>
                              <IconCircleCheck color={"green"} size={21} style={{ verticalAlign: "middle", marginInlineEnd: 2 }} />
                              success
                            </>
                          ) : (
                            <>
                              <IconClockHour4 color={"orange"} size={21} style={{ verticalAlign: "middle", marginInlineEnd: 4 }} />
                              {fqdnStatus?.distributionStatus === "CREATED" ? "Pending deployment" : "Pending creation"}
                            </>
                          )
                        ) : (
                          <>
                            <IconExclamationCircle color={"red"} size={21} style={{ verticalAlign: "middle", marginInlineEnd: 2 }} />
                            Failed
                          </>
                        )}
                      </Typography>
                      <IconButton style={{ backgroundColor: palette.grey[100] }} onClick={() => handleUpdate()}>
                        {loadingValidationFqdn ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                      </IconButton>
                    </Grid>
                  </Grid>
                </motion.div>
              )}
              {currentStep === "info" && (
                <motion.div
                  variants={variants}
                  initial='hidden'
                  animate='enter'
                  exit='exit'
                  transition={{ type: "spring", duration: 1 }}
                  style={{ width: "100%" }}
                >
                  <Grid container xs={12} alignItems='center' justifyContent='center' spacing={3} overflow={"auto"}>
                    <Grid item container xs={12} alignItems='center' justifyContent='center' columnSpacing={2} marginInlineStart={2}>
                      <Grid xs={8}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Domain name"}
                          control={control}
                          name={"fqdn"}
                          error={(errors as any)?.fqdn}
                          value={currentApp.fqdn}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          onClick={() => {
                            setCurrentStep("fqdn");
                            setIsEditSSO(true);
                          }}
                        >
                          Edit
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          onClick={() => {
                            setOnDelete(true);
                          }}
                          color='error'
                        >
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} marginInlineStart={2}>
                      <CopyTextField
                        text={fqdnStatus?.distributionDomainName}
                        label={"Distribution domain name"}
                        dynamicLabelWidth={true}
                      />
                      <Typography paddingInlineStart={2} display={"block"} variant='caption'>
                        *Please create a CNAME or A record for your domain name and point it to the distribution domain name.
                      </Typography>
                    </Grid>
                  </Grid>
                </motion.div>
              )}
            </Grid>
            <Grid
              container
              height={"10%"}
              width={"100%"}
              xs={12}
              direction={"row"}
              justifyContent={isBackEnabled ? "space-between" : "end"}
              padding={2}
            >
              {currentStep !== "info" ? (
                <>
                  {isBackEnabled && (
                    <Grid item>
                      <LoadingButton sx={{ minWidth: "100px" }} variant='outlined' onClick={() => handleBack()}>
                        Back
                      </LoadingButton>
                    </Grid>
                  )}
                  <Grid item>
                    <LoadingButton
                      loading={isNextLoading}
                      sx={{ minWidth: "100px" }}
                      variant='contained'
                      onClick={handleSubmit(handleNext)}
                      disabled={!isNextEnabled}
                    >
                      {watch("fqdn") === "" && currentStep === "fqdn" ? "Save" : "Next"}
                    </LoadingButton>
                  </Grid>
                </>
              ) : (
                <Grid container height={"10%"} width={"100%"} xs={12} alignItems={"center"} justifyContent={"center"}>
                  <LoadingButton sx={{ minWidth: "100px" }} variant='contained' onClick={() => handleDone()}>
                    Done
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container xs={12} padding={2} justifyContent={"center"} alignItems={"center"} direction={"column"}>
            <Typography variant='h3' marginBottom={2}>{`Please wait`}</Typography>
            <CircularProgress size='20px' />
          </Grid>
        )}
      </AnimatePresence>
    </>
  );
};

export default ManageDNSForm;
