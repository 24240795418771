// material-ui
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

/**
 * if you want to use image instead of <svg> uncomment following.
 */
// import logoDark from 'assets/images/logo-dark.svg';
import { FC } from "react";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { useBXContext } from "../BXEngine/BXContext";
import { BXIcon } from "./BXUI/Icon";

/**
 */

// ==============================|| LOGO SVG ||============================== //

const Logo: FC<{ application?: any }> = ({ application = undefined }) => {
  const theme: any = useTheme();
  const { currentApp, allowedApps } = useBXContext();
  const currentApplication = application || allowedApps.find(app => app.id === currentApp?.id);
  const iconApplication =
    (currentApplication?.iconConfig?.visibility === "PUBLIC" && currentApplication?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
      ? currentApplication?.iconConfig?.url
      : currentApplication?.iconConfig?.icon) || currentApplication?.icon;

  return (
    <>
      <BXIcon icon={iconApplication} fallBack={"Box"} />
      <Typography mt={1} fontWeight='500'>
        {currentApplication?.name}
      </Typography>
    </>
  );
};

export default Logo;
