import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  DialogActions,
  Grid,
  IconButton,
  Pagination,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { IconCheck, IconEdit, IconMessageLanguage, IconPlus, IconTrash } from "@tabler/icons-react";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { Controller, FieldValues, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import BXModal from "src/components/BXUI/Modal";
import { languagesConstant } from "src/stores/constant";
import { BXApp } from "src/types/BXAppType";
import { v4 as uuid } from "uuid";
import * as yup from "yup";

const ROWS_PER_PAGE = 10;
interface Translation {
  key: string;
  allLanguages: Record<string, string>;
}

type ManageLanguagesProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel: Function;
  multiLingual?: any;
  withAppInfo?: boolean;
  selectable?: boolean;
  activeTab?: number;
  withAuthConfig?: boolean;
  appId?: string;
  selectedRow?: any;
  setSelectedRow?: any;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

const schema = yup.object().shape({
  languages: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Language name is required"),
      rtl: yup.boolean(),
      defaultLanguage: yup.boolean(),
    })
  ),
  translations: yup.array().of(
    yup.object().shape({
      key: yup.string().required("Keyword is required"),
    })
  ),
});

export const isLanguageRTL = languageName => {
  const rtlLanguages = ["arabic", "hebrew", "persian", "urdu", "kashmiri", "pashto", "uyghur", "sorani kurdish", "punjabi", "sindhi"];
  return rtlLanguages.includes(languageName);
};

export const ManageLanguages: FC<ManageLanguagesProps> = ({
  onSave = _.noop,
  multiLingual = undefined,
  withAppInfo = true,
  setIsDirty,
  selectable = false,
  activeTab,
  selectedRow,
  setSelectedRow,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      ...multiLingual,
      languages: multiLingual?.languages,
      translations: multiLingual?.translations,
    } as BXApp,
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const modalTabs = [
    {
      name: "languages",
      label: "Languages",
      value: 1,
    },
    {
      name: "translations",
      label: "Translations",
      value: 2,
    },
  ];

  const {
    fields: languages,
    append: appendLanguages,
    remove: removeLanguages,
  } = useFieldArray({
    control,
    name: "languages",
  });

  const {
    fields: translations,
    append: appendTranslations,
    remove: removeTranslations,
  } = useFieldArray({
    control,
    name: "translations",
  });

  const [tabValue, setTabValue] = useState(activeTab || 1);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [selectedTranslationIndex, setSelectedTranslationIndex] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredTranslations, setFilteredTranslations] = useState<Translation[]>();
  const [newKeyword, setNewKeyword] = useState("");
  const [newTranslationValues, setNewTranslationValues] = useState({});
  const [keywordError, setKeywordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const startNumber = page * ROWS_PER_PAGE + 1;

  const isSelected = index => index === selectedRow;

  const handleOpenModal = index => {
    setSelectedTranslationIndex(index);
    setOpenEditModal(true);
  };

  const handleCloseModal = () => {
    setOpenEditModal(false);
  };

  const handleAddTranslation = () => {
    setOpenTranslationModal(true);
  };

  const handleCloseModalTranslation = () => {
    setOpenTranslationModal(false);
    setNewKeyword("");
    setNewTranslationValues({});
    setKeywordError("");
  };

  const handleRowSelect = index => {
    setSelectedRow(index === selectedRow ? -1 : index);
  };

  const handleAddLanguage = () => {
    const isFirstItem = languages?.length === 0;

    appendLanguages({
      id: uuid(),
      name: "",
      locale: "",
      rtl: false,
      defaultLanguage: isFirstItem,
    });
  };

  const handleDeleteLanguage = index => {
    removeLanguages(index);
  };

  const handleDeleteTranslation = index => {
    removeTranslations(index);
  };

  const handleDefaultLanguageChange = index => event => {
    languages?.forEach((language, idx) => {
      if (idx !== index) {
        setValue(`languages[${idx}].defaultLanguage`, false);
      }
    });
    setValue(`languages[${index}].defaultLanguage`, event.target.checked);
  };

  useEffect(() => {
    reset({
      ...multiLingual,
      languages: multiLingual?.languages,
      translations: multiLingual?.translations,
    });
  }, [multiLingual, reset]);

  // useEffect to filter translations based on search text
  useEffect(() => {
    const translations = watch("translations");
    const filtered = translations?.filter((translation: any) => translation?.key?.toLowerCase().includes(searchText?.toLowerCase()));
    setFilteredTranslations(filtered);
  }, [searchText, watch("translations")]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleSaveTranslation = () => {
    if (newKeyword.trim() === "") {
      setKeywordError("Keyword is required");
      return;
    }

    if (!/^[a-z]*$/.test(newKeyword)) {
      setKeywordError("Keyword Should be lowercase");
      return;
    }

    const newTranslation = {
      key: newKeyword,
      allLanguages: newTranslationValues,
    };

    appendTranslations(newTranslation);
    if (selectable) {
      handleSubmit(onSave)();
    }
    setOpenTranslationModal(false);
    setNewKeyword("");
    setNewTranslationValues({});
  };

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Box sx={{ bgcolor: "background.paper" }} mb={2}>
        <Tabs value={tabValue} onChange={handleTabChange} textColor='primary' indicatorColor='primary'>
          {modalTabs.map(
            ({ value, label, name }, index) =>
              !(index === 0 && selectable) && (
                <Tab key={name} label={<Typography fontWeight={"bold"}>{label}</Typography>} sx={{ textTransform: "none" }} value={value} />
              )
          )}
        </Tabs>
      </Box>

      <Container maxWidth={"lg"}>
        <Grid container spacing={3}>
          {withAppInfo && (
            <>
              {tabValue == 1 && (
                <>
                  <Grid container justifyContent='center' xs={12} mt={4}>
                    <Button startIcon={<IconPlus />} variant='outlined' onClick={handleAddLanguage}>
                      Add Language
                    </Button>
                  </Grid>

                  <Grid container justifyContent='center' xs={12} mt={2}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Language Name</TableCell>
                            <TableCell>Locale</TableCell>
                            <TableCell>RTL</TableCell>
                            <TableCell>Default</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell colSpan={12} align='center'>
                                <CircularProgress />
                              </TableCell>
                            </TableRow>
                          ) : (
                            <>
                              {_.isEmpty(languages) && (
                                <TableRow>
                                  <TableCell colSpan={12}>
                                    <Typography textAlign={"center"}>No Languages</Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                              {languages?.map((language, index) => {
                                const isEnglish = watch(`languages[${index}].name`) === "english";
                                return (
                                  <TableRow key={language.id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                      <Autocomplete
                                        options={languagesConstant.filter(
                                          option => !watch("languages").some(selected => selected.name === option.name)
                                        )}
                                        getOptionLabel={option => option?.name}
                                        disableClearable
                                        disabled={isEnglish}
                                        onChange={(e, selectedOption) => {
                                          const selectedName = selectedOption?.name || "";
                                          const isRTL = isLanguageRTL(selectedName);
                                          setValue(`languages[${index}].name`, selectedName);
                                          setValue(`languages[${index}].locale`, selectedOption?.code || "");
                                          setValue(`languages[${index}].rtl`, isRTL);
                                        }}
                                        value={languagesConstant.find(option => option.name === watch(`languages[${index}].name`))}
                                        renderInput={params => (
                                          <Controller
                                            control={control}
                                            name={`languages[${index}].name`}
                                            render={({ field }) => (
                                              <TextField
                                                {...field}
                                                {...params}
                                                error={(errors as any)?.languages?.[index]?.name}
                                                helperText={(errors as any)?.languages?.[index]?.name.message}
                                                label='Language Name'
                                                variant='outlined'
                                                onDragStart={e => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                }}
                                              />
                                            )}
                                          />
                                        )}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Typography>{watch(`languages[${index}].locale`)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Checkbox checked={watch(`languages[${index}].rtl`)} disabled />
                                    </TableCell>
                                    <TableCell>
                                      <Switch
                                        checked={watch(`languages[${index}].defaultLanguage`)}
                                        onChange={handleDefaultLanguageChange(index)}
                                      />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Tooltip title='Delete'>
                                        <Box mr={1} alignSelf='center'>
                                          <IconButton onClick={() => handleDeleteLanguage(index)} disabled={isEnglish}>
                                            <IconTrash />
                                          </IconButton>
                                        </Box>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              )}

              {tabValue === 2 && (
                <>
                  <Grid container xs={12} mt={4}>
                    <Grid item xs={6}>
                      <TextField
                        size='medium'
                        fullWidth
                        label={"Search"}
                        onChange={e => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                      <Button startIcon={<IconMessageLanguage />} variant='outlined' onClick={handleAddTranslation}>
                        Add Keyword
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent='center' xs={12} mt={2}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Keyword</TableCell>
                            {languages?.map((language, index) => (
                              <TableCell key={`language-${index}`}>{watch(`languages[${index}].name`)}</TableCell>
                            ))}
                            <TableCell align='right'>
                              <Typography fontWeight={"bold"} sx={{ paddingRight: selectable ? "40px" : "20px" }}>
                                Actions
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell colSpan={12} align='center'>
                                <CircularProgress />
                              </TableCell>
                            </TableRow>
                          ) : (
                            <>
                              {_.isEmpty(filteredTranslations) && (
                                <TableRow>
                                  <TableCell colSpan={12}>
                                    <Typography textAlign={"center"}>No Translations</Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                              {filteredTranslations
                                ?.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
                                .map((translation, index) => (
                                  <TableRow
                                    key={`translation-${index}`}
                                    hover
                                    sx={{ cursor: selectable ? "pointer" : "" }}
                                    {...(selectable && {
                                      role: "checkbox",
                                      "aria-checked": isSelected(index),
                                      selected: isSelected(index),
                                    })}
                                  >
                                    <TableCell>{startNumber + index}</TableCell>
                                    <TableCell>
                                      <Typography>{translation.key}</Typography>
                                    </TableCell>
                                    {languages?.map((language, langIndex) => {
                                      const languageName = watch(`languages[${langIndex}].name`) || "";
                                      const translationValue = languageName
                                        ? watch(`translations[${index}].allLanguages.${languageName}`)
                                        : "";

                                      return (
                                        <TableCell key={`translation-cell-${index}-${langIndex}`}>
                                          <Typography>
                                            {languageName && (typeof translationValue === "string" || typeof translationValue === "number")
                                              ? translationValue
                                              : ""}
                                          </Typography>
                                        </TableCell>
                                      );
                                    })}
                                    <TableCell align='right' sx={{ display: "flex", gap: "8px" }}>
                                      <IconButton onClick={() => handleOpenModal(index)}>{<IconEdit />}</IconButton>
                                      <IconButton onClick={() => handleDeleteTranslation(index)}>
                                        <IconTrash />
                                      </IconButton>
                                      {selectable && (
                                        <IconButton
                                          onClick={() => {
                                            handleRowSelect(index);
                                            handleSubmit(onSave);
                                          }}
                                          style={{ color: isSelected(index) ? "green" : "" }}
                                        >
                                          <IconCheck />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {!searchText && (
                      <Pagination
                        count={Math.ceil((filteredTranslations?.length ?? 0) / ROWS_PER_PAGE)}
                        page={page + 1}
                        onChange={(event, newPage) => {
                          setPage(newPage - 1);
                        }}
                        color='primary'
                      />
                    )}
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </Container>

      <BXModal maxWidth='md' label={"Edit Keyword"} title={"Edit Keyword"} open={openEditModal} onClose={handleCloseModal} withoutLabel>
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Keyword</TableCell>
              <TableCell>
                <TextField
                  label='Translation key'
                  fullWidth
                  value={watch(`translations[${selectedTranslationIndex}].key`)}
                  onChange={e => setValue(`translations[${selectedTranslationIndex}].key`, e.target.value)}
                  required
                  variant='outlined'
                />
              </TableCell>
              <StyledTableRow>
                <TableCell>Language</TableCell>
                <TableCell>Value</TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {languages?.map((language, langIndex) => {
                const languageName = watch(`languages[${langIndex}].name`) || "";
                return (
                  <React.Fragment key={`language-${langIndex}`}>
                    <TableRow>
                      <TableCell>{watch(`languages[${langIndex}].name`)}</TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          value={watch(`translations[${selectedTranslationIndex}].allLanguages.${languageName}`) || ""}
                          onChange={e => setValue(`translations[${selectedTranslationIndex}].allLanguages.${languageName}`, e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </BXModal>

      <BXModal
        maxWidth='md'
        label={"Add Keyword"}
        title={"Add Keyword"}
        open={openTranslationModal}
        onClose={handleCloseModalTranslation}
        withoutLabel
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>Keyword</TableCell>
              <TableCell>
                <TextField
                  label='Translation key'
                  value={newKeyword}
                  fullWidth
                  onChange={e => setNewKeyword(e.target.value)}
                  variant='outlined'
                  error={Boolean(keywordError)}
                  helperText={keywordError || " "}
                  required
                />
              </TableCell>
              <StyledTableRow>
                <TableCell>Language</TableCell>
                <TableCell>Value</TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {languages?.map((language, langIndex) => {
                const languageName = watch(`languages[${langIndex}].name`) || "";
                return (
                  <TableRow key={`language-${langIndex}`}>
                    <TableCell>{watch(`languages[${langIndex}].name`)}</TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        value={newTranslationValues[languageName] || ""}
                        onChange={e =>
                          setNewTranslationValues(prevState => ({
                            ...prevState,
                            [languageName]: e.target.value,
                          }))
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Grid item xs={12} mt={4} display={"flex"} justifyContent={"center"}>
            <DialogActions>
              <Button variant='outlined' onClick={handleSaveTranslation}>
                Save
              </Button>
            </DialogActions>
          </Grid>
        </TableContainer>
      </BXModal>

      <Grid item justifyContent='center' xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <Button onClick={handleSubmit(onSave)} variant='contained' aria-label={"save"}>
            Save
          </Button>
        </DialogActions>
      </Grid>
    </Box>
  );
};
