import { Typography as MUITypography } from "@mui/material";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { decodeMarkdown, formatDate } from "src/utils/generalUtils";
import { TypographyProps } from "./types";

const convertText = (text: string) => {
  const cleanedText = text.replace(/\\"/g, '"');

  const newlineParts = cleanedText.split(/\\n|\r?\n/);

  const result: React.ReactNode[] = [];
  newlineParts.forEach((part, index) => {
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/gi;
    const anchorTagRegex = /<a\s+href\s*=\s*"(.*?)"\s*>(.*?)<\/a>/gi;

    let match;
    let lastIndex = 0;

    while ((match = anchorTagRegex.exec(part)) !== null) {
      const textBefore = part.slice(lastIndex, match.index);

      if (textBefore) {
        const subParts = textBefore.split(urlRegex);
        const links = textBefore.match(urlRegex);

        if (!links) {
          result.push(textBefore);
        } else {
          subParts.forEach((subPart, subIndex) => {
            result.push(subPart);
            if (subIndex < links.length) {
              result.push(
                <a key={`link-${index}-${subIndex}`} href={links[subIndex]} target='_blank' style={{ color: "inherit" }} rel='noreferrer'>
                  {links[subIndex]}
                </a>
              );
            }
          });
        }
      }

      const href = match[1];
      const anchorText = match[2];
      result.push(
        <a key={`anchor-${index}-${match.index}`} href={href.trim()} target='_blank' style={{ color: "inherit" }} rel='noreferrer'>
          {anchorText.trim()}
        </a>
      );

      lastIndex = match.index + match[0].length;
    }

    const remainingAfterLastTag = part.slice(lastIndex);
    if (remainingAfterLastTag) {
      const subParts = remainingAfterLastTag.split(urlRegex);
      const links = remainingAfterLastTag.match(urlRegex);

      if (!links) {
        result.push(remainingAfterLastTag);
      } else {
        subParts.forEach((subPart, subIndex) => {
          result.push(subPart);
          if (subIndex < links.length) {
            result.push(
              <a key={`link-${index}-${subIndex}`} href={links[subIndex]} target='_blank' style={{ color: "inherit" }} rel='noreferrer'>
                {links[subIndex]}
              </a>
            );
          }
        });
      }
    }

    if (index < newlineParts.length - 1) {
      result.push(<br key={`br-${index}`} />);
    }
  });

  return result;
};

export const Typography: FC<TypographyProps> = ({ children, format, formatString, config, __config, metaData, ...restProps }) => {
  const location = useLocation();
  const isInBuilder = location.pathname.startsWith("/buildx/form-builder/");
  const { isUserLocalTime } = config || {};
  const isObject = typeof children === "object";

  const prettifyJSON = jsonString => {
    try {
      const formattedJSON = formatJSON(jsonString);
      return formattedJSON !== null ? <pre>{formattedJSON}</pre> : jsonString;
    } catch (error) {
      console.error("Error prettifying JSON:", error);
      return jsonString;
    }
  };

  let content = isObject ? prettifyJSON(JSON.stringify(children)) : children;

  if (typeof content === "string") {
    content = decodeMarkdown(content);

    content = convertText(content);
  }

  const style = isObject ? { overflow: "auto", maxHeight: "300px" } : {};

  if (format === "Date Time") {
    content = formatDate(content, formatString, isInBuilder ? content : "", isUserLocalTime);
  }

  return (
    <MUITypography {...restProps} style={style}>
      {content}
    </MUITypography>
  );
};
