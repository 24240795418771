import { Button, Grid, styled, TextareaAutosize } from "@mui/material";
import { FC, useState } from "react";

interface MarkdownEditorProps {
  markdownText?: string;
  handleChangeProp?: any;
  onClose: () => void;
}

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const MarkdownEditor: FC<MarkdownEditorProps> = ({ markdownText = "", handleChangeProp, onClose }) => {
  const [text, setText] = useState(markdownText);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleSave = () => {
    if (handleChangeProp) {
      handleChangeProp("label")({ target: { value: text } });
    }
    onClose();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Textarea style={{ width: "100%" }} color='primary' minRows={20} value={text} onChange={handleChange} />
      </Grid>
      <Grid item xs={12} textAlign='center'>
        <Button variant='contained' style={{ marginTop: "10px" }} onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default MarkdownEditor;
