import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, TextField, useTheme } from "@mui/material";
import { IconPlus } from "@tabler/icons-react";
import { FC } from "react";
import BXModal from "src/components/BXUI/Modal";

const CreateLayoutModal: FC<any> = ({
  openAddLayoutModal,
  layout,
  appId,
  isUpdate,
  isLoading,
  openViewBuilder,
  onSubmit,
  onOpen = () => {},
  onClose = () => {},
  title = "",
}) => {
  const { palette } = useTheme();
  const { id, name } = layout || {};

  return (
    <BXModal
      open={openAddLayoutModal}
      onClose={onClose}
      title={title}
      label={"Layout"}
      maxWidth={"sm"}
      buttonProps={{
        startIcon: <IconPlus />,
        color: "secondary",
        onClick: onOpen,
        variant: "contained",
        style: { backgroundColor: palette.primary.main, borderRadius: 24 },
        size: "small",
      }}
    >
      <Grid
        container
        spacing={3}
        pt={3}
        component={"form"}
        onSubmit={async (e: any) => {
          e.preventDefault();
          const formData: any = Object.fromEntries(new FormData(e.target).entries());

          onSubmit?.(formData);
        }}
      >
        <Grid item xs={12}>
          <TextField type='text' required name='name' defaultValue={name} fullWidth label='Layout name' />
        </Grid>

        <Grid item xs={12}>
          <Box gap={2} display='flex' justifyContent='center' alignItems='center'>
            <LoadingButton variant='contained' type='submit' loading={isLoading}>
              {isUpdate ? "Update Layout" : "Create Layout"}
            </LoadingButton>
            {isUpdate && (
              <Button variant='outlined' onClick={() => openViewBuilder?.(appId, id)}>
                {"View Builder"}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </BXModal>
  );
};

export default CreateLayoutModal;
