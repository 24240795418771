import { Button, Typography } from "@mui/material";
import { IconX } from "@tabler/icons-react";
import { SnackbarProvider } from "notistack";
import { useBXContext } from "src/BXEngine/BXContext";
import { closeSnackbarRef } from "./SnackbarUtilsConfigurator";

export default function CustomSnackbarProvider({ children }) {
  const { isRTL, currentApp } = useBXContext();
  const { maxSnack, duration, position, variant, closeButton } = currentApp?.appConfig?.snackbar || {};
  const autoHideDuration = duration ? Number(duration) * 1000 : 5000;
  const anchorOrigin = position
    ? {
        vertical: position.split("-")[0] === "top" || position.split("-")[0] === "bottom" ? position.split("-")[0] : "bottom",
        horizontal:
          position.split("-")[1] === "center" || position.split("-")[1] === "left" || position.split("-")[1] === "right"
            ? position.split("-")[1]
            : isRTL
            ? "left"
            : "right",
      }
    : { vertical: "bottom", horizontal: isRTL ? "left" : "right" };

  const actionButton = key => (
    <Button
      onClick={() => {
        closeSnackbarRef(key);
      }}
    >
      {closeButton == "IconX" && <IconX color='white' size={18} />}
      {closeButton == "Dismiss" && <Typography color={"white"}>Dismiss</Typography>}
    </Button>
  );
  const action = closeButton !== "Without Button" ? actionButton : null;
  return (
    <SnackbarProvider maxSnack={maxSnack} autoHideDuration={autoHideDuration} anchorOrigin={anchorOrigin} action={action} variant={variant}>
      {children}
    </SnackbarProvider>
  );
}
