import { Autocomplete, Box, Button, Grid, MenuItem, Step, StepLabel, Stepper, TextField, Typography, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { IconCircleCheck, IconCircleX, IconFileAlert, IconFileUpload, IconPlus } from "@tabler/icons-react";
import axios from "axios";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useQuery } from "react-query";
import BXModal from "src/components/BXUI/Modal";
import useAuth from "src/hooks/useAuth";
import MediaTable from "./MediaTable";

type ManageMediaProps = {
  children?: React.ReactNode;
};

export const ManageMedia: FC<ManageMediaProps> = ({ children }) => {
  const { palette } = useTheme();
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openAddMediaModal, setAddMediaModal] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<Array<string | ArrayBuffer | null>>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [visibilityValue, setVisibilityValue] = useState("PUBLIC");
  const [typeValue, setTypeValue] = useState("");
  const [addNewMedia, setAddNewMedia] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const steps = ["Select Files", "Select Visibility and Type", "Upload Files"];
  const typeValueError = /\s|[^a-zA-Z0-9_.-]/.test(typeValue);
  const helperTextTypeValue = typeValueError ? "Please avoid spaces" : "";

  let queryKeyMediaTypes = ["media-types"];

  const { data: mediaTypes, refetch: refetchMediaTypes } = useQuery(
    queryKeyMediaTypes,
    ({ pageParam }) => {
      return axios.get(process.env.REACT_APP_HOST_API_KEY + `/api/admin/media/types`, {
        params: {
          cursor: pageParam,
          limit: 20,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );

  const typeOptions = [...(mediaTypes?.data?.items || [])];
  const [typeFilter, setTypeFilter] = useState<string | undefined>();
  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    setFiles([]);
    setFileNames([]);
    setPreviews([]);
    setTypeValue("");

    const newFiles = [...acceptedFiles];
    const newFileNames = newFiles.map(file => file.name.toLowerCase());
    const newPreviews = newFiles.map(file => URL.createObjectURL(file) || null);

    setFiles(newFiles);
    setFileNames(newFileNames);
    setPreviews(newPreviews);
    handleNext();
  }, []);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement & {
      files: FileList;
    };

    const newFiles = Array.from(target.files || []);
    const newFileNames = newFiles.map(file => file.name.toLowerCase());
    const newPreviews = newFiles.map(file => URL.createObjectURL(file) || null);

    setFiles(newFiles);
    setFileNames(newFileNames);
    setPreviews(newPreviews);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    refetchMediaTypes();
    setAddNewMedia(false);
  }, [addNewMedia]);

  const handleUpload = async () => {
    try {
      setLoading(true);
      const s3UploadPromises = files.map(async (file, index) => {
        const { data } = await axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/uploads/s3-url", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });

        const { key, url } = data;

        const fileObject = new File([file], file.name, { type: file.type });
        await axios.put(url, fileObject, {
          headers: {
            "Content-Type": fileObject.type,
          },
        });

        const extension = file.name.split(".").pop() || "";

        const requestData = {
          name: fileNames[index].toLowerCase(),
          extension,
          type: typeValue !== "" ? typeValue.toLowerCase() : typeFilter,
          visibility: visibilityValue,
          url: key,
          enabled: false,
        };

        return requestData;
      });

      const uploadedFileData = await Promise.all(s3UploadPromises);

      const apiEndpoint =
        uploadedFileData.length === 1
          ? process.env.REACT_APP_HOST_API_KEY + "/api/admin/media"
          : process.env.REACT_APP_HOST_API_KEY + "/api/admin/media/bulk-create";

      const response = await axios.post(apiEndpoint, uploadedFileData.length === 1 ? uploadedFileData[0] : uploadedFileData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setLoading(false);
      setAddNewMedia(true);
      setError("");
    } catch (error: any) {
      setError(error);
      setErrorMessage(error.response.data.errorMessage);
      setLoading(false);
    }
  };

  const handleInputChange = (event, newValue) => {
    setTypeFilter(newValue);
  };

  return (
    <>
      <Grid container style={{ height: "100%" }}>
        <Grid style={{ height: "100%", padding: 10, paddingTop: 0 }} item md={12}>
          <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2 }}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid container marginBottom={1}>
            <Grid container flex={1} spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography fontSize={"20px"} color='textPrimary' fontWeight={600}>
                  Manage Media
                </Typography>
              </Grid>
            </Grid>
            <BXModal
              open={openAddMediaModal}
              onClose={() => {
                setAddMediaModal(false);
                setActiveStep(0);
                setTypeFilter("");
              }}
              title={`Add New Media`}
              label={"Media"}
              buttonProps={{
                startIcon: <IconPlus />,
                color: "secondary",
                onClick: () => {
                  setAddMediaModal(true);
                },
                variant: "contained",
                style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                size: "small",
              }}
            >
              <Stepper style={{ marginBottom: "30px" }} nonLinear activeStep={activeStep} sx={{ justifyContent: "center" }}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === 0 && (
                <Grid
                  container
                  spacing={3}
                  justifyContent='center'
                  alignItems='center'
                  minHeight={"250px"}
                  onDragOver={e => e.preventDefault()}
                >
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: "2px dashed #aaaaaa",
                      borderRadius: "8px",
                      padding: "16px",
                      textAlign: "center",
                      width: "400px",
                      cursor: "pointer",
                    }}
                  >
                    {isDragActive ? <p>Drop the files here ...</p> : <p>Drag and drop files here or click to select files</p>}
                    <input type='file' style={{ display: "none" }} onChange={handleOnChange} {...getInputProps()} />
                    <Button component='label' variant='contained' startIcon={<IconFileUpload />}>
                      Upload Files
                    </Button>
                  </Box>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid container height={"100%"} minHeight={"250px"}>
                  <Grid container justifyContent='flex-start' spacing={2} marginInlineEnd={4} width={"100%"}>
                    {user?.organization?.id === "default" && (
                      <Grid item xs={4}>
                        <TextField
                          select
                          name='visibility'
                          fullWidth
                          defaultValue='PUBLIC'
                          label='Media visibility'
                          onChange={e => setVisibilityValue(e.target.value)}
                        >
                          {["PUBLIC", "PRIVATE"].map(variant => (
                            <MenuItem key={variant} value={variant}>
                              {variant}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <Autocomplete
                        freeSolo
                        options={typeOptions}
                        getOptionLabel={option => option}
                        onInputChange={handleInputChange}
                        onChange={handleInputChange}
                        renderInput={params => <TextField {...params} variant='outlined' label='Type' fullWidth />}
                      />
                    </Grid>
                  </Grid>

                  {previews.map((preview, index) => {
                    const isInvalidName = files?.length === 0 || /\s|[^a-zA-Z0-9_.-]/.test(fileNames[index] ?? "");
                    const isError = isInvalidName;
                    const helperText = isInvalidName
                      ? "Invalid file name. Please use underscores, hyphens, and dots, and avoid spaces."
                      : "";

                    return (
                      <Grid key={index} alignItems='center' display={"flex"} flexDirection={"row"} py={1} px={4} width={"100%"}>
                        <Grid
                          sx={{ backgroundColor: `${palette.dark.dark}`, borderRadius: "10px" }}
                          width={"100%"}
                          alignItems='center'
                          display={"flex"}
                          flexDirection={"row"}
                          p={1}
                        >
                          {fileNames[index]?.toLowerCase().endsWith(".mp4") || fileNames[index]?.toLowerCase().endsWith(".mov") ? (
                            <video
                              width='100'
                              height='100'
                              style={{ objectFit: "cover", borderRadius: "10px" }}
                              muted
                              autoPlay
                              loop
                              controls={false}
                            >
                              <source src={preview !== null ? (preview as string) : undefined} type='video/mp4' />
                              Your browser does not support the video tag.
                            </video>
                          ) : ["jpeg", "jpg", "png", "webp", "gif", "svg"].some(ext =>
                              fileNames[index]?.toLowerCase().endsWith(`.${ext}`)
                            ) ? (
                            <img
                              src={preview !== null ? (preview as string) : undefined}
                              alt={`File Preview ${index + 1}`}
                              style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                            />
                          ) : (
                            <Box
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: `${palette.background.default}`,
                                borderRadius: "10px",
                              }}
                            >
                              <IconFileAlert width={50} height={50} />
                            </Box>
                          )}

                          <TextField
                            type='text'
                            style={{ paddingInlineStart: "10px" }}
                            name={`name_${index}`}
                            fullWidth
                            label={`Media name ${index + 1}`}
                            value={fileNames[index] ?? ""}
                            error={isError}
                            helperText={helperText}
                            onChange={e => {
                              const updatedFileNames = [...fileNames];
                              updatedFileNames[index] = e.target.value;
                              setFileNames(updatedFileNames);
                            }}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              )}

              {activeStep === 2 && (
                <Grid container height={"100%"} minHeight={"250px"}>
                  <Grid
                    container
                    alignItems='center'
                    justifyContent='start'
                    spacing={1}
                    marginInlineStart={4}
                    height={"60px"}
                    width={"100%"}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography>Please don't close the dialog until upload files.</Typography>
                      </Grid>
                      {error && (
                        <Grid item>
                          <Typography sx={{ color: "red" }}>{errorMessage}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {previews.map((preview, index) => (
                    <Grid key={index} alignItems='center' display={"flex"} flexDirection={"row"} py={1} px={4} width={"100%"}>
                      <Grid
                        sx={{ backgroundColor: `${palette.dark.dark}`, borderRadius: "10px" }}
                        width={"100%"}
                        alignItems='center'
                        display={"flex"}
                        flexDirection={"row"}
                        p={1}
                      >
                        {fileNames[index].toLowerCase().endsWith(".mp4") || fileNames[index].toLowerCase().endsWith(".mov") ? (
                          <video
                            width='100'
                            height='100'
                            style={{ objectFit: "cover", borderRadius: "10px" }}
                            muted
                            autoPlay
                            loop
                            controls={false}
                          >
                            <source src={preview !== null ? (preview as string) : undefined} type='video/mp4' />
                          </video>
                        ) : (
                          <div>
                            {fileNames[index].toLowerCase().endsWith(".jpeg") ||
                            fileNames[index].toLowerCase().endsWith(".jpg") ||
                            fileNames[index].toLowerCase().endsWith(".png") ||
                            fileNames[index].toLowerCase().endsWith(".svg") ||
                            fileNames[index].toLowerCase().endsWith(".gif") ? (
                              <img
                                src={preview !== null ? (preview as string) : undefined}
                                alt={`File Preview ${index + 1}`}
                                style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "10px" }}
                              />
                            ) : (
                              <Box
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: `${palette.background.default}`,
                                  borderRadius: "10px",
                                }}
                              >
                                <IconFileAlert width={50} height={50} />
                              </Box>
                            )}
                          </div>
                        )}
                        {Loading ? (
                          <Box width={"100%"} style={{ paddingInlineStart: "10px" }}>
                            <LinearProgress />
                          </Box>
                        ) : (
                          <Box width={"100%"} style={{ display: "flex", justifyContent: "end" }}>
                            {error ? (
                              <IconCircleX style={{ color: "red", fontSize: 24 }} />
                            ) : (
                              <IconCircleCheck style={{ color: "green", fontSize: 24 }} />
                            )}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}

              <Box pt={2} display={"flex"} justifyContent={"end"} marginInlineEnd={4}>
                <Button disabled={activeStep === 0 || (activeStep === 2 && !error)} onClick={handleBack}>
                  Back
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={
                    (activeStep === 0 && files?.length === 0) ||
                    (activeStep === 1 &&
                      (fileNames.some(name => name?.trim() === "") ||
                        typeFilter?.trim() === "" ||
                        fileNames.some(name => /\s|[^a-zA-Z0-9_.-]/.test(name)))) ||
                    /\s|[^a-zA-Z0-9_.-]/.test(typeValue) ||
                    (activeStep === 2 && Loading) ||
                    !typeFilter
                  }
                  onClick={() => {
                    if (activeStep === 1) {
                      handleUpload();
                    }
                    if (activeStep === steps.length - 1) {
                      setAddMediaModal(false);
                      setTypeFilter("");
                      setTimeout(() => {
                        setActiveStep(0);
                      }, 500);
                    } else {
                      handleNext();
                    }
                  }}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </BXModal>
            <Button
              onClick={() => {
                setAddMediaModal(true);
              }}
              variant='contained'
              startIcon={<IconPlus />}
            >
              Media
            </Button>
          </Grid>
          <MediaTable isImport={false} addNewMedia={addNewMedia} />
        </Grid>
      </Grid>
    </>
  );
};
