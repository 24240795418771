import { ReactNode, createContext, useContext } from "react";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";

interface State {
  formMethods: UseFormReturn<FieldValues>;
}

const StateContext = createContext<State | null>(null);

export const StateProvider = ({ children }: { children: ReactNode }) => {
  const formMethods = useForm<FieldValues>();

  return <StateContext.Provider value={{ formMethods }}>{children}</StateContext.Provider>;
};

export const useViewStateContext = () => {
  const context = useContext(StateContext);
  if (context === null) {
    throw new Error("useViewStateContext must be used within a StateProvider");
  }
  return context;
};
