import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Autocomplete, CircularProgress, Tab, TextField, useTheme } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { queryClient } from "src/BXEngine/BXContext";
import BXModal from "src/components/BXUI/Modal";
import { ReactComponent as IconApiApp } from "../../../../assets/images/icons/swagger Icon.svg";
import SwaggerUIModal from "../AppBuilder/components/SwaggerUIModal";
import { CreateOASForm } from "../ManageOAS/CreateOASForm";

const OASSelector = (props: any) => {
  const { swaggerProps } = props;
  const [swaggerKey, setSwaggerKey] = useState();
  const [value, setValue] = useState("1");

  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  let querykey = ["oas-list"];
  if (searchText.trim()) {
    querykey.push(searchText.trim());
  }
  const { data: options, isFetching } = useQuery(
    querykey,
    ({ pageParam }) => {
      return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/oas-def", {
        params: {
          cursor: pageParam,
          keyword: searchText || undefined,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!autoCompleteOpen,
    }
  );

  const loading = autoCompleteOpen && isFetching;

  const { palette } = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <BXModal
        title={"OAS Files"}
        icon={<IconApiApp color={palette.text.primary} />}
        withoutLabel
        buttonProps={{
          startIcon: <IconApiApp />,
          color: "primary",
          size: "small",
          variant: "contained",
        }}
      >
        {(handleClose: Function) => {
          return (
            <>
              <TabContext value={value}>
                <TabList
                  textColor='inherit'
                  // classes={{ indicator: classes.indicatorStyle, flexContainer: classes.root }}
                  onChange={handleChange}
                  aria-label='lab API tabs example'
                >
                  <Tab style={{ textTransform: "none" }} label='Uploaded Files' value='1' />
                  <Tab style={{ textTransform: "none" }} label='Upload file' value='2' />
                </TabList>
                <TabPanel value='1'>
                  <Autocomplete
                    sx={{ width: 300 }}
                    open={autoCompleteOpen}
                    onOpen={() => {
                      setAutoCompleteOpen(true);
                    }}
                    onClose={() => {
                      setAutoCompleteOpen(false);
                    }}
                    onChange={(e, newValue) => {
                      setSwaggerKey(newValue?.id);
                      handleClose();
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                    getOptionLabel={option => option.name}
                    options={(options?.data?.items as any) || []}
                    loading={loading}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='OAS files'
                        onChange={e => setSearchText(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color='inherit' size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </TabPanel>
                <TabPanel value='2'>
                  <CreateOASForm
                    onSave={(formData, event) => {
                      const data = {
                        fileName: formData?.fileName,
                        key: formData?.data?.key,
                        extension: formData?.data?.extension,
                      };
                      axios
                        .post(
                          process.env.REACT_APP_HOST_API_KEY + "/api/admin/oas-def",
                          {
                            name: data.fileName,
                            extension: data.extension,
                          },
                          {
                            params: { oasDefKey: data.key },
                            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                          }
                        )
                        .then(res => {
                          setSwaggerKey(res.data?.id);
                          queryClient.setQueryData(["oas-list"], ({ pages: [page1, ...rest] }) => {
                            return {
                              // @ts-ignore
                              pages: [{ ...page1, data: { items: [res.data, ...page1.data.items] } }, ...rest],
                            };
                          });
                        });
                      handleClose?.();
                    }}
                  />
                </TabPanel>
              </TabContext>
            </>
          );
        }}
      </BXModal>
      {swaggerKey && (
        <SwaggerUIModal
          isOpen={true}
          handleSwaggerClose={() => {
            setSwaggerKey(undefined);
          }}
          swaggerId={swaggerKey}
          {...swaggerProps}
        />
      )}
    </>
  );
};

export default OASSelector;
