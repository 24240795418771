import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IconPlaylistAdd, IconTrashX } from "@tabler/icons-react";
import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import BXModal from "src/components/BXUI/Modal";
import { v4 as uuid } from "uuid";
import DraggableRow from "../AppBuilder/forms/DraggableRow";
import { splitStringToNumbers } from "../FormBuilder/utils";

const GroupForm = (props: any) => {
  const { errors, control, watch, canHaveGroup, isGroupEnabled, setValue } = props;
  const seriesArray = useFieldArray({
    control,
    name: "series",
  });

  const handleAddLine = () => {
    seriesArray.fields.forEach((field: any, index) => {
      const newValue = [
        ...watch(`series[${index}].data`),
        {
          name: "",
          data: [],
          id: uuid(),
        },
      ];
      setValue(`series[${index}].data`, newValue);
    });
  };

  const handleEditName = (targetIdx: number, newName: string) => {
    seriesArray.fields.forEach((_field: any, index) => {
      setValue(`series[${index}].data[${targetIdx}].name`, newName);
    });
  };

  const handleDeleteLine = (targetIndex: number) => {
    seriesArray.fields.forEach((_field: any, index) => {
      const newValue = watch(`series[${index}].data`)?.filter((_line, idx) => idx !== targetIndex);
      setValue(`series[${index}].data`, newValue);
    });
  };
  if (canHaveGroup && !isGroupEnabled) {
    return (
      <NestedForm
        groupIndex={0}
        control={control}
        errors={errors}
        appendSeries={seriesArray.append}
        isSeriesEmpty={seriesArray.fields.length === 0}
        setValue={setValue}
        watch={watch}
        handleEditName={handleEditName}
        handleAddLine={handleAddLine}
        handleDeleteLine={handleDeleteLine}
      />
    );
  }
  return (
    <Card>
      <CardContent style={{ margin: "0", padding: "0" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell width={250}>{canHaveGroup ? "Group Title" : "Title"}</TableCell>
                <TableCell width={500}>{canHaveGroup ? "Y-Axis Configuration" : "Data"}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {seriesArray.fields?.map((group, groupIndex) => (
                <DraggableRow
                  component={TableRow}
                  key={group?.id}
                  id={group?.id}
                  index={groupIndex}
                  name='table-columns'
                  moveElement={seriesArray?.move}
                >
                  <TableCell>{groupIndex + 1}.</TableCell>
                  <TableCell width={300}>
                    {canHaveGroup ? (
                      <BXInput
                        fullWidth
                        label={"Group Title"}
                        name={`series[${groupIndex}].type`}
                        value={watch(`series[${groupIndex}].type`)}
                        control={control}
                        draggable
                        variant='outlined'
                        error={errors?.series?.[groupIndex]?.type}
                        onDragStart={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    ) : (
                      <BXInput
                        fullWidth
                        label={canHaveGroup ? "Line Title" : "Title"}
                        name={`series[${groupIndex}].name`}
                        value={watch(`series[${groupIndex}].name`)}
                        control={control}
                        draggable
                        variant='outlined'
                        error={errors?.series?.[groupIndex]?.name}
                        onDragStart={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell width={500}>
                    {canHaveGroup ? (
                      <BXModal
                        label={"Configure"}
                        maxWidth='md'
                        buttonProps={{
                          variant: "outlined",
                          style: errors?.series?.[groupIndex]?.data ? { borderColor: "red" } : {},
                        }}
                        title='Chart Config'
                      >
                        <NestedForm
                          groupIndex={groupIndex}
                          control={control}
                          watch={watch}
                          errors={errors}
                          group={group}
                          setValue={setValue}
                          handleEditName={handleEditName}
                          handleAddLine={handleAddLine}
                          handleDeleteLine={handleDeleteLine}
                        />
                      </BXModal>
                    ) : (
                      <BXInput
                        fullWidth
                        label={"Data"}
                        name={`series[${groupIndex}].dataString`}
                        control={control}
                        error={errors?.series?.[groupIndex]?.dataString}
                        onChange={() => {
                          setValue(`series[${groupIndex}].data`, splitStringToNumbers(watch(`series[${groupIndex}].dataString`)));
                        }}
                        draggable
                        onDragStart={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell width={100}>
                    <IconButton
                      onClick={() => {
                        seriesArray.remove(groupIndex);
                      }}
                    >
                      <IconTrashX color='red' />
                    </IconButton>
                  </TableCell>
                </DraggableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Grid container justifyContent={"center"} alignItems='center' mt={2} mb={2}>
        <Button
          variant='outlined'
          startIcon={<IconPlaylistAdd />}
          onClick={() => {
            if (canHaveGroup && isGroupEnabled) {
              seriesArray.append({
                id: uuid(),
                type: "",
                data: [...(watch(`series[${0}].data`) || [])],
              });
            } else {
              seriesArray.append({ id: uuid(), name: "", data: [] });
            }
          }}
        >
          {isGroupEnabled ? "Add Group" : "Add Line"}
        </Button>
      </Grid>
    </Card>
  );
};

function NestedForm(props): JSX.Element {
  const { groupIndex, control, errors, setValue, isSeriesEmpty, watch, handleEditName, handleAddLine, handleDeleteLine } = props;
  useEffect(() => {
    if (isSeriesEmpty) {
      setValue("series", [
        {
          type: "",
          data: [],
          id: uuid(),
        },
      ]);
    }
  }, []);

  const group = useFieldArray({
    control,
    name: `series[${groupIndex}].data`,
    keyName: "id",
  });
  return (
    <Card>
      <CardContent
        style={{
          margin: "0",
          padding: "0",
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell width={250}>Line Title</TableCell>
                <TableCell width={600}>Y-Axis Values(comma-separated)</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {group.fields?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography textAlign={"center"}>No Lines</Typography>
                  </TableCell>
                </TableRow>
              )}
              {group.fields?.map((line, lineIndex) => (
                <DraggableRow
                  component={TableRow}
                  key={line.id}
                  id={line.id}
                  index={lineIndex}
                  name='table-columns'
                  moveElement={group.move}
                >
                  <TableCell>{lineIndex + 1}.</TableCell>
                  <TableCell width={250}>
                    <BXInput
                      fullWidth
                      label={"Line Title"}
                      name={`series[${groupIndex}].data[${lineIndex}].name`}
                      value={watch(`series[${groupIndex}].data[${lineIndex}].name`)}
                      control={control}
                      onChange={() => {
                        handleEditName(lineIndex, watch(`series[${groupIndex}].data[${lineIndex}].name`));
                      }}
                      error={errors?.series?.[groupIndex]?.data?.[lineIndex]?.name}
                      draggable
                      onDragStart={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  </TableCell>
                  <TableCell width={450}>
                    <BXInput
                      fullWidth
                      label={"Y-Axis Values (comma-separated)"}
                      draggable
                      name={`series[${groupIndex}].data[${lineIndex}].dataString`}
                      control={control}
                      value={watch(`series[${groupIndex}].data[${lineIndex}].dataString`)}
                      error={errors?.series?.[groupIndex]?.data?.[lineIndex]?.dataString}
                      onChange={() => {
                        setValue(
                          `series[${groupIndex}].data[${lineIndex}].data`,
                          splitStringToNumbers(watch(`series[${groupIndex}].data[${lineIndex}].dataString`))
                        );
                      }}
                      onDragStart={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        handleDeleteLine(lineIndex);
                      }}
                    >
                      <IconTrashX color='red' />
                    </IconButton>
                  </TableCell>
                </DraggableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Grid container justifyContent={"center"} alignItems='center' mt={2} mb={2}>
        <Button variant='outlined' startIcon={<IconPlaylistAdd />} onClick={() => handleAddLine()}>
          Add Line
        </Button>
      </Grid>
    </Card>
  );
}

export default GroupForm;
