import { Divider, Grid, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { IconCopy } from "@tabler/icons-react";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";

const CopyTextField = ({ text, label, dynamicLabelWidth }: { text: string; label: string; dynamicLabelWidth?: boolean }) => {
  const { palette } = useTheme();
  return (
    <TextField
      disabled={true}
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "white",
        },
        padding: 0,
        width: "100%",
      }}
      InputProps={{
        style: { padding: 0, minWidth: "100%" },
        disabled: true,
        value: text,
        inputProps: {
          style: { paddingInlineStart: 0, paddingInlineEnd: 1, textAlign: "center" },
        },
        startAdornment: (
          <InputAdornment position='start' style={{ height: "100%" }}>
            <Grid
              container
              sx={{
                width: dynamicLabelWidth ? "auto" : "120px",
                height: "50px",
                whiteSpace: dynamicLabelWidth ? "nowrap" : "pre-line",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ paddingX: 1, verticalAlign: "center" }}
                color='lightgray'
                width={"100%"}
                textAlign={"center"}
                justifySelf={"center"}
              >
                {label}
              </Typography>
            </Grid>
            <Divider orientation='vertical' variant='middle' flexItem sx={{ margin: 0 }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='start'>
            <IconButton
              style={{ backgroundColor: "transparent", padding: 2 }}
              children={
                <IconCopy
                  color={palette.text.primary}
                  onClick={() => {
                    try {
                      navigator.clipboard.writeText(text);
                      enqueueSnackbarRef?.("Copied To Clipboard", {
                        variant: "success",
                      });
                    } catch (error) {
                      enqueueSnackbarRef?.("Couldn't copy To Clipboard", {
                        variant: "error",
                      });
                    }
                  }}
                  style={{ padding: 0 }}
                />
              }
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CopyTextField;
