import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogActions, DialogContent, Grid, InputLabel } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import * as yup from "yup";
import { TablePicker } from "../FormBuilder/components/TablePicker";

type CreateRoleFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel?: Function;
  editing?: boolean;
  role?: any;
  height?: string | number;
  withoutPassword?: boolean;
  onlyPassword?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateRoleForm: FC<CreateRoleFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  role,
  editing = false,
  height,
  withoutPassword = false,
  onlyPassword,
  setIsDirty,
}) => {
  const schema = yup
    .object({
      name: yup.string().required(),
      description: yup.string(),
      defaultRole: yup.boolean(),
      superAdminRole: yup.boolean(),
      policies: yup.array(yup.object({ id: yup.string() })),
    })
    .required();

  const defaultValues = {
    id: role?.id || undefined,
    name: role?.name,
    description: role?.description,
    defaultRole: role?.defaultRole,
    policies: role?.policies,
    superAdminRole: role?.superAdminRole,
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: editing
      ? defaultValues
      : {
          superAdminRole: false,
          defaultRole: true,
        },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [policies, setPolicies] = useState<any[]>([]);

  useCallbackPrompt(isDirty);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/policy", {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      })
      .then(res => {
        setPolicies(res.data?.items || []);
      });
    setIsDirty?.(isDirty);
  }, [isDirty]);

  return (
    <>
      <DialogContent>
        <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
          <Grid container spacing={3} height={height}>
            <Grid item xs={12}>
              <BXInput
                name={"name"}
                control={control}
                label='Role name'
                variant='outlined'
                error={errors.name}
                id={"bx-role-create-name-input"}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <BXInput
                name={"description"}
                control={control}
                label='Role description'
                variant='outlined'
                error={errors.description}
                id={"bx-role-create-description-input"}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} lg={12} gap={1} display={"flex"} flexDirection={"column"}>
              <InputLabel>Policies</InputLabel>
              <Controller
                name='policies'
                control={control}
                render={({ field }) => {
                  return (
                    <TablePicker
                      renderSubRow={(row, isSelectd) => (
                        <CodeBlock
                          codeContainerStyle={{
                            fontSize: "12px",
                          }}
                          theme={dracula}
                          text={JSON.stringify(row.statements, null, "\t")}
                          language='javascript'
                          showLineNumbers
                        />
                      )}
                      columns={[
                        {
                          name: "Policy",
                          source: "name",
                          sortable: true,
                        },
                        {
                          name: "Description",
                          source: "description",
                        },
                      ]}
                      rows={policies}
                      defaultSelection={field.value}
                      onSelectionChange={selection => field.onChange(selection)}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{ paddingInlineStart: 24, paddingInlineEnd: 24 }}>
        <Button onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};
