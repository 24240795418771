import MonacoEditor from "@monaco-editor/react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { DateTimePicker } from "@mui/x-date-pickers";
import { isObject, splitUpperCase } from "hd-utils";
import _ from "lodash";
import React, { ChangeEventHandler, FC, useEffect, useState } from "react";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXIconPicker } from "src/components/BXUI/FormControls/IconPicker";
import BXModal from "src/components/BXUI/Modal";
import { TranslationInput } from "../../../AppBuilder/components/TranslationInput";
import { CreateChartForm } from "../../../ManageComponents/CreateChartForm";
import { CreateSelectDataSource } from "../../../ManageComponents/CreateSelectDataSource";
import { MapChainActions } from "../../MapChainActions";
import StepperConfiguration from "../../StepperConfiguration";
import { ComponentItemType } from "../../types";
import {
  alignItemsValues,
  alignSelfValues,
  containersTypes,
  flexChildIncludedProps,
  flexContainerIncludedProps,
  flexDirectionValues,
  getItemClosestProp,
  gridChildIncludedProps,
  gridContainerIncludedProps,
  isParentFlex,
  isParentGrid,
  justifyContentValues,
  overflowValues,
  typographyAlign,
  typographyComponents,
  typographyFormats,
  uploaderTypes,
  visibilityTypes,
  wrapValues,
} from "../../utils";
import { customContainerConfig, flexContainerConfig, gridContainerConfig } from "../../viewBuilderComponentConfig";
import { AutoCompleteCSSComponent } from "./AutoCompleteCSSComponent";
import CustomErrorMessage from "./CustomErrorMessage";
import { InteractionConfig } from "./InteractionConfig";
import ItemsPropertiesAccordion from "./ItemsPropertiesAccordion";
import MapValuesComponent from "./MapValuesComponent";
import MapValueSelector from "./MapValueSelector";
import MarkdownEditor from "./MarkdownEditor";

interface ComponentEditorProps {
  item: any;
  layoutBreak: any;
  onDeleteItem?: (itemId: any) => void;
  onChangeProp?: (item: any) => void;
  appId?: string;
  collectionId?: string;
  pageId?: string;
  multiLingual?: any;
  setMultiLingual?: any;
  views?: any;
  view?: any;
  setView?: any;
}

const JsonThemes = [
  "apathy",
  "apathy:inverted",
  "ashes",
  "bespin",
  "brewer",
  "bright:inverted",
  "bright",
  "chalk",
  "codeschool",
  "colors",
  "eighties",
  "embers",
  "flat",
  "google",
  "grayscale",
  "grayscale:inverted",
  "greenscreen",
  "harmonic",
  "hopscotch",
  "isotope",
  "marrakesh",
  "mocha",
  "monokai",
  "ocean",
  "paraiso",
  "pop",
  "railscasts",
  "rjv-default",
  "shapeshifter",
  "shapeshifter:inverted",
  "solarized",
  "summerfruit",
  "summerfruit:inverted",
  "threezerotwofour",
  "tomorrow",
  "tube",
  "twilight",
];

export const interactionableComponents = [ComponentItemType.TextField];

export const ComponentEditor: FC<ComponentEditorProps> = ({
  item,
  layoutBreak,
  onDeleteItem,
  onChangeProp,
  appId,
  collectionId,
  pageId,
  multiLingual,
  setMultiLingual,
  views,
  view,
  setView,
}) => {
  const uploadTypes = ["Image", "Audio", "Video"];

  const {
    key,
    id,
    testId,
    src,
    sx,
    variant,
    uploaderType = "Default",
    format,
    formatString,
    defaultValue,
    disabled,
    placeholder,
    required,
    labelChip,
    isRegex,
    label,
    children,
    fontSize,
    component,
    iconName,
    alt,
    align,
    controls,
    disabledCache,
    size,
    backgroundColor,
    color,
    borderColor,
    labelColor,
    maxRows,
    multiline,
    rows,
    toolTip,
    JsonTheme,
    isEditable,
    minValue,
    maxValue,
    passwordValidation,
    includeLowercase,
    includeUppercase,
    includeNumbers,
    includeSymbols,
    minLengthPassword,
    emailValidation = true,
    colorPickerInvalid = true,
    numberValidation,
    urlValidation,
    stringValidation,
    minLength,
    textFieldRegex,
    maxLength,
    type,
    enableHttpValidation,
    enableHttpsValidation,
    uuidValidation = true,
    matchValidation = true,
    matchValue,
    customMatchMessageContent,
    customRequiredMessageContent,
    customRegexMessageContent,
    customStringMessageContent,
    customNumberMessageContent,
    customColorMessageContent,
    customEmailMessageContent,
    customPasswordMessageContent,
    customUrlMessageContent,
    customUUidMessageContent,
    isReadOnly,
    isLeft,
    isMapValues,
    enableLoading = true,
    mapValuesObject,
    endIcon,
    startIcon,
    startIconColor,
    endIconColor,
    endIconSize,
    startIconSize,
    uploadLabel,
    // TODO: remove this after supporting action config upload
    iconColor,
    IconBorderWidth,
    iconSize,
    IconBorderColor,
    IconBackgroundColor,
    DateDisplayStyle,
    isAdvance,
    groupName,
    radioValue,
    checkboxValue,
    containerType,
    singleValue,
    customErrorMessageStartDate,
    customErrorMessageEndDate,
  } = item?.props || {};

  const {
    groupReference,
    bulletsClickable,
    orientation,
    bulletHoverOpacity,
    bulletHoverColor,
    selectedBulletShape,
    selectedBulletColor,
    selectedBulletWidth,
    selectedBulletHeight,
    unselectedBulletHeight,
    unselectedBulletWidth,
    bulletDisplay,
    unselectedBulletOpacity,
    unselectedBulletColor,
    unselectedBulletShape,
    selectedBulletOpacity,
  } = item?.config?.navigator || {};

  const {
    uploadAllowedTypes,
    multipleFiles,
    signedUrl,
    finalizedUrl,
    customUrl,
    isCropImage,
    fileNumber,
    uploadUrlType,
    withPreview,
    widthPreview,
    heightPreview,
    borderRadiusPreview,
  } = item?.config?.uploadConfig || {};

  const { isUserLocalTime = true, isMapDataSource, dataEntry, keyLabel, keyValue } = item?.config || {};

  const { disableCondition = "", withOverlay = false, overlayColor = "#000000", overlayOpacity = "0.5" } = item?.config?.disable || {};

  const { selectedVisibilityType = "Hidden", visibilityCondition = "" } = item?.config?.visibility || {};

  //Loading Configuration on item
  const {
    loadingView = "",
    loadingImage,
    loadingOverlayColor = "#000000",
    loadingOverlayOpacity = "0.5",
    isLoadingViewEnabled,
    isLoadingImageEnabled,
    loadingViewType = "centered",
    loadingImageType = "centered",
  } = item?.config?.loadingConfig || {};

  const [styles, setStyles] = useState(sx);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActionsConfigModalOpen, setIsActionsConfigModalOpen] = useState(false);
  const [isInteractionConfigModalOpen, setIsInteractionConfigModalOpen] = useState(false);
  const [isMarkdownModalOpen, setMarkdownModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(-1);

  const { palette } = useTheme();
  const [accordionState, setAccordionState] = useState({
    general: false,
    dataSource: false,
    widthConfig: false,
    size: false,
    display: false,
    validation: false,
    chip: false,
    hint: false,
    media: false,
    image: false,
    upload: false,
    button: false,
    typography: false,
    iconButton: false,
    flexContainer: false,
    gridContainer: false,
    RepeatedSection: false,
    flexChildIncludedProps: false,
    gridChildIncludedProps: false,
    repeatedItem: false,
    chart: false,
    styles: false,
    JsonViewer: false,
    MarkdownViewer: false,
    ColorPicker: false,
    actionConfig: false,
    date: false,
    time: false,
    select: false,
    radio: false,
    checkbox: false,
    visibility: false,
    disable: false,
    repeated: false,
    checkboxGroup: false,
    radioGroup: false,
    interactionConfig: false,
    stepper: false,
    navigator: false,
    dateTime: false,
    loading: false,
  });

  type AccordionState = {
    general: boolean;
    dataSource: boolean;
    widthConfig: boolean;
    size: boolean;
    display: boolean;
    validation: boolean;
    chip: boolean;
    hint: boolean;
    media: boolean;
    image: boolean;
    upload: boolean;
    button: boolean;
    typography: boolean;
    iconButton: boolean;
    flexContainer: boolean;
    gridContainer: boolean;
    RepeatedSection: boolean;
    flexChildIncludedProps: boolean;
    gridChildIncludedProps: boolean;
    repeatedItem: boolean;
    chart: boolean;
    styles: boolean;
    JsonViewer: boolean;
    MarkdownViewer: boolean;
    ColorPicker: boolean;
    actionConfig: boolean;
    date: boolean;
    time: boolean;
    select: boolean;
    radio: boolean;
    checkbox: boolean;
    visibility: boolean;
    disable: boolean;
    repeated: boolean;
    checkboxGroup: boolean;
    radioGroup: boolean;
    dateTime: boolean;
    loading: boolean;
    interactionConfig: true;
    stepper: boolean;
    navigator: boolean;
  };

  const handleChangeStyles = (newStyle: any) => {
    setStyles(newStyle);
  };

  useEffect(() => {
    setStyles(sx);
  }, [item?.id]);

  useEffect(() => {
    const translations = multiLingual?.translations;
    if (selectedRow >= 0 && translations && selectedRow < translations.length) {
      const selectedTranslation = translations[selectedRow];
      const newLabel = `{i18n.${selectedTranslation.key}}`;
      const newItem = {
        ...item,
        props: {
          ...item?.props,
          label: newLabel,
          children: newLabel,
        },
      };

      onChangeProp?.(newItem);
    }
  }, [selectedRow]);

  const handleSave = data => {
    onChangeProp?.({ ...item, configData: { ...data } });
    setIsModalOpen(false);
  };

  const handleSaveSelectComponent = (data, selectedValue) => {
    onChangeProp?.({
      ...item,
      name: data.name,
      configData: { ...data.config },
      dataSourceMap: {
        ...item?.dataSourceMap,
        [selectedValue]: { ...data.config },
      },
    });
    setIsModalOpen(false);
  };

  // 🟥End Chart Logic

  function getFileType(filename: string): string {
    const extension = filename.split(".").pop()?.toLowerCase() ?? "";
    if (extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif") {
      return "img";
    } else if (extension === "mp3" || extension === "wav" || extension === "ogg") {
      return "audio";
    } else if (extension === "mp4" || extension === "avi" || extension === "mov") {
      return "video";
    } else {
      return "auto";
    }
  }

  const saveStyleHandler = () => {
    const newItem = { ...item, props: { ...item?.props, sx: { ...(styles as any) } } };
    onChangeProp?.(newItem);
  };

  const handleConfigChange =
    (type: string, dataType?: string, overrideValue?: any) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newItem = { ...item };

      let val: any = (isObject(e) ? e.target?.value : e) || "";

      if (["minutesStep", "secondsStep"].includes(type)) {
        val = Math.max(val, 1);
        val = Math.min(val, 60);
      }

      if (type === "hoursStep") {
        val = Math.max(val, 1);
        val = Math.min(val, 24);
      }

      if (["minutesStep", "secondsStep"].includes(type)) {
        val = Math.max(val, 1);
        val = Math.min(val, 60);
      }

      if (type === "hoursStep") {
        val = Math.max(val, 1);
        val = Math.min(val, 24);
      }

      if (dataType === "number") {
        val = Number(val);
      } else if (dataType === "boolean") {
        val = !_.get(newItem, "config." + type);
      }

      val = overrideValue?.(val) || val;

      _.set(newItem, "config." + type, val);

      if (type === "uploadConfig.uploadAllowedTypes" && (val.includes("Video") || val.includes("Audio"))) {
        _.set(newItem, "config.uploadConfig.isCropImage", false);
      }

      if (type === "uploadConfig.multipleFiles" && val) {
        _.set(newItem, "config.uploadConfig.isCropImage", false);
      }

      if (type === "uploadConfig.isCropImage" && val) {
        _.set(newItem, "config.uploadConfig.multipleFiles", false);
      }

      onChangeProp?.(newItem);
    };

  const updateChildrenProps = (children: any[], type: string, value: any) => {
    return children.map((child: any) => {
      const updatedChild = { ...child };
      if (type === "defaultValue") {
        updatedChild.props = { ...child.props, defaultValue: value };
      } else if (type === "key") {
        updatedChild.props = { ...child.props, groupName: value };
      } else if (type === "customRequiredMessageContent") {
        updatedChild.props = { ...child.props, customRequiredMessageContent: value };
      } else if (type === "singleValue") {
        updatedChild.props = { ...child.props, singleValue: value };
      } else if (type === "required") {
        updatedChild.props = { ...child.props, required: value };
      }
      return updatedChild;
    });
  };

  const handleDateConfigChange = (type, newDate) => {
    const newItem = { ...item };
    _.set(newItem, "config." + type, newDate);
    onChangeProp?.(newItem);
  };

  const onInteractionConfigSave = newConfig => {
    const updatedItem = {
      ...item,
      interactionConfig: [...newConfig.interactionConfig],
    };

    onChangeProp?.(updatedItem);
    setIsInteractionConfigModalOpen(false);
  };

  const handleChangeProp =
    (type: string, options: { mapValues?: boolean; mapValuesObject?: any } = {}) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const val = (isObject(e) ? e.target?.value : e) || "";
      let newItem = {};
      const newValue = options?.mapValues
        ? {
            mapValuesObject: options.mapValuesObject,
            value: val,
          }
        : val;
      const isCheckboxOrRadio = item?.config?.isGroup;
      if (isCheckboxOrRadio && (type === "defaultValue" || type === "key" || type === "customRequiredMessageContent")) {
        newItem = {
          ...item,
          props: {
            ...item.props,
            [type]: val,
          },
          children: updateChildrenProps(item.children || [], type, newValue),
        };
      } else if (flexContainerIncludedProps.includes(type) || gridContainerIncludedProps.includes(type)) {
        newItem = { ...item, props: { ...item?.props, [type]: newValue } };
      } else if (type === "src" && component === "auto") {
        newItem = { ...item, props: { ...item?.props, component: getFileType(val), [type]: newValue } };
      } else {
        newItem = { ...item, props: { ...item?.props, [type]: newValue } };
      }

      onChangeProp?.(newItem);
    };

  const handleChangeGridTemplateColumns = (type: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newItem = { ...item };
    const val = (isObject(e) ? e.target?.value : e) || "";
    _.set(newItem, type, val);

    if (
      type.includes("extraSmall") ||
      type.includes("small") ||
      type.includes("medium") ||
      type.includes("large") ||
      type.includes("extraLarge")
    ) {
      newItem.props.sx.gridTemplateColumns = {
        xs: `repeat(${newItem.props?.extraSmall}, 1fr)`,
        sm: `repeat(${newItem.props?.small}, 1fr)`,
        md: `repeat(${newItem.props?.medium}, 1fr)`,
        lg: `repeat(${newItem.props?.large}, 1fr)`,
        xl: `repeat(${newItem.props?.extraLarge}, 1fr)`,
      };
    }
    onChangeProp?.(newItem);
  };

  const handleSetChangeProp = (type: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newItem = { ...item };
    const val = (isObject(e) ? e.target?.value : e) || "";
    onChangeProp?.(_.set(_.cloneDeep(newItem), type, val));
  };

  const handleSetChangeIconProp = (path: string) => (newValue: any) => {
    const newItem = _.cloneDeep(item);

    if (newValue.icon) {
      _.set(newItem, `${path}.icon`, newValue.icon);
    }
    if (newValue.url) {
      _.set(newItem, `${path}.url`, newValue.url);
    }
    if (newValue.visibility) {
      _.set(newItem, `${path}.visibility`, newValue.visibility);
    }
    onChangeProp?.(newItem);
  };

  const handleSetChangePropStyle = (type: string) => (value: any) => {
    const newItem = { ...item };
    const val = value || "";

    onChangeProp?.(_.set(_.cloneDeep(newItem), type, val));
  };
  const changeContainerType = (item: any, value: any) => {
    if (value === ComponentItemType.GridContainer) {
      return {
        ...item,
        type: gridContainerConfig.type,
        props: {
          ...item.props,
          sx: gridContainerConfig.props.sx,
        },
        config: {
          ...item.config,
          placeholderConfig: gridContainerConfig.config.placeholderConfig,
        },
      };
    } else if (value === ComponentItemType.FlexContainer) {
      return {
        ...item,
        type: flexContainerConfig.type,
        props: {
          ...item.props,
          sx: flexContainerConfig.props.sx,
        },
        config: {
          ...item.config,
          placeholderConfig: flexContainerConfig.config.placeholderConfig,
        },
      };
    } else {
      return {
        ...item,
        type: customContainerConfig.type,
        props: {
          ...item.props,
          sx: customContainerConfig.props.sx,
        },
        config: {
          ...item.config,
          placeholderConfig: customContainerConfig.config.placeholderConfig,
        },
      };
    }
  };
  const handleChangeSelect = (type: string) => (event: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | any) => {
    let newItem = { ...item };
    const value = event.target.value;
    _.set(newItem, "props." + type, value as string);
    if (type === "containerType") {
      newItem = changeContainerType(newItem, value);
    }
    onChangeProp?.(newItem);
  };

  const handleChangeBackGround = (type: string) => (event: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | any) => {
    const newItem = { ...item };
    _.set(newItem, "props." + type, event.target.value + "!important");
    onChangeProp?.(newItem);
  };
  const updateProps = props => {
    let newProps = { ...props };
    if (isMapValues) {
      for (let key in newProps) {
        if (newProps.hasOwnProperty(key)) {
          const prop = newProps[key];
          if (typeof prop === "object" && prop !== null && "mapValuesObject" in prop && key !== "sx") {
            newProps[key] = prop.value;
          }
        }
      }
    }
    return newProps;
  };
  const handleMapValuesToggle = (type: string, callback?: (item: any, value: boolean) => void) => () => {
    const { prevSelectedType, selectedType, optionMap, ...restOfItem } = item || {};
    let savePrevType = { ...item };
    if (item?.optionMap && isMapValues) {
      savePrevType = {
        ...item,
        optionMap: {
          ...item?.optionMap,
          [item?.selectedType]: { ...restOfItem },
        },
      };
      onChangeProp?.(savePrevType);
    }

    let clonedOptionMap = _.cloneDeep(savePrevType?.optionMap);

    let newProps = updateProps(clonedOptionMap ? clonedOptionMap?.default?.props : item?.props);
    let itemChange = clonedOptionMap ? { ...clonedOptionMap?.default } : { ...item };

    const newItem = {
      ...itemChange,
      props: { ...newProps, [type]: !item?.props?.[type] },
      optionMap: clonedOptionMap
        ? {
            ...clonedOptionMap,
            default: { ...clonedOptionMap?.default, props: newProps },
          }
        : undefined,
      selectedType: "default",
    };

    onChangeProp?.(newItem);
  };

  const handleToggleProp = (type: string, callback?: (item: any, value: boolean) => void) => () => {
    let newItem = item;
    newItem = {
      ...newItem,
      props: { ...newItem?.props, [type]: !newItem?.props?.[type] },
      children:
        type === "singleValue" || type === "required"
          ? updateChildrenProps(newItem.children || [], type, !newItem?.props?.[type])
          : newItem.children,
    };
    onChangeProp?.(newItem);
    callback?.(newItem, !item?.props?.[type]);
  };

  const handleAdvanceToggleChange = (item, value) => {
    const newItem = { ...item, props: { ...item.props } };
    if (!value) {
      newItem.props.sx.gridTemplateColumns = {
        xs: `repeat(${item.props?.extraSmall}, 1fr)`,
        sm: `repeat(${item.props?.small}, 1fr)`,
        md: `repeat(${item.props?.medium}, 1fr)`,
        lg: `repeat(${item.props?.large}, 1fr)`,
        xl: `repeat(${item.props?.extraLarge}, 1fr)`,
      };
    } else {
      newItem.props.sx.gridTemplateColumns = newItem?.props?.gridTemplateColumns;
    }

    onChangeProp?.(newItem);
  };
  if (!item)
    return (
      <Typography display='flex' flex={1} justifyContent='center' alignItems='center' data-testId='test-drop' marginTop={2}>
        No Selected Item
      </Typography>
    );

  const handleAccordionChange = (accordionKey: keyof AccordionState) => {
    setAccordionState(prevState => ({
      ...prevState,
      [accordionKey]: !prevState[accordionKey],
    }));
  };

  const handleWidthTypeChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    const newValue = event.target.value as string;
    const newItem = { ...item, config: { ...item.config } };

    newItem.config.fixedWidth = newValue === "fixed";

    newItem.config.isDynamicWidth = newValue === "dynamic";

    onChangeProp?.(newItem);
  };
  const handleHeightTypeChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
    const newValue = event.target.value as string;
    const newItem = { ...item, config: { ...item.config } };

    newItem.config.isPercentageHeight = newValue != "fixed";

    newItem.config.isDynamicHeight = newValue === "dynamic";

    onChangeProp?.(newItem);
  };

  const widthType = item?.config?.fixedWidth ? "fixed" : item?.config?.isDynamicWidth ? "dynamic" : "percentage";
  const heightType = !item?.config?.isPercentageHeight ? "fixed" : item?.config?.isDynamicHeight ? "dynamic" : "percentage";
  const labelStyle = {
    fontSize: "12px",
  };

  return (
    <Grid container alignItems='center'>
      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
        <Grid item xs={6}>
          <Typography padding={1} color='primary' fontWeight={"bold"}>
            {item?.config?.placeholderConfig?.title}
          </Typography>
        </Grid>

        <Grid item xs={6} display={"flex"} justifyContent={"end"} alignItems={"center"} spacing={2}>
          <Typography sx={{ fontSize: "12px" }}>Map values</Typography>
          <Switch
            size='small'
            checked={!!isMapValues}
            onChange={handleMapValuesToggle("isMapValues")}
            inputProps={{ "aria-label": "map-values" }}
          />
        </Grid>
      </Grid>
      {isMapValues && (
        <MapValueSelector
          item={item}
          onChangeProp={onChangeProp}
          handleChangeProp={handleChangeProp}
          handleConfigChange={handleConfigChange}
          setView={setView}
        />
      )}
      <ItemsPropertiesAccordion expanded={accordionState.general} onChange={() => handleAccordionChange("general")} label='General'>
        <Grid container px={1} py={1} spacing={2}>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label={"Key"}
              value={key || ""}
              onChange={handleChangeProp("key")}
              InputLabelProps={{ style: { fontSize: "12px" } }}
              InputProps={{ style: { fontSize: "12px" } }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label={"ID"}
              value={testId || ""}
              onChange={handleChangeProp("testId")}
              InputLabelProps={{ style: { fontSize: "12px" } }}
              InputProps={{ style: { fontSize: "12px" } }}
            />
          </Grid>

          {item?.type !== ComponentItemType.FlexContainer &&
            item?.type !== ComponentItemType.GridContainer &&
            item?.type !== ComponentItemType.JsonViewer &&
            item?.type !== ComponentItemType.FileUploadInput &&
            !item?.props?.isChildContainerGroup && (
              <>
                {(item?.type === ComponentItemType.DatePicker ||
                  item?.type === ComponentItemType.TimePicker ||
                  item?.type === ComponentItemType.DateTimePicker) && (
                  <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography fontSize={12}>Now</Typography>
                    <Switch
                      size='small'
                      checked={!!item?.config?.defaultValueNow}
                      onChange={handleConfigChange("defaultValueNow", "boolean")}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ml: 2 }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    fullWidth
                    label={"Default Value"}
                    disabled={!!item?.config?.defaultValueNow}
                    value={defaultValue || ""}
                    onChange={handleChangeProp("defaultValue")}
                    InputLabelProps={{ style: { fontSize: "10px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
              </>
            )}
          {item?.config?.isGroup && (
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={"Default Value"}
                value={defaultValue || ""}
                onChange={handleChangeProp("defaultValue")}
                InputLabelProps={{ style: { fontSize: "10px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
          )}
          {item?.type === ComponentItemType.TextField && (
            <Grid item xs={12}>
              <TextField
                size='small'
                select
                fullWidth
                label={"Type"}
                value={type}
                onChange={handleChangeSelect("type")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              >
                {["Text", "Number", "Email", "Password", "URL", "UUID", "Match"].map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {item?.config?.placeholderConfig?.group == "container" && (
            <Grid item xs={12}>
              <TextField
                size='small'
                select
                fullWidth
                value={containerType || item?.type}
                label='Type Container'
                placeholder='Type Container'
                onChange={handleChangeSelect("containerType")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              >
                {containersTypes?.map(item => (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      </ItemsPropertiesAccordion>
      <ItemsPropertiesAccordion
        expanded={accordionState.dataSource}
        onChange={() => handleAccordionChange("dataSource")}
        label='Data Source'
      >
        <Grid container px={1} py={1} spacing={2}>
          <Grid item px={1} style={{ width: "100%" }}>
            <Button variant='outlined' onClick={() => setIsModalOpen(true)} style={{ width: "100%" }}>
              Select Data Source
            </Button>
          </Grid>
          <BXModal
            open={isModalOpen}
            icon={<SettingsIcon />}
            maxWidth='md'
            title='Select Data Source'
            onClose={() => setIsModalOpen(false)}
          >
            <CreateSelectDataSource
              item={item}
              isMapDataSource={isMapDataSource}
              onSave={handleSaveSelectComponent}
              row={{ name: item?.name, config: item?.configData }}
              isBuilder={true}
              handleConfigChange={handleConfigChange}
              onChangeProp={onChangeProp}
            />
          </BXModal>
        </Grid>
      </ItemsPropertiesAccordion>
      {item?.config?.isStepperContainer && (
        <ItemsPropertiesAccordion expanded={accordionState.stepper} onChange={() => handleAccordionChange("stepper")} label='Stepper'>
          <StepperConfiguration
            stepperId={item?.id}
            setView={setView}
            groupKey={item?.props?.key}
            stepperGroup={view?.stepperGroups?.[item?.id]}
            deleteComponent={onDeleteItem}
          />
        </ItemsPropertiesAccordion>
      )}

      {item?.type === ComponentItemType.StepperNavigator && (
        <ItemsPropertiesAccordion expanded={accordionState.navigator} onChange={() => handleAccordionChange("navigator")} label='Navigator'>
          <Grid container px={1} py={1} spacing={2}>
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label='Group Reference'
                value={groupReference || ""}
                onChange={handleConfigChange("navigator.groupReference")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                size='small'
                fullWidth
                label='Bullet Display'
                value={bulletDisplay || ""}
                onChange={handleConfigChange("navigator.bulletDisplay")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              >
                <MenuItem value='showSelectedOnly'>Show Selected Only</MenuItem>
                <MenuItem value='showUpToSelected'>Show Up to Selected</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} gap={1}>
              <Typography sx={{ fontSize: "12px", mb: 1 }}>Unselected Bullet</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Opacity'
                    value={unselectedBulletOpacity || ""}
                    onChange={handleConfigChange("navigator.unselectedBulletOpacity")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Color'
                    value={unselectedBulletColor || ""}
                    onChange={handleConfigChange("navigator.unselectedBulletColor")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Shape'
                    value={unselectedBulletShape || ""}
                    onChange={handleConfigChange("navigator.unselectedBulletShape")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Width'
                    value={unselectedBulletWidth || ""}
                    onChange={handleConfigChange("navigator.unselectedBulletWidth")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Height'
                    value={unselectedBulletHeight || ""}
                    onChange={handleConfigChange("navigator.unselectedBulletHeight")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} gap={1}>
              <Typography sx={{ fontSize: "12px", mb: 1 }}>Selected Bullet</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Opacity'
                    value={selectedBulletOpacity || ""}
                    onChange={handleConfigChange("navigator.selectedBulletOpacity")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Color'
                    value={selectedBulletColor || ""}
                    onChange={handleConfigChange("navigator.selectedBulletColor")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Shape'
                    value={selectedBulletShape || ""}
                    onChange={handleConfigChange("navigator.selectedBulletShape")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Width'
                    value={selectedBulletWidth || ""}
                    onChange={handleConfigChange("navigator.selectedBulletWidth")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Height'
                    value={selectedBulletHeight || ""}
                    onChange={handleConfigChange("navigator.selectedBulletHeight")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} gap={1}>
              <Typography sx={{ fontSize: "12px", mb: 1 }}>Bullet Hover</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Color'
                    value={bulletHoverColor || ""}
                    onChange={handleConfigChange("navigator.bulletHoverColor")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    label='Opacity'
                    value={bulletHoverOpacity || ""}
                    onChange={handleConfigChange("navigator.bulletHoverOpacity")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                size='small'
                fullWidth
                label='Orientation'
                value={orientation || ""}
                onChange={handleConfigChange("navigator.orientation")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              >
                <MenuItem value='horizontal'>Horizontal</MenuItem>
                <MenuItem value='vertical'>Vertical</MenuItem>
              </TextField>
            </Grid>

            <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>Clickable Bullets</Typography>
              <Switch
                size='small'
                checked={!!bulletsClickable}
                onChange={handleConfigChange("navigator.bulletsClickable", "boolean")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      <ItemsPropertiesAccordion expanded={accordionState.size} onChange={() => handleAccordionChange("size")} label='Size'>
        <Grid container px={1} py={1} spacing={2}>
          <Grid item xs={12}>
            <TextField
              size='small'
              select
              fullWidth
              label={"Width Type"}
              value={widthType}
              onChange={handleWidthTypeChange}
              InputLabelProps={{ style: { fontSize: "12px" } }}
              InputProps={{ style: { fontSize: "12px" } }}
            >
              <MenuItem key='fixed' value='fixed'>
                Fixed
              </MenuItem>
              <MenuItem key='dynamic' value='dynamic'>
                Dynamic
              </MenuItem>
              <MenuItem key='percentage' value='percentage'>
                Percentage
              </MenuItem>
            </TextField>
          </Grid>
          <Grid display='flex' item xs={12} justifyContent='space-between' alignItems='center'>
            {item?.config?.fixedWidth && !item?.config?.isDynamicWidth && (
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Width Px"}
                  value={getItemClosestProp(item?.config?.widthPx, layoutBreak) || ""}
                  onChange={handleConfigChange(`widthPx.${layoutBreak}`, "number")}
                  type='number'
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            )}
            {!item?.config?.fixedWidth && !item?.config?.isDynamicWidth && (
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Width %"}
                  value={getItemClosestProp(item?.config?.widthPercentage, layoutBreak) || ""}
                  onChange={handleConfigChange(`widthPercentage.${layoutBreak}`)}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              size='small'
              select
              fullWidth
              label={"Height Type"}
              value={heightType}
              onChange={handleHeightTypeChange}
              InputLabelProps={{ style: { fontSize: "12px" } }}
              InputProps={{ style: { fontSize: "12px" } }}
            >
              <MenuItem key='fixed' value='fixed'>
                Fixed
              </MenuItem>
              <MenuItem key='dynamic' value='dynamic'>
                Dynamic
              </MenuItem>
              <MenuItem key='percentage' value='percentage'>
                Percentage
              </MenuItem>
            </TextField>
          </Grid>
          <Grid display='flex' item xs={12} justifyContent='space-between' alignItems='center'>
            {!item?.config?.isPercentageHeight && !item?.config?.isDynamicHeight && (
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Height Px"}
                  value={getItemClosestProp(item?.config?.heightPx, layoutBreak) || ""}
                  onChange={handleConfigChange(`heightPx.${layoutBreak}`, "number")}
                  type='number'
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            )}
            {item?.config?.isPercentageHeight && !item?.config?.isDynamicHeight && (
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Height %"}
                  value={getItemClosestProp(item?.config?.heightPercentage, layoutBreak) || ""}
                  onChange={handleConfigChange(`heightPercentage.${layoutBreak}`)}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              options={overflowValues}
              renderInput={params => (
                <TextField
                  type='text'
                  placeholder={"Overflow"}
                  label={"Overflow"}
                  {...params}
                  value={item?.config?.overflow || ""}
                  size='small'
                />
              )}
              value={item?.config?.overflow || null}
              onChange={(event, value) => {
                handleConfigChange("overflow")(value);
              }}
            />
          </Grid>
        </Grid>
      </ItemsPropertiesAccordion>

      {(item?.type == ComponentItemType.Button || item?.type == ComponentItemType.CustomIconButton) && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.hint} onChange={() => handleAccordionChange("hint")} label='Hint'>
            <Grid container px={1} py={1} spacing={2}>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <MapValuesComponent
                  label='Hint'
                  value={isMapValues ? toolTip?.value : toolTip}
                  defaultValue={toolTip}
                  enableMapValues={isMapValues}
                  mapValuesObject={toolTip?.mapValuesObject || {}}
                  handleChangeProp={handleChangeProp}
                  valueString={"toolTip"}
                  inputLabelProps={{ style: { fontSize: "12px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      <ItemsPropertiesAccordion
        expanded={accordionState.actionConfig}
        onChange={() => handleAccordionChange("actionConfig")}
        label='Action'
      >
        <Grid container justifyContent='center' alignItems='center' py={1}>
          <Grid item px={1} style={{ width: "100%" }}>
            <Button variant='outlined' onClick={() => setIsActionsConfigModalOpen(true)} style={{ width: "100%" }}>
              Actions Configuration
            </Button>
          </Grid>
          <BXModal
            open={isActionsConfigModalOpen}
            icon={<SettingsIcon />}
            maxWidth='lg'
            title='Actions Configuration'
            onClose={() => setIsActionsConfigModalOpen(false)}
          >
            <MapChainActions
              item={item}
              view={view}
              views={views}
              onSave={() => {
                if (item?.actionConfig) {
                  // delete item?.actionConfig;
                  onChangeProp?.({ ...item });
                }
                setIsActionsConfigModalOpen(false);
              }}
              appId={appId}
              collectionId={collectionId}
              pageId={pageId}
              handleConfigChange={handleConfigChange}
              onChangeProp={onChangeProp}
            />
          </BXModal>
        </Grid>
      </ItemsPropertiesAccordion>
      {item?.type == ComponentItemType.CustomChip && (
        <ItemsPropertiesAccordion expanded={accordionState.chip} onChange={() => handleAccordionChange("chip")} label='Chip Configuration'>
          <Grid container px={1} py={1} spacing={2}>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <MapValuesComponent
                label='Label'
                value={isMapValues ? labelChip?.value : labelChip}
                defaultValue={labelChip}
                enableMapValues={isMapValues}
                mapValuesObject={labelChip?.mapValuesObject || {}}
                handleChangeProp={handleChangeProp}
                valueString={"labelChip"}
                inputLabelProps={{ style: { fontSize: "12px" } }}
                inputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <MapValuesComponent
                label='Label Color'
                value={isMapValues ? labelColor.value : labelColor}
                defaultValue={labelColor}
                enableMapValues={isMapValues}
                mapValuesObject={labelColor?.mapValuesObject || {}}
                handleChangeProp={handleChangeProp}
                valueString={"labelColor"}
                inputLabelProps={{ style: { fontSize: "12px" } }}
                inputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <MapValuesComponent
                label='Background Color'
                value={isMapValues ? backgroundColor?.value : backgroundColor}
                enableMapValues={isMapValues}
                defaultValue={backgroundColor}
                mapValuesObject={backgroundColor?.mapValuesObject || {}}
                handleChangeProp={handleChangeProp}
                valueString={"backgroundColor"}
                inputLabelProps={{ style: { fontSize: "12px" } }}
                inputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <MapValuesComponent
                label='Border Color'
                value={isMapValues ? borderColor?.value : borderColor}
                enableMapValues={isMapValues}
                defaultValue={borderColor}
                mapValuesObject={borderColor?.mapValuesObject || {}}
                handleChangeProp={handleChangeProp}
                valueString={"borderColor"}
                inputLabelProps={{ style: { fontSize: "12px" } }}
                inputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size='small'
                select
                fullWidth
                value={variant}
                onChange={handleChangeSelect("variant")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              >
                {["outlined", "filled"].map(variant => (
                  <MenuItem key={variant} value={variant}>
                    {variant}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.type === ComponentItemType.ColorPicker && (
        <ItemsPropertiesAccordion
          expanded={accordionState.ColorPicker}
          onChange={() => handleAccordionChange("ColorPicker")}
          label='Color picker'
        >
          <Grid container px={1} py={1} spacing={2}>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}> Read Only</Typography>
              <Switch
                size='small'
                checked={isReadOnly}
                onChange={handleToggleProp("isReadOnly")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>Box Color is left</Typography>
              <Switch size='small' checked={isLeft} onChange={handleToggleProp("isLeft")} inputProps={{ "aria-label": "controlled" }} />
            </Grid>
            <Grid item xs={12}>
              <MapValuesComponent
                label='Place Holder'
                value={isMapValues ? placeholder?.value : placeholder}
                enableMapValues={isMapValues}
                defaultValue={placeholder}
                mapValuesObject={placeholder?.mapValuesObject || {}}
                handleChangeProp={handleChangeProp}
                valueString={"placeholder"}
                inputLabelProps={{ style: { fontSize: "12px" } }}
                inputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.type === ComponentItemType.CustomSwitch && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.display} onChange={() => handleAccordionChange("display")} label='Display'>
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <TranslationInput
                  label={"label"}
                  fullWidth
                  value={label || ""}
                  onChange={handleChangeProp("label")}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}

      {item?.type === ComponentItemType.CustomRadio && (
        <>
          <ItemsPropertiesAccordion
            expanded={accordionState.radio}
            onChange={() => handleAccordionChange("radio")}
            label='Radio Configuration'
          >
            <Grid container xs={12} px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <TranslationInput
                  label={"label"}
                  fullWidth
                  value={label || ""}
                  onChange={handleChangeProp("label")}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>
              {!item?.props?.isChildContainerGroup && (
                <Grid item xs={6}>
                  <TranslationInput
                    label={"Radio Group Name"}
                    fullWidth
                    value={groupName || ""}
                    onChange={handleChangeProp("groupName")}
                    appId={appId}
                    multiLingual={multiLingual}
                    setMultiLingual={setMultiLingual}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container xs={12} px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <TranslationInput
                  label={"Radio Input Value"}
                  fullWidth
                  value={radioValue || ""}
                  onChange={handleChangeProp("radioValue")}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {item?.type === ComponentItemType.CustomCheckbox && (
        <>
          <ItemsPropertiesAccordion
            expanded={accordionState.checkbox}
            onChange={() => handleAccordionChange("checkbox")}
            label='Checkbox Configuration'
          >
            <Grid container xs={12} px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <TranslationInput
                  label={"label"}
                  fullWidth
                  value={label || ""}
                  onChange={handleChangeProp("label")}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>
              <Grid item xs={6}>
                <TranslationInput
                  label={"Checkbox Input Value"}
                  fullWidth
                  value={checkboxValue || ""}
                  onChange={handleChangeProp("checkboxValue")}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>
              {!item?.props?.isChildContainerGroup && (
                <Grid container px={1} py={2} spacing={2}>
                  <Grid item xs={12}>
                    <TranslationInput
                      label={"Checkbox Group Name"}
                      fullWidth
                      value={groupName || ""}
                      onChange={handleChangeProp("groupName")}
                      appId={appId}
                      multiLingual={multiLingual}
                      setMultiLingual={setMultiLingual}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                    />
                  </Grid>
                  <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontSize: "12px" }}>{"Single Value"}</Typography>
                    <Switch
                      size='small'
                      checked={singleValue}
                      onChange={handleToggleProp("singleValue")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}

      {(item?.type === ComponentItemType.TextField ||
        item?.type === ComponentItemType.FileUploadInput ||
        item?.type === ComponentItemType.DatePicker ||
        item?.type === ComponentItemType.CustomSwitch ||
        (item?.type === ComponentItemType.CustomCheckbox && !item?.props?.isChildContainerGroup) ||
        (item?.type === ComponentItemType.CustomRadio && !item?.props?.isChildContainerGroup) ||
        item?.type === ComponentItemType.DatePicker ||
        item?.type === ComponentItemType.ColorPicker ||
        item?.type === ComponentItemType.JsonViewer ||
        item?.type === ComponentItemType.DateTimePicker ||
        item?.config?.isGroup) && (
        <>
          <ItemsPropertiesAccordion
            expanded={accordionState.validation}
            onChange={() => handleAccordionChange("validation")}
            label='Validation'
          >
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                    <Grid item xs={12}>
                      <CustomErrorMessage
                        handleToggleProp={handleToggleProp}
                        validationType={required}
                        validationTitle={"Required"}
                        validationTypeString={"required"}
                        textFieldProps={{
                          size: "small",
                          type: "text",
                          value: customRequiredMessageContent,
                          onChange: handleChangeProp("customRequiredMessageContent"),
                        }}
                      />
                    </Grid>
                  </Grid>

                  {item?.type == ComponentItemType.TextField && type === "Text" && (
                    <>
                      <Grid
                        container
                        display='flex'
                        marginInlineStart={"5px"}
                        item
                        xs={12}
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item xs={12}>
                          <CustomErrorMessage
                            handleToggleProp={handleToggleProp}
                            validationType={isRegex}
                            validationTitle={"Regex"}
                            validationTypeString={"isRegex"}
                            textFieldProps={{
                              size: "small",
                              type: "text",
                              value: customRegexMessageContent,
                              onChange: handleChangeProp("customRegexMessageContent"),
                            }}
                          />
                        </Grid>
                        {isRegex && (
                          <Grid item xs={12}>
                            <MapValuesComponent
                              label='Regex'
                              value={isMapValues ? textFieldRegex?.value : textFieldRegex}
                              defaultValue={textFieldRegex}
                              enableMapValues={isMapValues}
                              mapValuesObject={textFieldRegex?.mapValuesObject || {}}
                              handleChangeProp={handleChangeProp}
                              valueString={"textFieldRegex"}
                              inputLabelProps={{ style: { fontSize: "12px" } }}
                              inputProps={{ style: { fontSize: "12px" } }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}

                  {item?.type == ComponentItemType.TextField && type === "Text" && (
                    <>
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <CustomErrorMessage
                            validationTitle={"Text"}
                            validationTypeString={"stringValidation"}
                            validationType={stringValidation}
                            handleToggleProp={handleToggleProp}
                            textFieldProps={{
                              size: "small",
                              type: "text",
                              value: customStringMessageContent,
                              onChange: handleChangeProp("customStringMessageContent"),
                            }}
                          />
                        </Grid>
                      </Grid>

                      {stringValidation && (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              size='small'
                              type='number'
                              value={minLength || ""}
                              label={"Min Length"}
                              onChange={handleChangeProp("minLength")}
                              InputLabelProps={{ style: { fontSize: "12px" } }}
                              InputProps={{ style: { fontSize: "12px" } }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              size='small'
                              type='number'
                              value={maxLength || ""}
                              label={"Max Length"}
                              onChange={handleChangeProp("maxLength")}
                              InputLabelProps={{ style: { fontSize: "12px" } }}
                              InputProps={{ style: { fontSize: "12px" } }}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                  {item?.type == ComponentItemType.TextField && type === "Number" && (
                    <>
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <CustomErrorMessage
                            validationTitle={"Number"}
                            validationTypeString={"numberValidation"}
                            validationType={numberValidation}
                            handleToggleProp={handleToggleProp}
                            textFieldProps={{
                              size: "small",
                              type: "text",
                              value: customNumberMessageContent,
                              onChange: handleChangeProp("customNumberMessageContent"),
                            }}
                          />
                        </Grid>
                      </Grid>

                      {numberValidation && (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              size='small'
                              type='number'
                              label={"Min Value"}
                              value={minValue || ""}
                              onChange={handleChangeProp("minValue")}
                              InputLabelProps={{ style: { fontSize: "12px" } }}
                              InputProps={{ style: { fontSize: "12px" } }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              size='small'
                              type='number'
                              label={"Max Value"}
                              value={maxValue || ""}
                              onChange={handleChangeProp("maxValue")}
                              InputLabelProps={{ style: { fontSize: "12px" } }}
                              InputProps={{ style: { fontSize: "12px" } }}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                  {item?.type == ComponentItemType.ColorPicker && (
                    <>
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <CustomErrorMessage
                            validationTitle={"Color Picker"}
                            validationTypeString={"colorPickerInvalid"}
                            validationType={colorPickerInvalid}
                            handleToggleProp={handleToggleProp}
                            textFieldProps={{
                              size: "small",
                              type: "text",
                              value: customColorMessageContent,
                              onChange: handleChangeProp("customColorMessageContent"),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {item.type === ComponentItemType.TextField && type === "Email" && (
                    <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                      <Grid item xs={12}>
                        <CustomErrorMessage
                          validationTitle={"Email"}
                          validationTypeString={"emailValidation"}
                          validationType={emailValidation}
                          handleToggleProp={handleToggleProp}
                          textFieldProps={{
                            size: "small",
                            type: "text",
                            value: customEmailMessageContent,
                            onChange: handleChangeProp("customEmailMessageContent"),
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {item.type === ComponentItemType.TextField && type === "Password" && (
                    <>
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <CustomErrorMessage
                            validationTitle={"Password"}
                            validationTypeString={"passwordValidation"}
                            validationType={passwordValidation}
                            handleToggleProp={handleToggleProp}
                            textFieldProps={{
                              size: "small",
                              type: "text",
                              value: customPasswordMessageContent,
                              onChange: handleChangeProp("customPasswordMessageContent"),
                            }}
                          />
                        </Grid>
                      </Grid>

                      {passwordValidation && (
                        <Grid container marginInlineStart={"15px"} xs={12}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={includeLowercase}
                                  onChange={handleToggleProp("includeLowercase")}
                                  name='includeLowercase'
                                  color='primary'
                                  size='small'
                                />
                              }
                              label='Include Lowercase Characters'
                              sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={includeUppercase}
                                  onChange={handleToggleProp("includeUppercase")}
                                  name='includeUppercase'
                                  color='primary'
                                  size='small'
                                />
                              }
                              label='Include Uppercase Characters'
                              sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={includeNumbers}
                                  onChange={handleToggleProp("includeNumbers")}
                                  name='includeNumbers'
                                  color='primary'
                                  size='small'
                                />
                              }
                              label='Include Numbers'
                              sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={includeSymbols}
                                  onChange={handleToggleProp("includeSymbols")}
                                  name='includeSymbols'
                                  color='primary'
                                  size='small'
                                />
                              }
                              label='Include Symbols'
                              sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
                            />
                          </Grid>
                          <Grid container xs={12} marginTop={2}>
                            <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"start"}>
                              <Typography sx={{ fontSize: "12px" }}>Min Password Length:</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                size='small'
                                type='number'
                                value={minLengthPassword || 0}
                                onChange={handleChangeProp("minLengthPassword")}
                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                InputProps={{ style: { fontSize: "12px" } }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {item.type === ComponentItemType.TextField && type === "URL" && (
                    <>
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <CustomErrorMessage
                            validationTitle={"URL"}
                            validationTypeString={"urlValidation"}
                            validationType={urlValidation}
                            handleToggleProp={handleToggleProp}
                            textFieldProps={{
                              size: "small",
                              type: "text",
                              value: customUrlMessageContent,
                              onChange: handleChangeProp("customUrlMessageContent"),
                            }}
                          />
                        </Grid>
                      </Grid>
                      {urlValidation && (
                        <Grid container marginInlineStart={"15px"} xs={12}>
                          <Grid item xs={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={enableHttpValidation}
                                  onChange={handleToggleProp("enableHttpValidation")}
                                  name='enableHttpValidation'
                                  color='primary'
                                  size='small'
                                />
                              }
                              label='Enable http'
                              sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={enableHttpsValidation}
                                  onChange={handleToggleProp("enableHttpsValidation")}
                                  name='enableHttpsValidation'
                                  color='primary'
                                  size='small'
                                />
                              }
                              label='Enable https'
                              sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {item.type === ComponentItemType.TextField && type === "UUID" && (
                    <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                      <Grid item xs={12}>
                        <CustomErrorMessage
                          validationTitle={"UUID"}
                          validationTypeString={"uuidValidation"}
                          validationType={uuidValidation}
                          handleToggleProp={handleToggleProp}
                          textFieldProps={{
                            size: "small",
                            type: "text",
                            value: customUUidMessageContent,
                            onChange: handleChangeProp("customUUidMessageContent"),
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {item.type === ComponentItemType.TextField && type === "Match" && (
                    <>
                      <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                          <CustomErrorMessage
                            validationTitle={"Match"}
                            validationTypeString={"matchValidation"}
                            validationType={matchValidation}
                            handleToggleProp={handleToggleProp}
                            textFieldProps={{
                              size: "small",
                              type: "text",
                              value: customMatchMessageContent,
                              onChange: handleChangeProp("customMatchMessageContent"),
                            }}
                          />
                          {matchValidation && (
                            <Grid item xs={12} marginTop={2}>
                              <MapValuesComponent
                                label='Match Value'
                                value={isMapValues ? matchValue?.value : matchValue}
                                defaultValue={matchValue}
                                enableMapValues={isMapValues}
                                mapValuesObject={matchValue?.mapValuesObject || {}}
                                handleChangeProp={handleChangeProp}
                                valueString={"matchValue"}
                                inputLabelProps={{ style: { fontSize: "12px" } }}
                                inputProps={{ style: { fontSize: "12px" } }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}

      {(item?.type === ComponentItemType.TextField ||
        item?.type === ComponentItemType.FileUploadInput ||
        item?.type === ComponentItemType.DatePicker ||
        item?.type === ComponentItemType.DateTimePicker ||
        item?.type === ComponentItemType.BXSelect) && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.display} onChange={() => handleAccordionChange("display")} label='Display'>
            <Grid container px={1} py={1} spacing={1}>
              {uploaderType === "TextField" && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Label'
                    value={uploadLabel}
                    onChange={handleChangeProp("uploadLabel")}
                    inputProps={{ style: { fontSize: "12px" } }}
                    size='small'
                  />
                </Grid>
              )}
              {uploaderType === "Default" && (
                <>
                  <Grid item xs={6}>
                    <TranslationInput
                      label={"label"}
                      fullWidth
                      value={label || ""}
                      onChange={handleChangeProp("label")}
                      appId={appId}
                      multiLingual={multiLingual}
                      setMultiLingual={setMultiLingual}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size='small'
                      fullWidth
                      label={"Place Holder"}
                      value={placeholder || ""}
                      onChange={handleChangeProp("placeholder")}
                      InputLabelProps={{ style: { fontSize: "12px" } }}
                      InputProps={{ style: { fontSize: "12px" } }}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container px={1} py={1} spacing={2}>
              {item?.type === ComponentItemType.FileUploadInput && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      size='small'
                      select
                      fullWidth
                      value={uploaderType}
                      label='Type'
                      placeholder='Type'
                      onChange={handleChangeSelect("uploaderType")}
                      InputLabelProps={{ style: { fontSize: "12px" } }}
                      InputProps={{ style: { fontSize: "12px" } }}
                    >
                      {uploaderTypes?.map(item => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {item?.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {
                    // TODO: remove this after supporting action config upload
                  }
                  {uploaderType === "IconButton" ? (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          label='Icon Name'
                          value={iconName}
                          onChange={handleChangeProp("iconName")}
                          inputProps={{ style: { fontSize: "12px" } }}
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MapValuesComponent
                          label='Background Color'
                          value={isMapValues ? IconBackgroundColor?.value : IconBackgroundColor}
                          enableMapValues={isMapValues}
                          defaultValue={IconBackgroundColor}
                          mapValuesObject={IconBackgroundColor?.mapValuesObject || {}}
                          handleChangeProp={handleChangeProp}
                          valueString={"IconBackgroundColor"}
                          inputLabelProps={{ style: { fontSize: "12px" } }}
                          inputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MapValuesComponent
                          label='Border Color'
                          value={isMapValues ? IconBorderColor?.value : IconBorderColor}
                          enableMapValues={isMapValues}
                          defaultValue={IconBorderColor}
                          mapValuesObject={IconBorderColor?.mapValuesObject || {}}
                          handleChangeProp={handleChangeProp}
                          valueString={"IconBorderColor"}
                          inputLabelProps={{ style: { fontSize: "12px" } }}
                          inputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MapValuesComponent
                          label='Icon Size'
                          value={isMapValues ? iconSize?.value : iconSize}
                          enableMapValues={isMapValues}
                          defaultValue={iconSize}
                          mapValuesObject={iconSize?.mapValuesObject || {}}
                          handleChangeProp={handleChangeProp}
                          valueString={"iconSize"}
                          inputLabelProps={{ style: { fontSize: "12px" } }}
                          inputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MapValuesComponent
                          label='Border Width'
                          value={isMapValues ? IconBorderWidth?.value : IconBorderWidth}
                          enableMapValues={isMapValues}
                          defaultValue={IconBorderWidth}
                          mapValuesObject={IconBorderWidth?.mapValuesObject || {}}
                          handleChangeProp={handleChangeProp}
                          valueString={"IconBorderWidth"}
                          inputLabelProps={{ style: { fontSize: "12px" } }}
                          inputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MapValuesComponent
                          label='Icon Color'
                          value={isMapValues ? iconColor?.value : iconColor}
                          enableMapValues={isMapValues}
                          defaultValue={iconColor}
                          mapValuesObject={iconColor?.mapValuesObject || {}}
                          handleChangeProp={handleChangeProp}
                          valueString={"iconColor"}
                          inputLabelProps={{ style: { fontSize: "12px" } }}
                          inputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          label='Start Icon'
                          value={startIcon}
                          onChange={handleChangeProp("startIcon")}
                          inputProps={{ style: { fontSize: "12px" } }}
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size='small'
                          label='End Icon'
                          value={endIcon}
                          onChange={handleChangeProp("endIcon")}
                          InputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='Start Icon Color'
                          value={startIconColor}
                          onChange={handleChangeProp("startIconColor")}
                          inputProps={{ style: { fontSize: "12px" } }}
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size='small'
                          label='End Icon Color'
                          value={endIconColor}
                          onChange={handleChangeProp("endIconColor")}
                          InputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='Start Icon Size'
                          value={startIconSize}
                          onChange={handleChangeProp("startIconSize")}
                          inputProps={{ style: { fontSize: "12px" } }}
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size='small'
                          label='End Icon Size'
                          value={endIconSize}
                          onChange={handleChangeProp("endIconSize")}
                          InputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ fontSize: "12px" }}>Media Preview</Typography>
                    <Switch
                      size='small'
                      checked={!!withPreview}
                      onChange={handleConfigChange("uploadConfig.withPreview", "boolean")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  {withPreview == true && (
                    <Grid container px={1} py={1} spacing={2} sx={{ ml: 0.1 }}>
                      <Grid item xs={6}>
                        <TextField
                          size='small'
                          fullWidth
                          label={"Width Px"}
                          value={widthPreview || ""}
                          onChange={handleConfigChange("uploadConfig.widthPreview", "number")}
                          type='number'
                          InputLabelProps={{ style: { fontSize: "12px" } }}
                          InputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size='small'
                          fullWidth
                          label={"Height Px"}
                          value={heightPreview || ""}
                          onChange={handleConfigChange("uploadConfig.heightPreview", "number")}
                          type='number'
                          InputLabelProps={{ style: { fontSize: "12px" } }}
                          InputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size='small'
                          fullWidth
                          label={"border Radius"}
                          value={borderRadiusPreview || ""}
                          onChange={handleConfigChange("uploadConfig.borderRadiusPreview", "number")}
                          InputLabelProps={{ style: { fontSize: "12px" } }}
                          InputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {item?.type === ComponentItemType.TextField && (
                <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ fontSize: "12px" }}>Multiline</Typography>
                  <Switch
                    size='small'
                    checked={!!multiline}
                    onChange={handleToggleProp("multiline")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              )}

              {multiline && (
                <Grid item container alignItems={"space-between"} justifyContent={"space-between"}>
                  <Grid display='flex' item alignItems='center'>
                    <TextField
                      size='small'
                      fullWidth
                      label={"Rows"}
                      value={~~rows || 1}
                      type='number'
                      onChange={handleChangeProp("rows")}
                      InputLabelProps={{ style: { fontSize: "12px" } }}
                      InputProps={{ style: { fontSize: "12px" } }}
                    />
                  </Grid>
                  <Grid display='flex' item alignItems='center'>
                    <TextField
                      size='small'
                      fullWidth
                      label={"Max Rows"}
                      value={maxRows || 1}
                      type='number'
                      onChange={handleChangeProp("maxRows")}
                      InputLabelProps={{ style: { fontSize: "12px" } }}
                      InputProps={{ style: { fontSize: "12px" } }}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>Disabled</Typography>
                <Switch
                  size='small'
                  checked={!!disabled}
                  onChange={handleToggleProp("disabled")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {item?.type === ComponentItemType.JsonViewer && (
        <ItemsPropertiesAccordion
          expanded={accordionState.JsonViewer}
          onChange={() => handleAccordionChange("JsonViewer")}
          label='JsonViewer'
        >
          <Grid container xs={12} gap={2} padding={1}>
            <Grid item xs={12}>
              <TextField
                size='small'
                select
                fullWidth
                label='Theme'
                value={JsonTheme}
                onChange={handleChangeProp("JsonTheme")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              >
                {JsonThemes.map(variant => (
                  <MenuItem key={variant} value={variant}>
                    {variant}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>Editable</Typography>
              <Switch
                size='small'
                checked={isEditable}
                onChange={handleToggleProp("isEditable")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "12px" }}>Default Value</Typography>
              <MonacoEditor
                width='100%'
                height='200px'
                language='json'
                theme='vs-dark'
                defaultValue={formatJSON(JSON.stringify(defaultValue || "{}"))}
                onChange={e => {
                  try {
                    const src = isObject(e) ? e : JSON.parse(e || "{}");
                    const newItem = { ...item, props: { ...item?.props, defaultValue: src } };
                    onChangeProp?.(newItem);
                  } catch {}
                }}
                options={{
                  fontSize: 12,
                  lineDecorationsWidth: "1px",
                  colorDecorators: true,
                  minimap: {
                    enabled: false,
                  },
                }}
              />
            </Grid>
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.type === ComponentItemType.MarkdownViewer && (
        <ItemsPropertiesAccordion
          expanded={accordionState.MarkdownViewer}
          onChange={() => handleAccordionChange("MarkdownViewer")}
          label='Markdown'
        >
          <Grid container justifyContent='center' alignItems='center' py={1}>
            <Grid item px={1} style={{ width: "100%" }}>
              <Button variant='outlined' onClick={() => setMarkdownModalOpen(true)} style={{ width: "100%" }}>
                Markdown Configuration
              </Button>
            </Grid>
            <BXModal
              open={isMarkdownModalOpen}
              icon={<SettingsIcon />}
              maxWidth='md'
              title='Markdown Configuration'
              onClose={() => setMarkdownModalOpen(false)}
            >
              <MarkdownEditor markdownText={label} handleChangeProp={handleChangeProp} onClose={() => setMarkdownModalOpen(false)} />
            </BXModal>
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.type === "CustomAccordion" && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.display} onChange={() => handleAccordionChange("display")} label='Display'>
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <TranslationInput
                  label={"label"}
                  fullWidth
                  value={label || ""}
                  onChange={handleChangeProp("label")}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {(item?.type === ComponentItemType.DatePicker ||
        item?.type === ComponentItemType.TimePicker ||
        item?.type === ComponentItemType.DateTimePicker) && (
        <ItemsPropertiesAccordion
          expanded={accordionState.dateTime}
          onChange={() => handleAccordionChange("dateTime")}
          label={item?.type === ComponentItemType.DatePicker ? "Date" : item?.type === ComponentItemType.TimePicker ? "Time" : "DateTime"}
        >
          <Grid container spacing={2} px={2} py={1}>
            {item?.type !== ComponentItemType.TimePicker && (
              <>
                <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                  <Typography fontSize={12}>User local time</Typography>
                  <Switch
                    size='small'
                    checked={isUserLocalTime}
                    onChange={handleConfigChange("isUserLocalTime", "boolean")}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ ml: 2 }}
                  />
                </Grid>
                <Grid container item xs={12} display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography fontSize={12}>24-Hour time format</Typography>
                  <Switch
                    size='small'
                    checked={!!item?.config?.ampm}
                    onChange={handleConfigChange("ampm", "boolean")}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ ml: 2 }}
                  />
                </Grid>
                {item?.type === ComponentItemType.DatePicker && (
                  <Grid item xs={12}>
                    <Typography fontSize={12}>Display Style</Typography>
                    <RadioGroup row value={item?.config?.displayStyle} onChange={handleConfigChange("displayStyle")} sx={{ mt: 1 }}>
                      <FormControlLabel
                        value='DatePicker'
                        control={<Radio size='small' />}
                        label={<Typography fontSize={12}>Date Picker</Typography>}
                      />
                      <FormControlLabel
                        value='DateCalendar'
                        control={<Radio size='small' />}
                        label={<Typography fontSize={12}>Date Calendar</Typography>}
                      />
                    </RadioGroup>
                  </Grid>
                )}
                <Grid container item xs={12}>
                  <Typography fontSize={12} fontWeight='bold'>
                    DateTime Range
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container item xs={12} justifyContent='space-between' alignItems='center' pl={2}>
                    <Grid xs={12}>
                      <Typography fontSize={12}>Start range</Typography>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center' pl={2} spacing={1}>
                      <Typography fontSize={12}>now</Typography>
                      <Switch
                        size='small'
                        checked={!!item?.config?.startsNow}
                        onChange={handleConfigChange("startsNow", "boolean")}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ ml: 2 }}
                      />
                      <Grid item xs={12}>
                        <DateTimePicker
                          disabled={item?.config?.startsNow}
                          value={item?.config?.startDate}
                          onChange={newDate => handleDateConfigChange("startDate", newDate)}
                          sx={{ mt: 1, width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size='small'
                          fullWidth
                          label='Custom Error Message - startDate'
                          value={customErrorMessageStartDate || ""}
                          onChange={handleChangeProp("customErrorMessageStartDate")}
                          InputLabelProps={{ style: { fontSize: "12px" } }}
                          InputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container item xs={12} justifyContent='space-between' alignItems='center' pl={2}>
                    <Grid xs={12}>
                      <Typography fontSize={12}>End range</Typography>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center' pl={2} spacing={1}>
                      <Typography fontSize={12}>now</Typography>
                      <Switch
                        size='small'
                        checked={!!item?.config?.endsNow}
                        onChange={handleConfigChange("endsNow", "boolean")}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ ml: 2 }}
                      />
                      <Grid item xs={12}>
                        <DateTimePicker
                          disabled={item?.config?.endsNow}
                          value={item?.config?.endDate}
                          onChange={newDate => handleDateConfigChange("endDate", newDate)}
                          sx={{ mt: 1, width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size='small'
                          fullWidth
                          label='Custom Error Message - EndDate'
                          value={customErrorMessageEndDate || ""}
                          onChange={handleChangeProp("customErrorMessageEndDate")}
                          InputLabelProps={{ style: { fontSize: "12px" } }}
                          InputProps={{ style: { fontSize: "12px" } }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {item?.type !== ComponentItemType.DatePicker && (
              <>
                <Grid item>
                  <Grid container item xs={12}>
                    <Typography fontSize={12} fontWeight='bold'>
                      Time
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={1} pl={2}>
                    {["Hours", "Minutes", "Seconds"].map(label => (
                      <Grid key={label} container item xs={12} alignItems='center' rowSpacing={1}>
                        <Grid item xs={7}>
                          <Typography fontSize={12}>{label}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Switch
                            size='small'
                            checked={!!item?.config?.[`${label.toLowerCase()}Enabled`]}
                            onChange={handleConfigChange(`${label.toLowerCase()}Enabled`, "boolean")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            type='number'
                            size='small'
                            label='Steps'
                            value={item?.config?.[`${label.toLowerCase()}Step`] || ""}
                            onChange={handleConfigChange(`${label.toLowerCase()}Step`, "number")}
                            disabled={!item?.config?.[`${label.toLowerCase()}Enabled`]}
                            InputLabelProps={{ style: { fontSize: "12px" } }}
                            InputProps={{ style: { fontSize: "12px" } }}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size='small'
                            fullWidth
                            label={`Custom Error Message - ${label}Step`}
                            value={_.get(item?.props, `customErrorMessage${label}Step`) || ""}
                            onChange={handleChangeProp(`customErrorMessage${label}Step`)}
                            disabled={!item?.config?.[`${label.toLowerCase()}Enabled`]}
                            InputLabelProps={{ style: { fontSize: "12px" } }}
                            InputProps={{ style: { fontSize: "12px" } }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.type === "Button" && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.button} onChange={() => handleAccordionChange("button")} label='Button'>
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={12}>
                <TranslationInput
                  label={"Text"}
                  fullWidth
                  value={children || ""}
                  onChange={handleChangeProp("children")}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label='Background Color'
                  placeholder='Background Color'
                  value={sx?.backgroundColor?.replace(/!important/g, "") || ""}
                  onChange={handleChangeBackGround("sx.backgroundColor")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
                {/* <MapValuesComponent
                  label='Background Color'
                  value={
                    isMapValues
                      ? sx?.backgroundColor?.value.replace(/!important/g, "") || ""
                      : sx?.backgroundColor?.replace(/!important/g, "") || ""
                  }
                  enableMapValues={isMapValues}
                  mapValuesObject={color?.mapValuesObject || {}}
                  handleChangeProp={handleChangeBackGround}
                  valueString={"sx.backgroundColor"}
                  inputLabelProps={{ style: { fontSize: "12px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                /> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  select
                  fullWidth
                  value={variant}
                  onChange={handleChangeSelect("variant")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                >
                  {["text", "contained", "outlined"].map(variant => (
                    <MenuItem key={variant} value={variant}>
                      {variant}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
                <Typography sx={{ fontSize: "12px" }}>{!enableLoading ? "Disabled loading" : "Enable loading"}</Typography>
                <Switch size='small' checked={enableLoading} onChange={handleToggleProp("enableLoading")} />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {(item?.type === ComponentItemType.FlexContainer || item?.type === ComponentItemType.StepperContainer) && (
        <ItemsPropertiesAccordion
          expanded={accordionState.flexContainer}
          onChange={() => handleAccordionChange("flexContainer")}
          label='Flex Container'
        >
          <Grid container px={1} py={1} spacing={2}>
            <Grid item xs={12}>
              <MapValuesComponent
                label='Background Color'
                value={isMapValues ? backgroundColor?.value : backgroundColor}
                defaultValue={backgroundColor}
                enableMapValues={isMapValues}
                mapValuesObject={backgroundColor?.mapValuesObject || {}}
                handleChangeProp={handleChangeProp}
                valueString={"backgroundColor"}
                inputLabelProps={{ style: { fontSize: "12px" } }}
                inputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
            {flexContainerIncludedProps.map((name, index) => {
              let inputField;
              const label = splitUpperCase(name)?.join(" ");
              if (["justifyContent", "flexWrap", "alignItems", "flexDirection"].includes(name)) {
                const options = {
                  flexWrap: wrapValues,
                  justifyContent: justifyContentValues,
                  alignItems: alignItemsValues,
                  flexDirection: flexDirectionValues,
                };

                inputField = (
                  <Autocomplete
                    key={index}
                    options={options[name as keyof typeof options]}
                    renderInput={params => (
                      <TextField
                        type='text'
                        name={name}
                        placeholder={label}
                        label={label}
                        value={item?.props[name] || ""}
                        {...params}
                        size='small'
                        InputLabelProps={{ style: { fontSize: "12px" } }}
                        InputProps={{ style: { fontSize: "12px" } }}
                      />
                    )}
                    value={item?.props?.sx?.[name] || null}
                    // onChange={(event, value) => handleSetChangeProp(`props.sx.${name}`)(value)}
                    onInputChange={(event, value) => handleSetChangePropStyle(`props.sx.${name}`)(value)}
                  />
                );
              } else {
                inputField = (
                  <TextField
                    size='small'
                    type='text'
                    name={name}
                    placeholder={label}
                    fullWidth
                    label={label}
                    value={item?.props?.sx?.[name] || ""}
                    onChange={handleSetChangeProp(`props.sx.${name}`)}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                );
              }
              return (
                <>
                  <Grid item xs={12} key={index}>
                    {inputField}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </ItemsPropertiesAccordion>
      )}
      {item?.type === ComponentItemType.GridContainer && (
        <ItemsPropertiesAccordion
          expanded={accordionState.gridContainer}
          onChange={() => handleAccordionChange("gridContainer")}
          label='Grid Container'
        >
          <Grid container px={1} py={1} spacing={2}>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center' gap={"1px"}>
              <Typography sx={{ fontSize: "12px" }}>{!!isAdvance ? "Advance" : "Basic"}</Typography>
              <Switch
                size='small'
                checked={!!isAdvance}
                onChange={handleToggleProp("isAdvance", handleAdvanceToggleChange)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            {gridContainerIncludedProps.map((name, index) => {
              const isSxProps =
                name === "justifyContent" || name === "align-content" || name === "margin" || name === "padding" || name === "gap";

              const label = splitUpperCase(name)?.join(" ");

              const inputField = (
                <TextField
                  size='small'
                  type='text'
                  name={name}
                  placeholder={label}
                  fullWidth
                  label={label}
                  value={isSxProps ? item?.props?.sx?.[name] || "" : item?.props?.[name] || ""}
                  onChange={isSxProps ? handleSetChangeProp(`props.sx.${name}`) : handleChangeGridTemplateColumns(`props.${name}`)}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              );

              return (
                <>
                  {!isAdvance &&
                    (name == "gridTemplateColumns" || name == "justifyContent" || name == "align-content" ? null : (
                      <Grid item xs={12} key={index}>
                        {inputField}
                      </Grid>
                    ))}
                  {isAdvance &&
                    (name == "extraSmall" || name == "small" || name == "medium" || name == "large" || name == "extraLarge" ? null : (
                      <Grid item xs={12} key={index}>
                        {inputField}
                      </Grid>
                    ))}
                </>
              );
            })}
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.config?.acceptTypes?.includes("CustomCheckbox") && (
        <ItemsPropertiesAccordion
          expanded={accordionState.checkboxGroup}
          onChange={() => handleAccordionChange("checkboxGroup")}
          label='Checkbox Group Configuration'
        >
          <Grid container px={1} py={1} spacing={2}>
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={"Data Entry"}
                value={dataEntry || ""}
                onChange={handleConfigChange("dataEntry")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={"label"}
                value={keyLabel || ""}
                onChange={handleConfigChange("keyLabel")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={"Value"}
                value={keyValue || ""}
                onChange={handleConfigChange("keyValue")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>{"Single Value"}</Typography>
              <Switch
                size='small'
                checked={singleValue}
                onChange={handleToggleProp("singleValue")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.config?.acceptTypes?.includes("CustomRadio") && (
        <ItemsPropertiesAccordion
          expanded={accordionState.radioGroup}
          onChange={() => handleAccordionChange("radioGroup")}
          label='Radio Group Configuration'
        >
          <Grid container px={1} py={1} spacing={2}>
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={"Data Entry"}
                value={dataEntry || ""}
                onChange={handleConfigChange("dataEntry")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={"label"}
                value={keyLabel || ""}
                onChange={handleConfigChange("keyLabel")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label={"Value"}
                value={keyValue || ""}
                onChange={handleConfigChange("keyValue")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.type === ComponentItemType.CustomIconButton && (
        <>
          <ItemsPropertiesAccordion
            expanded={accordionState.iconButton}
            onChange={() => handleAccordionChange("iconButton")}
            label='Icon Button'
          >
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <BXIconPicker
                  name='props.iconConfig.icon'
                  label='Icon'
                  defaultValue={item?.props?.iconConfig?.icon || ""}
                  previewIcon={false}
                  onChange={handleSetChangeProp("props.iconConfig.icon")}
                  handleSetChangeIconProp={handleSetChangeIconProp}
                  objName={"props.iconConfig"}
                  showCaption={false}
                  inputLabelProps={{ style: { fontSize: "12px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                  size='small'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  label='Label'
                  placeholder='Label'
                  value={item?.actionConfig?.label || ""}
                  onChange={handleSetChangeProp("actionConfig.label")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  label='Border radius'
                  placeholder='Border radius'
                  value={item?.props?.style?.borderRadius || ""}
                  onChange={handleSetChangeProp("props.style.borderRadius")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <MapValuesComponent
                  label='Icon Size'
                  value={isMapValues ? iconSize.value : iconSize}
                  defaultValue={iconSize}
                  enableMapValues={isMapValues}
                  mapValuesObject={iconSize?.mapValuesObject || {}}
                  handleChangeProp={handleChangeProp}
                  valueString={"iconSize"}
                  inputLabelProps={{ style: { fontSize: "12px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  label='Border Color'
                  placeholder='Border Color'
                  value={item?.props?.borderColor || ""}
                  onChange={handleSetChangeProp("props.borderColor")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  label='Border Width'
                  placeholder='Border Width'
                  value={item?.props?.borderWidth || ""}
                  onChange={handleSetChangeProp("props.borderWidth")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  label='Icon Color'
                  placeholder='Icon Color'
                  value={item?.props?.iconColor || ""}
                  onChange={handleSetChangeProp("props.iconColor")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  label='Background Color'
                  placeholder='Background Color'
                  value={backgroundColor || ""}
                  onChange={handleChangeProp("backgroundColor")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid item xs={6} display={"flex"} justifyContent={"start"} alignItems={"center"} spacing={2}>
                <Typography sx={{ fontSize: "12px" }}>{!enableLoading ? "Disabled loading" : "Enable loading"}</Typography>
                <Switch size='small' checked={enableLoading} onChange={handleToggleProp("enableLoading")} />
              </Grid>
              {/* <Grid item xs={6}>
                <TextField
                  size='small'
                  select
                  fullWidth
                  value={size}
                  onChange={handleChangeSelect("size")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                >
                  {["small", "medium", "large"].map(size => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      <ItemsPropertiesAccordion
        expanded={accordionState.visibility}
        onChange={() => handleAccordionChange("visibility")}
        label='Visibility'
      >
        <Grid container spacing={2} p={1}>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label='visibility condition'
              placeholder='visibility condition'
              value={visibilityCondition || ""}
              onChange={handleConfigChange("visibility.visibilityCondition")}
              InputLabelProps={{ style: { fontSize: "12px" } }}
              InputProps={{ style: { fontSize: "12px" } }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              size='small'
              select
              fullWidth
              value={selectedVisibilityType}
              label='Type'
              placeholder='Type'
              onChange={handleConfigChange("visibility.selectedVisibilityType")}
              InputLabelProps={{ style: { fontSize: "12px" } }}
              InputProps={{ style: { fontSize: "12px" } }}
            >
              {visibilityTypes?.map(item => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </ItemsPropertiesAccordion>
      <ItemsPropertiesAccordion expanded={accordionState.disable} onChange={() => handleAccordionChange("disable")} label='Disable'>
        <Grid container spacing={2} p={1}>
          <Grid item xs={12}>
            <TextField
              size='small'
              fullWidth
              label='disable condition'
              placeholder='disable condition'
              value={disableCondition || ""}
              onChange={handleConfigChange("disable.disableCondition")}
              InputLabelProps={{ style: { fontSize: "12px" } }}
              InputProps={{ style: { fontSize: "12px" } }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>With overlay</Typography>
              <Switch
                size='small'
                checked={withOverlay}
                onChange={handleConfigChange("disable.withOverlay", "boolean")}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </Grid>
          {withOverlay && (
            <>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label='overlay color'
                  placeholder='overlay color'
                  value={overlayColor || ""}
                  onChange={handleConfigChange("disable.overlayColor")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label='overlay opacity'
                  placeholder='overlay opacity'
                  value={overlayOpacity || ""}
                  onChange={handleConfigChange("disable.overlayOpacity")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </ItemsPropertiesAccordion>
      {item?.type === ComponentItemType.Avatar && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.image} onChange={() => handleAccordionChange("image")} label='Image'>
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <BXIconPicker
                  name='props.iconConfig.icon'
                  label='src'
                  defaultValue={item?.props?.iconConfig?.icon || ""}
                  previewIcon={false}
                  onChange={handleSetChangeProp("props.iconConfig.icon")}
                  handleSetChangeIconProp={handleSetChangeIconProp}
                  objName={"props.iconConfig"}
                  showCaption={false}
                  inputLabelProps={{ style: { fontSize: "12px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                  size='small'
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size='small'
                  fullWidth
                  label='Alt'
                  placeholder='Alternative'
                  value={alt || ""}
                  onChange={handleChangeProp("alt")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {item?.type === ComponentItemType.Typography && (
        <>
          <ItemsPropertiesAccordion
            expanded={accordionState.typography}
            onChange={() => handleAccordionChange("typography")}
            label='Typography'
          >
            <Grid container px={1} py={1} spacing={2}>
              <Grid item xs={6}>
                <TranslationInput
                  label={"Text"}
                  fullWidth
                  value={children || ""}
                  onChange={handleChangeProp("children")}
                  appId={appId}
                  multiLingual={multiLingual}
                  setMultiLingual={setMultiLingual}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                />
              </Grid>

              <Grid item xs={6}>
                <MapValuesComponent
                  label='font Size'
                  value={isMapValues ? fontSize.value : fontSize}
                  enableMapValues={isMapValues}
                  defaultValue={fontSize}
                  mapValuesObject={fontSize?.mapValuesObject || {}}
                  handleChangeProp={handleChangeProp}
                  valueString={"fontSize"}
                  inputLabelProps={{ style: { fontSize: "12px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size='small'
                  select
                  fullWidth
                  value={variant}
                  label='Variant'
                  placeholder='Variant'
                  onChange={handleChangeSelect("variant")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                >
                  {typographyComponents?.map(item => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size='small'
                  select
                  fullWidth
                  value={align}
                  label='Align'
                  placeholder='Align'
                  onChange={handleChangeSelect("align")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                >
                  {typographyAlign?.map(item => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size='small'
                  select
                  fullWidth
                  value={format}
                  label='Type'
                  placeholder='Type'
                  onChange={handleChangeSelect("format")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                >
                  {typographyFormats?.map(item => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {format === "Date Time" && (
                <>
                  <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                    <Typography fontSize={12}>User local time</Typography>
                    <Switch
                      size='small'
                      checked={isUserLocalTime}
                      onChange={handleConfigChange("isUserLocalTime", "boolean")}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ ml: 2 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size='small'
                      fullWidth
                      value={formatString}
                      label='Date Format'
                      placeholder='Date Format'
                      onChange={handleChangeSelect("formatString")}
                      InputLabelProps={{ style: { fontSize: "12px" } }}
                      InputProps={{ style: { fontSize: "12px" } }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <MapValuesComponent
                  label='Color'
                  value={isMapValues ? color?.value : color}
                  enableMapValues={isMapValues}
                  defaultValue={color}
                  mapValuesObject={color?.mapValuesObject || {}}
                  handleChangeProp={handleChangeProp}
                  valueString={"color"}
                  inputLabelProps={{ style: { fontSize: "12px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {item?.type === ComponentItemType.CustomMediaCard && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.media} onChange={() => handleAccordionChange("media")} label='Media'>
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={6}>
                <BXIconPicker
                  name='props.iconConfig.icon'
                  label='Source'
                  defaultValue={item?.props?.iconConfig?.icon || ""}
                  previewIcon={false}
                  onChange={handleSetChangeProp("props.iconConfig.icon")}
                  handleSetChangeIconProp={handleSetChangeIconProp}
                  objName={"props.iconConfig"}
                  showCaption={false}
                  inputLabelProps={{ style: { fontSize: "12px" } }}
                  inputProps={{ style: { fontSize: "12px" } }}
                  size='small'
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size='small'
                  select
                  fullWidth
                  value={component}
                  onChange={handleChangeSelect("component")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                >
                  <MenuItem key='img' value='img'>
                    image
                  </MenuItem>
                  <MenuItem key='video' value='video'>
                    video
                  </MenuItem>
                  <MenuItem key='audio' value='audio'>
                    audio
                  </MenuItem>
                  <MenuItem key='auto' value='auto'>
                    auto detect
                  </MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  size='small'
                  select
                  fullWidth
                  value={sx?.objectFit || "cover"}
                  onChange={handleChangeSelect("sx.objectFit")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                >
                  <MenuItem key='img' value='cover'>
                    Cover
                  </MenuItem>
                  <MenuItem key='video' value='contain'>
                    Contain
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center' gap={"1px"}>
                  <Typography sx={{ fontSize: "12px" }}>Disabled Cache </Typography>
                  <Switch
                    size='small'
                    checked={!!disabledCache}
                    onChange={handleToggleProp("disabledCache")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              </Grid>
              {(component === "video" || component === "audio") && (
                <Grid item xs={12}>
                  <Grid
                    display='flex'
                    marginInlineStart={"5px"}
                    item
                    xs={12}
                    justifyContent='space-between'
                    alignItems='center'
                    gap={"1px"}
                  >
                    <Typography sx={{ fontSize: "12px" }}>Controls</Typography>
                    <Switch
                      size='small'
                      checked={!!controls}
                      onChange={handleToggleProp("controls")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {(item?.type === ComponentItemType.SplineChart ||
        item?.type === ComponentItemType.ColumnChart ||
        item?.type === ComponentItemType.CircularChart ||
        item?.type === ComponentItemType.PieChart) && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.chart} onChange={() => handleAccordionChange("chart")} label='Chart'>
            <Grid container justifyContent='center' alignItems='center' py={1}>
              <Grid item px={1} style={{ width: "100%" }}>
                <Button variant='outlined' onClick={() => setIsModalOpen(true)} style={{ width: "100%" }}>
                  Chart Configuration
                </Button>
              </Grid>
              <BXModal
                open={isModalOpen}
                icon={<SettingsIcon />}
                maxWidth='md'
                title='Chart Configuration'
                onClose={() => setIsModalOpen(false)}
              >
                <CreateChartForm onSave={handleSave} data={item?.configData} type={item?.type} />
              </BXModal>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {item?.type === ComponentItemType.BXSelect && (
        <>
          <ItemsPropertiesAccordion expanded={accordionState.select} onChange={() => handleAccordionChange("select")} label='Select'>
            <Grid container px={1} py={1} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Data Entry"}
                  value={dataEntry || ""}
                  onChange={handleConfigChange("dataEntry")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"label"}
                  value={keyLabel || ""}
                  onChange={handleConfigChange("keyLabel")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Value"}
                  value={keyValue || ""}
                  onChange={handleConfigChange("keyValue")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            </Grid>
          </ItemsPropertiesAccordion>
        </>
      )}
      {isParentFlex(item) && (
        <ItemsPropertiesAccordion
          expanded={accordionState.flexChildIncludedProps}
          onChange={() => handleAccordionChange("flexChildIncludedProps")}
          label='Flex Child'
        >
          <Grid container px={1} py={1} spacing={1} sx={{ width: "100%" }}>
            {flexChildIncludedProps.map((name, index) => {
              let inputField;
              const label = splitUpperCase(name)?.join(" ");
              if (["align-self"].includes(name)) {
                const options: any = {
                  "align-self": alignSelfValues,
                };
                inputField = (
                  <Autocomplete
                    key={index}
                    options={options[name]}
                    renderInput={params => (
                      <TextField
                        type='text'
                        name={name}
                        placeholder={label}
                        label={label}
                        value={item?.config?.parentStyle?.[name] || ""}
                        {...params}
                        size='small'
                        InputLabelProps={{ style: { fontSize: "12px" } }}
                        InputProps={{ style: { fontSize: "12px" } }}
                      />
                    )}
                    value={item?.config?.parentStyle?.[name] || null}
                    onChange={(event, value) => handleSetChangeProp(`config.parentStyle.${name}`)(value)}
                  />
                );
              } else {
                inputField = (
                  <TextField
                    size='small'
                    type='text'
                    name={name}
                    placeholder={label}
                    fullWidth
                    label={label}
                    value={item?.config?.parentStyle?.[name] || ""}
                    onChange={handleSetChangeProp(`config.parentStyle.${name}`)}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                );
              }
              return (
                <Grid item xs={6} key={index}>
                  {inputField}
                </Grid>
              );
            })}
          </Grid>
        </ItemsPropertiesAccordion>
      )}
      {isParentGrid(item) && (
        <ItemsPropertiesAccordion
          expanded={accordionState.gridChildIncludedProps}
          onChange={() => handleAccordionChange("gridChildIncludedProps")}
          label='Grid Child'
        >
          <Grid container px={1} py={1} spacing={1} sx={{ width: "100%" }}>
            {gridChildIncludedProps.map((name, index) => {
              let inputField;
              const label = splitUpperCase(name)?.join(" ");

              inputField = (
                <TextField
                  size='small'
                  type='text'
                  name={name}
                  placeholder={label}
                  fullWidth
                  label={label}
                  value={item?.config?.parentStyle?.[name] || ""}
                  onChange={handleSetChangeProp(`config.parentStyle.${name}`)}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              );

              return (
                <Grid item xs={6} key={index}>
                  {inputField}
                </Grid>
              );
            })}
          </Grid>
        </ItemsPropertiesAccordion>
      )}

      {item?.type === ComponentItemType.FileUploadInput && (
        <ItemsPropertiesAccordion expanded={accordionState.upload} onChange={() => handleAccordionChange("upload")} label='Upload'>
          {item?.type === ComponentItemType.FileUploadInput && (
            <Grid container px={1} py={1} spacing={1}>
              <Grid item xs={12}>
                <FormControl size='small' fullWidth>
                  <InputLabel style={{ backgroundColor: palette.background.paper }} id='demo-multiple-checkbox-label'>
                    Allowed Types
                  </InputLabel>
                  <Select
                    labelId='demo-multiple-checkbox-label'
                    id='demo-multiple-checkbox'
                    multiple
                    value={uploadAllowedTypes || []}
                    onChange={
                      handleConfigChange("uploadConfig.uploadAllowedTypes", undefined, (value: any) => {
                        return typeof value === "string" ? value?.split(",") : value;
                      }) as any
                    }
                    input={<OutlinedInput label='Tag' />}
                    renderValue={selected => selected.join(", ")}
                  >
                    {uploadTypes?.map(name => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={uploadAllowedTypes?.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} marginInlineStart={1}>
                <RadioGroup defaultValue='aws' value={uploadUrlType} onChange={handleConfigChange("uploadConfig.uploadUrlType")} row>
                  <Grid xs={6}>
                    <FormControlLabel value='aws' control={<Radio size='small' />} label={<span style={labelStyle}>AWS</span>} />
                  </Grid>
                  <Grid xs={6}>
                    <FormControlLabel value='custom' control={<Radio size='small' />} label={<span style={labelStyle}>Custom</span>} />
                  </Grid>
                </RadioGroup>
              </Grid>

              {uploadUrlType === "aws" || !uploadUrlType ? (
                <>
                  <Grid item xs={6}>
                    <TextField
                      size='small'
                      fullWidth
                      variant='outlined'
                      required
                      label='Signed Url'
                      value={signedUrl}
                      onChange={handleConfigChange("uploadConfig.signedUrl")}
                      InputLabelProps={{ style: { fontSize: "10px" } }}
                      InputProps={{ style: { fontSize: "12px" } }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      size='small'
                      fullWidth
                      variant='outlined'
                      required
                      label='Finalized Url'
                      value={finalizedUrl}
                      onChange={handleConfigChange("uploadConfig.finalizedUrl")}
                      InputLabelProps={{ style: { fontSize: "10px" } }}
                      InputProps={{ style: { fontSize: "12px" } }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    variant='outlined'
                    required
                    label='Custom Url'
                    value={customUrl}
                    onChange={handleConfigChange("uploadConfig.customUrl")}
                    InputLabelProps={{ style: { fontSize: "10px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
              )}

              <Grid container marginTop={1}>
                <Grid item xs={12} paddingInlineStart={2}>
                  <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                    <Typography sx={{ fontSize: "12px" }}>Multiple files</Typography>
                    <Switch
                      size='small'
                      checked={!!multipleFiles}
                      onChange={handleConfigChange("uploadConfig.multipleFiles", "boolean")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} paddingInlineStart={2}>
                  <Grid display='flex' item justifyContent='space-between' alignItems='center' gap={"1px"}>
                    <Typography sx={{ fontSize: "12px" }}>Crop image</Typography>
                    <Switch
                      size='small'
                      checked={!!isCropImage}
                      onChange={handleConfigChange("uploadConfig.isCropImage", "boolean")}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={uploadAllowedTypes && (uploadAllowedTypes.includes("Video") || uploadAllowedTypes.includes("Audio"))}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  variant='outlined'
                  required
                  label={"Number of files"}
                  type='number'
                  value={fileNumber}
                  onChange={handleConfigChange("uploadConfig.fileNumber")}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            </Grid>
          )}
        </ItemsPropertiesAccordion>
      )}
      <ItemsPropertiesAccordion expanded={accordionState.repeated} onChange={() => handleAccordionChange("repeated")} label='Repeated'>
        <Grid container px={1} py={1} spacing={2}>
          <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
            <Typography sx={{ fontSize: "12px" }}>Enabled</Typography>
            <Switch size='small' checked={!!item?.config?.repeated?.enabled} onChange={handleConfigChange("repeated.enabled", "boolean")} />
          </Grid>
          {!!item?.config?.repeated?.enabled && (
            <>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Data"}
                  value={item?.config?.repeated?.data || ""}
                  onChange={handleConfigChange("repeated.data")}
                  InputLabelProps={{ style: { fontSize: "10px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Min items"}
                  value={item?.config?.repeated?.minItems || ""}
                  onChange={handleConfigChange("repeated.minItems")}
                  InputLabelProps={{ style: { fontSize: "10px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  label={"Max items"}
                  value={item?.config?.repeated?.maxItems || ""}
                  onChange={handleConfigChange("repeated.maxItems")}
                  InputLabelProps={{ style: { fontSize: "10px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
              <Grid display='flex' marginInlineStart={"5px"} item xs={12}>
                <TextField
                  size='small'
                  fullWidth
                  disabled={item?.config?.repeated?.data?.trim()}
                  label={"Default number of items"}
                  value={item?.config?.repeated?.defaultItems || ""}
                  onChange={handleConfigChange("repeated.defaultItems")}
                  InputLabelProps={{ style: { fontSize: "10px" } }}
                  InputProps={{ style: { fontSize: "12px" } }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion expanded={accordionState.styles} onChange={() => handleAccordionChange("styles")} label='Styles'>
        <Box px={1} py={1}>
          <Box display={"flex"} alignItems='center' justifyContent='space-between' mb={2}>
            <Typography sx={{ fontSize: "16px" }}></Typography>
            <Button onClick={saveStyleHandler} variant='contained' disabled={JSON.stringify(sx) === JSON.stringify(styles)}>
              Save
            </Button>
          </Box>

          <Box mb={2}>
            <AutoCompleteCSSComponent handleChangeStyles={handleChangeStyles} styles={sx} id={item?.id} />
          </Box>
          <Box marginTop={2} mb={2}>
            <Button fullWidth onClick={() => onDeleteItem?.(item.id)} variant='outlined'>
              Delete Item
            </Button>
          </Box>
        </Box>
      </ItemsPropertiesAccordion>

      <ItemsPropertiesAccordion
        expanded={accordionState.interactionConfig}
        onChange={() => handleAccordionChange("interactionConfig")}
        label='Interactions'
      >
        <Grid container justifyContent='center' alignItems='center' py={1}>
          <Grid item px={1} style={{ width: "100%" }}>
            <Button variant='outlined' onClick={() => setIsInteractionConfigModalOpen(true)} style={{ width: "100%" }}>
              Interactions Configuration
            </Button>
          </Grid>
          <BXModal
            open={isInteractionConfigModalOpen}
            icon={<SettingsIcon />}
            maxWidth='md'
            title='Interaction Configuration'
            onClose={() => setIsInteractionConfigModalOpen(false)}
          >
            <InteractionConfig item={item} onSave={onInteractionConfigSave} />
          </BXModal>
        </Grid>
      </ItemsPropertiesAccordion>

      {(item?.type === ComponentItemType.BXLayout || item?.type === ComponentItemType.BXView) && (
        <ItemsPropertiesAccordion expanded={accordionState.loading} onChange={() => handleAccordionChange("loading")} label='Loading'>
          <Grid container px={1} py={1} spacing={2}>
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>Enable Loading View</Typography>
              <Switch
                size='small'
                checked={isLoadingViewEnabled}
                onChange={handleConfigChange("loadingConfig.isLoadingViewEnabled", "boolean")}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ ml: 2 }}
              />
            </Grid>
            {isLoadingViewEnabled && (
              <>
                <Grid item xs={12}>
                  <RadioGroup
                    defaultValue='centered'
                    value={loadingViewType}
                    onChange={handleConfigChange("loadingConfig.loadingViewType")}
                    row
                  >
                    <Grid xs={6}>
                      <FormControlLabel value='cover' control={<Radio size='small' />} label={<span style={labelStyle}>Cover</span>} />
                    </Grid>
                    <Grid xs={6}>
                      <FormControlLabel
                        value='centered'
                        control={<Radio size='small' />}
                        label={<span style={labelStyle}>Centered</span>}
                      />
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    select
                    fullWidth
                    value={loadingView}
                    label='Select Loading View'
                    onChange={handleConfigChange("loadingConfig.loadingView")}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{ style: { fontSize: "12px" } }}
                  >
                    {views?.map(view => (
                      <MenuItem key={view?.id} value={view?.id}>
                        {view?.info?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </>
            )}
            <Grid display='flex' marginInlineStart={"5px"} item xs={12} justifyContent='space-between' alignItems='center'>
              <Typography sx={{ fontSize: "12px" }}>Enable Loading Image</Typography>
              <Switch
                size='small'
                checked={isLoadingImageEnabled}
                onChange={handleConfigChange("loadingConfig.isLoadingImageEnabled", "boolean")}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ ml: 2 }}
              />
            </Grid>
            {isLoadingImageEnabled && (
              <>
                <Grid item xs={12}>
                  <RadioGroup
                    defaultValue='centered'
                    value={loadingImageType}
                    onChange={handleConfigChange("loadingConfig.loadingImageType")}
                    row
                  >
                    <Grid xs={6}>
                      <FormControlLabel value='cover' control={<Radio size='small' />} label={<span style={labelStyle}>Cover</span>} />
                    </Grid>
                    <Grid xs={6}>
                      <FormControlLabel
                        value='centered'
                        control={<Radio size='small' />}
                        label={<span style={labelStyle}>Centered</span>}
                      />
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <BXIconPicker
                    name='loading image'
                    label='Loading Image'
                    defaultValue={loadingImage || ""}
                    previewIcon={false}
                    onChange={handleConfigChange("loadingConfig.loadingImage")}
                    showCaption={false}
                    inputLabelProps={{ style: { fontSize: "12px" } }}
                    inputProps={{ style: { fontSize: "12px" } }}
                    size='small'
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label='Overlay color'
                placeholder='Overlay color'
                value={loadingOverlayColor}
                onChange={handleConfigChange("loadingConfig.loadingOverlayColor")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size='small'
                fullWidth
                label='Overlay opacity'
                placeholder='Overlay opacity'
                value={loadingOverlayOpacity}
                onChange={handleConfigChange("loadingConfig.loadingOverlayOpacity")}
                InputLabelProps={{ style: { fontSize: "12px" } }}
                InputProps={{ style: { fontSize: "12px" } }}
              />
            </Grid>
          </Grid>
        </ItemsPropertiesAccordion>
      )}
    </Grid>
  );
};
