import { LoadingButton } from "@mui/lab";
import { Button, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { IconPlus } from "@tabler/icons-react";
import React, { FC, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import BXModal from "src/components/BXUI/Modal";
import useAuth from "src/hooks/useAuth";
import TemplatesTable from "./TemplatesTable";

type ManageTemplatesProps = {
  children?: React.ReactNode;
};

export const ManageTemplates: FC<ManageTemplatesProps> = ({ children }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { user } = useAuth();
  const [openAddTemplateModal, setAddTemplateModal] = useState(false);
  const { saveAsTemplate } = useBXBuilderContext();
  const { mutateAsync: onSaveAsTemplate, isLoading } = useMutation({
    mutationFn: (data: any) => saveAsTemplate({ ...data }, data?.id),
  });

  const onRedirectToTemplate = (id: string) => {
    navigate(`/buildx/form-builder/template/${id}`);
  };

  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const handleChange = (event): any => {
    const value = event.target.value;
    setName(value);
    if (value.trim() === "") {
      setError(true);
      setHelperText("name name is required");
    } else {
      setError(false);
      setHelperText("");
    }
  };
  return (
    <>
      <Grid container style={{ height: "100%" }}>
        <Grid style={{ height: "100%", padding: 10, paddingTop: 0 }} item md={12}>
          <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2 }}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid container marginBottom={1}>
            <Grid container flex={1} spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography fontSize={"20px"} color='textPrimary' fontWeight={600}>
                  Manage Templates
                </Typography>
              </Grid>
            </Grid>
            <BXModal
              open={openAddTemplateModal}
              onClose={() => {
                setAddTemplateModal(false);
              }}
              title={`Add New Template`}
              label={"Template"}
              buttonProps={{
                startIcon: <IconPlus />,
                color: "secondary",
                onClick: () => {
                  setAddTemplateModal(true);
                },
                variant: "contained",
                style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                size: "small",
              }}
            >
              <Grid
                container
                spacing={3}
                pt={3}
                component={"form"}
                onSubmit={async (e: any) => {
                  e.preventDefault();
                  if (name.trim() === "") {
                    setError(true);
                    setHelperText("name is required");
                  } else {
                    const formData: any = Object.fromEntries(new FormData(e.target).entries());
                    const data = await onSaveAsTemplate({ ...formData, config: [] });
                    onRedirectToTemplate(data.data.id);
                  }
                }}
              >
                <Grid item xs={4}>
                  <TextField
                    type='text'
                    name='name'
                    fullWidth
                    label='Template name'
                    onChange={handleChange}
                    error={error}
                    helperText={helperText}
                  />
                </Grid>
                {user?.organization?.id === "default" && (
                  <Grid item xs={4}>
                    <TextField select name='visibility' fullWidth defaultValue='PRIVATE' label='Template visibility'>
                      {["PUBLIC", "PRIVATE"].map(variant => (
                        <MenuItem key={variant} value={variant}>
                          {variant}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <TextField select name='type' fullWidth defaultValue='VIEW' label='Template type'>
                    {["VIEW", "APPLICATION", "PAGE"].map(variant => (
                      <MenuItem key={variant} value={variant}>
                        {variant}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} display='flex' justifyContent={"center"} alignItems={"center"}>
                  <LoadingButton loading={isLoading} variant='contained' type='submit'>
                    Create Template
                  </LoadingButton>
                </Grid>
              </Grid>
            </BXModal>
            <Button
              onClick={() => {
                setAddTemplateModal(true);
              }}
              variant='contained'
              startIcon={<IconPlus />}
            >
              Template
            </Button>
          </Grid>
          <TemplatesTable isImport={false} />
        </Grid>
      </Grid>
    </>
  );
};
