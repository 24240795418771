import { FC } from "react";
import Markdown from "react-markdown";
import { useLocation } from "react-router-dom";
import { formBuilderPath } from "src/config";
import { decodeMarkdown } from "src/utils/generalUtils";
import { MarkdownViewerProps } from "./types";

export const MarkdownViewer: FC<MarkdownViewerProps> = componentProps => {
  const { label } = componentProps;
  const location = useLocation();
  const markdownClassName = location.pathname.startsWith(formBuilderPath) ? "markdown-content" : "";

  return <Markdown className={markdownClassName}>{decodeMarkdown(label)}</Markdown>;
};
