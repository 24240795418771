import { Buffer } from "buffer";
import { gzip, ungzip } from "pako";
import axios from "./axios";
import axiosExt from "./axiosExt";
import { CustomError, ErrorHelper } from "./ErrorHelper";
const Services = {
  fetchPresigned: async () => {
    try {
      const response = await axios.get(`/uploads/s3-url`);
      if (!response.data.key || !response.data.url) {
        ErrorHelper.throwError("API_INVALID_PREFETCH_URI_RESPONSE");
      }
      return response.data;
    } catch (err) {
      const errKey = err instanceof CustomError ? err.name : ErrorHelper.findErrorKey("FETCH_PRESIGN_URL_ERROR");
      return { error: errKey };
    }
  },
  s3Upload: async (url: string, file: File) => {
    try {
      const response = await axiosExt.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
        timeout: 2 * 60 * 1000,
      });
      if (response.status !== 200) {
        ErrorHelper.throwError("API_INVALID_S3_UPLOAD_RESPONSE");
      }
      return { status: response.status };
    } catch (err) {
      const errKey = err instanceof CustomError ? err.name : ErrorHelper.findErrorKey("PUT_S3_UPLOAD_ERROR");
      return { error: errKey };
    }
  },
  finalizeAsset: async (key: string, ext: string = "jpg") => {
    try {
      const response = await axios.post(`/uploads/finalize/s3-copy?key=${key}&extn=${ext}`);
      if (!response.data.msg) {
        ErrorHelper.throwError("API_INVALID_FINAL_URI_RESPONSE");
      }
      return response.data;
    } catch (err) {
      const errKey = err instanceof CustomError ? err.name : ErrorHelper.findErrorKey("POST_FINAL_URL_ERROR");
      return { error: errKey };
    }
  },
  uploadAsset: async (file: File, filename: string, isFinalUrlReqd: boolean = false) => {
    try {
      const presignedData = await Services.fetchPresigned();
      if (presignedData.error) {
        ErrorHelper.throwError(presignedData.error);
      }
      const s3Response = await Services.s3Upload(presignedData.url, file);
      if (s3Response.error) {
        ErrorHelper.throwError(s3Response.error);
      }
      if (!isFinalUrlReqd) {
        return { key: presignedData.key };
      }
      const fileExt = Helpers.getFileExtn(filename);
      const finalizedData = await Services.finalizeAsset(presignedData.key, fileExt);
      if (finalizedData.error) {
        ErrorHelper.throwError(finalizedData.error);
      }
      return { key: finalizedData.msg };
    } catch (err) {
      const customErr = err instanceof CustomError ? err : ErrorHelper.getError("FILE_UPLOAD_ERROR");
      return { error: customErr.name };
    }
  },
};

export const Helpers = {
  generateUrl: (base: string, queryParams: { [key: string]: any } = {}, ...subParts: string[]) => {
    let url = `${base}`;
    if (subParts?.length) {
      url += subParts.reduce((total, subPart) => `${total}/${subPart}`, "");
    }
    url += `?`;
    for (const key of Object.keys(queryParams)) {
      url += `${key}=${queryParams[key]}&`;
    }
    url = url.slice(0, -1);
    return url;
  },
  concatUrl: (urlParts: string[]) => {
    let url = "";
    for (const urlPart of urlParts) {
      url += urlPart;
    }
    return url;
  },
  getFileExtn: (filename: string) => filename.split(".")[filename.split(".").length - 1],
};

export function compressData(data) {
  if (!data) return data;
  const compressedData = gzip(JSON.stringify(data));
  const base64Compressed = Buffer.from(new Uint8Array(compressedData)).toString("base64");
  return base64Compressed;
}

export function decompressData(data) {
  if (!data) return data;

  if (typeof data === "object") {
    return data;
  }
  const compressedData = Buffer.from(data, "base64");
  const decompressedData = ungzip(compressedData, { to: "string" });
  return JSON.parse(decompressedData);
}

export default Services;
