import { Box, Chip, Divider } from "@mui/material";
import { capitalize } from "lodash";

export const PolicyStatementSummary = ({ policy }) => {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      {policy.statements.map((statement, index) => (
        <>
          <Box
            display={"inline-flex"}
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={1}
            style={{ textTransform: "initial", paddingBottom: 4, paddingTop: 4 }}
          >
            <b>{capitalize(statement.effect)}</b> {statement.action.map(action => (action == "*" ? "All actions" : action)).join(", ")}{" "}
            <i>of:</i>{" "}
            {statement.resources.map(action => (
              <Chip size='small' label={action == "*" ? "All Resources" : action}></Chip>
            ))}
          </Box>
          {policy.statements.length > 1 && index < policy.statements.length - 1 && <Divider />}
        </>
      ))}
    </Box>
  );
};
