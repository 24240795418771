import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Checkbox, TableSortLabel, TextField, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { SortDirection } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _, { get } from "lodash";
import * as React from "react";

type TablePickerRowProps = {
  renderSubRow?: (row: any, isSelected: boolean) => React.ReactNode;
  row: any;
  columns: TablePickerColumnType[];
  onSelectChange?: (value: any, isSelected: boolean) => void;
  selected?: boolean;
};
const TablePickerRow: React.FC<TablePickerRowProps> = ({ columns, row, onSelectChange, renderSubRow, selected }) => {
  const [open, setOpen] = React.useState(false);
  
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            checked={Boolean(selected)}
            onChange={e => onSelectChange?.(row, Boolean(e.target.checked))}
          />
        </TableCell>
        <TableCell>
          <IconButton aria-label='expand row' size='small' style={{ padding: 0 }} onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map(col => (
          <TableCell key={col.source} component='th' scope='row'>
            {col.source && get(row, col.source)}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>{renderSubRow?.(row, Boolean(selected))}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

function flowFilter(array, substr) {
  return _.filter(array, _.flow(_.identity, _.values, _.join, _.toLower, _.partialRight(_.includes, substr?.toLowerCase?.())));
}

type TablePickerColumnType = {
  name: string;
  sortable?: boolean;
  filterable?: boolean;
  verticalAlign?: string;
  horizontalAlign?: string;
  source?: string;
  type?: string;
  allowWrap?: boolean;
};

type TablePickerProps = {
  rows: any[];
  columns: TablePickerColumnType[];
  renderSubRow?: (row: any, isSelected: boolean) => React.ReactNode;
  defaultSelection?: any[];
  onSelectionChange?: (selection: any[]) => void;
};

export const TablePicker: React.FC<TablePickerProps> = ({ rows, columns, renderSubRow, defaultSelection = [], onSelectionChange }) => {
  const [selection, setSelection] = React.useState<any[]>(defaultSelection);
  const [allSelected, setAllSelected] = React.useState<Boolean>(false);
  
  const onSelectAllClick = () => {
    setSelection((prevSelection) => {
      const newSelection = allSelected ? [] : filteredAndSortedRows;
      setAllSelected(!allSelected);
      return newSelection;
    });
  }

  const numSelected = React.useMemo(() => selection.length, [selection]);
  const rowCount = React.useMemo(() => rows.length, [rows]);
  const { palette } = useTheme();

  React.useEffect(() => {
    onSelectionChange?.(selection);
  }, [selection, onSelectionChange]);

  const [searchValue, setSearchValue] = React.useState<string>("");
  const [order, setOrder] = React.useState<SortDirection>("asc");
  const [orderBy, setOrderBy] = React.useState(columns?.[0]?.source);

  const filteredAndSortedRows = React.useMemo(() => {

    const filteredRows = flowFilter(rows, searchValue);
    
    const sortedRows = _.sortBy(filteredRows, orderBy ? [orderBy] : []);
  
    if (order === "desc") {
      sortedRows.reverse();
    }
  
    return sortedRows;
  }, [rows, searchValue, order, orderBy]);
  

  const handleRequestSort = React.useCallback(
    property => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy, setOrder, setOrderBy]
  );

  return (
    <Paper style={{ width: "100%", overflow: "auto", border: "1px solid #303540" }}>
      <Box paddingInlineStart={2} paddingInlineEnd={2} paddingBottom={1} paddingTop={2}>
        <TextField fullWidth label='Search' size='small' onChange={e => setSearchValue(e.currentTarget.value)} />
      </Box>
      <TableContainer style={{ maxHeight: 300 }} component={Paper}>
        <Table stickyHeader aria-label='table' size='small'>
          <caption style={{ position: "sticky", bottom: 0, padding: "4px 16px", background: palette.background.paper, zIndex: 2 }}>
            {selection.length} items selected
          </caption>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  color='primary'
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell />
              {columns.map(col => (
                <TableCell key={col.source} width={"40%"} sortDirection={orderBy === col.source ? order : false}>
                  <TableSortLabel
                    active={orderBy === col.source}
                    direction={orderBy === col.source ? order || undefined : "asc"}
                    onClick={() => handleRequestSort(col.source)}
                  >
                    {col.name}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredAndSortedRows.map(row => (
              <TablePickerRow
                selected={selection.some(item => item.id === row.id)}
                renderSubRow={renderSubRow}
                columns={columns}
                onSelectChange={(value, isSelected) => {
                  setSelection(oldSelection => (isSelected ? [...oldSelection, value] : oldSelection.filter(item => item.id !== value.id)));
                }}
                key={row.id}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
