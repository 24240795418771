import { FC } from "react";
import ReactJson from "react-json-view";

export const JsonViewer: FC<any> = componentProps => {
  const { value, defaultValue, onChange, JsonTheme, isEditable, ...rest } = componentProps;

  return (
    <ReactJson
      src={value || defaultValue}
      style={{ height: "100%", padding: 10, borderRadius: 5, overflow: "auto" }}
      theme={JsonTheme}
      onEdit={isEditable ? edit => onChange(edit?.updated_src) : false}
      {...rest}
    />
  );
};
