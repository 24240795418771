import { css } from "@codemirror/lang-css";
import { useCodeMirror } from "@uiw/react-codemirror";
import { useCallback, useEffect, useMemo, useRef } from "react";

export function AutoCompleteCSSComponent({
  id,
  handleChangeStyles,
  styles,
}: {
  id?: any;
  handleChangeStyles: (arg: any) => void;
  styles: string;
}) {
  const editor = useRef();

  interface CSSObject {
    [key: string]: string;
  }

  const cssInit = useMemo(
    () =>
      convertObjectToCSS(styles as any) ||
      `{
        width: 50px;
        height: 50px;
      }
      `,
    [id]
  );

  function convertCSSStringToObject(cssString: string): CSSObject {
    const cssObject: CSSObject = {};
    const cssProperties = cssString
      .substring(1, cssString.length - 1)
      .split(";")
      .map(property => property.replace(/\s*([^:]+)\s*:/, "$1:"));

    cssProperties.forEach(property => {
      const [name, value] = property.split(":");
      if (name && value) {
        let transformedName = name.replace(/-./g, x => x.charAt(1).toUpperCase());
        transformedName = transformedName.replace("xp", "");
        cssObject[transformedName] = value.replace(/\s+/g, " ").trim();
      }
    });

    return cssObject;
  }

  function convertObjectToCSS(cssObject: CSSObject): string {
    let cssString = "";

    for (const property in cssObject) {
      if (Object.prototype.hasOwnProperty.call(cssObject, property)) {
        const value = cssObject[property];
        cssString += `${property}: ${value};\n`;
      }
    }
    return `{\n${cssString}}`;
  }

  const onChange = useCallback(value => {
    handleChangeStyles(convertCSSStringToObject(value));
  }, []);

  const { setContainer } = useCodeMirror({
    container: editor.current,
    extensions: [css()],
    basicSetup: {
      lineNumbers: false,
    },
    value: cssInit,
    onChange,
    theme: "dark",
  });
  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.current]);
  return <div ref={editor as any} />;
}
