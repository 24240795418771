import { FormControl, TextFieldProps } from "@mui/material";
import { FC } from "react";
import { Control, Controller, UseFormSetValue, useWatch } from "react-hook-form";
import { BXIconPicker } from "./IconPicker";

type RHFIconPickerProps = TextFieldProps & {
  label: string;
  control: Control<any>;
  name: string;
  defaultValue?: any;
  id?: string;
  error?: any;
  showCaption?: boolean;
  setValue: UseFormSetValue<any>;
  objName?: {};
};

export const RHFIconPicker: FC<RHFIconPickerProps> = ({
  name,
  control,
  label,
  id,
  defaultValue,
  error,
  setValue,
  variant = "outlined",
  required = false,
  showCaption,
  objName,
  ...rest
}) => {
  const watchedValue = useWatch({ control, name: objName ?? "icon" } as any);
  const fallbackIcon = useWatch({ control, name: "icon" });

  const effectiveValue = watchedValue?.icon ?? fallbackIcon;
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <BXIconPicker
            name={name}
            control={control}
            label={label}
            defaultValue={defaultValue}
            watchedValue={watchedValue}
            onChange={onChange}
            value={value}
            showCaption={showCaption}
            setValue={setValue}
            objName={objName}
          />
        </FormControl>
      )}
    />
  );
};
