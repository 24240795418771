import React, { useCallback, useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBlocker } from "./useBlocker";
import { BXContext } from "../BXEngine/BXContext";
import { adminPaths, formBuilderPath } from "src/config";


export function useCallbackPrompt(when: boolean): (boolean | (() => void))[] {
  const { setIsAdministrationMode } = useContext(BXContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);

    const isAdministrationPath = adminPaths.includes(location.pathname);
    const isFormBuilderPath = location.pathname === formBuilderPath;

    if (!isAdministrationPath && !isFormBuilderPath) {
      setIsAdministrationMode(false);
    }
  }, [location.pathname, setIsAdministrationMode]);

  // handle blocking when user clicks on another route, prompt will be shown
  const handleBlockedNavigation = useCallback(
    nextLocation => {
      // check if the next location and the current location are not equal
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        // eslint-disable-next-line no-restricted-globals
        if (confirm("There are some changes. Are you sure you want to navigate?")) {
          confirmNavigation();
        } else {
          cancelNavigation();
        }
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
