// material-ui
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";

import useTheme from "@mui/material/styles/useTheme";
import makeStyles from "@mui/styles/makeStyles";
// import User1 from 'assets/images/users/user-round.svg';
// assets
import { IconLogout, IconSettings } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
// project imports
import MainCard from "src/components/cards/MainCard";
import Transitions from "src/components/extended/Transitions";
// import UpgradePlanCard from './UpgradePlanCard';
import useAuth from "src/hooks/useAuth";
import { useBXContext } from "../../../../BXEngine/BXContext";

// style const
const useStyles = makeStyles((theme: any) => ({
  navContainer: {
    width: "100%",
    maxWidth: "350px",
    minWidth: "300px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  headerAvatar: {
    cursor: "pointer",
    ...theme.typography.mediumAvatar,
    marginInline: "8px !important",
    marginTop: "0px !important",
  },
  profileChip: {
    height: "48px !important",
    alignItems: "center !important",
    borderRadius: "27px !important",
    transition: "all .2s ease-in-out",
    backgroundColor: theme.palette.background.paper + " !important",
  },
  profileLabel: {
    lineHeight: 0,
    padding: "12px",
  },
  listItem: {
    marginTop: "5px",
  },
  cardContent: {
    padding: "16px !important",
  },
  card: {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.dark[800] : theme.palette.primary.light,
    marginBottom: "16px",
    marginTop: "16px",
  },
  searchControl: {
    width: "100%",
    paddingInlineEnd: "8px",
    paddingInlineStart: "16px",
    marginBottom: "16px",
    marginTop: "16px",
  },
  startAdornment: {
    fontSize: "1rem",
    color: theme.palette.grey[500],
  },
  flex: {
    display: "flex",
  },
  name: {
    fontWeight: 400,
  },
  ScrollHeight: {
    height: "100%",
    maxHeight: "calc(100vh - 250px)",
    overflowX: "hidden",
  },
  badgeWarning: {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.dark.dark : theme.palette.warning.dark,
    color: "#fff",
  },
}));

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const classes = useStyles();
  const theme = useTheme();

  // const [sdm, setSdm] = React.useState(true);
  // const [value, setValue] = React.useState('');
  // const [notification, setNotification] = React.useState(false);
  const [selectedIndex] = React.useState(1);
  const { logout, user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [user_name, setUserName] = React.useState<string | undefined>("");
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = React.useRef<any>(null);
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if (user != null) {
      if (user.roles) {
        setUserName(user.roles[0]?.name);
      }
    }
  }, [user]);

  const navigate = useNavigate();

  const { setCurrentApp } = useBXContext();

  return (
    <>
      <Chip
        classes={{ root: classes.profileChip, label: classes.profileLabel }}
        icon={
          <Avatar
            src={user?.profilePhoto}
            className={classes.headerAvatar}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup='true'
            color='inherit'
          />
        }
        label={<IconSettings stroke={1.5} size='1.5rem' color={theme.palette.primary.main} />}
        variant='outlined'
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        color='primary'
      />
      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <CardContent className={classes.cardContent}>
                    <Grid container direction='column' spacing={0}>
                      <Grid item className={classes.flex}>
                        <Typography component='span' variant='h4' className={classes.name}>
                          {user?.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle2'>{user_name}</Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 2 }} />
                    <List component='nav' className={classes.navContainer}>
                      <ListItemButton className={classes.listItem} selected={selectedIndex === 4} onClick={handleLogout}>
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size='1.3rem' />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant='body2'>Logout</Typography>} />
                      </ListItemButton>
                    </List>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
