import useTheme from "@mui/material/styles/useTheme";
import "./PageError.css";

const PageError = ({ error }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        backgroundColor: theme.palette.background.default,
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 100,
      }}
    >
      <span className='errorCode'>{error?.errorCode}</span>
      <span className='errorMessage'>{error?.errorMessage}</span>
      <span className='requestStatusCode'>{error?.requestStatusCode}</span>
    </div>
  );
};

export default PageError;
