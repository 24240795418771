import { FormControl, FormControlLabel, Radio } from "@mui/material";
import { FC } from "react";
import { CustomRadioProps } from "./types";

export const CustomRadio: FC<CustomRadioProps> = props => {
  const { groupName, label, radioValue, onChange, value } = props;

  const handleRadioChange = event => {
    onChange?.(event.target.value);
  };

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Radio
            name={groupName}
            value={radioValue?.toLowerCase() || label?.toLowerCase()}
            checked={value === (radioValue?.toLowerCase() || label?.toLowerCase())}
            onChange={handleRadioChange}
          />
        }
        label={label}
      />
    </FormControl>
  );
};
