// @mui
import { Box, Card, CardHeader, CardProps } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// utils
// import { fNumber } from './formatNumber';
// components
import { ApexOptions } from "apexcharts";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import Chart, { useChart } from "../../../../BuildX/FormBuilder/components/SplineChart/chart";
import { PieChartProps } from "./types";

// ----------------------------------------------------------------------

const LEGEND_HEIGHT = -40; // Define your legend height

const StyledChart = styled("div")(({ theme }) => ({
  height: `calc(100vh - ${LEGEND_HEIGHT}px)`, // Set the chart height relative to viewport height
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": {
    height: `calc(100% - ${LEGEND_HEIGHT}px)`, // Make the chart area relative to the parent div
  },
  "& .apexcharts-canvas svg, .apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important" as "relative",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(100% - ${LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chart: {
    colors?: string[];
    series: {
      label: string;
      value: number;
    }[];
    options?: ApexOptions;
  };
}

export const PieChart: FC<PieChartProps> = ({ options, __data, ...other }) => {
  const { config } = other?.componentData || {};
  const { title, sourceType, subheader, apiMode, series } = config || {};
  const { lineTitle, responseLineKey, fixedLines, valuesKey, chartLineData } = apiMode || {};
  const { id, pageId, info } = other;
  const { isAdministrationMode, currentApp, viewsState } = useBXContext();
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const theme = useTheme();
  let queryKeys = [id];
  const { data: apiData } = useValue({
    queryKeys,
    __data,
    viewId: id,
    pageId,
    dataSource: sourceType === "fixedData" ? undefined : (apiMode as any),
    options: { enabled: !isAdministrationMode },
  });

  const chartSeries: any = series?.map(i => parseFloat(i?.data as any));

  const responseDataArr = _.get(apiData, responseLineKey);

  const [labels, setLabels] = useState(
    sourceType === "API"
      ? responseDataArr?.map(line => _.get(line, lineTitle)).slice(0, fixedLines ? chartLineData.length : undefined)
      : series?.map(i => i?.name)
  );

  useEffect(() => {
    setLabels(
      sourceType === "API"
        ? responseDataArr?.map(line => _.get(line, lineTitle)).slice(0, fixedLines ? chartLineData.length : undefined)
        : series?.map(i => i?.name)
    );
  }, [sourceType, series, responseDataArr, fixedLines, chartLineData.length, lineTitle]);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },

    labels: labels,
    stroke: {
      colors: [theme.palette.background.paper],
    },
    legend: {
      floating: true,
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
    ...options,
  });

  return (
    <Card sx={{ height: "100%" }}>
      {sourceType === "fixedData" ? (
        <>
          <CardHeader title={title} subheader={subheader} />
          <Box sx={{ mt: -5, mx: 3 }}>
            <StyledChart>
              <Chart type='pie' series={chartSeries} options={chartOptions} />
            </StyledChart>
          </Box>
        </>
      ) : (
        <>
          <CardHeader title={title} subheader={subheader} />
          <Box sx={{ mt: -5, mx: 3 }}>
            <StyledChart>
              <Chart
                type='pie'
                series={
                  fixedLines
                    ? chartLineData?.map((line, index) =>
                        replaceDataPlaceholders({
                          queryString: line.data,
                          chart: {
                            line: responseDataArr?.[index],
                          },
                          viewsState,
                          pageId,
                          env: currentApp?.env,
                        })
                      )
                    : responseDataArr?.map(line => {
                        return _.get(line, valuesKey);
                      })
                }
                options={chartOptions}
              />
            </StyledChart>
          </Box>
        </>
      )}
    </Card>
  );
};
