import { Chip } from "@mui/material";
import { joinObjects } from "hd-utils";
import { FC } from "react";
import { CustomChipProps } from "./types";

export const CustomChip: FC<CustomChipProps> = props => {
  const {
    labelChip,
    backgroundColor,
    borderColor,
    labelColor,
    isMapValues,
    mapValuesObject,
    size,
    sx,
    style,
    variant,
    onDelete,
    clickable,
  } = props;

  return (
    <Chip
      size={size}
      sx={sx}
      style={joinObjects(
        {
          backgroundColor: backgroundColor,
          width: "100%",
          height: "100%",
          borderColor: borderColor,
          borderWidth: "1px",
          borderStyle: "solid",
          color: labelColor,
        },
        style
      )}
      label={typeof labelChip === "object" && labelChip !== null ? labelChip?.value : labelChip}
      variant={variant}
      onDelete={onDelete}
      clickable={clickable}
    />
  );
};
