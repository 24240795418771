import { CircularProgress, FormControl, FormHelperText, TextField, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import axios from "axios";

import _ from "lodash";
import { FC, useEffect } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { BXEngine, KeysToComponentMap, isNativeComponent } from "src/BXEngine";
import { useBXContext, useValue } from "src/BXEngine/BXContext";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { ElementBaseProps } from "src/types/UIElement";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { getLastKeyFromObject } from "src/utils/generalUtils";
import { ActionButton, useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { BXInput, BXSwitch } from "../FormControls";
import { BXDatePicker } from "../FormControls/DatePicker";

type Props = {
  actions?: any[];
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
  };
  views: any;
  __data?: any;
  path?: string;
} & ElementBaseProps;

const Form: FC<Props> = props => {
  const {
    id = "",
    dataSource,
    selectedViewId,
    info,
    actions,
    views,
    __data = {},
    closeModal,
    parentIds = [],
    showModalHeader,
    setIsDirty,
    pageId,
    path,
  } = props;

  const localViews = views?.filter((view: any) => view.id != id);
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders();
  const { viewsState, currentApp, queriesStateGraphQL } = useBXContext();
  const { palette } = useTheme();

  const queryKeys = [
    `${pageId}-${info?.viewName}`,
    // selectedViewId
  ];

  const { data: apiData, isLoading: isFetching } = useValue({
    queryKeys,
    __data,
    viewId: id,
    pageId,
    limit: dataSource?.limit || 20,
    isUserInput: dataSource?.sourceType == "USER INPUT",
    dataEntry: dataSource?.dataEntry,
    options: {
      onSuccess: (data: any) => {
        if (dataSource?.sourceType == "API") {
          reset(replaceFormData(_.cloneDeep(code), data));
        }
      },
      enabled: dataSource?.sourceType == "API",
    },
  });

  const replaceFormData = (data: any, apiData: any) => {
    if (_.isObject(data) as any) {
      _.forOwn(data, (value, key) => {
        if (_.isArray(value)) {
          value?.forEach((item, index) => {
            if (_.isObject(item) as any) {
              replaceFormData(data[key][index], apiData);
            }
          });
        } else if (_.isObject(value)) {
          replaceFormData(data[key], apiData);
        } else {
          if (value?.[0] === "{" && value?.[value?.length - 1] === "}") {
            const newValue = value.substring(1, value.length - 1);
            const options = newValue.split(",");
            if (options[3] || options?.[5]) {
              const inputValue = options[3];
              data[key] =
                replaceDataPlaceholders({
                  queryString: inputValue,
                  item: dataSource?.dataEntry ? _.get(apiData, dataSource?.dataEntry as any) : apiData,
                  viewsState,
                  pageId,
                  __data,
                  env: currentApp?.env,
                }) ||
                replaceDataPlaceholders({
                  queryString: options?.[5],
                  item: dataSource?.dataEntry ? _.get(apiData, dataSource?.dataEntry as any) : apiData,
                  viewsState,
                  pageId,
                  __data,
                  env: currentApp?.env,
                });
            } else {
              data[key] = options?.[1] == "formTable" ? [] : options?.[1] === "keyValue" ? {} : "";
            }
          }
        }
      });
      return data;
    }
  };

  let code = {};
  try {
    code = JSON.parse(dataSource?.template);
  } catch (e) {}

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isDirty },
    control,
    setValue,
    getValues,
    reset,
  } = useForm<FieldValues>({
    defaultValues: replaceFormData(_.cloneDeep(code), apiData),
  });

  useCallbackPrompt(isDirty);

  // useEffect(() => {
  //   setIsDirty?.(isDirty);
  // }, [isDirty]);

  const columnSize = 12 / Number(dataSource?.columnCount) || 3;

  const {
    data: componentData,
    isFetching: componentDataFetching,
    isLoading,
  } = useQuery(
    [["components", id]],
    async ({ pageParam }) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_HOST_API_KEY}/api/component?ids=${dataSource?.templateComponentIds?.join(",")}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      const ids: any = {};
      data?.items?.forEach((item: any) => {
        ids[item?.name] = item;
      });
      return ids;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!dataSource?.templateComponentIds?.length,
    }
  );

  useEffect(() => {
    if (!isValid && isSubmitting && !_.isEmpty(errors)) {
      enqueueSnackbarRef?.("Validation Error", {
        variant: "error",
      });
    }
  }, [!isValid && isSubmitting]);

  useEffect(() => {
    reset(replaceFormData(_.cloneDeep(code), apiData));
  }, [isFetching, componentDataFetching]);

  const renderInputBaseOnType = ({ type, path, key, isRequired, viewName, inputLabel, elementValue, spanCol }: any) => {
    let input = <></>;

    if (KeysToComponentMap[type] || isNativeComponent(type)) {
      const view = localViews.find((view: any) => view?.info?.name == viewName) || {};

      input = (
        <BXEngine
          auth={{}}
          path={[path, viewName || key].join(".")}
          page={{ views, layout: view, id: pageId } as any}
          layout={[
            isNativeComponent(type)
              ? {
                  type,
                  children: [
                    replaceDataPlaceholders({
                      queryString: elementValue,
                      viewsState,
                      pageId,
                      __data,
                      env: currentApp?.env,
                    }) || elementValue,
                  ],
                }
              : { ...view, type },
          ]}
          uploadData={{
            inputName: inputLabel,
            inputValidationName: path + `[${key}]`,
            control,
            error: _.get(errors, path + `[${key}]`) ? { ..._.get(errors, path + `[${key}]`), message: "The Field is required" } : null,
            isRequired,
            key,
          }}
          inputLabel={inputLabel}
          isRequired={isRequired}
          control={control}
          inputValidationName={path + `[${key}]`}
          getValues={getValues}
          error={_.get(errors, path + `[${key}]`) ? { ..._.get(errors, path + `[${key}]`), message: "The Field is required" } : null}
          componentName={viewName}
          componentData={componentData?.[viewName]}
          isVisible
          __data={{
            ...__data,
            [(getLastKeyFromObject(__data) || "") + "#."]: info?.viewName,
          }}
          parentIds={[...parentIds, id]}
          closeModal={closeModal}
        />
      );

      return (
        <Grid item xs={type === "divider" || type === "keyValue" ? 12 : spanCol || columnSize}>
          {input}
        </Grid>
      );
    }

    switch (type) {
      case "string":
        input = (
          <PermissibleRender isAllowed action={["WRITE"]} path={[path, key].join(".")}>
            {({ permitted }) => {
              return (
                <Grid item xs={spanCol || columnSize}>
                  {/* @ts-ignore */}
                  <BXInput
                    required={isRequired}
                    name={path + `[${key}]`}
                    control={control}
                    error={
                      _.get(errors, path + `[${key}]`) ? { ..._.get(errors, path + `[${key}]`), message: "The Field is required" } : null
                    }
                    variant='outlined'
                    fullWidth
                    label={inputLabel || key}
                    disabled={!permitted}
                  />
                </Grid>
              );
            }}
          </PermissibleRender>
        );
        break;
      case "textarea":
        const error = _.get(errors, path + `[${key}]`) ? { ..._.get(errors, path + `[${key}]`), message: "The Field is required" } : null;

        input = (
          <PermissibleRender isAllowed action={["WRITE"]} path={[path, key].join(".")}>
            {({ permitted }) => {
              return (
                <Grid item xs={spanCol || columnSize}>
                  {/* @ts-ignore */}
                  <Controller
                    control={control}
                    name={path + `[${key}]`}
                    rules={{
                      required: isRequired,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <TextField
                          disabled={!permitted}
                          aria-label='minimum height'
                          minRows={2}
                          maxRows={5}
                          placeholder={inputLabel || key}
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            maxHeight: 300,
                            backgroundColor: palette.background.paper,
                            color: "#bdc8f0",
                            borderColor: Boolean(error) ? "red" : "rgb(189 200 240 / 20%)",
                            outline: Boolean(error) ? "red" : "rgb(189 200 240 / 20%)",
                          }}
                          multiline
                          aria-errormessage={"error message"}
                          onChange={e => {
                            onChange(e);
                          }}
                          id={id}
                          value={value || ""}
                        />
                        {Boolean(error) && <FormHelperText error>{error?.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
              );
            }}
          </PermissibleRender>
        );
        break;

      case "boolean":
        input = (
          <PermissibleRender isAllowed action={["WRITE"]} path={[path, key].join(".")}>
            {({ permitted }) => {
              return (
                <Grid item xs={spanCol || columnSize}>
                  <BXSwitch
                    disabled={!permitted}
                    required={isRequired}
                    name={path + `[${key}]`}
                    control={control}
                    error={
                      _.get(errors, path + `[${key}]`) ? { ..._.get(errors, path + `[${key}]`), message: "The Field is required" } : null
                    }
                    label={inputLabel || key}
                  />
                </Grid>
              );
            }}
          </PermissibleRender>
        );
        break;
      case "date":
        input = (
          <PermissibleRender isAllowed action={["WRITE"]} path={[path, key].join(".")}>
            {({ permitted }) => {
              return (
                <Grid item xs={spanCol || columnSize}>
                  <BXDatePicker
                    disabled={!permitted}
                    required={isRequired}
                    name={path + `[${key}]`}
                    control={control}
                    error={
                      _.get(errors, path + `[${key}]`) ? { ..._.get(errors, path + `[${key}]`), message: "The Field is required" } : null
                    }
                    variant='outlined'
                    fullWidth
                    label={inputLabel || key}
                  />
                </Grid>
              );
            }}
          </PermissibleRender>
        );
        break;
    }
    return input;
  };
  const generateForm = (template: any, path = "") => {
    let jsx = <></>;

    const setObjectValue = (name: any, value: any, path = "") => {
      const values = _.map(value, (oValue, oKey) => {
        if ((oValue?.[0] === "{" && oValue?.[oValue?.length - 1] === "}") || _.isObject(oValue)) {
          return true;
        } else {
          return false;
        }
      }).filter(Boolean);
      if (values.length == 0) {
        return;
      }
      jsx = (
        <>
          {jsx}
          <Grid
            item
            container
            border='0.5px solid white'
            spacing={2}
            margin={2}
            paddingY={1}
            paddingBottom={2}
            paddingInlineEnd={2}
            borderRadius={2.5}
          >
            <Grid item xs={12}>
              <Typography mb={1}>{name}</Typography>
            </Grid>
            {generateForm(value, path)}
          </Grid>
        </>
      );
    };

    if (_.isObject(template) as any) {
      _.forOwn(template, (value, key) => {
        if (_.isArray(value)) {
          value?.forEach((item, index) => {
            if (_.isObject(item) as any) {
              setObjectValue(`${key}[${index}]`, template[key][index], `${path ? path + `[${key}][${index}]` : `${key}[${index}]`}`);
            }
          });
        } else if (_.isObject(value)) {
          setObjectValue(`${key}`, template[key], `${path ? path + `[${key}]` : key}`);
        } else {
          if (value?.[0] === "{" && value?.[value?.length - 1] === "}") {
            const newValue = value.substring(1, value.length - 1);
            const options = newValue.split(",");

            const inputLabel = options?.[0];
            const type = options?.[1];
            const isRequired = options?.[2] == "required";
            const elementValue = options?.[3];

            const viewName = options?.[4];
            const spanCol = options?.[6];

            jsx = (
              <>
                {jsx}
                <PermissibleRender isAllowed action={["VIEW"]} path={[path, key].join(".")}>
                  {({ permitted }) =>
                    permitted && renderInputBaseOnType({ type, path, key, inputLabel, isRequired, viewName, elementValue, spanCol })
                  }
                </PermissibleRender>
              </>
            );
          }
        }
      });
      return jsx;
    }
  };

  if (isLoading) return <CircularProgress />;
  return (
    <Box marginY={1} paddingX={1}>
      <Card>
        {!showModalHeader && (
          <Grid container paddingX={2} mt={1} mb={1}>
            <Grid container xs={12}>
              <Typography flex={1} fontSize={"16px"} lineHeight={2}>
                {replaceDataPlaceholders({
                  queryString: info?.name,
                  viewsState,
                  pageId,
                  __data,
                  env: currentApp?.env,
                })}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid container padding={2} spacing={2}>
          {generateForm(_.cloneDeep(code))}
        </Grid>

        {!_.isEmpty(actions) && (
          <Grid container spacing={2} padding={1} mb={2} justifyContent='center' alignItems='center'>
            {actions?.map(action => (
              <PermissibleRender isAllowed action={["VIEW"]} path={[path, action?.label].join(".")}>
                {({ permitted }) => {
                  return (
                    permitted && (
                      <Grid item key={action?.id}>
                        <ActionButton
                          path={[path, action?.label].join(".")}
                          viewName={info?.viewName}
                          pageId={pageId}
                          queryKeys={queryKeys}
                          item={_.get(apiData, dataSource?.dataEntry as any) || {}}
                          handleSubmit={handleSubmit}
                          key={action.id}
                          tableId={id}
                          tableAction={{
                            action: action,
                            label: action?.label,
                            icon: action?.icon,
                            condition: action?.condition,
                          }}
                          views={views}
                          iconButton={false}
                          formData={getValues}
                          __data={__data}
                          closeModal={closeModal}
                          parentIds={parentIds}
                        />
                      </Grid>
                    )
                  );
                }}
              </PermissibleRender>
            ))}
          </Grid>
        )}
      </Card>
    </Box>
  );
};

export default Form;
