// third-party
import { Link } from "react-router-dom";
// import moment from 'moment';

// material-ui
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import useTheme from "@mui/styles/useTheme";

// import EditIcon from '@mui/icons-material/Edit';

// Tabler
// import { IconEye } from '@tabler/icons-react';

// project imports
import { IconCode } from "@tabler/icons-react";
import classNames from "classnames";
import _ from "lodash";
import { Fragment, useState } from "react";
import ReactJson from "react-json-view";
import { useMutation } from "react-query";
import { BXEngine } from "src/BXEngine";
import { queryClient, replaceBaseUrl, useBXContext } from "src/BXEngine/BXContext";
import Avatar from "src/components/extended/Avatar";
import { gridSpacing } from "src/stores/constant";
import { PowerPack } from "src/types/powerPack";
import { UIElement } from "src/types/UIElement";
import axios from "src/utils/axios";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { getAuthorizationHeader, getLastKeyFromObject } from "src/utils/generalUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { BXConfirmationDialog } from "../BXUI/AlertDialog/ConfirmationDialog";
import { formatJSON, useReplaceDataPlaceholders } from "../BXUI/DataTable/ActionButton";
import CreatePayload from "../BXUI/DataTable/CreatePayload";
import { BXIcon } from "../BXUI/Icon";
import BXModal from "../BXUI/Modal";
import { ViewerModal } from "../BXUI/viewerModal";
// assets
// const assetImages = require.context('assets/images', true);
// const defaultCover: string = assetImages('./not_available_grey.png').default;

// style card
const useStyles = makeStyles((theme: Theme) => ({
  followerBlock: {
    padding: "0px",
    // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    border: theme.palette.mode === "dark" ? "1px solid transparent" : `1px solid${theme.palette.grey[100]}`,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  primaryLight: {
    color: theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.grey[500],
    cursor: "pointer",
  },
  btnProfile: {
    width: "100%",
    paddingInlineStart: "10px",
    paddingInlineEnd: "10px",
    border: 0,
    borderRadius: 0,
    borderBottom: "2.5px solid",
    "&:hover ": {
      color: "#fff",
    },
    "&.MuiButton-outlinedPrimary:hover ": {
      background: theme.palette.primary.main,
    },
    "&.MuiButton-outlinedSecondary": {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    "&.MuiButton-outlinedSecondary:hover ": {
      background: theme.palette.error.main,
      color: "#fff",
    },
  },
  btnActionProfile: {
    flex: "1 1 auto",
    margin: "5px",
    borderRadius: "4px",
    color: theme.palette.text.primary,
  },
  btnActionProfileSelected: {
    backgroundColor: theme.palette?.text?.primary + " !important",
    color: theme.palette?.primary?.main,
  },
  videoMedia: {
    height: "330px",
    // border: `0.5px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.common.black}`,
    [theme.breakpoints.down("lg")]: {
      height: "220px",
    },
  },
  creatorImage: {
    width: 40,
    height: 40,
    marginInlineEnd: 10,
    float: "left",
  },
  bgPrimary: {
    background: theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.background.paper,
  },
  elliptSingleLine: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  assetId: {
    fontSize: "0.65rem",
  },
}));

// Power Pack Card Interfaces

interface PowerPacksCardProps extends PowerPack {
  handleView?: (id: string) => void;
  handleMint?: (id: string) => void;
  handleDelete?: (id: string) => void;
  handleFeatured?: (id: string, value: boolean) => void;
  handleFlagged?: (id: string, value: boolean) => void;
  caption?: string;
  originalUrl?: string;
  dataString?: string;
  createdOn?: number;
  creator?: any;
  index?: number;
  stats?: any;
  actions?: any[];
  userInputsValues?: { [inputName: string]: any };
  isUserInput?: boolean;
  views: any;
  onSelectRow: any;
  gridId: any;
  coreData: any;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  viewType?: string;
  src?: string;
  prevId?: string;
  gridKey?: string;
  playOnHover?: boolean;
  dataSource?: any;
  pageId?: string;
  queryKeys?: any[];
  viewName?: string;
  path?: string;
}

export const PowerPackDetailsCard = (item: PowerPacksCardProps) => {
  const {
    index: cardIndex,
    cover,
    highUri,
    id = "",
    lowUri,
    gridKey,
    actions,
    userInputsValues,
    isUserInput,
    views,
    onSelectRow,
    playOnHover,
    gridId,
    coreData,
    viewType,
    prevId,
    dataSource,
    __data = {},
    closeModal,
    parentIds = [],
    pageId,
    queryKeys = [],
    viewName,
    path,
  } = item;

  const theme: any = useTheme();
  const classes = useStyles();

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const { currentApp, currentProfileId, viewsState, setViewStacks, checkPermissions } = useBXContext();
  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders();
  const filteredActions = path
    ? actions?.filter(action => checkPermissions([{ path: [path, action.label].join("."), action: "WRITE" }], true))
    : actions || [];

  const playVideo = (event: React.MouseEvent<HTMLVideoElement>) => {
    const vid: HTMLMediaElement = event.target as HTMLMediaElement;
    if (vid) vid.play();
  };

  const pauseVideo = (event: React.MouseEvent<HTMLVideoElement>) => {
    const vid: HTMLMediaElement = event.target as HTMLMediaElement;
    if (vid) vid.pause();
  };

  const { originalUrl, stats, creator, caption, prettyTime } = !gridKey ? coreData : _.get(coreData, gridKey) || {};
  const src = typeof coreData != "object" ? coreData : viewType == "image-grid" ? _.get(coreData, gridKey || "") : originalUrl;

  const renderCoverCard = (lowAssetUri: string | undefined, highAssetUri: string | undefined, covers: string[] | undefined) => (
    <CardMedia
      onMouseEnter={viewType == "card-list" && playOnHover ? playVideo : undefined}
      onMouseLeave={viewType == "card-list" && playOnHover ? pauseVideo : undefined}
      sx={{ border: `0px`, backgroundSize: "contain", objectFit: "contain" }}
      className={classes.videoMedia}
      component={viewType == "card-list" ? "video" : "img"}
      src={src}
      image={src}
      controls={viewType == "card-list"}
    />
  );

  const onAction = (variables: any) => {
    const { method, body, headers, source, isDismissibleView } = variables || {};
    const token = localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);
    const url = replaceBaseUrl(source, currentApp);
    return axios.request({
      url: replaceDataPlaceholders({
        queryString: url,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
      }),
      method: method?.toLowerCase?.(),
      headers: {
        ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
        ...replaceDataPlaceholdersRecursively({
          obj: headers,
          viewsState,
          pageId,
          item,
          __data,
          env: currentApp?.env,
        }),
      },
      data:
        body &&
        replaceDataPlaceholdersRecursively({
          obj: JSON.parse(body || "{}"),
          viewsState,
          pageId,
          item,
          __data,
          env: currentApp?.env,
        }),
    });
  };

  const { mutate, isLoading } = useMutation(onAction, {
    onSuccess: async (action: any, variables, context) => {
      const statusMessage = variables?.statusMessages?.find((item: any) => item?.key == action?.request?.status)?.value;
      enqueueSnackbarRef?.(
        replaceDataPlaceholders({
          queryString: statusMessage,
          item,
          viewsState,
          pageId,
          __data,
          actionResponse: action?.data,
          env: currentApp?.env,
        }) || "Posted Successfully",
        {
          variant: "success",
        }
      );

      if (variables?.isDismissibleView != "No") {
        closeModal?.();
      }
      queryClient.refetchQueries(queryKeys);
      if (parentIds) {
        parentIds.forEach((id: any) => queryClient.refetchQueries([id]));
      }
      const { method } = action || {};
      // success: remove item from data
      if (method?.toLowerCase() === "delete") {
      } else if (method?.toLowerCase() === "post") {
        // update record because it is an item action
      } else if (method?.toLowerCase() === "put") {
        // update record because it is an item action
      } else if (method?.toLowerCase() === "patch") {
        // update record because it is an item action
      }
    },
    onError: (error: any, variables) => {
      const statusMessage = variables?.statusMessages?.find((item: any) => item?.key == error?.requestStatusCode)?.value;

      enqueueSnackbarRef?.(
        replaceDataPlaceholders({
          queryString: statusMessage,
          item,
          viewsState,
          pageId,
          __data,
          actionResponse: error,
          env: currentApp?.env,
        }) ||
          error?.response?.data?.error ||
          JSON.parse(error?.response?.config?.data || "{}")?.errorMessage ||
          "Wrong Services",
        {
          variant: "error",
        }
      );
      // setError(error || "Something went wrong");
    },

    mutationKey: id,
  });

  const handleActionClick = (action: any) => {
    if (action?.dialog?.enabled && !openConfirmation) setOpenConfirmation(true);
    else {
      mutate(action);
    }
  };

  return (
    <Card style={{ backgroundColor: theme.palette.background }} className={classes.followerBlock} key={id} sx={{ boxShadow: 7 }}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sx={{ "&.MuiGrid-root": { pt: 2 } }}>
          {renderCoverCard(lowUri, highUri, cover)}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} paddingX={1.5} pb={1}>
            {viewType == "card-list" && (
              <>
                <Grid item>
                  <Avatar
                    alt={creator?.name}
                    src={
                      replaceDataPlaceholders({
                        queryString: dataSource?.videoUserImage,
                        item: coreData,
                        viewsState,
                        pageId,
                        __data,
                        env: currentApp?.env,
                      }) || creator?.profilePhoto
                    }
                  />
                </Grid>
                <Grid item xs sx={{ overflow: "hidden" }}>
                  <Typography
                    className={classes.elliptSingleLine}
                    variant='caption'
                    component='div'
                    sx={{ color: theme.palette.grey[700], minHeight: 20 }}
                  >
                    {replaceDataPlaceholders({
                      queryString: dataSource?.videoTitle,
                      item: coreData,
                      viewsState,
                      pageId,
                      __data,
                      env: currentApp?.env,
                    }) || creator?.name}
                  </Typography>
                  <Typography variant='caption' color='primary' fontWeight={600} component={Link} to={`/user/edit/${creator?.id}`}>
                    @
                    {replaceDataPlaceholders({
                      queryString: dataSource?.videoUsername,
                      item: coreData,
                      viewsState,
                      pageId,
                      __data,
                      env: currentApp?.env,
                    }) || creator?.handle}
                  </Typography>
                  <br />
                  <Tooltip title={caption || ""}>
                    <Typography
                      height={20}
                      variant='subtitle2'
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                    >
                      {replaceDataPlaceholders({
                        queryString: dataSource?.videoSubTitle,
                        item: coreData,
                        viewsState,
                        pageId,
                        __data,
                        env: currentApp?.env,
                      }) || caption}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <BXModal icon={<IconCode />} title='Api Mode' maxWidth='lg' withoutLabel>
                    {(handleClose: any) => {
                      return (
                        <>
                          <ReactJson src={coreData as any} theme={"paraiso"} style={{ width: "100%" }} />
                        </>
                      );
                    }}
                  </BXModal>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3} textAlign='center'>
                      <Typography variant='h6'>{stats?.playCount || 0}</Typography>
                      <Typography variant='caption'>Views</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign='center'>
                      <Typography variant='h6'>{stats?.commentCount || 0}</Typography>
                      <Typography variant='caption'>Comments</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign='center'>
                      <Typography variant='h6'>{stats?.likeCount || 0}</Typography>
                      <Typography variant='caption'>Likes</Typography>
                    </Grid>
                    <Grid item xs={3} textAlign='center'>
                      <Typography variant='h6'>{prettyTime || 0}</Typography>
                      <Typography variant='caption'>Age</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container flexWrap='wrap' justifyContent='space-between' marginInlineStart='-5px' width='calc(100% + 5px)'>
                {filteredActions?.map((action, index) => {
                  const { actionSourceType, showModal, modalSize, actionViewType } = action;
                  const isAPI = _.isNaN(actionSourceType?.type) || actionSourceType?.type == "API";
                  const isDownload = actionSourceType?.type == "Download";
                  const selectedView = views?.find((view: any) => view?.id == actionSourceType?.id);
                  const isStack = actionViewType == "Stack";

                  const data = replaceDataPlaceholders({
                    queryString: action?.condition,
                    item: item,
                    viewsState,
                    pageId,
                    __data,
                    env: currentApp?.env,
                  });

                  const highlightedValue = replaceDataPlaceholders({
                    queryString: action?.highlightedValue,
                    item,
                    viewsState,
                    pageId,
                    __data,
                    env: currentApp?.env,
                  });

                  if ((!eval(data) && !_.isEmpty(data)) || action.isGlobal) return <></>;

                  if (isAPI) {
                    return (
                      <Fragment key={`${action?.id}-${index}`}>
                        {action?.dialog?.enabled && (
                          <BXConfirmationDialog
                            open={openConfirmation}
                            title={action?.dialog?.message || "Are you sure?"}
                            iconButton
                            onConfirm={() => {
                              handleActionClick(action);
                              setOpenConfirmation(false);
                            }}
                            onCancel={() => {
                              setOpenConfirmation(false);
                            }}
                          />
                        )}
                        {!showModal || showModal == "No" ? (
                          <Tooltip placement='bottom' title={action?.label}>
                            <IconButton
                              className={classNames(classes.btnActionProfile, {
                                [classes.btnActionProfileSelected]: eval(highlightedValue),
                              })}
                              aria-label={action?.label}
                              data-id={id}
                              onClick={() => handleActionClick(action)}
                            >
                              <BXIcon
                                icon={
                                  (action?.iconConfig?.visibility === "PUBLIC" &&
                                  action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                    ? action?.iconConfig?.url
                                    : action?.iconConfig?.icon) || action?.icon
                                }
                                width={20}
                                height={20}
                                color={"currentColor"}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <BXModal
                            label={action.label}
                            icon={
                              <BXIcon
                                icon={
                                  (action?.iconConfig?.visibility === "PUBLIC" &&
                                  action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                    ? action?.iconConfig?.url
                                    : action?.iconConfig?.icon) || action?.icon
                                }
                                width={20}
                                height={20}
                                color={"currentColor"}
                              />
                            }
                            buttonProps={{
                              variant: "contained",
                              startIcon: (
                                <BXIcon
                                  icon={
                                    (action?.iconConfig?.visibility === "PUBLIC" &&
                                    action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                      ? action?.iconConfig?.url
                                      : action?.iconConfig?.icon) || action?.icon
                                  }
                                  width={20}
                                  height={20}
                                  color={"currentColor"}
                                />
                              ),
                              className: classNames(classes.btnActionProfile, {
                                [classes.btnActionProfileSelected]: eval(highlightedValue),
                              }),
                            }}
                            title={action.label}
                            withoutLabel={true}
                          >
                            {(handleClose: Function) => {
                              return (
                                <CreatePayload
                                  payload={{
                                    body: formatJSON(
                                      JSON.stringify(
                                        replaceDataPlaceholdersRecursively({
                                          obj: JSON.parse(action?.body || "{}"),
                                          viewsState,
                                          pageId,
                                          item,
                                          __data,
                                          env: currentApp?.env,
                                        })
                                      )
                                    ),
                                    headers: replaceDataPlaceholdersRecursively({
                                      obj: { ...action?.headers },
                                      viewsState,
                                      pageId,
                                      item,
                                      __data,
                                      env: currentApp?.env,
                                    }),
                                  }}
                                  onSave={(payload: any) => {
                                    handleActionClick({ ...action, ...payload });
                                    handleClose?.();
                                  }}
                                  label={action?.label}
                                  onClose={() => handleClose()}
                                  isLoading={isLoading}
                                />
                              );
                            }}
                          </BXModal>
                        )}
                      </Fragment>
                    );
                  } else if (isDownload) {
                    return (
                      <Tooltip key={action?.id} placement='bottom' title={action?.label}>
                        <IconButton
                          className={classes.btnActionProfile}
                          aria-label={action?.label}
                          data-id={id}
                          onClick={async () => {
                            const data =
                              replaceDataPlaceholders({
                                queryString: action.source,
                                item,
                                viewsState,
                                pageId,
                                __data,
                                env: currentApp?.env,
                              }) || "";

                            const token = localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);

                            let _data = "";
                            if (action?.downloadType === "api") {
                              const { data } = await axios.get(
                                replaceDataPlaceholders({
                                  queryString: action?.directUrl,
                                  item,
                                  viewsState,
                                  pageId,
                                  __data,
                                  env: currentApp?.env,
                                }),
                                {
                                  headers: {
                                    ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
                                  },
                                }
                              );
                              _data = data?.[action?.downloadKey];
                            }

                            const url = !action.source ? (item?.dataString ? item?.dataString : "") : data;

                            fetch(_data || url)
                              .then(response => response.blob())
                              .then(blob => {
                                const url = URL.createObjectURL(blob);
                                if (action.openImage) {
                                  return window.open(url, "_blank");
                                }
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", blob.type);
                                document.body.appendChild(link);
                                link.click();
                              });
                          }}
                        >
                          <BXIcon
                            icon={
                              (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                ? action?.iconConfig?.url
                                : action?.iconConfig?.icon) || action?.icon
                            }
                            width={20}
                            height={20}
                            color={"currentColor"}
                          />
                        </IconButton>
                      </Tooltip>
                    );
                  } else if (isStack) {
                    return (
                      <Tooltip key={action?.id} title={action?.label}>
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            onSelectRow?.(item, true);
                            setViewStacks(
                              prev =>
                                [
                                  ...prev,
                                  <BXEngine
                                    auth={{}}
                                    path={[path, action?.label].join(".")}
                                    page={{ views, layout: selectedView, id: pageId } as any}
                                    layout={[{ ...(selectedView as any), type: actionSourceType?.type }]}
                                    isVisible
                                    selectedViewId={item.id}
                                    __data={{
                                      ...__data,
                                      [(getLastKeyFromObject(__data) || "") + "#."]: viewName,
                                    }}
                                    index={cardIndex}
                                    closeModal={closeModal}
                                    parentIds={[...parentIds, gridId]}
                                    prevVideoCardId={prevId}
                                  />,
                                ] as UIElement[]
                            );
                          }}
                          aria-label={action?.label}
                          data-id={id}
                          className={classNames(classes.btnActionProfile)}
                        >
                          <BXIcon
                            icon={
                              (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                ? action?.iconConfig?.url
                                : action?.iconConfig?.icon) || action?.icon
                            }
                            width={20}
                            height={20}
                            color={"currentColor"}
                          />
                        </IconButton>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <Fragment key={action?.id}>
                        <ViewerModal
                          icon={
                            <BXIcon
                              icon={
                                (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                  ? action?.iconConfig?.url
                                  : action?.iconConfig?.icon) || action?.icon
                              }
                              width={20}
                              height={20}
                              color={"currentColor"}
                            />
                          }
                          action={action}
                          viewName={viewName}
                          pageId={pageId}
                          actionSourceType={actionSourceType}
                          item={item}
                          index={cardIndex}
                          showHeader={false}
                          selectedView={selectedView}
                          onSelectRow={onSelectRow}
                          views={views}
                          isUserInput={isUserInput}
                          buttonProps={{
                            variant: "",
                            className: classes.btnActionProfile,
                            "aria-label": action?.label,
                          }}
                          withoutLabel
                          modalSize={modalSize}
                          __data={__data}
                          id={gridId}
                          prevVideoCardId={prevId}
                          parentIds={parentIds}
                        />
                      </Fragment>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
