/* eslint-disable no-lone-blocks */
import { FC } from "react";
import { useAppState } from "src/features/appState/hooks";
import { generateKeyDescriptor } from "src/utils/generalUtils";
import { ComponentItemType, StepperComponentState } from "src/views/pages/BuildX/FormBuilder/types";
import { stepperGroupComponentState } from "src/views/pages/BuildX/FormBuilder/utils";
import { RenderChildren } from "./RenderChildren";
import { RepeatedItemsComponent } from "./RepeatedItemsComponent";

export const triggerActionableComponent = async (event, pageId, viewName, key, viewsState) => {
  const componentRef = viewsState?.[`${pageId}-${viewName}`]?.refs?.[key];
  await componentRef?.current?.handleActionMapper(event, viewsState);
};

export const handleKeyDown = (event, element, actionHandlerMapper, pageId, viewName, viewsState) => {
  const keyDescriptor = generateKeyDescriptor(event);
  const onKeyPressTriggers = element.interactionConfig?.filter(
    interaction => interaction.type === "OnKeyPress" && interaction?.key && interaction?.key === keyDescriptor
  );

  onKeyPressTriggers?.forEach((trigger, index) => {
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState);
    } else {
      const actionHandler = actionHandlerMapper();
      actionHandler?.();
    }
  });
};

export const handleChange = (event, element, onChange, actionHandlerMapper, pageId, viewName, viewsState) => {
  if (!element?.config?.isGroup) {
    onChange(event);
  }

  const onChangeTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnChange");

  onChangeTriggers?.forEach((trigger, index) => {
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState);
    } else {
      const actionHandler = actionHandlerMapper();
      actionHandler?.();
    }
  });
};

export const handleClick = (event, element, pageId, viewName, viewsState) => {
  const onClickTriggers = element.interactionConfig?.filter(interaction => interaction.type === "OnClick");
  onClickTriggers?.forEach((trigger, index) => {
    if (trigger?.triggerSelf === false) {
      triggerActionableComponent(event, pageId, viewName, trigger?.actionableKey, viewsState);
    }
  });
};

export const getControllerNameFallback = (element: any) => {
  switch (element?.type) {
    case ComponentItemType.CustomTabs: {
      return element?.config?.parent?.key;
    }
    default: {
      return "";
    }
  }
};

export const RenderItems: FC<any> = props => {
  const { data, pageId, __data, info, item, currentApp, viewsState, multiLingual, index: itemIndex, referenceStepperGroups } = props;
  const { watch } = useAppState();
  const key = `${pageId}.${info?.viewName}._BX_stepperGroups`;

  return (
    <>
      {!!data?.length &&
        data?.map((element: any, index: number) => {
          if (element?.config?.repeated?.enabled) {
            return (
              <RepeatedItemsComponent
                key={element?.id + "-" + index + "-" + itemIndex}
                element={element}
                item={item}
                viewsState={viewsState}
                pageId={pageId}
                __data={__data}
                props={props}
                currentApp={currentApp}
                multiLingual={multiLingual}
                referenceStepperGroups={referenceStepperGroups}
                viewName={info?.viewName}
              />
            );
          }
          const componentStepperState = stepperGroupComponentState(element?.props?.key, watch(key));

          const hiddenByStepper = componentStepperState === StepperComponentState.FoundButNotSelected;

          return (
            <RenderChildren
              key={element?.id + "-" + index}
              element={element}
              index={itemIndex}
              hiddenByStepper={hiddenByStepper}
              {...props}
            />
          );
        })}
    </>
  );
};
