import { Box, Grid, Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { IconCaretDownFilled, IconDotsVertical } from "@tabler/icons-react";
import { getHoverDirection } from "@udecode/slate-plugins";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { mergeRefs } from "react-merge-refs";
import ActionPopover from "./ActionPopover";
import { ComponentsTreeItemProps } from "./TreeTypes";

const componentsTreeItemStyles = {
  container: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
    margin: "4px 0",
    padding: "5px",
    cursor: "pointer",
    opacity: 1,
    border: "none",
    transition: "background-color 0.3s ease",
  },
};

const ComponentsTreeItem: React.FC<ComponentsTreeItemProps> = ({
  componentItem,
  moveItem,
  handleDeleteItem,
  activeComponent,
  handleCopyItems,
  children,
  handlePasteItems,
  view,
  setView,
  isCanvasClicked,
  selectedItemsId,
  setSelectedItemsId,
  setIsCanvasClicked,
  handleSelectComponent,
  handleSelectComponentSingleAndMulti,
  isOpen,
  handleToggleOpen,
  openStates,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [dropLine, setDropLine] = useState("");
  const [contextMenuPosition, setContextMenuPosition] = useState<{ top: number; left: number } | null>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const rootRef = useRef(null);
  const isSelected = selectedItemsId?.length > 0 ? selectedItemsId?.includes(componentItem.id) : componentItem?.id === activeComponent?.id;
  const isActive = activeComponent?.id === componentItem.id;
  const greedyComponent =
    componentItem?.type === "FlexContainer" || componentItem?.type === "GridContainer" || componentItem?.type === "CustomContainer";
  const showEmptyMessage = greedyComponent && (!componentItem?.children || componentItem?.children?.length === 0);
  const [{ isDragging }, drag] = useDrag<any, void, { isDragging: boolean }>({
    type: "Component",
    item: { id: componentItem.id, type: componentItem.type, selectedItemsId },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop<any, void, { isOver: boolean; isOverCurrent: boolean; canDrop: boolean }>({
    accept: "Component",
    drop(item, monitor) {
      const direction = getHoverDirection(item, monitor, rootRef, componentItem.id);
      const draggedIds = item.selectedItemsId?.length ? item.selectedItemsId : [item.id];

      if (
        greedyComponent &&
        item.id !== componentItem.id &&
        !isOpen &&
        (direction || componentItem?.children?.length === 0) &&
        dropLine === "middle"
      ) {
        moveItem(draggedIds, componentItem.id, "middle");
      } else if (item.id !== componentItem.id && (direction || componentItem?.children?.length === 0)) {
        moveItem(draggedIds, componentItem.id, direction);
      }
    },
    hover(item, monitor) {
      if (!rootRef.current) {
        return;
      }
      const hoverBoundingRect = (rootRef as any).current.getBoundingClientRect();
      const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
      const buffer = hoverHeight * 0.2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (hoverClientY < buffer) {
        setDropLine("top");
      } else if (hoverClientY > hoverHeight - buffer) {
        setDropLine("bottom");
      } else {
        setDropLine("middle");
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (componentItem: any) => (event: React.MouseEvent) => {
    const isMultiSelectKey = event?.shiftKey || event?.ctrlKey || event?.metaKey;

    if (!isMultiSelectKey) {
      handleSelectComponent(componentItem);
      return;
    } else {
      handleSelectComponentSingleAndMulti(componentItem, event);
    }
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenuPosition({ top: event.clientY, left: event.clientX });
    setAnchorEl(event.currentTarget);
  };

  const handleAccordionToggle = () => {
    handleToggleOpen(componentItem.id);
  };

  const isCurrentDropTarget = isOverCurrent && dropLine;
  let backgroundColor = `${theme.palette.background.paper}`;
  if (isOverCurrent || (isOver && greedyComponent)) {
    backgroundColor = `${theme.palette.primary.main}`;
  }
  useEffect(() => {
    if (isActive && rootRef.current && view?.dataSource?.formBuilderConfig?.isSyncTreeWithCanvasEnabled) {
      (rootRef as any).current.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
    }
  }, [isActive, activeComponent]);

  useEffect(() => {
    if (isSelected && isCanvasClicked) {
      setSelectedItemsId([componentItem.id]);
      setIsCanvasClicked(false);
    }
  }, [isSelected, isCanvasClicked]);

  return (
    <div
      ref={mergeRefs([rootRef, drag, drop])}
      style={{
        position: "relative",
        marginBottom: 4,
      }}
    >
      <Grid
        container
        xs={12}
        alignItems='center'
        sx={{
          ...componentsTreeItemStyles.container,
          maxHeight: "42px",
          width: "100%",
          "&:hover": {
            backgroundColor: !isSelected ? "rgba(210, 215, 231, 0.25)" : "",
          },
          backgroundColor: isSelected ? theme.palette.primary.light : "rgba(210, 215, 231, 0.10)",
          border:
            isCurrentDropTarget && canDrop && greedyComponent && dropLine === "middle" && !isOpen
              ? `2px solid ${theme.palette.primary.main}`
              : "none",
        }}
        onClick={handleClick(componentItem)}
        onContextMenu={handleContextMenu}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {(componentItem?.type === "FlexContainer" ||
          componentItem?.type === "GridContainer" ||
          componentItem?.type === "CustomContainer") && (
          <Grid
            item
            xs={1}
            style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", cursor: "pointer" }}
            onClick={event => {
              event.stopPropagation();
              handleAccordionToggle();
            }}
          >
            <IconCaretDownFilled
              style={{
                color: theme?.palette?.primary[200],
                transform: isOpen ? "rotate(360deg)" : "rotate(270deg)",
                transition: "transform 0.3s ease",
              }}
            />
          </Grid>
        )}

        <Grid item xs={10}>
          <Stack direction='row' spacing={0} alignItems='center'>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.default,
                  border: `1px solid ${theme.palette.primary[200]}`,
                  borderRadius: ".8vh",
                  width: "40px",
                  height: "28px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginInlineEnd: "8px",
                }}
              >
                <img src={componentItem?.config?.placeholderConfig?.image} alt='' />
              </Box>
            </Grid>
            <Typography
              sx={{
                color: theme?.palette?.primary[200],
                whiteSpace: "nowrap",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              variant='subtitle1'
            >
              {componentItem?.props?.key}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            onClick={handleContextMenu}
            sx={{
              cursor: "pointer",
              display: isHovered ? "block" : "none",
            }}
          >
            <IconDotsVertical size={20} style={{ color: theme?.palette?.primary[200] }} />
          </Box>
        </Grid>
      </Grid>
      {isOpen && showEmptyMessage && (
        <Box
          sx={{
            paddingLeft: "30px",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
        >
          <Typography sx={{ fontSize: "10px", color: theme?.palette?.primary[200] }}>This Container is empty.</Typography>
        </Box>
      )}
      {isOpen && componentItem?.children && (
        <Box sx={{ paddingLeft: "16px" }}>
          {componentItem.children.map((child: any, childIndex: number) => (
            <ComponentsTreeItem
              key={childIndex}
              componentItem={child}
              handleDeleteItem={handleDeleteItem}
              setSelectedItemsId={setSelectedItemsId}
              activeComponent={activeComponent}
              handleCopyItems={handleCopyItems}
              handleSelectComponentSingleAndMulti={handleSelectComponentSingleAndMulti}
              handlePasteItems={handlePasteItems}
              view={view}
              setView={setView}
              selectedItemsId={selectedItemsId}
              moveItem={moveItem}
              handleSelectComponent={handleSelectComponent}
              openStates={openStates}
              isOpen={openStates[child.id]}
              handleToggleOpen={handleToggleOpen}
            />
          ))}
        </Box>
      )}
      <ActionPopover
        open={open}
        onClose={handleClose}
        handleDeleteItem={handleDeleteItem}
        handleCopyItems={handleCopyItems}
        handlePasteItems={handlePasteItems}
        view={view}
        children={children}
        activeComponent={activeComponent}
        selectedItemsId={selectedItemsId}
        contextMenuPosition={contextMenuPosition}
        setView={setView}
      />

      {isCurrentDropTarget && canDrop && (dropLine === "top" || dropLine === "bottom") && (
        <div
          style={{
            backgroundColor,
            width: "100%",
            height: 3,
            position: "absolute",
            [dropLine]: 0,
            left: 0,
          }}
        />
      )}
    </div>
  );
};

export default ComponentsTreeItem;
