import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export let validationRegistry: Record<string, { [key: string]: (value: any) => true | string }> = {};

// Register a validation function for a specific field
export const registerValidation = (name: string, validate: { [key: string]: (value: any) => true | string }) => {
  validationRegistry[name] = validate;
};
interface AppState {
  values: Record<string, any>;
  errors: Record<string, string>;
  dirtyFields: Record<string, boolean>;
  initialValues: Record<string, any>;
  loading: Record<string, any>; //Track loading views and pages state
}

const initialState: AppState = {
  values: {},
  errors: {},
  dirtyFields: {},
  initialValues: {},
  loading: {}
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    initializeAppField: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      setNestedValue(state.values, name, value);
      setNestedValue(state.initialValues, name, value);
      setNestedValue(state.dirtyFields, name, false);
      deleteNestedValue(state.errors, name);
    },
    setAppValue: (state, action: PayloadAction<{ name: string; value: any; isDisabledDirtyField?: boolean }>) => {
      const { name, value, isDisabledDirtyField } = action.payload;
      setNestedValue(state.values, name, value);

      if (!isDisabledDirtyField) {
        const isDirty = value !== getNestedValue(state.initialValues, name, "");

        if (isDirty) {
          setNestedValue(state.dirtyFields, name, isDirty);
        } else {
          deleteNestedValue(state.dirtyFields, name);
        }
      }

      deleteNestedValue(state.errors, name);
    },
    setAppError: (state, action: PayloadAction<{ name: string; error: string }>) => {
      const { name, error } = action.payload;
      if (error) {
        setNestedValue(state.errors, name, error);
      } else {
        deleteNestedValue(state.errors, name); // Remove the error property if no error
      }
    },
    removeAppField: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;

      deleteNestedValue(state.values, name);
      deleteNestedValue(state.initialValues, name);
      deleteNestedValue(state.dirtyFields, name);
      deleteNestedValue(state.errors, name);
      // TODO: we need also to remove validations for this field
      validationRegistry = {};
    },
    resetAppState: (state, action: PayloadAction<{ name?: string }>) => {
      const { name } = action.payload;

      if (name) {
        // Reset a specific field if name is provided
        const initialValue = getNestedValue(state.initialValues, name);
        setNestedValue(state.values, name, initialValue);
        deleteNestedValue(state.errors, name);
        deleteNestedValue(state.dirtyFields, name);
      } else {
        // Reset the entire state
        state.values = { ...state.initialValues };
        state.errors = {};
        state.dirtyFields = {};
      }
    },
    //Set and reset the loading state for a specific page or view by key (name).
    setLoading: (state, action: PayloadAction<{ keys?: any}>) => {
      const { keys } = action.payload;
    
      if (keys) {
        if (keys.page) {
          state.loading[keys.page] = true;
        }
        if (keys.view) {
          state.loading[keys.view] = true;
        }
      }
    },
    resetLoading: (state, action: PayloadAction<{ keys?: any}>) => {
      const { keys } = action.payload;
    
      if (keys) {
        if (keys.page) {
          state.loading[keys.page] = false;
        }
        if (keys.view) {
          state.loading[keys.view] = false;
        }
      }
    },
    removeDirtyFlags: (state, action: PayloadAction<{ name?: string }>) => {
      const { name } = action.payload;
      if (name) {
        deleteNestedValue(state.dirtyFields, name);
      } else {
        state.dirtyFields = {};
      }
    },
    
  }
});


export const { setAppValue, setAppError, initializeAppField, removeAppField, resetAppState, setLoading, resetLoading, removeDirtyFlags } = appStateSlice.actions;
export default appStateSlice.reducer;

// Helper functions to set/get nested values
function setNestedValue(object: any, path: string, value: any) {
  const keys = path.replace(/\[(\w+)\]/g, ".$1").split(".");
  let current = object;

  keys.slice(0, -1).forEach(key => {
    if (!current[key]) {
      current[key] = {}; // Create object if not exist
    }
    current = current[key];
  });

  current[keys[keys.length - 1]] = value;
}

function getNestedValue(object: any, path: string, defaultValue: any = undefined) {
  const keys = path.replace(/\[(\w+)\]/g, ".$1").split(".");
  let current = object;

  for (let key of keys) {
    if (current[key] === undefined) {
      return defaultValue;
    }
    current = current[key];
  }

  return current;
}

function deleteNestedValue(object: any, path: string) {
  const keys = path.replace(/\[(\w+)\]/g, ".$1").split(".");
  let current = object;
  let parents: {
    parent: any;
    key: string;
  }[] = [];

  // Traverse to the desired nested object
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (current[key] === undefined) {
      return; // Exit if the path doesn't exist
    }
    parents.push({ parent: current, key });
    current = current[key];
  }

  const finalKey = keys[keys.length - 1];
  delete current[finalKey]; // Delete the specific error property

  // Clean up empty parent objects
  for (let i = parents.length - 1; i >= 0; i--) {
    const { parent, key } = parents[i];
    if (Object.keys(parent[key]).length === 0) {
      delete parent[key];
    } else {
      break; // Stop if we find a non-empty parent
    }
  }
}
