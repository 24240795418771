export const appColor = {
  // paper & background
  paper: "#1B1F24",
  background: "#121418",

  textPrimary: "#d7dcec",

  // primary
  primaryLight: "#1877F233",
  primaryMain: "#1877F2",
  lightGrey: "#303038",
  primaryDark: "#091f3c",
  primary200: "#8591a1",
  primary800: "#05152b",

  // secondary
  secondaryLight: "#e6f4f1",
  secondaryMain: "#2ca58d",
  secondaryDark: "#279d85",
  secondary200: "#96d2c6",
  secondary800: "#1b8a70",

  // success Colors
  successLight: "#b9f6ca",
  success200: "#69f0ae",
  successMain: "#00e676",
  successDark: "#00c853",

  // error
  errorLight: "#ef9a9a",
  errorMain: "#f44336",
  errorDark: "#c62828",

  // orange
  orangeLight: "#fbe9e7",
  orangeMain: "#ffab91",
  orangeDark: "#d84315",

  // warning
  warningLight: "#fff8e1",
  warningMain: "#ffe57f",
  warningDark: "#ffc107",

  // grey
  grey50: "#fafafa",
  grey100: "#f5f5f5",
  grey200: "#eeeeee",
  grey300: "#e0e0e0",
  grey500: "#9e9e9e",
  grey600: "#757575",
  grey700: "#616161",
  grey900: "#212121",

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkBackground: "#1B1F24", // level 3
  darkPaper: "#121418", // level 4

  // dark 800 & 900
  darkLevel1: "#071a33", // level 1
  darkLevel2: "#121418", // level 2

  // primary dark
  darkPrimaryLight: "#e2e5e8",
  darkPrimaryMain: "#54657b",
  darkPrimaryDark: "#2f445e",
  darkPrimary200: "#8591a1",
  darkPrimary800: "#05152b",

  // secondary dark
  darkSecondaryLight: "#e6f4f1",
  darkSecondaryMain: "#2ca58d",
  darkSecondaryDark: "#279d85",
  darkSecondary200: "#96d2c6",
  darkSecondary800: "#1b8a70",
};
