import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// material-ui
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

// project imports
import config from "src/config";
import { gridSpacing } from "src/stores/constant";

// assets
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import HomeIcon from "@mui/icons-material/Home";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import { RouteObject } from "react-router";
import { NavItemType, NavItemTypeObject, OverrideIcon } from "src/types";

// style constant
const useStyles = makeStyles((theme: any) => ({
  link: {
    display: "flex",
    color: theme.palette.grey[900],
    textDecoration: "none",
    alignContent: "center",
    alignItems: "center",
  },
  activeLink: {
    display: "flex",
    textDecoration: "none",
    alignContent: "center",
    alignItems: "center",
    color: theme.palette.grey[500],
  },
  icon: {
    marginInlineEnd: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: "1rem",
    height: "1rem",
    color: theme.palette.secondary.main,
  },
  separator: {
    width: 16,
    marginInlineStart: 8,
    marginInlineEnd: 8,
  },
  content: {
    padding: "16px !important",
  },
  noPadding: {
    padding: "16px !important",
    paddingInlineStart: "0 !important",
  },
  card: {
    marginBottom: theme.spacing(gridSpacing),
    border: "1px solid",
    borderColor: theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.primary[200] + 75,
  },
  root: {
    background: "transparent",
    boxShadow: "none",
    border: "none",
  },
  titleTop: {
    marginBottom: theme.spacing(1),
  },
  titleBottom: {
    marginTop: theme.spacing(1),
  },
  divider: {
    borderColor: theme.palette.primary.main,
    marginBottom: theme.spacing(gridSpacing),
  },
}));

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends React.CSSProperties {
  mb?: string;
  bgcolor?: string;
}

export interface BreadCrumbsProps {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  navigation?: RouteObject[];
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

const Breadcrumbs: FC<BreadCrumbsProps> = ({
  card,
  divider,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  ...others
}) => {
  const classes = useStyles();
  const [main, setMain] = useState<NavItemType | undefined>();
  const [item, setItem] = useState<NavItemType>();

  useEffect(() => {
    navigation?.map((menu: any, index: number) => {
      if (menu?.type && menu?.type === "group") {
        getCollapse(menu as { children: NavItemType[]; type?: string });
      }
      return false;
    });
  });

  // set active item state

  const getCollapse = (menu: NavItemTypeObject) => {
    if (menu.children) {
      menu.children.filter((collapse: NavItemType) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse as { children: NavItemType[]; type?: string });
        } else if (collapse.type && collapse.type === "item") {
          if (document.location.pathname === config.basename + collapse.url) {
            setMain(menu);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  // item separator
  const SeparatorIcon = separator!;
  const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size='1rem' /> : "/";

  // card class
  let cardClass = classes.card;
  if (card === false) {
    cardClass = classes.root;
  }

  // card content class
  let contentClass = classes.content;
  if (card === false) {
    contentClass = classes.noPadding;
  }

  let mainContent;
  let itemContent;
  let breadcrumbContent: React.ReactElement = <Typography />;
  let itemTitle: NavItemType["title"] = "";
  let CollapseIcon;
  let ItemIcon;

  // collapse item
  if (main && main.type === "collapse") {
    CollapseIcon = main.icon ? main.icon : AccountTreeTwoToneIcon;
    mainContent = (
      <Typography component={Link} to='#' variant='subtitle1' className={classes.link}>
        {icons && <CollapseIcon className={classes.icon} />}
        {main.title}
      </Typography>
    );
  }

  // items
  if (item && item.type === "item") {
    itemTitle = item.title;

    ItemIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;
    itemContent = (
      <Typography variant='subtitle1' className={classes.activeLink}>
        {icons && <ItemIcon className={classes.icon} />}
        {itemTitle}
      </Typography>
    );

    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <Card className={cardClass} {...others}>
          <CardContent className={contentClass}>
            <Grid
              container
              direction={rightAlign ? "row" : "column"}
              justifyContent={rightAlign ? "space-between" : "flex-start"}
              alignItems={rightAlign ? "center" : "flex-start"}
              spacing={1}
            >
              {title && !titleBottom && (
                <Grid item>
                  <Typography variant='h3' sx={{ fontWeight: 500 }}>
                    {item.title}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <MuiBreadcrumbs
                  classes={{ separator: classes.separator }}
                  aria-label='breadcrumb'
                  maxItems={maxItems || 8}
                  separator={separatorIcon}
                >
                  <Typography component={Link} to='/' color='inherit' variant='subtitle1' className={classes.link}>
                    {icons && <HomeTwoToneIcon className={classes.icon} />}
                    {icon && <HomeIcon className={classes.icon} style={{ marginInlineEnd: 0 }} />}
                    {!icon && "Dashboard"}
                  </Typography>
                  {mainContent}
                  {itemContent}
                </MuiBreadcrumbs>
              </Grid>
              {title && titleBottom && (
                <Grid item>
                  <Typography variant='h3' sx={{ fontWeight: 500 }}>
                    {item.title}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
          {card === false && divider !== false && <Divider className={classes.divider} />}
        </Card>
      );
    }
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
