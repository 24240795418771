import ErrorIcon from "@mui/icons-material/Error";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";

interface CSVProgressDialogProps {
  open: boolean;
  handleClose: () => void;
  jsonData: any[];
}
const CSVProgressDialog: React.FC<CSVProgressDialogProps> = ({ open, handleClose, jsonData }) => {
  const firstRow = jsonData[0];
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedErrorRow, setSelectedErrorRow] = useState<any | null>(null);
  const [isProcessingDone, setIsProcessingDone] = useState(false); // Add this state

  useEffect(() => {
    const isDone = jsonData.length === 0 || jsonData.every(row => row.status !== "Pending");
    setIsProcessingDone(isDone);
  }, [jsonData]);

  if (!firstRow) {
    return null;
  }

  const columnNames = Object.keys(firstRow);

  const handleIconClick = (row: any) => {
    setSelectedErrorRow(row);
    setIsDialogOpen(true);
  };

  const handleCancelClick = () => {
    setIsConfirmOpen(true);
  };

  const handleConfirmCancel = () => {
    setIsConfirmOpen(false);
    handleClose();
  };

  const handleCancelConfirmationClose = () => {
    setIsConfirmOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} gap={2}>
      <Dialog open={open} onClose={handleCancelConfirmationClose} maxWidth='xl' fullWidth>
        {" "}
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>{"CSV Processing Progress"}</DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <DialogContentText id='csv-progress-dialog-description'>
            {isProcessingDone ? "Processing Completed" : "Showing the progress of CSV file processing..."}
          </DialogContentText>
          <TableContainer component={Paper}>
            <Table aria-label='CSV Processing Progress Table'>
              <TableHead>
                <TableRow>
                  {columnNames.map(
                    columnName =>
                      columnName !== "__id" &&
                      columnName !== "__error" && (
                        <TableCell key={columnName} style={{ padding: "1vh 4vh" }}>
                          {columnName}
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {jsonData.map((row, index) => (
                  <TableRow key={index}>
                    {columnNames.map(
                      columnName =>
                        columnName !== "__id" &&
                        columnName !== "__error" && (
                          <TableCell key={columnName} style={{ padding: "1vh 4vh" }}>
                            {columnName === "status" ? (
                              row.status === "Pending" ? (
                                <CircularProgress size={20} />
                              ) : (
                                <Box
                                  sx={{
                                    color: row.status === "Success" ? "green" : "red",
                                    display: "flex",
                                    flexDirection: "columns",
                                    alignItems: "center",
                                    // justifyContent: "center",
                                  }}
                                >
                                  {row[columnName]}
                                  {row.status === "Error" ? (
                                    <button
                                      onClick={() => handleIconClick(row)}
                                      style={{ background: "none", border: "none", cursor: "pointer" }}
                                    >
                                      <ErrorIcon
                                        style={{ verticalAlign: "middle", marginInlineEnd: "4px", color: "red" }}
                                        fontSize={"small"}
                                      />
                                    </button>
                                  ) : null}
                                </Box>
                              )
                            ) : (
                              row[columnName]
                            )}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isConfirmOpen} maxWidth='sm' fullWidth onClose={handleCancelConfirmationClose}>
        <DialogContent>
          <DialogContentText>{"Are you sure you want to cancel?"}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmationClose}>Cancel</Button>
          <Button onClick={handleConfirmCancel} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth='md' fullWidth>
        <DialogTitle>Error Details</DialogTitle>
        <DialogContent>
          <ReactJson src={selectedErrorRow?.__error} theme='paraiso' />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CSVProgressDialog;
